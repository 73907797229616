import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Utils
import Utils from '../resources/Utils'
import { editAmount } from '../actions/actions'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      height: '90%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  modalTitle: {
    fontWeight: 600
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  }
})

class AmountModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amount: 0,
      openSnack: false,
      errorMessage: ''
    }

    this.handleRender = this.handleRender.bind(this)
    this.handleChangeAmount = this.handleChangeAmount.bind(this)
    this.getMinAmountToPay = this.getMinAmountToPay.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.go = this.go.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleChangeAmount(event) {
    if (event.target.value.length > 64 || !Utils.isDouble(event.target.value))
      return
    this.setState({amount: event.target.value})
  }

  getMinAmountToPay(newAmount, originalAmount) {
    // Si puede hacer pagos parciales (abonos)
    if (this.props.init.config.data.partialPayment) {
      // Revisar el porcentaje mínimo a pagar por cuenta
      let minAmoutPerAccount = (originalAmount * (this.props.init.config.data.percentageAmountPerAccount / 100))
      if (minAmoutPerAccount < originalAmount) {
        if (minAmoutPerAccount < this.props.init.config.data.minAmmountPartialPayment) {
          return Number(this.props.init.config.data.minAmmountPartialPayment)
        }
        else {
          return Number(minAmoutPerAccount)
        }
      }
      else {
        if (minAmoutPerAccount < this.props.init.config.data.minAmmountPartialPayment) {
          return Number(this.props.init.config.data.minAmmountPartialPayment)
        }
        else {
          return Number(minAmoutPerAccount)
        }
      }
    }
    else {
      return Number(originalAmount)
    }
  }

  handleConfirm() {
    let amount = Number(this.state.amount)
    let oldAmount = Number(this.state.oldAmount)
    let minAmount = this.getMinAmountToPay(amount, oldAmount)
    minAmount = minAmount.toFixed(2)
    let maxAmount = Number(this.props.init.config.data.maxAmountPartialPayment)

    // Si el monto es superior al adeudo y no se puede saldar a favor
    if (amount > oldAmount && !this.props.init.config.data.positiveBalance) {
      this.setState({
        openSnack: true,
        errorMessage: Utils.getMessages().ConfirmPayment.notPositiveBalance
      })
    }
    // Si el monto no cubre lo minimo a pagar
    else if (amount < minAmount) {
      this.setState({
        openSnack: true,
        errorMessage: Utils.getMessages().AmountModal.validationMinAmount + '' + Utils.numberWithCommas(minAmount) + ' MXN'
      })
    }
    // No se pueden hacer pagos de $0.00 MXN
    else if (amount <= 0) {
      this.setState({
        openSnack: true,
        errorMessage: Utils.getMessages().ConfirmPayment.amountValidation
      })
    }
    else if (amount > maxAmount) {
      this.setState({
        openSnack: true,
        errorMessage: Utils.getMessages().AmountModal.validationMaxAmount + '' + Utils.numberWithCommas(maxAmount.toFixed(2)) + ' MXN'
      })
    }
    else {
      this.go(amount)
    }
  }

  go(amount) {
    this.props.editAmount({
      accountNumber: this.props.account.account,
      amount: amount
    })
    this.handleCloseSnackbar()
    this.props.handleConfirm()
  }


  handleClose() {
    this.handleCloseSnackbar()
    this.props.handleClose()
  }

  handleCloseSnackbar() {
    this.setState({errorMessage: '', openSnack: false})
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleConfirm()
    }
  }

  handleRender() {
    if (Utils.isUserLoggedIn()) {
      let oldValue = Number(this.props.account.amount)
      let idx = this.props.account.idx
      if (!Utils.isEmpty(this.props.init.accountsForPay[idx].oldValue)) {
        oldValue = Number(this.props.init.accountsForPay[idx].oldValue)
      }
      this.setState({
        oldAmount: oldValue,
        amount: Number(this.props.account.amount)
      })
    }
    else {
      let oldValue = Number(this.props.account.amount)
      if (!Utils.isEmpty(this.props.init.account.data.oldValue)) {
        oldValue = Number(this.props.init.account.data.oldValue)
      }
      this.setState({
        oldAmount: oldValue,
        amount: Number(this.props.account.amount)
      })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
        onRendered={this.handleRender}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
            {Utils.getMessages().AmountModal.title}
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
            {Utils.getMessages().AmountModal.description} <strong>{this.props.account.account}</strong>
          </Typography>
          <form noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().AmountModal.placeholder}
              type="text"
              value={this.state.amount}
              onChange={this.handleChangeAmount}
              autoFocus
            />
            <div className={classes.actions}>
              <Button
                  onClick={this.handleClose}
                >
                {Utils.getMessages().AmountModal.cancelButton}
              </Button>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={this.handleConfirm}
              >
                {Utils.getMessages().AmountModal.confirmButton}
              </Button>
            </div>
          </form>
          <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={this.state.openSnack}
            onClose={this.handleCloseSnackbar}
            message={
              <span>{this.state.errorMessage}</span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    editAmount: (data) => {
      dispatch(editAmount(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AmountModal)
