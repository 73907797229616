import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Slide, withTheme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    contenido:{
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class FullScreenModal extends Component {

    constructor(props){
        super(props)

    }
 
    render() {

        const { classes, open }  = this.props;

        return (
            <Dialog fullScreen open={open} onClose={this.props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        { this.props.titulo }
                        </Typography>
                        {
                            this.props.buttons
                        }
                    </Toolbar>
                </AppBar>
                <div className={classes.contenido}>
                {
                    this.props.contenido
                }
                </div>
                
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = () => {
  return {

  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(FullScreenModal)

