import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Components
import CardTable from '../components/CardTable'
import Title from '../components/Title'
import Breadcrumb from '../components/Breadcrumb'
import Stepper from '../components/Stepper'
import PaymentModal from '../components/PaymentModalBanWire'
import Empty from '../components/Empty'

// Utils
import Utils from '../resources/Utils'
import cardsEmptyImg from '../resources/images/cards-empty.svg'
import { requestCardsByUser, addPaymentMethod } from '../actions/actions'

const styles = theme => ({
  cardTable: {
    marginTop: 16
  },
  stepper: {
    marginTop: 32,
    float: 'right'
  }
})

class CardList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
      openSnack: false,
      showCards: false,
      checkboxes: []
    }

    // Modal handlers
    this.handlePaymentModal = this.handlePaymentModal.bind(this)
    this.handleConfirmModal = this.handleConfirmModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)

    // Others
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.handleStepperNext = this.handleStepperNext.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      if (Utils.isEmpty(this.props.init.accountsForPay)) {
        this.props.history.push("/cuentas")
      }
      else {
        let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER)).data.id
        this.props.requestCardsByUser(userId)
      }
    }
    else {
      this.props.history.push("/")
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.user.cards !== this.props.init.user.cards) {
      if (!Utils.isEmpty(this.props.init.user.cards.data)) {
        if (this.props.init.user.cards.data.length > 0) {
          let checkboxes = []
          this.props.init.user.cards.data.forEach(function(card, idx) {
            checkboxes.push(false)
          })

          this.setState({
            showCards: true,
            checkboxes: checkboxes
          })
        }
      }
    }
  }

  // Modal handlers

  handlePaymentModal() {
    this.setState({openModal: true})
  }

  handleConfirmModal() {
    this.setState({openModal: false})
    this.props.history.push("/pago")
  }

  handleCloseModal() {
    this.setState({openModal: false})
  }

  // Others

  handleStepperNext() {
    let checkboxes = false
    let checkboxSelected = 0
    this.state.checkboxes.forEach(function(value, idx) {
      if (value) {
        checkboxSelected = idx
        checkboxes = true
      }
    })

    if (!checkboxes) {
      this.setState({
        openSnack: true,
        message: Utils.getMessages().Cards.errorSelectCard
      })
    }
    else {
      let cardData = this.props.init.user.cards.data[checkboxSelected]
      let userData = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))

      let paymentData = {
        userId: this.props.init.userId,
        save: false,
        id: cardData.id,
        type: cardData.type,
        number: cardData.number,
        month: cardData.month,
        year: cardData.year,
        email: userData.data.email,
        titular: cardData.titular,
        alias: cardData.alias,
        token: cardData.token || ''
      }
      this.props.addPaymentMethod(paymentData)
      this.props.history.push("/confirmacion")
    }
  }

  handleCheckboxClick(name, event) {
    let position = Number(name.split('-')[1])
    let checkboxes = this.state.checkboxes

    checkboxes.forEach(function(checkbox, idx) {
      checkboxes[idx] = false
    })
    checkboxes[position] = event.target.checked

    this.setState({
      checkboxes: checkboxes
    })
  }

  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  renderWithCards(classes) {
    return (
      <div>
        <Title 
          title={Utils.getMessages().Cards.title}
          description={Utils.getMessages().Cards.description}
        />
        <Breadcrumb
          breadcrumbs={[
              {
                name: Utils.getMessages().Cards.breadcrumb1,
                path: '/cuentas'
              },
              {
                name: Utils.getMessages().Cards.breadcrumbCurrent,
                path: this.props.match.url
              }
            ]}
        />
        <CardTable 
          className={classes.cardTable}
          checkboxChecked={this.state.checkboxes}
          onClick={this.handleCheckboxClick}
          cards={this.props.init.user.cards.data}
        />
        <Stepper
          className={classes.stepper}
          back={{
            name: Utils.getMessages().Cards.addNewCardButton
          }}
          next={{
            name: Utils.getMessages().Cards.continueButton
          }}
          onClickBack={this.handlePaymentModal}
          onClickNext={this.handleStepperNext}
        />

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.message}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <PaymentModal
          open={this.state.openModal}
          handleConfirm={this.handleConfirmModal}
          handleClose={this.handleCloseModal}
        />
      </div>
    )
  }

  render() {
    
    const { classes } = this.props
    
    if (this.state.showCards) {
      return this.renderWithCards(classes)
    }
    else {
      return (
        <div>
          
          <Empty
            title={Utils.getMessages().Cards.titleEmpty}
            description={Utils.getMessages().Cards.descriptionEmpty}
            emptyImg={cardsEmptyImg}
            buttonTitle={Utils.getMessages().Cards.addCardButton}
            callToAction={() => {this.handlePaymentModal()}}
          />
          <PaymentModal
            open={this.state.openModal}
            handleConfirm={this.handleConfirmModal}
            handleClose={this.handleCloseModal}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestCardsByUser: (userId) => {
      dispatch(requestCardsByUser(userId))
    },
    addPaymentMethod: (data) => {
      dispatch(addPaymentMethod(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CardList)
