import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import Utils from '../resources/Utils'
import Title from '../components/Title'

// Components

// Utils

const styles = theme => ({
  container: {
    width: '40%',
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
      width: '100%'
    }
  }
})


class BBVAPaymentFailure extends Component {
  constructor(props) {
    super(props)

    this.state = {
      
    }


    this.handleExitOk = this.handleExitOk.bind(this)
  }

  componentDidMount(prevProps){
    console.log("pros",this.props.location.state);
    console.log("prevProps", prevProps);
  }

  handleExitOk(){
    if (Utils.isUserLoggedIn()) {
      this.props.history.push("/cuentas")
    }
    else {      
      if(this.props.match.params.accountNumber){
        const account = this.props.match.params.accountNumber
        this.props.history.push("/cuentas/" + account)
      } else {
        this.props.history.push("/")
      }      
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.center}>
        <div className={classes.container}>

          <Title 
            title={Utils.getMessages().ConfirmPayment.error}
            description={Utils.getMessages().ConfirmPayment.errorPayment}
          />
          < br></br>
          <div className={classes.actions}>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={this.handleExitOk}
              >
                {Utils.getMessages().Accounts.exitButton}
              </Button>
            </div>

        </div>
      </div>
    )
    
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
   
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(BBVAPaymentFailure)
