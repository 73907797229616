import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import LoadingBar from 'react-redux-loading-bar'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import { Grid, AppBar, Toolbar, Typography, Hidden } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'

// Utils
import Utils from '../resources/Utils'

const styles = theme => ({
  appBar: {
    backgroundColor: theme.palette.background.main,
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    float: 'left',
    marginRight: '2%',
    height: 'auto',
    width: theme.sizes.sizeNavbarLogo
  },
  navbarTextItem: {
    padding: 0,
    margin: 0,
  },
  mainTitle: {
    float: 'left',
    margin: 0,
    padding: 0,
    fontWeight: 600,
    marginTop: 3,
    [theme.breakpoints.down('sm')]: {
      marginTop: 6,
      fontSize: 16
    }
  },
  userContainer: {
    textAlign: 'right',
    margin: 0,
    padding: 0
  },
  userIcon: {
    float: 'right',
    padding: 0,
    margin: 0,
    marginTop: 4,
    marginLeft: 12,
    [theme.breakpoints.down('xs')]: {
      opacity: 0,
      heigth: 0,
    }
  },
  userData: {
    margin: 0,
    padding: 0,
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    }
  },
  version: {
    margin: 0,
    padding: 0,
    fontSize: 10
  },
  versionNotSession: {
    fontWeight: 600,
    fontSize: 12
  },
  menuButton: {
    margin: 0,
    marginRight: 12
  },
  loading: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    height: 10
  }
})

class Navbar extends Component {
  getUserEmail() {
    if (Utils.isUserLoggedIn()) {
      let user = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
      return user.data.email
    }
    return ''
  }

  render() {
    const { classes } = this.props

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {
            (false) ?

            <IconButton className={classes.menuButton} color="primary">
              <MenuIcon />
            </IconButton>
            
            :

            ''

          }
          <Grid container justify="flex-start" alignItems="center">
            <Grid item lg={9} md={8} sm={6} xs={6} className={classes.navbarTextItem}>
              <a href='https://jumapam.gob.mx/' rel='noopener noreferrer'><img className={classes.logo} alt="" src={this.props.mainLogo} /></a>
              <Typography className={classes.mainTitle} variant="h6" color="primary">
                {this.props.mainTitle}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={6}>
              {
                (Utils.isUserLoggedIn()) ?
                <div className={classes.userContainer}>
                  <Hidden smDown>
                    <IconButton className={classes.userIcon} color="primary">
                      <AccountCircle />
                    </IconButton>
                  </Hidden>
                  <Typography className={classes.userData} variant="body1" color="primary">
                    <strong>{this.getUserEmail()}</strong>
                  </Typography>
                  <Typography className={classes.version} variant="body1" color="primary">
                    v{Utils.constants.version}
                  </Typography>
                </div>
                :
                <div className={classes.userContainer}>
                  <Typography className={classes.versionNotSession} variant="body1" color="primary">
                    v{Utils.constants.version}
                  </Typography>
                </div>
              }
            </Grid>
          </Grid>
        </Toolbar>
        <LoadingBar className={classes.loading} />
      </AppBar>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, null)
)(Navbar)
