import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

// Utils
import Utils from '../resources/Utils'
import GeneradorForms from './GeneradorForms'
import StepperCustom from './StepperCustom'
import Map from './Map'
import { Typography } from '@material-ui/core'
import { requestActualizarImagenUsuario, requestGiros, requestGuardarImagenDireccion, requestGuardarPersonaDireccion, requestImagenesUsuario } from '../actions/actions'

const styles = (theme) => ({
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: "OpenSans-Regular"
    },
})

class DatosContratoDireccion extends Component {
	constructor(props) {
		super(props)

		this.state = {
            tabState: this.props.tabState,
            stepperState: this.props.stepperState,
			generadorFormsDireccionState: this.props.datosDireccion,
            stateTipoUsuario: this.props.stateTipoUsuario,
            stateDataMap: this.props.stateDataMap ,
            formFilesDireccionstate: this.props.datosFilesDireccion ,
            ubicacion: {
                lat: 0,
                lng: 0,
            },
            datosGuardados:{},
            estadoArchivos:[],

		}

        this.handleSetState = this.handleSetState.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.procesoSubirImgs = this.procesoSubirImgs.bind(this)
	}


    componentDidMount(){
        let self = this
        navigator.geolocation.getCurrentPosition(function(position) {
            self.setState({
                ubicacion: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
            })
        });
        this.props.requestGiros()
    }

    componentDidUpdate(prevProps) {
		if (Utils.isUserLoggedIn()) {
            if (prevProps.init.giros !== this.props.init.giros) {
				const { data, status } = this.props.init.giros
				if(status === Utils.constants.status.ok){
                    const { Data, IsValid } = data             
                    if(IsValid){
                        if(Array.isArray(Data)){                     
                            this.setState({
                                giros: Data.map( (v) => { return { label: v.descripcionGiro, value: v.giroID, color: "black"} } )
                            })  
                        } 
                    }		
				}  
			}
			if(prevProps.init.guardarPersonaDireccionTramite !== this.props.init.guardarPersonaDireccionTramite){
                const { isValid, data } = this.props.init.guardarPersonaDireccionTramite
                const { datosGuardados } = this.state
                if(isValid){
                    if(data > 0){

                        datosGuardados.idPersonaDireccion = data
                        this.setState({ loading: true, datosGuardados: datosGuardados })
                        this.guardarImagenes(data)
                       
                    }             
                }else {
                   
                }
            }

            if(prevProps.init.actualizarImagenUsuario !== this.props.init.actualizarImagenUsuario){
                const { isValid, message, data } = this.props.init.actualizarImagenUsuario
                if(isValid){
                    this.procesoSubirImgs(data)	
                }else {
					this.setState({ loading: false} )
					this.props.handleOpenSnackbar({message: message})
				}
            }

            if (prevProps.init.guardarImagenDireccion !== this.props.init.guardarImagenDireccion) {
                const { isValid, message, data } = this.props.init.guardarImagenDireccion
				if(isValid){
					this.procesoSubirImgs(data)	
				} else {
					this.setState({ loading: false} )
					this.props.handleOpenSnackbar({message: message})
				}
			}
		}
	}

	getLabelDireccion(){
        return {
            id: "Identificador",
            sucursal: "Sucursal",
			ciudad: "Ciudad",
			colonia: "Colonia",
			cumunidad: "Comunidad",
            calle: "Calle",
			entreCalle1: "Entre calle",
            entreCalle2: "y Calle",
            numeroExt: "No. Ext",
            numeroInt: "No. Int",
            codigoPostal: "Código postal",
            manzana: "Manzana",
            lote: "Lote",
            nivel: "Nivel",
            fraccion: "Fracción",
            numeroTomas: "Número de tomas",
            numeroCatastral: "Número catastral",
            sector: "Sector",
            cuenta: "Cuenta",
            direccionCompleta: "Dirección completa",
        }
    }

	getOriginParamsDireccion(){
        return [
            {
                type: "text",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "select",
                actions: { 
                    create: true, read: true
                },
                name: "ciudad",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "select",
                actions: { 
                    create: true, read: true
                },
                name: "colonia",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "select",
                actions: { 
                    create: true, read: true
                },
                name: "cumunidad",
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "select",
                actions: { 
                    create: true, read: true
                },
                name: "calle",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "select",
                actions: { 
                    create: true, read: true
                },
                name: "entreCalle1",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "select",
                actions: { 
                    create: true, read: true
                },
                name: "entreCalle2",
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "numeroExt",
                required: true,
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "numeroInt",
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "number",
                actions: { 
                    create: true, read: true
                },
                name: "codigoPostal",
                required: true,
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:4,
					lg:2,
					xl:1
                }
            },{
                type: "number",
                actions: { 
                    create: true, read: true
                },
                name: "manzana",
                required: true,
                tooltip:{
                    titulo: "Manzana",
                    description: "Lo puedes obtener de la clave catastral"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "number",
                actions: { 
                    create: true, read: true
                },
                name: "lote",
                required: true,
                tooltip:{
                    titulo: "Lote",
                    descripcion: "Lo puedes obtener de la clave catastral"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "number",
                actions: { 
                    create: true, read: true
                },
                name: "nivel",
                tooltip:{
                    titulo: "Nivel",
                    descripcion: "Lo puedes obtener de la clave catastral"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "fraccion",
                tooltip:{
                    titulo: "Fracción",
                    descripcion: "Lo puedes obtener de la clave catastral"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "number",
                actions: { 
                    create: true, read: true
                },
                name: "numeroTomas",
                tooltip:{
                    titulo: "Número de tomas",
                    descripcion: "La cantidad de tomas que quiere contratar"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:4,
					lg:2,
					xl:1
                }
            },{
                type: "number",
                actions: { 
                    create: true, read: true
                },
                name: "numeroCatastral",
                required: true,
                tooltip:{
                    titulo: "Número catastral",
                    descripcion: "Lo puedes obtener en el recibo del predial"
                },
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:2,
					xl:1
                }
            },{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "direccionCompleta",
                required: true,
                filter: true,
                grid:{
					xs:12,
                }
            },
        ]
    }

    getLabelFilesDireccion(){
        return {
            id: "Identificador",
            evidenciaDomicilio: "Evidencia domicilio",
        }
    }

	getOriginParamsFilesDireccion(){
        return [
            {
                type: "text",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "fileupload",
                actions: { 
                    create: true, read: true
                },
                name: "evidenciaDomicilio",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:12,
					lg:12,
					xl:12
                }
            },
        ]
    }

    handleSetState(){
        this.setState({})
        this.props.handleSetState()
    }

    procesoSubirImgs(data){
        let { estadoArchivos, personaId } = this.state
        let archivosNoSincronizados = estadoArchivos.filter( x => x.sincronizado === false )
        const indexArchivoSincronizado = estadoArchivos.findIndex( x => x.tipoImagenDireccionId === parseInt(data.tipoImagenPersonaId))
        const { datosGuardados } = this.state
        if(indexArchivoSincronizado > -1){
            estadoArchivos[indexArchivoSincronizado].sincronizado = true
            this.setState({ estadoArchivos: estadoArchivos })
            archivosNoSincronizados = estadoArchivos.filter( x => x.sincronizado === false )
            if(archivosNoSincronizados.length > 0) {
                this.setState({ loading: true, messageLoading: this.getMessageImagenLoading(parseInt(archivosNoSincronizados[0].tipoImagenDireccionId)) })
                this.guardarImagen({ 
                    imagen: archivosNoSincronizados[0].file.file, 
                    tipoImagenDireccionId: archivosNoSincronizados[0].tipoImagenDireccionId,
                    personaId: personaId,
                    id: archivosNoSincronizados[0].file.id,
                })
            } else {
                this.props.handleOpenSnackbar({message: "Datos e Imagenes guardados correctamente..", variant: 'success' })
                this.props.handleSaved(datosGuardados)
            }
        } else {
            this.setState({ loading: false} )
            this.props.handleOpenSnackbar({message: "Datos e Imagenes guardados correctamente..", variant: 'success' })
            this.props.handleSaved(datosGuardados)
        }
    }

    validarDato(dato, origin, label){

        const element = document.getElementById(origin.name)
        if(origin.required){
            switch (origin.type) {
                case "text":
                    if(dato){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }
                case "number":
                    if(dato){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }
                case "date":
                    if(dato){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }
                case "select":
                    if(dato){
                        if(dato.value){
                            return true
                        } else {
                            this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                            element.focus()
                            return false
                        }
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }
                   
                case "email":
                    if(Utils.validateEmail(dato)){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message:'Correo con formato incorrecto.'})
                        element.focus()
                        return false
                    }

                case "fileupload":
                
                    if(dato){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }

                
                    
                default:
                    return false
            }
        } else {
            return true
        }
    }   

    guardarDatos(){

		const { 
			generadorFormsDireccionState, 
			stateDataMap, 
		} = this.state

        const {
            persona
        } = this.props

        const { codigoPostal, direccionCompleta, fraccion, lote, manzana, nivel, numeroCatastral, numeroExt, numeroInt, numeroTomas, selectedcalle, selectedciudad, selectedcolonia, selectedcumunidad, selectedentreCalle1, selectedentreCalle2 } = generadorFormsDireccionState.form
		const { currentMarkerClicked } = stateDataMap							

		let	dataDireccion = {}
        
        dataDireccion = {
            personaId: persona.id ,
			latitud: currentMarkerClicked.lat,
			longitud: currentMarkerClicked.lng,
			ciudad: selectedciudad ? selectedciudad.label : '',
			colonia: selectedcolonia ? selectedcolonia.label : '',
			comunidad: selectedcumunidad ? selectedcumunidad.label : '',
			calle: selectedcalle ? selectedcalle.label : '',
			entreCalle1: selectedentreCalle1 ? selectedentreCalle1.label : '',
			entreCalle2: selectedentreCalle2 ? selectedentreCalle2.label : '',
			noExt: numeroExt,
			noInt: numeroInt,
			cp: codigoPostal,
			manzana: manzana,
			lote: lote,
			nivel: nivel,
			fraccion: fraccion,
			numeroTomas: numeroTomas,
			numeroCatastral: numeroCatastral,
			direccionCompleta : direccionCompleta,
		}
        this.setState({datosGuardados: {
			personaDireccion: dataDireccion,
		}})
		this.props.requestGuardarPersonaDireccion({
			personaDireccion: dataDireccion,
		})
	}

	guardarImagenes(personaDireccionId){
		const { 
			formFilesDireccionstate,
            generadorFormsDireccionState,
            datosGuardados
		} = this.state

		
		const { evidenciaDomicilio } = formFilesDireccionstate.form
		const files = [
            { file: evidenciaDomicilio, sincronizado: false,  tipoImagenDireccionId: 1},
        ]

        if(generadorFormsDireccionState.form){
            if(Array.isArray(generadorFormsDireccionState.form.imagenes)){
                for (let index = 0; index < files.length; index++) {
                    const element = files[index];
                    if(!element.file.file){
                        files[index].sincronizado = true          
                    }
                }
            }
        }
        const filesNoSincronizados =  files.filter( x => x.sincronizado === false)

        console.log(filesNoSincronizados)
        if(filesNoSincronizados.length > 0){
            this.setState({ 
                estadoArchivos: files, 
                loading: true,
                messageLoading: this.getMessageImagenLoading(filesNoSincronizados[0].tipoImagenDireccionId),
                personaDireccionId: personaDireccionId
            })
            this.guardarImagen({ 
                imagen: filesNoSincronizados[0].file.file, 
                tipoImagenDireccionId: filesNoSincronizados[0].tipoImagenDireccionId,
                personaDireccionId: personaDireccionId,
                id: filesNoSincronizados[0].file.id,
            })
            
        }  else {
            this.setState({ loading: false} )
            this.props.handleOpenSnackbar({message: "Datos e Imagenes guardados correctamente..", variant: 'success' })
            this.props.handleSaved(datosGuardados)
        }
	}

    getMessageImagenLoading(id){
		switch (id) {
			case 1:
				return "SUBIENDO IDENTIFICACIÓN"		
			case 2:
				return "SUBIENDO SELFIE"			
			case 3:
				return "SUBIENDO ACREDITACION EMPRESA'"
            case 4:
				return "SUBIENDO COMPROBANTE DE DOMICILIO"		
			default:
				break;
		}
	}

	guardarImagen({ imagen, tipoImagenDireccionId, personaDireccionId, id }){
		let formData = new FormData();
		
        const { generadorFormsDireccionState,  } = this.state

        if(generadorFormsDireccionState.form){
            if(Array.isArray(generadorFormsDireccionState.form.imagenes)){
                const imgData = generadorFormsDireccionState.form.imagenes.find(x => x.tipoImagen === tipoImagenDireccionId)
                if(imgData){
                    id = imgData.id
                }
            }
        }

        if(generadorFormsDireccionState.form){
            console.log("ID IMAGEN DIRECCION", id)
            if(id){
                formData.append('id', id)
                formData.append('tipoImagenDireccionId', tipoImagenDireccionId)
                formData.append('image', imagen)
                this.props.requestActualizarImagenUsuario(formData)
            } else {
                formData.append('image', imagen)
                formData.append('personaDireccionId', personaDireccionId)
                formData.append('tipoImagenDireccionId', tipoImagenDireccionId)
                this.props.requestGuardarImagenDireccion(formData)
            }
        }	
	}

    handleNext(){

        const { stateDataMap, generadorFormsDireccionState, formFilesDireccionstate, stepperState, formContratoState } = this.state
        const { activeStep } = stepperState

        let formValid = true
        let origins = []
        switch (activeStep) {
            case 0:
                if(!stateDataMap.currentMarkerClicked){
                    formValid = false
                    this.props.handleOpenSnackbar({message: "Es necesario que selecciones donde está la úbicación de instalación de la toma"})
                }
                return formValid
            case 1:
                origins = this.getOriginParamsDireccion();
                for (let index = 0; index < origins.length; index++) {
                    const v = origins[index]
                    if(!this.validarDato(generadorFormsDireccionState.form[ (v.type === "select" ? 'selected': '') + v.name ], v, this.getLabelDireccion()[v.name]  )){
                        formValid = false
                        index += origins.length
                    }   
                }

                return formValid
            case 2:
                origins = this.getOriginParamsFilesDireccion();
                for (let index = 0; index < origins.length; index++) {
                    const v = origins[index]
                    console.log(formFilesDireccionstate)
                    if(!this.validarDato(formFilesDireccionstate.form[ (v.type === "select" ? 'selected': '') + v.name ], v, this.getLabelFilesDireccion()[v.name]  )){
                        formValid = false
                        index += origins.length
                    }   
                }

                if(formValid){
                    this.guardarDatos()
                }

                return formValid
            default:
                break;
        }

    }
	render() {
        const { formFilesDireccionstate, generadorFormsDireccionState, ubicacion,giros, stepperState, stateDataMap, formContratoState } = this.state
        const { classes } = this.props

        let ciudades= []
        let calles =[]
        let colonias = []
        let cumunidad =[]
        if(Array.isArray(stateDataMap.results)){
            if(stateDataMap.results.length > 0){
                const address_components = stateDataMap.results[0].address_components
                const findPostalCode =  address_components.find(x => x.types.find(y => y === 'postal_code') )
                const findNumExterior =  address_components.find(x => x.types.find(y => y === 'street_number') )
                const direccionCompleta =  stateDataMap.results[0].formatted_address

                ciudades = stateDataMap.results.filter( x => x.address_components.find( y => y.types.find( z => z === "locality") ) ).map( (v) => { 
                    const r = v.address_components.find( x => x.types.find( y => y === "locality"))
                    return r.long_name
                })

                calles = stateDataMap.results.filter( x => x.address_components.find( y => y.types.find( z => z === "route") ) ).map( (v) => { 
                    const r = v.address_components.find( x => x.types.find( y => y === "route"))
                    return r.long_name
                })

                colonias = stateDataMap.results.filter( x => x.address_components.find( y => y.types.find( z => z === "sublocality") ) ).map( (v) => { 
                    const r = v.address_components.find( x => x.types.find( y => y === "sublocality"))
                    return r.long_name
                })

                cumunidad = stateDataMap.results.filter( x => x.address_components.find( y => y.types.find( z => z === "neighborhood") ) ).map( (v) => { 
                    const r = v.address_components.find( x => x.types.find( y => y === "neighborhood"))
                    return r.long_name
                })     

                ciudades = ciudades.filter(Utils.distinct).map( (v) => { return { label: v, value:v, color: "black" } } )
                calles = calles.filter(Utils.distinct).map( (v) => { return { label: v, value:v, color: "black" } } )
                colonias = colonias.filter(Utils.distinct).map( (v) => { return { label: v, value:v, color: "black" } } )
                cumunidad  = cumunidad.filter(Utils.distinct).map( (v) => { return { label: v, value:v, color: "black" } } )

                if(findPostalCode){
                    generadorFormsDireccionState.form.codigoPostal = findPostalCode.long_name
                    generadorFormsDireccionState.form.numeroExt = findNumExterior.long_name
                    generadorFormsDireccionState.form.direccionCompleta = direccionCompleta
                }

                if(ciudades.length > 0){
                    generadorFormsDireccionState.form.selectedciudad = ciudades[0]
                }

                if(calles.length > 0){
                    generadorFormsDireccionState.form.selectedcalle = calles[0]
                }

                if(colonias.length > 0){
                    generadorFormsDireccionState.form.selectedcolonia = colonias[0]
                }

                if(cumunidad.length > 0){
                    generadorFormsDireccionState.form.selectedcumunidad = cumunidad[0]
                }

            }      
        }
    
		return (
			<div>
                <StepperCustom
                    pasos={['Buscar dirección por mapa', 'Completar información', 'Evidencia domicilio']}
                    contenidoFinal="INFOMRACIÓN DEL DOMICILIO CAPTURADA"
                    stepperState={ stepperState }
                    handleNext = { this.handleNext }
                    reiniciarText={"Revisar infomración capturada"}
                    finalizarText={"Guardar datos de dirección y crear tramite"}
                    getContenido={
                        (step) => {
                            switch (step) {
                                case 0:
                                  return <div>
                                    <Typography paragraph={true} variant={"subtitle1"} className={classes.instructions}>
                                        <strong>Instrucciones de llenado:</strong> Ubica en el mapa el punto donde se encuentra el domicilio.
                                    </Typography>
                                    <hr />  
                                    <Map
                                        dataMarkers={[
                                            {
                                                lat: ubicacion.lat, 
                                                lng: ubicacion.lng, 
                                                size: {
                                                    x: 12,
                                                    y: 12
                                                }, 
                                                icon: '/circulo.png'
                                            }
                                        ]}
                                        zoom={16}
                                        center = {ubicacion}
                                        stateDataMap={stateDataMap}
                                        handleSetState={this.handleSetState}
                                    ></Map></div> 
                                case 1:
                                     return <div>
                                        <Typography paragraph={true} variant={"subtitle1"} className={classes.instructions}>
                                            <strong>Instrucciones de llenado:</strong> Identifica el domicilio en donde deseas contratar el servicio, siendo lo mas especifico posible. Puedes apoyarte en el recibo del predial para identificar datos como: el numero catastral, la manzana y el lote.
                                        </Typography>
                                        <hr />  
                                        <GeneradorForms
                                            key={'generadorFormsDireccionState'}
                                            generadorFormsState={generadorFormsDireccionState}
                                            forms={this.getOriginParamsDireccion()}
                                            labels={this.getLabelDireccion()}
                                            multiselectData={
                                                /* aqui va el data de los multi select */
                                                {
                                                    ciudad: ciudades,
                                                    calle: calles,
                                                    cumunidad: cumunidad,
                                                    entreCalle1: calles,
                                                    entreCalle2: calles,
                                                    colonia: colonias,
                                                }
                                            }
                                    ></GeneradorForms>
                
                                    </div>
                                case 2:
                                  return <div>
                                    <Typography paragraph={true} variant={"subtitle1"} className={classes.instructions}>
                                        <strong>Instrucciones de llenado:</strong> Debes de seleccionar una imagen en la cual se vea la fachada del domicilio, además de documentos como escrituras, carta compra/ventas, comprobante de domicilio.
                                    </Typography>
                                    <hr />    
                                    <GeneradorForms
                                        key={'formFilesDireccionstate'}
                                        generadorFormsState={formFilesDireccionstate}
                                        forms={this.getOriginParamsFilesDireccion()}
                                        labels={this.getLabelFilesDireccion()}
                                        multiselectData={
                                            /* aqui va el data de los multi select */
                                            {
                                            }
                                        }
                                        ></GeneradorForms>
                                    </div>
                                default:
                                  return 'Unknown step';
                              }
                        }
                    }
                ></StepperCustom>

               
                    
			</div>
		)
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = (dispatch) => {
	return {
        requestGuardarPersonaDireccion: (data) => {
			dispatch(requestGuardarPersonaDireccion(data))
		},
        requestImagenesUsuario: (data) => {
			dispatch(requestImagenesUsuario(data))
		},
		requestActualizarImagenUsuario: (data) => {
			dispatch(requestActualizarImagenUsuario(data))
		},
        requestGuardarImagenDireccion: (data) => {
			dispatch(requestGuardarImagenDireccion(data))
		},
        requestGiros: (data) => {
			dispatch(requestGiros(data))
		},
	}
}

export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(DatosContratoDireccion)
