
import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

import SelectLIB from 'react-select'

import chroma from 'chroma-js';

// Utils
import { Typography } from '@material-ui/core'


const styles = (theme) => ({
    fecha:{
        width:"100%",
    },
    containerElement:{
        paddingRight: theme.spacing(2)
    },
    container:{
        marginBottom:  theme.spacing(5)
    }
})

const colourStyles = {
	control: styles => ({ ...styles, backgroundColor: 'white', borderRadius:"3px" }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = chroma(data.color);
		return {
		...styles,
		borderRadius:"3px",
		backgroundColor: isDisabled
			? null
			: isSelected
			? data.color
			: isFocused
			? color.alpha(0.1).css()
			: null,
		color: isDisabled
			? '#ccc'
			: isSelected
			? chroma.contrast(color, 'white') > 2
			? 'white'
			: 'black'
			: data.color,
		cursor: isDisabled ? 'not-allowed' : 'default',

		':active': {
			...styles[':active'],
			backgroundColor:
			!isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
		},
		};
	},
	multiValue: (styles, { data }) => {
		const color = chroma(data.color);
		return {
		...styles,
		borderRadius:"3px",
		backgroundColor: color.alpha(0.1).css(),
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		borderRadius:"3px",
		color: data.color,
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		borderRadius:"3px",
		':hover': {
		backgroundColor: data.color,
		color: 'white',
		},
	}),
	singleValue: (styles, { data }) => ({ ...styles, color:data.color })
}

class CustomCombo extends Component {

    constructor(props){
        super(props)
      
    }

    render() {

        //const { classes } = this.props

		return (
			<div style={{zIndex:"200"}}>
				<Typography variant="body1">
                    { this.props.title }
                </Typography>
                <SelectLIB
					id={this.props.id}
					style={{zIndex:"200"}}
                    closeMenuOnSelect={true}
                    isClearable={this.props.isClearable}
                    isLoading={this.props.isLoading}
                    value={ this.props.value }
                    isMulti={ this.props.isMulti }
                    options={ this.props.options }
                    placeholder={this.props.placeholder }
                    onChange={ this.props.onChange }
					onInputChange={ this.props.onInputChange }
                    styles={colourStyles} 
                    noResultsText={"No se encontraron datos"}
                    theme={ theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                        ...theme.colors,
                        primary25: 'hotpink',
                        primary: 'black',																	  
                        },})}
					
                />
			</div>
		)
	}
}



const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = () => {
  return {

  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CustomCombo)

