// Soporte a navegadores antiguos (IE)
import 'es6-promise/auto'
import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// Material UI
import { createMuiTheme } from '@material-ui/core/styles'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

// Utils
import Utils from './resources/Utils'
import * as serviceWorker from './serviceWorker'
import store from './store'
import App from './App'

import axios from 'axios'

const options = {
  headers: {
    officeId: document.domain
  }
}

//axios(Utils.constants.HOST_API + "/offices/" + "8-JUMAPAM" + "/configs", options)
axios(Utils.constants.HOST_API + "/offices/" + document.domain + "/configs", options)
.then(function(response) {
  initApp(response.data)
})
.catch(function(err) {
  if (Utils.checkLanguage().toLowerCase() !== 'es') {
    document.title = 'Virtual Office'
    ReactDOM.render(
      <div style={{textAlign: 'center', marginTop: 72}}>
        <h1>The web app could not be loaded.</h1>
        <p>Please try again later. Thank you!</p>
      </div>,
      document.getElementById('root')
    )
  }
  else {
    document.title = 'Oficina Virtual'
    ReactDOM.render(
      <div style={{textAlign: 'center', marginTop: 72}}>
        <h1>No se ha podido cargar la aplicación.</h1>
        <p>Por favor intenta de nuevo más tarde. Gracias.</p>
      </div>,
      document.getElementById('root')
    )
  }
})

function initApp(obj) {
  if (Utils.checkLanguage().toLowerCase() !== 'es') {
    document.title = obj.data.name + ' - Online Payment'
  }
  else {
    document.title = obj.data.name + ' - Pago en Línea'
  }

  localStorage.setItem(Utils.constants.KEY_LS_OFFICE, obj.data.instance)
  localStorage.setItem(Utils.constants.KEY_LS_ENTITY_TYPE, 2)//Pruebas 2 bancomer y 1 banwire
  

  let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = obj.data.favicon
  document.getElementsByTagName('head')[0].appendChild(link)
  
  const appBarColor = obj.data.appBarColor
  const primaryColor = obj.data.primaryColor
  const secondaryColor = obj.data.secondaryColor
  const textTitleColor = obj.data.textTitleColor
  const textDescriptionColor = obj.data.textDescriptionColor

  let theme = createMuiTheme({
    typography: {
      useNextVariants: true,
      htmlFontSize: 16,
      fontFamily: "'Roboto', sans-serif",
      h1: {
        fontSize: 42,
        fontWeight: 700,
        color: textTitleColor
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
        color: textDescriptionColor
      },
      body2: {
        fontSize: 16,
        fontWeight: 400
      }
    },
    sizes: {
      sizeNavbarLogo: obj.data.sizeNavbarLogo,
      sizeLogo: obj.data.sizeLogo
    },
    palette: {
      primary: {
        light: primaryColor + 20,
        main: primaryColor,
        dark: primaryColor - 20,
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: secondaryColor + 20,
        main: secondaryColor,
        dark: secondaryColor - 20,
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#FF5353'
      },
      background: {
        main: appBarColor,
        secondary: '#FBFBFB'
      },
      border: {
        main: '#EAEAEA'
      }
    },
  })

  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
  )
  
  serviceWorker.unregister()

}
