import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

// Material UI
import { withTheme } from '@material-ui/core/styles'

// Components
import PaymentsTable from '../components/PaymentsTable'

// Utils
import { Typography } from '@material-ui/core'
import { requestPaymentsByOfficeId } from '../actions/actions'
import Utils from '../resources/Utils'
class PaymentList extends Component {
  render() {
    return (
      <div>
        <Typography><h2>{Utils.getMessages().paymentsMessages.title}</h2></Typography>
        <PaymentsTable/>
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
 
    requestPaymentsByOfficeId:(data)=>{
      dispatch(requestPaymentsByOfficeId(data))
    },
  }
}

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentList)
