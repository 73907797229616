import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

// Material UI
import { withStyles, withTheme } from '@material-ui/core/styles'

// Components

// Utils
import ConfigsTable from '../components/ConfigsForm'
const styles = theme => ({
  stepper: {
    marginTop: 32,
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      marginTop: 16,
      textAlign: 'center'
    }
  },
})

class Configs extends Component {
  constructor(props) {
    super(props)

  }
  render() {
    return (
    <div>
      <ConfigsTable/>
    </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })



export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps)
)(Configs)
