import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import Typography from '@material-ui/core/Typography'
import { Button } from '@material-ui/core'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Utils
import Utils from '../resources/Utils'
import { requestOpenAutomaticPayment } from '../actions/actions'
import { createAutoPayment } from '../sagas/api'

const styles = theme => ({
  content: {
    backgroundColor: 'white',
    padding: theme.spacing.unit * 7,
    paddingTop: theme.spacing.unit * 14,
    minWidth: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 6,
      paddingTop: theme.spacing.unit * 14,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit * 10,
    }
  },
  toolbar: theme.mixins.toolbar,
  checkboxContainer: {
    width: '60%',
    margin: '0 auto',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      textAlign: 'center'
    }
  },
  points: {
    textAlign: 'left'
  },
  checkbox: {
    padding: 0,
    margin: 0,
    marginTop: -4,
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginRight: 4
    }
  },
  checkMarkSuccess: {
    textAlign: 'center'
  },
  checkFromText: {
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    }
  },
  footerButtons: {
    background: '#F0F7FF',
    position: 'fixed',
    left: 0,
    bottom: 0,
    padding: '24px 0',
    paddingLeft: '10%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%'
    }
  }
})

class AutomaticPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      automaticPayment: false
    }
    this.acceptAutoPayment = this.acceptAutoPayment.bind(this)
    this.closeAutomaticPayment = this.closeAutomaticPayment.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
  }

  componentWillMount() {
    let automaticPayment = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_AUTO_PAYMENT))
    if (automaticPayment) {
      this.setState({
        automaticPayment: automaticPayment.open
      }, function () {
        this.props.requestOpenAutomaticPayment(automaticPayment)
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.automaticPayment != this.props.init.automaticPayment) {
      this.setState({
        automaticPayment: this.props.init.automaticPayment.open
      })
    }
  }

  async acceptAutoPayment() {
    this.setState({
      loading: true
    })
    let automaticPayment = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_AUTO_PAYMENT))
    let autoPaymentResponse = await createAutoPayment(automaticPayment.data)
    if (autoPaymentResponse.status === 'OK') {
      const self = this
      this.setState({
        openSnack: true,
        loading: false,
        messageSnack: 'Listo. ¡Gracias por cumplir con tus pagos!'
      }, function () {
        self.closeAutomaticPayment()
      })
    }
    else {
      this.setState({
        openSnack: false,
        loading: false,
        messageSnack: Utils.getMessages().General.error
      })
    }
  }

  closeAutomaticPayment() {
    let automaticPayment = { open: false }
    this.setState({
      automaticPayment: automaticPayment.open
    }, function () {
      localStorage.setItem(Utils.constants.KEY_LS_AUTO_PAYMENT, JSON.stringify(automaticPayment))
      this.props.requestOpenAutomaticPayment(automaticPayment)
    })
  }

  handleCloseSnackbar() {
    this.setState({
      openSnack: false,
      messageSnack: ''
    })
  }

  getAmountLimit() {
    let automaticPayment = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_AUTO_PAYMENT))
    try {
      return Number(automaticPayment.data.amountLimit).toFixed(2)
    } catch (err) {
      return 0
    }
  }

  render() {

    const { classes } = this.props

    return (
      <div>
      {
        (this.state.automaticPayment) ?
        <div className={classes.footerButtons}>
          <Grid container className={classes.checkboxContainer}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography variant="h6"><strong>Olvídate de pagar el agua.</strong></Typography>
              <Typography variant="body1">Ahora puedes domiciliar tus pagos a tu tarjeta.</Typography>
              <br />
              {
                (!this.state.loading) ?
                <div>
                  <Button onClick={this.acceptAutoPayment} variant="contained" color="primary">Aceptar</Button>
                  <Button onClick={this.closeAutomaticPayment}>Cerrar</Button>
                </div>
                :
                <span>Procesando...</span>
              }
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.points}>
              <ul>
                <li><Typography variant="body1" onClick={() => { this.handleClickCheckbox() }}>No te realizaremos cargos mayores a <strong>${ Utils.numberWithCommas(this.getAmountLimit()) } M.N.</strong> (monto límite).</Typography></li>
                <li><Typography variant="body1" onClick={() => { this.handleClickCheckbox() }}>Cancela cuando quieras y gestiona tus pagos desde tu perfil.</Typography></li>
              </ul>
            </Grid>
          </Grid>
        </div>
        :
        ''
      }
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={this.state.openSnack}
        onClose={this.handleCloseSnackbar}
        message={
          <span>{this.state.messageSnack}</span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestOpenAutomaticPayment: (data) => {
      dispatch(requestOpenAutomaticPayment(data))
    }
  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AutomaticPayment)
