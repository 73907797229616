import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Utils from '../resources/Utils'
import { requestDoPayment, requestOpenAutomaticPayment } from '../actions/actions'

// Components
import TicketTable from '../components/TicketTable'
import Title from '../components/Title'
import Stepper from '../components/Stepper'
import EmailModal from '../components/EmailModal'
import ScheduledPaymentsModal from '../components/ScheduledPaymentsModal'

const styles = theme => ({
  container: {
    textAlign: 'center'
  },
  titleContainer: {
    margin: '0 auto',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  ticketTableContainer: {
    textAlign: 'left',
    width: '60%',
    margin: '0 auto',
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  emailMessage: {
    textAlign: 'center',
    marginTop: 16,
    margin: '0 auto',
    width: '100%'
  },
  stepper: {
    textAlign: 'center',
    margin: '0 auto',
    marginTop: 32
  }
})

class Ticket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountIndex: 0,
      openScheduledPaymentsModal: false,
      openEmailModal: false,
      showCheckMark: false,
      openSnack: false,
      showInfo: false,
      checked: [
        false
      ],
      accountNumber: ''
    }

    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.handleConfirmScheduledPaymentsModal = this.handleConfirmScheduledPaymentsModal.bind(this)
    this.handleCloseScheduledPaymentsModal = this.handleCloseScheduledPaymentsModal.bind(this)
    this.handleOpenEmailModal = this.handleOpenEmailModal.bind(this)
    this.handleConfirmEmailModal = this.handleConfirmEmailModal.bind(this)
    this.handleCloseEmailModal = this.handleCloseEmailModal.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.getAccounts = this.getAccounts.bind(this)
    this.getLastDigit = this.getLastDigit.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isEmpty(this.props.init.payment)) {
      this.props.history.push("/")
    }
    else {
      if (Utils.isEmpty(this.props.init.payment.data)) {
        this.props.history.push("/")
      }
      else {
        let checked = []
        this.props.init.payment.data.accounts.forEach(function(value) {
          checked.push(false)
        })

        let automaticPaymenetOpen = false
        if (this.props.init.config.data.automaticPayment) {
          if (Utils.isUserLoggedIn()) {
            automaticPaymenetOpen = true
          } else {
            if (this.props.init.paymentData.save) {
              automaticPaymenetOpen = true
            }
          }
        }

        this.setState({
          showInfo: true,
          checked: checked,
          automaticPayment: automaticPaymenetOpen
        }, function () {
          let automaticPayment = {
            open: automaticPaymenetOpen,
            data: {
              officeId: this.props.init.payment.data.officeId,
              userId: this.props.init.payment.data.userId,
              email: this.props.init.paymentData.email,
              amountLimit: this.props.init.payment.data.amountLimit,
              accounts: this.props.init.accountsForPay,
              paymentMethod: this.props.init.payment.data.paymentMethod
            }
          }
          localStorage.setItem(Utils.constants.KEY_LS_AUTO_PAYMENT, JSON.stringify(automaticPayment))
          this.props.requestOpenAutomaticPayment(automaticPayment)
        })
      }
    }
  }

  handleCloseSnackbar() {
    this.setState({ openSnack: false })
  }

  handleDownload() {
    const API_PDF = Utils.constants.HOST_EMAIL_API + "/payments/" + this.props.init.payment.data.token + "/download"
    window.open(API_PDF)
  }

  getAccounts() {
    let accounts = []
    this.props.init.payment.data.accounts.forEach(function (account, idx) {
      accounts.push(account)
    })
    return accounts
  }

  getLastDigit() {
    try {
      let number = this.props.init.paymentData.number
      if (this.props.init.paymentData.type === 'american-express') {
        return number.substr(number.length - 5)
      }
      else {
        return number.substr(number.length - 4)
      }
    }
    catch (err) {
      return "●●●●"
    }
  }

  handleOpenEmailModal() {
    this.setState({
      openEmailModal: true
    })
  }

  handleConfirmEmailModal() {
    this.setState({ openEmailModal: false })
  }

  handleCloseEmailModal(value) {
    this.setState({ openEmailModal: value })
  }

  handleCheckboxClick(event, idx) {
    if (this.state.checked[idx]) {
      let checked = []
      this.state.checked.forEach(function (value, i) {
        if (idx === i) {
          checked.push(!value)
        }
        else {
          checked.push(value)
        }
      })
      this.setState({ checked: checked })
    }
    else {
      this.setState({
        accountIndex: idx,
        accountNumber: this.props.init.payment.data.accounts[idx].account,
        openScheduledPaymentsModal: true
      })
    }
  }

  handleConfirmScheduledPaymentsModal(check) {
    let checked = []
    let self = this
    this.state.checked.forEach(function (value, idx) {
      if (self.state.accountIndex === idx) {
        checked.push(check)
      }
      else {
        checked.push(value)
      }
    })

    this.setState({
      checked: checked,
      openScheduledPaymentsModal: false
    })
  }

  handleCloseScheduledPaymentsModal() {
    this.setState({ openScheduledPaymentsModal: false })
  }

  render() {

    const { classes } = this.props

    return (
      <div>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <Title
              title={Utils.getMessages().Ticket.title}
              description={Utils.getMessages().Ticket.description}
            />
          </div>
          <div className={classes.stepper}>
            <Stepper
              back={{
                name: Utils.getMessages().Ticket.sendByEmailButton
              }}
              next={{
                name: Utils.getMessages().Ticket.downloadButton
              }}
              onClickBack={this.handleOpenEmailModal}
              onClickNext={this.handleDownload}
            />
          </div>
          <div className={classes.ticketTableContainer}>
            {
              (this.state.showInfo) ?
                <div>
                  <TicketTable
                    accounts={this.getAccounts()}
                    total={this.props.init.payment.data.amountWithFee}
                    balance={this.props.init.payment.data.amountLimit}
                    fee={this.props.init.payment.data.fee}
                    checked={this.state.checked}
                    onClick={this.handleCheckboxClick}
                  />
                  {
                    (this.props.init.paymentData.save) ?
                      <div className={classes.emailMessage}>
                        <Typography variant="body1">
                          {Utils.getMessages().Ticket.checkEmailMessage} <strong>{this.props.init.paymentData.email}</strong>
                        </Typography>
                      </div>
                      :
                      ''
                  }
                </div>
                :

                ''
            }
          </div>
        </div>

        <EmailModal
          open={this.state.openEmailModal}
          handleConfirm={this.handleConfirmEmailModal}
          handleClose={this.handleCloseEmailModal}
        />

        <ScheduledPaymentsModal
          open={this.state.openScheduledPaymentsModal}
          handleConfirm={this.handleConfirmScheduledPaymentsModal}
          handleClose={this.handleCloseScheduledPaymentsModal}
          accountNumber={this.state.accountNumber}
          numberCard={this.getLastDigit()}
        />

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{Utils.getMessages().ConfirmPayment.errorPayment}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestDoPayment: (data) => {
      dispatch(requestDoPayment(data))
    },
    requestOpenAutomaticPayment: (open) => {
      dispatch(requestOpenAutomaticPayment(open))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Ticket)
