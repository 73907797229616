import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import { withTheme, withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose';
import { Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import ClearAllIcon from '@material-ui/icons/ClearAll';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper:{
        marginTop: theme.spacing(2)
    },
    actions: {
		textAlign: 'end',
	},
    actionContainer:{
        padding: theme.spacing(2),
    },
    actionButtons:{
        backgroundColor: theme.palette.background.secondary,
    },
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	}, 
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
});

const TabPanel = (props) =>  {
    const { children, value, index, ...other } = props;

    return (
        <div
            key={index}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Typography component="div" style={{ padding: 8 * 3 }}>
                {children}
            </Typography>
        )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class CustomTabs extends Component {

    constructor(props){
        super(props)
      
        this.state = {
            tabState: this.props.state
        }
    }
    
    a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    handleChange = (event, newValue) => {
        let { tabState } = this.state
        tabState.index = newValue
        this.setState({ tabState: tabState })
    };


    render() {

        const { classes }  = this.props;
        const { tabState } = this.state


        return (
            <div className={classes.root}>
            <AppBar position="static" color="inherit">
                <Tabs
                    value={tabState.index}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs custom"
                >

                    {
                        this.props.tabs.map( (v, index) => {
                            return <Tab icon={ v.icon } key={ "tab-" + index} label={v.descripcion} {...this.a11yProps(index)} />
                        } )
                    }
                </Tabs>
            </AppBar>

            {
                this.props.tabs.map( (v, index) => {
                    return <Paper  key={"paper-panel-" + index} className={classes.paper}> <TabPanel key={"tab-panel-" + index} value={tabState.index} index={index}>    
                        { v.contenido }                   
                    </TabPanel> </Paper>
                } )
            }
            <br/>
            <Paper className={classes.actionButtons}>
                <div className={classes.actionContainer}>
                    <div className={classes.actions}>

                        <Button
                            onClick={this.props.handleClear}
                            size={"small"}
                            startIcon={<ClearAllIcon></ClearAllIcon>}
                            >
                            { "Limpiar" }
                        </Button>

                        {
                            this.props.actions.map( (v, i) => {
                                return  v.display ? <span key={i} className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        color={ v.color }
                                        disabled={this.props.loading}
                                        onClick={v.handleClick}
                                        startIcon={v.icon}
                                        size="small"
                                        >
                                        { v.textName}
                                    </Button>
                                    {this.props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </span>
                                : null
                            } )
                        }
                        
                       
                    </div>
                </div>
            </Paper>
            
            </div>
        )
    }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = () => {
  return {

  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CustomTabs)

