import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 24;

class Options extends React.Component {

  constructor(props){
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose(option, idx) {
    this.setState({ anchorEl: null });
    this.props.handleCloseOptions(this.props.idx, option)
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: 'auto',
              width: 200,
            },
          }}
        >
        {
          this.props.options.map((option, idx) => {
            return (
              <MenuItem key={idx} onClick={() => { this.handleClose(option) }}>
                {option}
              </MenuItem>
            )
          })
        }
        </Menu>
      </div>
    );
  }
}

export default Options
