import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'

// Components
import Title from '../components/Title'
import NoteAddIcon from '@material-ui/icons/NoteAdd';

// Utils
import Utils from '../resources/Utils'
import { requestGuardarTramite, requestObtenerTipoTramite, requestObtenerTramites, requestUsuarioDireccion } from '../actions/actions'
import CircleLoading from '../components/CircleLoading'
import MySnackbarContentWrapper from '../components/CustomSnackBar'
import ModalTramite from '../components/ModalTramite'
import TramitesListTable from '../components/TramitesListTable'
import { Button, Grid } from '@material-ui/core'

const styles = theme => ({
	receiptTable: {
		marginTop: 16
	},
	select: {
		width: 300,
		marginRight: theme.spacing(1)
	},
	paper:{
		padding:  theme.spacing(3)
	}
})

class Tramites extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			openSnack: false,
			variant: 'warning',
			errorMessage: '',
			modalTramite: {
				open: false
			},
			tramites: []
		}

		this.handleSetState = this.handleSetState.bind(this)
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
		this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this)
	}

	componentDidMount() {
		Utils.scrollTop()
		this._requestTramites()
		this.setState({loading:true})
	}

	componentDidUpdate(prevProps) {
		if(prevProps.init.obtenerTipoTramite !== this.props.init.obtenerTipoTramite){
			const { isValid, data } = this.props.init.obtenerTipoTramite
			if(isValid){
				this.setState({tiposTramite: data, loading:false})
			}else {
				this.setState({ loading: false} )
			}
		}
		if(prevProps.init.usuarioDireccion !== this.props.init.usuarioDireccion){
			const { isValid, data } = this.props.init.usuarioDireccion
			if(isValid){
				this.setState({personas: data, loading:false})
			}else {
				this.setState({ loading: false} )
			}
		}

		if(prevProps.init.obtenerTramites !== this.props.init.obtenerTramites){
			const { isValid, data } = this.props.init.obtenerTramites
			if(isValid){
				this.setState({tramites: data, loading:false})
			}else {
				this.setState({ loading: false} )
			}
		}

		if(prevProps.init.guardarTramite !== this.props.init.guardarTramite){
			const { isValid, data } = this.props.init.guardarTramite
			if(isValid){
				this.handleOpenSnackbar({message: 'Tramite guardado exitosamente!', variant:'success'})
				this._requestTramites()
			}else {
				this.setState({ loading: false} )
			}
		}
	}

	_requestTramites(){
		let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
		userId = userId.data.id
		this.props.requestObtenerTramites({ userId:userId })
	}

	handleCloseShowReceipt() {
		this.setState({openModal: false})
	}

	handleOpenSnackbar({message, variant}) {
		this.setState({openSnack: true, variant: variant || 'warning', errorMessage: message })
	}

	handleCloseSnackbar() {
		this.setState({openSnack: false})
	}

	handleSetState(){
		this.setState({})
	}

	render() {
		
		const { classes } = this.props
		const { loading} = this.state

		return (
			<div>
			<Title 
				title={"Mis Tramites"}
				description={"Tramites creados o en proceso"}
			/>
			<br />

			<Grid container>
				<Grid item xs={12}>
					<div style={{float:"right", marginBottom:10}}>
						<Button
							startIcon={<NoteAddIcon></NoteAddIcon>}
							variant="contained"
							color="primary"
							onClick={ () => { 
								let { modalTramite } = this.state
								modalTramite.open = true
								this.setState({ modalTramite: modalTramite}) 
							} }
						>
							Crear tramite
						</Button>
					</div>
				</Grid>
			</Grid>
			{

				!loading ? 
							
				<TramitesListTable
					tramites = {this.state.tramites}
				>

				</TramitesListTable>
							
				:
				<CircleLoading mensajeCargando={"Cargando tramites.."} />
				
			}
		
			<ModalTramite
				open={this.state.modalTramite.open}
				handleClose={ () => { 
					let { modalTramite } = this.state
					modalTramite.open = false
					this.setState({ modalTramite: modalTramite}) 
				} }
				titulo={"Crear tramite"}
				descripcion={"Crea tramites para que un agente valide los datos"}
				handleOpenSnackbar = {this.handleOpenSnackbar}
			>
				
			</ModalTramite>
			<Snackbar
				anchorOrigin={{vertical: 'top', horizontal: 'center'}}
				open={this.state.openSnack}
				autoHideDuration={5000}

				onClose={this.handleCloseSnackbar}
			>
				<MySnackbarContentWrapper
					onClose={this.handleCloseSnackbar}
					variant={this.state.variant}
					message={
						<span>{this.state.errorMessage}</span>
					}
				/>
			</Snackbar>
		</div>
		)

		
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
	return {
		requestGuardarTramite: (data) => {
			dispatch(requestGuardarTramite(data))
		},
		requestUsuarioDireccion: (data) => {
			dispatch(requestUsuarioDireccion(data))
		},
		requestObtenerTipoTramite: (data) => {
			dispatch(requestObtenerTipoTramite(data))
		},
		requestObtenerTramites: (data) => {
			dispatch(requestObtenerTramites(data))
		},
	}
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Tramites)
