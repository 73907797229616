import React, { Component } from 'react'
import compose from 'recompose/compose'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

// Resources
import Utils from '../resources/Utils'
import Amex from '../resources/images/amex.png'
import MasterCard from '../resources/images/mastercard.png'
import Visa from '../resources/images/visa.png'

const styles = theme => ({
  headerTable: {
    fontWeight: 700
  },
  cardTypeCell: {
    padding: 0,
    margin: 0,
    textAlign: 'right'
  },
  cardType: {
    width: 64
  },
  hidden: {
    display: 'none'
  }
})

class ScheduledPaymentsTable extends Component {
  constructor(props) {
    super(props)
    this.getCardTypeImg = this.getCardTypeImg.bind(this)
    this.getCardNumber = this.getCardNumber.bind(this)
  }

  getCardTypeImg(type) {
    if (type === 'mastercard') {
      return MasterCard
    }
    else if (type === 'visa') {
      return Visa
    }
    else if (type === 'american-express') {
      return Amex
    }
    else {
      return ''
    }
  }

  getCardNumber(type, number) {
    if (type === 'american-express') {
      return "●●●● ●●●●●● " + number
    }
    else {
      return "●●●● ●●●● ●●●● " + number
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Table 
        className={this.props.className}
      >
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="subtitle2" className={classes.headerTable}>Cuenta</Typography></TableCell>
            <TableCell className={classes.cardTypeCell}></TableCell>
            <TableCell><Typography variant="subtitle2" className={classes.headerTable}>Tarjeta</Typography></TableCell>
            <TableCell numeric><Typography variant="subtitle2" className={classes.headerTable}>Monto máximo</Typography></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
      {
        this.props.scheduledPayments.map((schedule, idx) => {
          return (
            <TableRow key={idx}>
              <TableCell>
                <Typography variant="body2">{schedule.accountNumber}</Typography>
              </TableCell>
              <TableCell className={classes.cardTypeCell}>
                <img className={classes.cardType} alt="" src={this.getCardTypeImg(schedule.cardType)}/>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{this.getCardNumber(schedule.cardType, schedule.cardNumber)}</Typography>
                {
                  (schedule.cardAlias !== '') ?

                  <Typography variant="body1" style={{fontSize: 14}}>{schedule.cardAlias}</Typography>

                  :

                  ''
                }
              </TableCell>
              <TableCell numeric><Typography variant="body2">$ {Utils.numberWithCommas(schedule.amountLimit.toFixed(2))}</Typography></TableCell>
              <TableCell>
                <Grid container>
                  <Grid item lg={6}>
                    <IconButton onClick={() => { this.props.onEdit(idx) }}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  <Grid item lg={6}>
                    <IconButton onClick={() => { this.props.onDelete(idx) }}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>
    )
  }
}

export default compose(
  withTheme,
  withStyles(styles)
)(ScheduledPaymentsTable)
