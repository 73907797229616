import React, { Component } from 'react'
import compose from 'recompose/compose'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import { green, orange } from '@material-ui/core/colors';

const styles = theme => ({
  title: {
	marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      	fontSize: '32px!important'
    }
  },
  titlePorcentaje:{
		fontSize: 32,
		color: orange["500"],
		[theme.breakpoints.down('sm')]: {
			fontSize: '32px!important'
		}
	}
})

class TitlePerfil extends Component {
	constructor(props) {
		super(props);
		this.state = {
			style: {
				fontSize: 42,
				fontWeight: 700
			}
		}
	}
	componentWillMount() {
		if (this.props.variant === 'subtitle') {
		this.setState({
			style: {
			fontSize: 32,
			fontWeight: 700
			}
		})
		}
		else {
		this.setState({
			style: {
			fontSize: 42,
			fontWeight: 700
			}
		})
		}
	}

  	render() {
		const { classes, validated } = this.props
		return (
		<header>
			<div style={{display:"flex"}}>
				<Typography variant="h1" style={this.state.style} className={classes.title}>
					{this.props.title}
				</Typography>
				<Typography variant="h1" className={classes.titlePorcentaje}>
					{this.props.porcentajePerfil}
				</Typography>
					{ !this.props.isCompleted ? <IconButton
						onClick={ this.props.handleCompletarPerfil }
						style={{ color: validated ? green["500"] : orange["500"], marginBottom:"27px"}}
						title={"Completar perfil"}
						>
							<InfoIcon style={{ fontSize:"40px", position:"absolute" }}></InfoIcon> 
					</IconButton>: null
					} 
			</div>
		 	{
				 !this.props.loading ? 
				<Typography variant="body1">
					{
						validated ?
						<strong>Ya puedes solicitar servicios, en el menu lateral izquierdo opción (Servicios).</strong>
						:
						<strong>{ this.props.isCompleted ? "Tus datos se encuentran en validación por el personal del OOMS" : null} </strong>
					}

				 	
				</Typography>
				: null	
			}
			<Typography variant="body1">
				{this.props.description}
			</Typography>
		</header>
		)
	}
}

export default compose(
  withTheme,
  withStyles(styles),
)(TitlePerfil)
