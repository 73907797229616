import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// Components
import PaymentTable from '../components/PaymentTable'
import Title from '../components/Title'
import Breadcrumb from '../components/Breadcrumb'
import Spinner from '../components/Spinner'
import Stepper from '../components/Stepper'
import AmountModal from '../components/AmountModal'

// Utils
import Utils from '../resources/Utils'
import { requestDoPayment } from '../actions/actions'

const styles = theme => ({
  paymentTableContainer: {
    width: '70%',
    margin: '0 auto',
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  checkboxContainer: {
    width: '60%',
    margin: '0 auto',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    }
  },
  checkbox: {
    padding: 0,
    margin: 0,
    marginTop: -4,
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginRight: 4
    }
  },
  checkMarkSuccess: {
    textAlign: 'center'
  },
  checkFromText: {
    display: 'inline-block',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    }
  },
  stepper: {
    textAlign: 'center',
    margin: '0 auto',
    marginTop: 16
  },
  footerButtons: {
    background: '#F0F7FF',
    position: 'fixed',
    left: 0,
    bottom: 0,
    padding: '24px 0',
    paddingLeft: '10%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%'
    }
  }
})

class ConfirmPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onPayment: false,
      showCheckMark: false,
      openSnack: false,
      message: '',
      checkbox: false,
      openAmountModal: false,
      accountSelected: {}
    }

    this.handleEditAmount = this.handleEditAmount.bind(this)
    this.handleClickCheckbox = this.handleClickCheckbox.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.handleConfirmAmountModal = this.handleConfirmAmountModal.bind(this)
    this.handleCloseAmountModal = this.handleCloseAmountModal.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.doPayment = this.doPayment.bind(this)
    this.getBreadcrumbs = this.getBreadcrumbs.bind(this)
    this.getReference = this.getReference.bind(this)
    this.getTotal = this.getTotal.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isEmpty(this.props.init.paymentData)) {
      this.handleBack()
    }
  }

  componentDidUpdate(prevProps) {
    let self = this
    if (prevProps.init.payment !== this.props.init.payment) {
      if (this.props.init.payment.status === 'OK') {
        this.setState({showCheckMark: true})
        setTimeout(function() {
          self.props.history.push("/pago")
        }, 3000)
      }
      else {
        let message = Utils.getMessages().ConfirmPayment.errorPayment
        if (!Utils.isEmpty(this.props.init.payment.devMessage)) {
          message = this.props.init.payment.devMessage
        }
        this.setState({
          onPayment: false,
          openSnack: true,
          message: message
        })
      }
    }
  }

  getBreadcrumbs() {
    let breadcrumbs = []
    if (Utils.isUserLoggedIn()) {
      breadcrumbs = [{
          name: Utils.getMessages().ConfirmPayment.breadcrumbLogin1,
          path: '/cuentas'
        },
        {
          name: Utils.getMessages().ConfirmPayment.breadcrumbLogin2,
          path: '/tarjetas'
        },
        {
          name: Utils.getMessages().ConfirmPayment.breadcrumbCurrent,
          path: this.props.match.url
        }
      ]

      return breadcrumbs
    }
    else {
      breadcrumbs = [{
          name: Utils.getMessages().ConfirmPayment.breadcrumb1,
          path: '/cuentas/' + this.props.match.params.accountNumber
        },
        {
          name: Utils.getMessages().ConfirmPayment.breadcrumbCurrent,
          path: this.props.match.url
        }
      ]
      return breadcrumbs
    }
  }

  handleEditAmount(idx) {
    if (this.state.onPayment)
      return
    let account = {}
    if (Utils.isUserLoggedIn()) {
      account = this.props.init.accountsForPay[idx]
      account.idx = idx
    }
    else {
      account = this.props.init.account.data
    }
    this.setState({
      openAmountModal: true,
      accountSelected: account
    })
  }

  handleConfirmAmountModal() {
    this.setState({
      openAmountModal: false,
      openSnack: true,
      message: Utils.getMessages().ConfirmPayment.editAmountOk
    })
  }

  handleCloseAmountModal() {
    this.setState({
      openAmountModal: false
    })
  }

  handleClickCheckbox() {
    if (this.state.onPayment)
      return
    this.setState({
      checkbox: !this.state.checkbox
    })
  }

  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  handleBack() {
    if (Utils.isUserLoggedIn()) {
      this.props.history.push("/cuentas")
    }
    else {
      this.props.history.push("/cuentas/" + this.props.match.params.accountNumber)
    }
  }

  getTotal() {
    let total = 0
    this.props.init.accountsForPay.forEach(function(account) {
      total += account.amount
    })
    return total
  }

  getReference() {
    let reference = ""
    let self = this
    this.props.init.accountsForPay.forEach(function(account, idx) {
      if (idx < self.props.init.accountsForPay.length - 1) {
        reference += account.account + ","
      }
      else {
        reference += account.account
      }
    })
    return reference
  }

  doPayment() {
    if (this.state.onPayment)
      return

    if (!this.state.checkbox) {
      this.setState({
        openSnack: true,
        message: Utils.getMessages().ConfirmPayment.termsValidation
      })
      return
    }

    if (this.getTotal() <= 0) {
      this.setState({
        openSnack: true,
        message: Utils.getMessages().ConfirmPayment.amountValidation
      })
      return
    }

    if (this.getTotal() > this.props.init.config.data.maxAmountPartialPayment && this.props.init.accountsForPay.length <= 1) {
      this.setState({
        openSnack: true,
        message: Utils.getMessages().ConfirmPayment.maxAmount
      })
      return
    }

    this.setState({
      onPayment: true,
      openSnack: true,
      message: Utils.getMessages().ConfirmPayment.onPayment
    })

    if (Utils.isUserLoggedIn()) {
      let accounts = []

      this.props.init.accountsForPay.forEach(function(account) {
        if (account.amount > 0) {
          if (Utils.isEmpty(account.oldValue)) {
            account.oldValue = account.amount
          }

          // paymentTypes
          // 0 = ABONO
          // 1 = SALDO A FAVOR
          // 2 = PAGO COMPLETO CON SALDO A FAVOR
          // 3 = PAGO COMPLETO

          // Abono
          if (Number(account.amount) < Number(account.oldValue)) {
            account.paymentType = 0
          }
          else if (Number(account.amount) > Number(account.oldValue)) {
            // Saldo a favor
            if (Number(account.oldValue) <= 0) {
              account.paymentType = 1
            }
            // Pago completo y saldo a favor
            else {
              account.paymentType = 2
            }
          }
          // Pago completo
          else {
            account.paymentType = 3
          }

          accounts.push(account)
        }
      })

      if (accounts.length <= 0) {
        this.setState({
          openSnack: true,
          message: Utils.getMessages().ConfirmPayment.amountValidation
        })
        return
      }

      let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER)).data.id
      let email = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER)).data.email
      const paymentData = {
        officeId: this.props.init.officeId,
        userId: userId,
        cardId: this.props.init.paymentData.id,
        token: this.props.init.paymentData.token,
        titular: this.props.init.paymentData.titular || '',
        email: email,
        alias: this.props.init.paymentData.alias || '',
        reference: this.getReference(),
        amount: this.getTotal(),
        accounts: accounts,
        origin: 'WEB_AUTH',
        senderId: '',
        recipientId: ''
      }
      
      this.props.requestDoPayment(paymentData)
    }
    else {
      let account = this.props.init.account.data
      if (Utils.isEmpty(account.oldValue)) {
        account.oldValue = account.amount
      }

      // paymentTypes
      // 0 = ABONO
      // 1 = SALDO A FAVOR
      // 2 = PAGO COMPLETO CON SALDO A FAVOR
      // 3 = PAGO COMPLETO

      // Abono
      if (Number(account.amount) < Number(account.oldValue)) {
        account.paymentType = 0
      }
      else if (Number(account.amount) > Number(account.oldValue)) {
        // Saldo a favor
        if (Number(account.oldValue) <= 0) {
          account.paymentType = 1
        }
        // Pago completo y saldo a favor
        else {
          account.paymentType = 2
        }
      }
      // Pago completo
      else {
        account.paymentType = 3
      }

      const paymentData = {
        officeId: this.props.init.officeId,
        userId: null,
        save: this.props.init.paymentData.save,
        type: this.props.init.paymentData.type,
        number: this.props.init.paymentData.number,
        month: this.props.init.paymentData.month,
        year: this.props.init.paymentData.year,
        ccv: this.props.init.paymentData.ccv,
        titular: this.props.init.paymentData.titular || '',
        email: this.props.init.paymentData.email,
        alias: this.props.init.paymentData.alias || '',
        reference: account.account,
        paymentType: account.paymentType,
        originalAmount: Number(account.oldValue),
        amount: Number(account.amount),
        origin: 'WEB',
        senderId: '',
        recipientId: ''
      }
      
      this.props.requestDoPayment(paymentData)
    }
  }

  render() {
    
    const { classes } = this.props

    return (
      <div>
        {
          (!this.state.showCheckMark) ?
          <div>
            <Title 
              title={Utils.getMessages().ConfirmPayment.title}
              description={Utils.getMessages().ConfirmPayment.description}
            />
            <Breadcrumb
              breadcrumbs={this.getBreadcrumbs()}
            />
            {
              (this.props.init.paymentData) ?
                <div className={classes.paymentTableContainer}>
                  <PaymentTable 
                    paymentData={this.props.init.paymentData}
                    accounts={this.props.init.accountsForPay}
                    onEdit={this.handleEditAmount}
                  />
                </div>
              :
              ''
            }
            {
              (!this.state.onPayment) ?
              <div className={classes.footerButtons}>
                <Grid container className={classes.checkboxContainer}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      checked={this.state.checkbox}
                      onClick={ (event) => { this.handleClickCheckbox() }}
                    />
                    <Typography variant="body1" className={classes.checkFromText} onClick={ () => { this.handleClickCheckbox() }}>{Utils.getMessages().ConfirmPayment.termsMessage} <a rel="noopener noreferrer" target="_blank" href={Utils.constants.paymentsProviderURL}>{Utils.constants.paymentsProvider}</a></Typography>
                  </Grid>
                </Grid>
                <div className={classes.stepper}>
                  <Stepper
                    back={{
                      name: Utils.getMessages().ConfirmPayment.cancelButton
                    }}
                    next={{
                      name: Utils.getMessages().ConfirmPayment.confirmButton
                    }}
                    onClickBack={this.handleBack}
                    onClickNext={this.doPayment}
                  />
                </div>
              </div>
              :
              <div className={classes.checkboxContainer}>
                <Typography variant="body1">{Utils.getMessages().ConfirmPayment.onPayment}</Typography>
              </div>
            }
          </div>
          :
          <div className={classes.checkMarkSuccess}>
            <Spinner />
            <br />
            <br />
            <Title
              title={Utils.getMessages().ConfirmPayment.successPaymentTitle}
              description={Utils.getMessages().ConfirmPayment.successPaymentDescription}
            />
          </div>
        }
        <AmountModal
          open={this.state.openAmountModal}
          account={this.state.accountSelected}
          handleConfirm={this.handleConfirmAmountModal}
          handleClose={this.handleCloseAmountModal}
        />
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={this.state.message}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestDoPayment: (data) => {
      dispatch(requestDoPayment(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ConfirmPayment)
