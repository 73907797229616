import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

// Images
import Amex from '../resources/images/amex.png'
import MasterCard from '../resources/images/mastercard.png'
import Visa from '../resources/images/visa.png'

import Utils from '../resources/Utils'

const styles = theme => ({
  container: {
    margin: 0,
    padding: 0
  },
  titlePayment: {
    [theme.breakpoints.only('xs')]: {
      fontSize: 18
    }
  },
  description: {
    fontSize: 16
  },
  concepts: {
    margin: 0,
    padding: 0,
    float: 'right',
    [theme.breakpoints.only('xs')]: {
      marginTop: 8,
      marginLeft: '25%',
      float: 'none',
    }
  },
  totalAmount: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center'
    }
  },
  totalAmountData: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center'
    }
  },
  numbers: {
    textAlign: 'right'
  },
  subTotalAmount: {
    fontSize: 11,
  },
  ccType: {
    width: 60,
    height: 60,
    float: 'right',
    marginRight: '33%',
    [theme.breakpoints.down('sm')]: {
      width: 44,
      height: 44
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '20%',
      width: 44,
      height: 44
    }
  },
  oldAmount: {
    textDecoration: 'line-through',
    color: '#BABABA'
  },
  accountList: {
    width: '80%',
    listStyle: 'none',
    margin: '0 auto',
    padding: 0,
    overflow: 'auto',
    [theme.breakpoints.only('xs')]: {
      padding: '0px 18px'
    }
  },
  accountListItem: {
    display: 'block',
    margin: 0,
    padding: 0,
    marginTop: 8
  },
  leftItemAccountList: {
    display: 'inline-block',
    width: '50%',
    [theme.breakpoints.only('xs')]: {
      width: '50%'
    }
  },
  accountData: {
    [theme.breakpoints.only('xs')]: {
      fontSize: 12,
    }
  },
  rightItemAccountList: {
    textAlign: 'right',
    display: 'inline-block',
    width: '50%',
    [theme.breakpoints.only('xs')]: {
      width: '50%'
    }
  },
  oldValue: {
    color: 'red',
    padding: 0,
    margin: 0,
    display: 'block',
    fontSize: 10,
  },
  oldValueLast: {
    color: 'red',
    padding: 0,
    margin: 0,
    display: 'block',
    fontSize: 10,
    marginBottom: 8
  },
  line: {
    opacity: 0.5
  }
})

class PaymentTable extends Component {
  getCCType() {
    if (this.props.paymentData.type === 'visa')
      return Visa
    else if (this.props.paymentData.type === 'mastercard')
      return MasterCard
    else if (this.props.paymentData.type === 'american-express')
      return Amex
    else
      return ''
  }

  getCCTypeDescription() {
    if (this.props.paymentData.type === 'visa')
      return 'Visa'
    else if (this.props.paymentData.type === 'mastercard')
      return 'Mastercard'
    else if (this.props.paymentData.type === 'american-express')
      return 'American Express'
    else
      return ''
  }

  getTitle() {
    return (this.getCC() + " - (" + this.props.paymentData.month + "/" + this.props.paymentData.year + ")")
  }

  getDescription() {
    return this.props.paymentData.titular + " (" + this.getCCTypeDescription() + ")"
  }

  getTotal() {
    let total = 0
    this.props.accounts.map(account => {
      return total += Number(account.amount)
    })
    return Utils.numberWithCommas(Number(total).toFixed(2))
  }

  getTotalWithFee() {
    let total = 0
    this.props.accounts.map(account => {
      return total += Number(account.amount)
    })
    return Utils.numberWithCommas(Number(total + this.getFee()).toFixed(2))
  }

  getUserFee() {
    return ((this.props.init.config.data.percentageFee * this.props.init.config.data.userFee) / 100)
  }

  getFee() {
    let total = 0
    this.props.accounts.map(account => {
      return total += Number(account.amount)
    })
    let userFee = this.getUserFee()
    let totalWithFee = ((total * userFee) / 100)
    return totalWithFee
  }

  getCC() {
    let number = this.props.paymentData.number || ''
    if (this.props.paymentData.type === 'american-express') {
      return "●●●● ●●●●● " + number.substr(number.length - 5)
    }
    else {
      return "●●●● ●●●● ●●●● " + number.substr(number.length - 4)
    }
  }

  acceptPositiveBalance() {
    return this.props.init.config.data.positiveBalance
  }

  acceptPartialPayment() {
    return this.props.init.config.data.partialPayment
  }

  getTitleType(account) {
    if (!account) {
      return Utils.getMessages().ConfirmPayment.balance
    }
    else {
      if (account.amount < account.oldValue) {
        return Utils.getMessages().ConfirmPayment.partialPayment
      }
      else if (account.amount > account.oldValue) {
        return Utils.getMessages().ConfirmPayment.positiveBalance
      }
      else {
        return Utils.getMessages().ConfirmPayment.balance
      }
    }
  }

  render() {

    const { classes } = this.props

    return (
      <Card className={classes.container}>
        <CardContent style={{backgroundColor: '#F0F7FF'}}>
          <Grid container>
            <Grid item lg={2} md={2} sm={1} xs={2}>
              <img className={classes.ccType} alt="" src={this.getCCType()} />
            </Grid>
            <Grid item lg={5} md={5} sm={6} xs={10}>
              <Typography variant="h6" component="h2">
                <strong className={classes.titlePayment}>{this.getTitle()}</strong>
              </Typography>
              <Typography className={classes.description} color="textSecondary">
                {this.getDescription()}
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={12} className={classes.numbers}>
              {
                (this.props.init.config.data.userFee > 0) ?
                <table className={classes.concepts}>
                  <tr>
                    <td>
                      <Typography className={classes.subTotalAmount} variant="subtitle1">
                        <strong>{Utils.getMessages().ConfirmPayment.balanceToPay}</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography className={classes.subTotalAmount} color="textSecondary">
                        $ { this.getTotal() }
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography className={classes.subTotalAmount} color="subtitle1">
                        <strong>{Utils.getMessages().ConfirmPayment.transactionCost}</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography className={classes.subTotalAmount} color="textSecondary">
                        $ { Utils.numberWithCommas(Number(this.getFee()).toFixed(2)) }
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <Typography className={classes.totalAmount} variant="subtitle1">
                        <strong>{Utils.getMessages().ConfirmPayment.totalToPay}</strong>
                      </Typography>
                      <Typography className={classes.totalAmountData} color="textSecondary">
                        $ { this.getTotalWithFee() }
                      </Typography>
                    </td>
                  </tr>
                </table>
                :
                <div>
                  <Typography className={classes.totalAmount} variant="subtitle1">
                    <strong>{Utils.getMessages().ConfirmPayment.totalToPay}</strong>
                  </Typography>
                  <Typography className={classes.totalAmountData} color="textSecondary">
                    $ { this.getTotalWithFee() }
                  </Typography>
                </div>
              }
            </Grid>
          </Grid>
        </CardContent>
        <CardContent style={{margin: 0, padding: '12px 0'}}>
          <ul className={classes.accountList}>
          {
            this.props.accounts.map((account, idx) => {
              return (
                <li key={idx} className={classes.accountListItem}>
                  <div className={classes.leftItemAccountList}>
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <strong># {account.account}</strong>
                        <br />
                        <div className={classes.accountData}>
                          <label>{account.name}</label>
                          <br />
                          <label>{account.shortAddress}</label>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.rightItemAccountList}>
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        <strong>{ this.getTitleType(account) }:</strong>
                        <br />
                        <label>$ { Utils.numberWithCommas(Number(account.amount).toFixed(2)) }</label>
                        <br />
                        {
                          (this.getTitleType(account) !== Utils.getMessages().ConfirmPayment.balance) ?
                          <div>
                            <hr className={classes.line} />
                            <label className={classes.oldValue}><strong>$ { Utils.numberWithCommas(Number(account.oldValue).toFixed(2)) }</strong></label>
                            <label className={classes.oldValueLast}>{Utils.getMessages().ConfirmPayment.currentBalance}</label>
                          </div>
                          :
                          ''
                        }
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        {
                          (this.acceptPositiveBalance() || this.acceptPartialPayment()) ?
                          <IconButton onClick={() => { this.props.onEdit(idx) }}>
                            <EditIcon />
                          </IconButton>
                          :
                          ''
                        }
                      </Grid>
                    </Grid>
                  </div>
                  {
                    (idx < this.props.accounts.length - 1) ?
                    
                    <Divider style={{marginTop: 0}}/>
                    
                    :
                    
                    ''
                  }
                </li>
              )
            })
          }
          </ul>
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, null)
)(PaymentTable)
