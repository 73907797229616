import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Components
import AccountTable from '../components/AccountTable'
import Title from '../components/Title'
import Stepper from '../components/Stepper'
import AccountModal from '../components/AccountModal'
import DeleteDialog from '../components/DeleteDialog'
import ShowInfoModal from '../components/ShowInfoModal'
import Empty from '../components/Empty'

// Utils
import Utils from '../resources/Utils'
import accountsEmptyImg from '../resources/images/accounts-empty.svg'
import { requestAccountsByUser, requestEstadoCuenta } from '../actions/actions'
import CircleLoading from '../components/CircleLoading'

const styles = theme => ({
  accountTable: {
    marginTop: 16
  },
  stepper: {
    marginTop: 32,
    float: 'right'
  }
})

class AccountSetting extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editAccount: false,
      openDeleteDialog: false,
      openAccountModal: false,
      openShowInfoModal: false,
      openSnack: false,
      message: '',
      showAccounts: false,
      account: {},
      loading: false,
    }

    //
    this.handleShowInfo = this.handleShowInfo.bind(this)
    this.handleEditAccount = this.handleEditAccount.bind(this)
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this)
    this.handleCloseShowInfoModal = this.handleCloseShowInfoModal.bind(this)

    // Dialog handlers
    this.handleCancelDeleteAccount = this.handleCancelDeleteAccount.bind(this)
    this.handleConfirmDeleteAccount = this.handleConfirmDeleteAccount.bind(this)

    // Modal handlers
    this.handleConfirmAccountModal = this.handleConfirmAccountModal.bind(this)
    this.handleCloseAccountModal = this.handleCloseAccountModal.bind(this)

    // Navigation handlers
    this.handleNewWaterAccount = this.handleNewWaterAccount.bind(this)

    // Others
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.getAccounts = this.getAccounts.bind(this)

    this.handleDownloadEstadoCuenta = this.handleDownloadEstadoCuenta.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {      
      let user = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
      user = user.data
      this.setState({loading: true})
      this.props.requestAccountsByUser(user.id)
    }
    else {
      this.props.history.push('/')
    }
  }

  componentDidUpdate(prevProps) {
    if (Utils.isUserLoggedIn()) {

      // Mostrar cuentas
      if (prevProps.init.user !== this.props.init.user) {
        if (this.props.init.user.accounts.status === 'OK') {
          let showAccounts = false
          if (this.props.init.user.accounts.data.length > 0) {
            showAccounts = true
          }
          this.setState({showAccounts: showAccounts, loading:false,})
        }
      }

      // Cuenta agregada
      if (prevProps.init.addAccount !== this.props.init.addAccount) {
        let showAccounts = false
        let openSnack = false
        let message = Utils.getMessages().General.error
        if (this.props.init.addAccount.status === 'OK') {
          openSnack = true
          if (this.props.init.addAccount.data.new) {
            message = Utils.getMessages().Accounts.addOk
          }
          else {
            message = Utils.getMessages().Accounts.existOk
          }
          if (this.props.init.user.accounts.data.length > 0) {
            showAccounts = true
          }
        }
        this.setState({
          showAccounts: showAccounts,
          openSnack: openSnack,
          message: message
        })
      }

      // Cuenta editada
      if (prevProps.init.editAccount !== this.props.init.editAccount) {
        let openSnack = false
        let message = Utils.getMessages().General.error
        if (this.props.init.editAccount.status === 'OK') {
          openSnack = true
          message = Utils.getMessages().Accounts.editOk
        }
        this.setState({
          openSnack: openSnack,
          message: message
        })
      }

      // Cuenta eliminada
      if (prevProps.init.deleteAccount !== this.props.init.deleteAccount) {
        let showAccounts = true
        let openSnack = false
        let message = Utils.getMessages().General.error
        if (this.props.init.deleteAccount.status === 'OK') {
          openSnack = true
          message = Utils.getMessages().Accounts.deleteOk
          if (this.props.init.user.accounts.data.length === 0) {
            showAccounts = false
          }
        }
        this.setState({
          showAccounts: showAccounts,
          openSnack: openSnack,
          message: message
        })
      }

      
      if(prevProps.init.estadoCuenta !== this.props.init.estadoCuenta){
        const { status, data } = this.props.init.estadoCuenta
        if(status === Utils.constants.status.ok){
          const { Data, Message, IsValid } = data
          if(IsValid){
            window.open(Data, "_blank");
          } else {
            this.setState({
              openSnack: true,
              messageSnack: Message
            })
          }
        } else{
          this.setState({
            openSnack: true,
            messageSnack: Utils.getMessages().General.error
          })
        }

      }
    }
    else {
      this.props.history.push('/')
    }
  }

  //

  handleShowInfo(idx) {
    this.setState({
      openShowInfoModal: true,
      account: this.props.init.user.accounts.data[idx]
    })
  }

  handleEditAccount(idx) {
    this.setState({
      editAccount: true,
      openAccountModal: true,
      account: this.props.init.user.accounts.data[idx]
    })
  }

  handleDeleteAccount(idx) {
    this.setState({
      openDeleteDialog: true,
      account: this.props.init.user.accounts.data[idx]
    })
  }

  // Dialog handlers

  handleCancelDeleteAccount() {
    this.setState({
      openDeleteDialog: false
    })
  }

  handleConfirmDeleteAccount() {
    this.setState({
      openDeleteDialog: false
    })
  }

  handleDownloadEstadoCuenta(contrato){
    this.props.requestEstadoCuenta({ contratoId: contrato.contractId, tipoFormatoEstadoCuenta: 1 })
  }

  
  // Modal handlers

  handleCloseShowInfoModal() {
    this.setState({openShowInfoModal: false})
  }

  handleConfirmAccountModal() {
    this.setState({openAccountModal: false})
  }

  handleCloseAccountModal() {
    this.setState({openAccountModal: false})
  }

  handleNewWaterAccount() {
    this.setState({
      openAccountModal: true,
      editAccount: false
    })
  }

  // Others

  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  getAccounts() {
    return this.props.init.user.accounts.data
  }

  renderWithAccounts(classes) {
    return (
      <div>
        <Title 
          title={Utils.getMessages().Accounts.title}
          description={Utils.getMessages().Accounts.description}
        />
        <AccountTable 
          className={classes.accountTable}
          onShowInfo={this.handleShowInfo}
          onEdit={this.handleEditAccount}
          onDelete={this.handleDeleteAccount}
          accounts={this.getAccounts()}
          onDownloadEstadoCuenta={ this.handleDownloadEstadoCuenta }
        />
        <Stepper
          className={classes.stepper}
          next={{
            name: Utils.getMessages().Accounts.addAccountButton
          }}
          onClickNext={this.handleNewWaterAccount}
        />

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.message}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <AccountModal
          open={this.state.openAccountModal}
          editMode={this.state.editAccount}
          account={this.state.account}
          handleConfirm={this.handleConfirmAccountModal}
          handleClose={this.handleCloseAccountModal}
        />

        <ShowInfoModal
          open={this.state.openShowInfoModal}
          account={this.state.account}
          handleClose={this.handleCloseShowInfoModal}
        />

        <DeleteDialog
          open={this.state.openDeleteDialog}
          title={Utils.getMessages().Accounts.deleteAccountTitle}
          description={
            <span>{Utils.getMessages().Accounts.deleteAccountDescription} <strong>{this.state.account.account}</strong></span>
          }
          dataType="account"
          data={this.state.account}
          onCancel={this.handleCancelDeleteAccount}
          onConfirm={this.handleConfirmDeleteAccount}
        />

      </div>
    )
  }

  render() {
    
    const { classes } = this.props
    const { loading, showAccounts } = this.state


    if(!loading){
      if (this.state.showAccounts) {
        return this.renderWithAccounts(classes)
      }
      else {
        return (
          <div>
            <Empty
              title={Utils.getMessages().Accounts.emptyTitle}
              description={Utils.getMessages().Accounts.emptyDescription}
              emptyImg={accountsEmptyImg}
              buttonTitle={Utils.getMessages().Accounts.addAccountButton}
              callToAction={() => { this.handleNewWaterAccount() }}
            />
            <AccountModal
              open={this.state.openAccountModal}
              handleConfirm={this.handleConfirmAccountModal}
              handleClose={this.handleCloseAccountModal}
            />
          </div>
        )
      }
    } else {
      return <CircleLoading mensajeCargando={Utils.getMessages().Accounts.loading} />
    }
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestAccountsByUser: (userId) => {
      dispatch(requestAccountsByUser(userId))
    },
    requestEstadoCuenta: (data) => {
      dispatch(requestEstadoCuenta(data))
    },
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AccountSetting)
