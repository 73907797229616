import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = () => ({

});

class RadioOptions extends Component {

    constructor(props){
        super(props)
      
        this.state = {
			stateRadio: this.props.stateRadio || {
                value: null
            }
        }
		this.handleChange = this.handleChange.bind(this)
    }

	handleChange = (event) => {
        let stateRadio = this.state.stateRadio
        stateRadio.value = event.target.value
        
        this.setState({ stateRadio: stateRadio })
        this.props.handleSetStateParent()
    }

    render() {
        const { stateRadio } = this.state
		return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{ this.props.topic}</FormLabel>
            <RadioGroup row  aria-label={this.props.name} name={this.props.name} value={stateRadio.value} onChange={this.handleChange}>
                {
                    this.props.options.map( (v, i) => {
                        return <FormControlLabel key={i} value={v.value} control={<Radio size="small" color="primary" checked={ v.value === parseInt(stateRadio.value)} />} label={v.label} />
                        
                    } )
                }    
            </RadioGroup>
        </FormControl>
		)
	}
}



const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = () => {
  return {

  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(RadioOptions)

