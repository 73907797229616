import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'

import Utils from '../resources/Utils'

// Actions
import { requestTimbrar } from '../actions/actions'
import { CircularProgress, MenuItem, Select } from '@material-ui/core' 
import MySnackbarContentWrapper from './CustomSnackBar'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  modalContainer: {
    overflow: 'scroll'
  },
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      background: 'white',
      width: '90%',
      height: '100%',
      paddingTop: '20%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  modalTitle: {
    fontWeight: 600
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  smallTextField: {
    width: '30%',
    paddingRight: '3%',
    marginTop: 12
  },
  LastSmallTextField: {
    width: '33%',
    marginTop: 12
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  },
  select: {
    width: "100%"
  },
  root: {
    display: 'flex',
    float: 'right',
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

class CFDIModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contribuyenteSelected: 0,
      contribuyentes: [], 
      socialName: '',
      rfc: '',
      loading: false,
      errorMessage: '',
      openSnack: false
    }
    this.handleChangeSocialName = this.handleChangeSocialName.bind(this)
    this.handleChangeRFC = this.handleChangeRFC.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.clearData = this.clearData.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleRender = this.handleRender.bind(this)
    this.changeContribuyente = this.changeContribuyente.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (Utils.isUserLoggedIn()) {

      if (prevProps.init.timbrar !== this.props.init.timbrar) {
        const { data, status } = this.props.init.timbrar
        if(status === Utils.constants.status.ok){
          this.handleClose()
        } else {
          this.setState({
            errorMessage: 'No se ha podido realizar el timbre',
            openSnack: true,
            variant: 'error'
          })       
        }
        this.setState({loading: false})
      }
    }
  }

  handleChangeSocialName(event) {
    this.setState({socialName: event.target.value})
  }

  handleChangeRFC(event) {
    this.setState({rfc: event.target.value})
  }

  handleChangeEmail(event) {
    let emails = [event.target.value]
    this.setState({emails: emails})
  }

  handleConfirm() {
    this.setState({loading: true})
    this.props.requestTimbrar({ 
      comprobantePagoId: this.props.payment.comprobantePagoID,
      rfc: this.state.rfc,
      contratoIdContribuyente: this.props.contrato.contractId})

  }

  handleCloseSnackbar() {
    this.setState({errorMessage: '', openSnack: false})
  }

  handleClose() {
    this.clearData()
    this.props.handleClose()
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleConfirm()
    }
  }

  changeContribuyente(event){
    const { contribuyentes } = this.props

    this.setState({
      contribuyenteSelected: event.target.value
    })

    const contribuyente = contribuyentes.find( x => x.contribuyenteID === event.target.value)
    if(contribuyente){
      this.setState({
        socialName: contribuyente.razonSocial,
        rfc: contribuyente.rfc
      })
    }

  }

  handleRender() {
    const { contribuyenteID, rfc, razonSocial } =  this.props.contribuyentes[0]
    this.setState({
      contribuyenteSelected: contribuyenteID,
      rfc: rfc,
      socialName: razonSocial
    })
  }

  clearData() {
    this.setState({
      socialName: '',
      rfc: '',
    })
  }

  render() {
    const { classes, contribuyentes } = this.props
    const { loading } = this.state
    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
        className={classes.modalContainer}
        onRendered={this.handleRender}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
            {Utils.getMessages().CFDIModal.title}
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
            {Utils.getMessages().CFDIModal.description}
          </Typography>
          <form noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>


            <Select
              value={this.state.contribuyenteSelected}
              onChange={(event) => {this.changeContribuyente(event)}}
              className={classes.select}
            >
              {
                contribuyentes.map((contribuyente, idx) => {
                  return (<MenuItem key={idx} value={contribuyente.contribuyenteID}><strong>{contribuyente.rfc}</strong> - ({contribuyente.razonSocial})</MenuItem>)
                })
              }
            </Select>


            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().CFDIModal.placeholderSocialName}
              type="text"
              multiline
              rows={3}
              value={this.state.socialName}
              onChange={this.handleChangeSocialName}
              disabled={true}
              autoFocus
            />
            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().CFDIModal.placeholderRFC}
              type="text"
              value={this.state.rfc}
              onChange={this.handleChangeRFC}
              disabled={true}
            />

            <div className={classes.root}>
              <Button
                  onClick={this.handleClose}
                  disabled={loading}
                >
                {Utils.getMessages().CFDIModal.cancelButton}
              </Button>

              <div className={classes.wrapper}>
                <Button
                  className={classes.primaryButton}
                  variant="contained"
                  color="primary"
                  onClick={this.handleConfirm}
                  disabled={loading}
                >
                 {Utils.getMessages().CFDIModal.confirmButton}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
              
            </div>
          </form>

              
          <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={this.state.openSnack}
            autoHideDuration={5000}
            onClose={this.handleCloseSnackbar}
          >
            <MySnackbarContentWrapper
             onClose={this.handleCloseSnackbar}
              variant={this.state.variant}
              message={
                <span>{this.state.errorMessage}</span>
              }
            />
          </Snackbar>

        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestTimbrar: (data) => {
      dispatch(requestTimbrar(data))
    },
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CFDIModal)
