import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import MailIcon from '@material-ui/icons/Mail';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CodeIcon from '@material-ui/icons/Code';
import Utils from '../resources/Utils'
import ReceiptIcon from '@material-ui/icons/Receipt';

const styles = theme => ({
  headerTable: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    fontWeight: 700
  },
  headerAmount: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    fontWeight: 700,
    float: 'right',
    width: 100
  },
  smallCell: {
    margin: 0,
    padding: 0
  },
  emojiAlias: {
    marginRight: 8,
    width: 18
  },
  hidden: {
    display: 'none'
  },
  money: {
    textAlign: 'right',
    margin: 0,
    padding: 0
  }
})

class PaymentSuiteListTable extends Component {
  render() {
    const { classes, contribuyentes } = this.props

    const showTimbrar = contribuyentes.length > 0

    return (
      <Table>
        <TableHead>
          <TableRow>
          
            <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Payments.place}</Typography></TableCell>
            <TableCell className={classes.money}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Payments.amount}</Typography></TableCell>
            
            <Hidden smDown>
              <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Payments.date}</Typography></TableCell>
            </Hidden>

            <Hidden smDown>
              <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Payments.typePay}</Typography></TableCell>
            </Hidden>

            <Hidden>
              <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Payments.comprobante}</Typography></TableCell>
            </Hidden>

            {
              showTimbrar ?
              <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Payments.cfdi}</Typography></TableCell>
              : null
            }
          </TableRow>
        </TableHead>
      <TableBody>
      {
        this.props.payments.map((payment, idx) => {

          return (
            <TableRow key={idx}>

              <TableCell><Typography variant="body2"> {payment.caja}</Typography></TableCell>
              <TableCell className={classes.money}><Typography variant="body2">$ {Utils.numberWithCommas(payment.importePago)}</Typography></TableCell>
            
              <Hidden smDown>
                <TableCell><Typography variant="body2">{Utils.formatDate(payment.fechaPago)}</Typography></TableCell>
              </Hidden>

              <Hidden smDown>
                <TableCell><Typography variant="body2">{payment.tipoPago}</Typography></TableCell>
              </Hidden>

              <Hidden>
                <TableCell>
                {
                    payment.comprobantePagoID ? 
                      <IconButton color="primary" onClick={() => { this.props.comprobantePago(payment) }}><ReceiptIcon /></IconButton> 
                    :
                    null
                  }
                </TableCell>
              </Hidden>

              {
                showTimbrar ? 
                  <TableCell>
                  {
                    !payment.comprobanteID ? 
                      <IconButton color="primary" onClick={() => { this.props.cfdi(payment) }}><SendIcon /></IconButton> 
                    :
                    <span>                    
                      <IconButton title={"Descargar PDF"} color="primary" onClick={() => { this.props.printAsPdf(payment) }}><PictureAsPdfIcon /></IconButton> 
                      <IconButton title={"Descargar XML"} color="primary" onClick={() => { this.props.printAsXML(payment) }}><CodeIcon /></IconButton>
                      <IconButton title={"Enviar correo"} color="primary" onClick={() => { this.props.sendMail(payment) }}><MailIcon /></IconButton>
                    </span>
                  }
                  </TableCell> : null
              }

            
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, null)
)(PaymentSuiteListTable)
