import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Components
import WelcomeMessage from '../components/WelcomeMessage'
import InitForm from '../components/InitForm'
import LoginForm from '../components/LoginForm'

// Actions
import { deleteWaterAccount } from '../actions/actions'
import Utils from '../resources/Utils'

const styles = theme => ({
  home: {
    margin: "0 auto",
    marginTop: 122,
    [theme.breakpoints.down('lg')]: {
      width: "75%"
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 104,
      width: "85%"
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
      textAling: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
      width: '95%'
    }
  }
})

class Home extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      openSnack: false,
      messageSnack: ''
    }
    
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.getWelcomeTitle = this.getWelcomeTitle.bind(this)
    this.getWelcomeDescription = this.getWelcomeDescription.bind(this)
    this.allowFastFlow = this.allowFastFlow.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init !== this.props.init) {
      if (this.props.init.reset) {
        this.setState({
          openSnack: true,
          messageSnack: Utils.getMessages().Profile.deleteUserAccountOk
        })
      }
    }
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      this.props.history.push("/cuentas")
    }
    else {
      this.props.deleteWaterAccount()
    }
  }

  handleCloseSnackbar() {
    this.setState({
      openSnack: false,
      messageSnack: ''
    })
  }

  allowFastFlow() {
    if (this.props.init.config.data.fastFlow === 0) {
      return false
    }
    return true
  }

  getWelcomeTitle() {
    if (Utils.checkLanguage().toLowerCase() !== 'es') {
      return this.props.init.config.data.welcomeTitleEN
    }
    return this.props.init.config.data.welcomeTitle
  }

  getWelcomeDescription() {
    if (Utils.checkLanguage().toLowerCase() !== 'es') {
      return this.props.init.config.data.welcomeDescriptionEN
    }
    return this.props.init.config.data.welcomeDescription
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        {
          (!Utils.isEmpty(this.props.init.config.data)) ?
            <Grid 
              container
              justify="flex-start"
              alignItems="flex-start"
              className={classes.home}
            >
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <WelcomeMessage 
                  welcomeTitle={this.getWelcomeTitle()}
                  welcomeDescription={this.getWelcomeDescription()}
                  mainLogo={this.props.init.config.data.mainLogo}
                  secondaryLogo={this.props.init.config.data.secondaryLogo}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {
                  (this.allowFastFlow()) ?
                    <InitForm/>
                  :
                    <LoginForm/>
                }
              </Grid>
            </Grid>
          :

          ''
        }

        <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={this.state.openSnack}
            onClose={this.handleCloseSnackbar}
            message={
              <span>{this.state.messageSnack}</span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    deleteWaterAccount: () => {
      dispatch(deleteWaterAccount())
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Home)
