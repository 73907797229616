import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// Utils
import Utils from '../resources/Utils'
import { requestDeleteAccount, requestDeleteCard, requestDeleteScheduledPayments } from '../actions/actions'

const styles = theme => ({
  container: {
    padding: 16
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  }
})

class DeleteDialog extends Component {
  constructor(props) {
    super(props)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.user.accounts !== this.props.init.user.accounts) {
      this.props.onConfirm()
    }
    if (prevProps.init.user.cards !== this.props.init.user.cards) {
      this.props.onConfirm()
    }
    if (prevProps.init.scheduledPayments !== this.props.init.scheduledPayments) {
      this.props.onConfirm()
    }
  }

  async handleConfirm() {
    const userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER)).data.id
    if (this.props.dataType === 'account') {
      this.props.requestDeleteAccount({
        userId: userId,
        accountNumber: this.props.data.account
      })
    }
    else if (this.props.dataType === 'card') {
      this.props.requestDeleteCard({
        id: this.props.data.id,
        userId: userId
      }) 
    }
    else if (this.props.dataType === 'scheduledPayment') {
      this.props.requestDeleteScheduledPayments({
        id: this.props.data.id,
        userId: userId
      })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Dialog
        open={this.props.open}
        onBackdropClick={this.props.onCancel}
        onEscapeKeyDown={this.props.onCancel}
      >
        <div className={classes.container}>
          <DialogTitle>
            <strong>{this.props.title}</strong>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onCancel}>
              {Utils.getMessages().DeleteDialog.cancelButton}
            </Button>
            <Button 
              className={classes.primaryButton}
              color="primary"
              variant="contained"
              onClick={this.handleConfirm}
            >
              {Utils.getMessages().DeleteDialog.confirmButton}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestDeleteAccount: (data) => {
      dispatch(requestDeleteAccount(data))
    },
    requestDeleteCard: (data) => {
      dispatch(requestDeleteCard(data))
    },
    requestDeleteScheduledPayments: (data) => {
      dispatch(requestDeleteScheduledPayments(data))
    }
  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteDialog)
