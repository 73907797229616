import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

import CustomTabs from '../components/Tabs'

import DatosContrato from '../components/DatosContratoUsuario'

import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import SaveIcon from '@material-ui/icons/Save';

import Snackbar from '@material-ui/core/Snackbar'
import MySnackbarContentWrapper from '../components/CustomSnackBar'

// Utils
import Utils from '../resources/Utils'
import DatosContratoDireccion from '../components/DatosContratoDireccion'
import { requestActualizarImagenUsuario, requestGuardarImagenUsuario, requestGuardarPersona, requestImagenesUsuario, requestUsuarioDireccion } from '../actions/actions'
import CircleLoading from '../components/CircleLoading'

const styles = theme => ({
	wrapper: {
		margin: theme.spacing(1) ,
		position: 'relative',
	}, 
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
})

class DatosCompletosContrato extends Component {
	constructor(props) {
		super(props)

		this.state = {
			estadoArchivos: [],
			
			tabState: { index: 0},
			stepperStateUsuario: { activeStep: 0 },
			stepperStateDireccion: { activeStep: 0 },
			datosFilesDireccion:{form:{}},
			datosUsuario: { form:{} },
			datosEmpresa:{ form:{} },
			datosContrato: { form:{} },
			
			datosFilesUsuario:{form:{}},
			
			
			datosDireccion:{form:{}},
			
			stateDataMap: {},
			loading: false,
			messageLoading: '',
			stateTipoUsuario:{
				value: 1
			},
			openSnack: false,
			variant: 'warning',
			errorMessage: '',
			
		}

		this.handleSetState = this.handleSetState.bind(this)
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
		this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this)
		this.guardarImagenes = this.guardarImagenes.bind(this)
		this.guardarDatos = this.guardarDatos.bind(this)
	}
	
	async componentDidUpdate(prevProps) {
		if (Utils.isUserLoggedIn()) {
			if (prevProps.init.guardarImagenUsuario !== this.props.init.guardarImagenUsuario) {
				const { isValid, message, data } = this.props.init.guardarImagenUsuario	
				if(isValid){
					let { estadoArchivos } = this.state
					let archivosNoSincronizados = estadoArchivos.filter( x => x.sincronizado === false )
					const indexArchivoSincronizado = estadoArchivos.findIndex( x => x.tipoImagenUsuarioId === parseInt(data.tipoImagenUsuarioId))
					if(indexArchivoSincronizado > -1){
						estadoArchivos[indexArchivoSincronizado].sincronizado = true
						this.setState({ estadoArchivos: estadoArchivos })
						archivosNoSincronizados = estadoArchivos.filter( x => x.sincronizado === false )
						if(archivosNoSincronizados.length > 0){
							this.setState({ loading: true, messageLoading: this.getMessageImagenLoading(parseInt(archivosNoSincronizados[0].tipoImagenUsuarioId)) })
							this.guardarImagen({ 
								imagen: archivosNoSincronizados[0].file.file, 
								tipoImagenUsuarioId: archivosNoSincronizados[0].tipoImagenUsuarioId  })
						} else {
							this.setState({ loading: true, messageLoading: "GUARDANDO DATOS.." })
							this.guardarDatos()
						}
					} else {
						this.setState({ loading: false} )
						this.handleOpenSnackbar({message: 'Algo paso al guardar los datos'})
					}
					
				} else {
					this.setState({ loading: false} )
					this.handleOpenSnackbar({message: message})
				}
			}

			if (prevProps.init.guardarUsuarioDireccion !== this.props.init.guardarUsuarioDireccion) {
				const { isValid, message, data } = this.props.init.guardarUsuarioDireccion	
				if(isValid){
					this.setState({ loading: false} )
					this.handleOpenSnackbar({message: "Datos e Imagenes guardados correctamente..", variant: 'success' })
					this.props.history.push(Utils.constants.paths.profile)
				} else {
					this.setState({ loading: false} )
					this.handleOpenSnackbar({message: message})
				}
			}

			if (prevProps.init.usuarioDireccion !== this.props.init.usuarioDireccion) {
				const { data, isValid, message } = this.props.init.usuarioDireccion
				if(isValid){
					const { monitorRequestUsers, usersOomsDirection, usersOomsUser } = data
					if(monitorRequestUsers && usersOomsDirection && usersOomsUser){
						if(!monitorRequestUsers.validated){
							this.props.history.push(Utils.constants.paths.profile)
						}
					} 
				} 
			}
		}

	}

	async componentDidMount() {
		Utils.scrollTop()
		if (Utils.isUserLoggedIn()) {
			let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
			userId = userId.data.id
			this.props.requestUsuarioDireccion({ userId:userId })
		}
	}

	handleOpenSnackbar({message, variant}) {
		this.setState({openSnack: true, variant: variant || 'warning', errorMessage: message })
	}

	handleCloseSnackbar() {
		this.setState({openSnack: false})
	}

	handleSetState(){
		this.setState({})
	}

	getMessageImagenLoading(id){
		switch (id) {
			case 1:
				return "SUBIENDO IMAGEN DE IDENTIFICACIÓN"		
			case 2:
				return "SUBIENDO IMAGEN DE SELFIE"			
			case 3:
				return "SUBIENDO IMAGEN DE COMPROBANTE DE DOMICILIO"		
			default:
				break;
		}
	}

	guardarDatos(){

		const { 
			datosContrato, 
			stateTipoUsuario,
			datosEmpresa,
			datosDireccion, 
			stateDataMap, 
			datosUsuario, 
		} = this.state

		const { value } = stateTipoUsuario
		const { selectedtipoContrato, selectedtipoGiro } = datosContrato.form								
		const { codigoPostal, direccionCompleta, fraccion, lote, manzana, nivel, numeroCatastral, numeroExt, numeroInt, numeroTomas, selectedcalle, selectedciudad, selectedcolonia, selectedcumunidad, selectedentreCalle1, selectedentreCalle2 } = datosDireccion.form
		const { currentMarkerClicked } = stateDataMap

		let dataUsuario = {}
		let	dataDireccion = {}
		const userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))

		console.log(value)
		if(parseInt(value) === 1){//USUARIO
			const { apellidoMaterno, apellidoPaterno, fechaNacimiento, nombre, rfc, selectedsexo } = datosUsuario.form

			var fcNacimiento = null
			var dtN = new Date(fechaNacimiento),
				current_date = dtN.getDate(),
				current_month = dtN.getMonth() + 1,
				current_year = dtN.getFullYear()

			fcNacimiento = current_year + '-' +  
			( (current_month < 10) ?  ( '0' + current_month ) : current_month ) + '-' + 
			( (current_date < 10) ?  ( '0' + current_date ) : current_date )

			dataUsuario = {									
				userId: userId.data.id,
				giroId: selectedtipoGiro ? selectedtipoGiro.value : '',
				tipoContratoId: selectedtipoContrato ? selectedtipoContrato.value : '',
				tipoUsuarioId: 1,
				apMaterno: apellidoMaterno,
				apPaterno: apellidoPaterno,
				fechaNacimiento: fcNacimiento,
				nombre: nombre,
				RFC: rfc,
				sexoId: selectedsexo ? selectedsexo.value : '',
			}

		} else if (parseInt(value) ===  2){//EMPRESA
			const { correo, nombreComercial, razonSocial, rfc } = datosEmpresa.form
			dataUsuario = {									
				userId:  userId.data.id,
				giroId: selectedtipoGiro ? selectedtipoGiro.value : '',
				tipoContratoId: selectedtipoContrato ? selectedtipoContrato.value : '',
				tipoUsuarioId: 2,
				correoElecEmpresa: correo,
				nombreComercial: nombreComercial,
				razonSocial: razonSocial,
				rfcEmpresa: rfc,
			}
		}

		dataDireccion = {
			latitud: currentMarkerClicked.lat,
			longitud: currentMarkerClicked.lng,
			ciudad: selectedciudad ? selectedciudad.label : '',
			colonia: selectedcolonia ? selectedcolonia.label : '',
			comunidad: selectedcumunidad ? selectedcumunidad.label : '',
			calle: selectedcalle ? selectedcalle.label : '',
			entreCalle1: selectedentreCalle1 ? selectedentreCalle1.label : '',
			entreCalle2: selectedentreCalle2 ? selectedentreCalle2.label : '',
			noExt: numeroExt,
			noInt: numeroInt,
			cp: codigoPostal,
			manzana: manzana,
			lote: lote,
			nivel: nivel,
			fraccion: fraccion,
			numeroTomas: numeroTomas,
			numeroCatastral: numeroCatastral,
			direccionCompleta : direccionCompleta,
		}
		
		this.props.requestGuardarPersona({
			usersOomsUser: dataUsuario,
			usersOomsDirection: dataDireccion,
		})
	}

	guardarImagenes(){
		const { 
			datosFilesUsuario, 
			datosFilesDireccion
		} = this.state

		
		const { evidenciaIdentificacion, evidenciaSelfie } = datosFilesUsuario.form
		const { evidenciaDomicilio } = datosFilesDireccion.form
		const files = [ { file: evidenciaIdentificacion, sincronizado: false,  tipoImagenUsuarioId: 1}, { file: evidenciaSelfie, sincronizado: false, tipoImagenUsuarioId: 2 }, { file: evidenciaDomicilio, sincronizado: false, tipoImagenUsuarioId: 3 }]
		this.setState({ 
			estadoArchivos: files, 
			loading: true,
			messageLoading: this.getMessageImagenLoading(files[0].tipoImagenUsuarioId)
		})
		this.guardarImagen({ 
			imagen: files[0].file.file, 
			tipoImagenUsuarioId: files[0].tipoImagenUsuarioId  
		})
	}

	guardarImagen({ imagen, tipoImagenUsuarioId }){
		const userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
		let formData = new FormData();
		formData.append('image', imagen)
		formData.append('userId', userId.data.id)
		formData.append('tipoImagenUsuarioId', tipoImagenUsuarioId)

		this.props.requestGuardarImagenUsuario(formData)
	}

	render() {

		const { 
			datosContrato, 
			loading, 
			messageLoading,
			stateTipoUsuario,
			datosEmpresa,
			datosDireccion, 
			stateDataMap, 
			datosUsuario, 
			datosFilesUsuario, 
			tabState, 
			stepperStateUsuario,
			stepperStateDireccion,
			datosFilesDireccion
		} = this.state
		const { classes } = this.props
		
		let allready = tabState.formDatosContratoDireccion && tabState.formDatosContratoUsuario

		console.log("DATOS USUARIO", datosUsuario)
		console.log("DATOS EMPRESA", datosEmpresa)
		console.log("DATOS CONTRATO", datosContrato)
		console.log("DATOS FILES USUARIO", datosFilesUsuario)
		console.log("DATOS DIRECCION", datosDireccion)
		console.log("DIRECCION FILES", datosFilesDireccion)
		console.log("DATOS MAPA", stateDataMap)

		return (
			<div>

				{
				loading ? 
				<CircleLoading mensajeCargando={messageLoading} />
				: 
				<CustomTabs
					state= { tabState }
					loading= {loading}
					actions= {
						[
							{
								textName:"Guardar",
								icon: <SaveIcon></SaveIcon>,
								handleClick: (e) => {
									console.log("Guardar solicitud de datos")

									this.guardarImagenes()

								},
								color:"primary",
								display: allready
							}
						]
					}
					tabs={
						[
							{
								descripcion: "DATOS DEL CONTRATO",
								contenido:  
									<DatosContrato
										tabState={tabState}
										stepperState={stepperStateUsuario}
										stateTipoUsuario={stateTipoUsuario}
										datosEmpresa={datosEmpresa}
										datosUsuario={datosUsuario}
										datosContrato={datosContrato}
										datosFilesUsuario={datosFilesUsuario}
										handleSetState= {this.handleSetState}
										handleOpenSnackbar = { this.handleOpenSnackbar}
									/>,
								icon: <PersonIcon></PersonIcon>
							},
							{
								descripcion: "DIRECCIÓN",
								contenido:  
									<DatosContratoDireccion
										tabState={tabState}
										stepperState={stepperStateDireccion}
										datosDireccion={datosDireccion}
										stateDataMap= {stateDataMap}
										datosFilesDireccion= {datosFilesDireccion}
										handleSetState= {this.handleSetState}
										handleOpenSnackbar = { this.handleOpenSnackbar}
									></DatosContratoDireccion>,
								icon: <HomeIcon></HomeIcon>
							},
						]
					}
				/>
				}

				<Snackbar
					anchorOrigin={{vertical: 'top', horizontal: 'center'}}
					open={this.state.openSnack}
					autoHideDuration={5000}
					onClose={this.handleCloseSnackbar}
				>
					<MySnackbarContentWrapper
						onClose={this.handleCloseSnackbar}
						variant={this.state.variant}
						message={
							<span>{this.state.errorMessage}</span>
						}
					/>
				</Snackbar>

			</div>
		)
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = (dispatch) => {
	return {
		requestGuardarImagenUsuario: (data) => {
			dispatch(requestGuardarImagenUsuario(data))
		},
		requestGuardarPersona: (data) => {
			dispatch(requestGuardarPersona(data))
		},
		requestImagenesUsuario: (data) => {
			dispatch(requestImagenesUsuario(data))
		},
		requestActualizarImagenUsuario: (data) => {
			dispatch(requestActualizarImagenUsuario(data))
		},
		requestUsuarioDireccion: (data) => {
			dispatch(requestUsuarioDireccion(data))
		},
	}
}

export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(DatosCompletosContrato)
