import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Utils
import Utils from '../resources/Utils'
import { requestRegisterValidToken, requestRecoveryValidToken, requestNewPassword } from '../actions/actions'

const styles = theme => ({
  form: {
    margin: '0 auto',
    marginTop: 16,
    backgroundColor: theme.palette.background.secondary,
    padding: "16px 32px"
  },
  textField: {
    width: '100%',
    marginTop: 8
  },
  mainButton: {
    width: '100%',
    marginTop: 16,
    fontWeight: 600,
    fontSize: 14
  }
})

class NewPasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openSnack: false,
      message: '',
      password: '',
      confirmPassword: ''
    }

    this.handleCloseSnack = this.handleCloseSnack.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentWillMount() {
    if (this.props.flow === 'register') {
      this.props.requestRegisterValidToken(this.props.match.params.token)
    }
    else {
      this.props.requestRecoveryValidToken(this.props.match.params.token)
    }
  }

  componentDidUpdate(prevProps) {
    // Validar token
    if (prevProps.init.registerToken !== this.props.init.registerToken) {      
      if (!this.props.init.registerToken.isValid) {
        this.props.history.push(Utils.constants.paths.home)
        console.log("INVAID TOKEN")
      }
    }

    // Validar token
    if (prevProps.init.recoveryToken !== this.props.init.recoveryToken) {
      if (!this.props.init.recoveryToken.isValid) {
        this.props.history.push(Utils.constants.paths.home)
      }
    }

    // Nuevo password
    if (prevProps.init.newPassword !== this.props.init.newPassword) {
      if (this.props.init.newPassword) {
        this.props.history.push(Utils.constants.paths.login)
      }
      else {
        this.setState({
          openSnack: true,
          message: Utils.getMessages().General.errorNewPassword
        })
      }
    }
  }

  handleCloseSnack() {
    this.setState({
      openSnack: false,
      message: ''
    })
  }

  handleConfirm() {
    if (this.state.password.trim().length >= Utils.constants.MIN_PASSWORD) {
      if (this.state.password.trim() === this.state.confirmPassword.trim()) {
        const token = this.props.match.params.token
        let data = {
          token: token,
          password: this.state.password
        }
        this.props.requestNewPassword(data)
      }
      else {
        this.setState({
          openSnack: true,
          message: Utils.getMessages().Profile.errorConfirmPassword
        })
      }
    }
    else {
      this.setState({
        openSnack: true,
        message: Utils.getMessages().Profile.notSecurePassword
      })
    }
  }

  handleChangePassword(event) {
    this.setState({password: event.target.value})
  }

  handleChangeConfirmPassword(event) {
    this.setState({confirmPassword: event.target.value})
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleConfirm()
    }
  }
  
  render() {

    const { classes } = this.props

    return (
      <form className={classes.form} noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
        <TextField
          label={Utils.getMessages().RecoveryPassword.placeholderNewPassword}
          type="password"
          value={this.state.password}
          onChange={this.handleChangePassword}
          className={classes.textField}
          autoFocus
        />
        <TextField
          label={Utils.getMessages().RecoveryPassword.placeholderConfirmNewPassword}
          type="password"
          value={this.state.confirmPassword}
          onChange={this.handleChangeConfirmPassword}
          className={classes.textField}
        />
        {
          (this.props.init.config.data) ?
            <Button
              variant="contained"
              color="primary"
              className={classes.mainButton}
              onClick={this.handleConfirm}
            >
              {Utils.getMessages().RecoveryPassword.confirmButton}
            </Button>
            :
            ''
        }
        
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnack}
          message={
            <span>
              {this.state.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnack}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

      </form>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestNewPassword: (data) => {
      dispatch(requestNewPassword(data))
    },
    requestRegisterValidToken: (token) => {
      dispatch(requestRegisterValidToken(token))
    },
    requestRecoveryValidToken: (token) => {
      dispatch(requestRecoveryValidToken(token))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(NewPasswordForm)
