export const RESET_APP_STATE = "RESET_APP_STATE"

export const resetAppState = () => ({
  type: RESET_APP_STATE
})

//

export const REQUEST_OFFICE_CONFIG = "REQUEST_OFFICE_CONFIG"
export const REQUEST_EDIT_OFFICE_CONFIG = "REQUEST_OFFICE_CONFIG"
export const REQUEST_PAYMENTS_BY_OFFICE_ID = "REQUEST_PAYMENTS_BY_OFFICE_ID"
export const REQUEST_USERS_BY_OFFICE_ID = "REQUEST_USERS_BY_OFFICE_ID"
export const REQUEST_LOGS_PAYMENTS_USERS_BY_OFFICE_ID = "REQUEST_LOGS_PAYMENTS_USERS_BY_OFFICE_ID"
export const REQUEST_ACCOUNTS_LOGS_PAYMENTS_USERS_BY_EMAIL = "REQUEST_ACCOUNTS_LOGS_PAYMENTS_USERS_BY_EMAIL"
export const REQUEST_DESCRIPTIONS_LOGS_PAYMENTS_USERS_BY_ACCOUNT = "REQUEST_DESCRIPTIONS_LOGS_PAYMENTS_USERS_BY_ACCOUNT"
export const REQUEST_EDIT_USERS_BY_EMAIL = "REQUEST_EDIT_USERS_BY_EMAIL"
export const RESPONSE_OFFICE_CONFIG = "RESPONSE_OFFICE_CONFIG"
export const RESPONSE_EDIT_OFFICE_CONFIG = "REQUEST_OFFICE_CONFIG"
export const RESPONSE_PAYMENTS_BY_OFFICE_ID = "RESPONSE_PAYMENTS_BY_OFFICE_ID"
export const RESPONSE_USERS_BY_OFFICE_ID = "RESPONSE_USERS_BY_OFFICE_ID"
export const RESPONSE_LOGS_PAYMENTS_USERS_BY_OFFICE_ID = "RESPONSE_LOGS_PAYMENTS_USERS_BY_OFFICE_ID"
export const RESPONSE_ACCOUNTS_LOGS_PAYMENTS_USERS_BY_EMAIL = "RESPONSE_ACCOUNTS_LOGS_PAYMENTS_USERS_BY_EMAIL"
export const RESPONSE_DESCRIPTIONS_LOGS_PAYMENTS_USERS_BY_ACCOUNT = "RESPONSE_DESCRIPTIONS_LOGS_PAYMENTS_USERS_BY_ACCOUNT"
export const RESPONSE_EDIT_USERS_BY_EMAIL = "RESPONSE_EDIT_USERS_BY_EMAIL"


export const requestOfficeConfig = (officeId) => ({
  type: REQUEST_OFFICE_CONFIG,
  officeId
})
export const requestEditOfficeConfigs = (data) => ({
  type: REQUEST_EDIT_OFFICE_CONFIG,
  data
})
export const requestUsersByOfficeId = (data) => ({
  type: REQUEST_USERS_BY_OFFICE_ID,
  data
})

export const responseUsersByOfficeId = (data) => ({
  type: RESPONSE_USERS_BY_OFFICE_ID,
  data
})
export const requestLogsPaymentsUsersByOfficeId = (data) => ({
  type: REQUEST_LOGS_PAYMENTS_USERS_BY_OFFICE_ID,
  data
})
export const responseLogsPaymentsUsersByOfficeId = (data) => ({
  type: RESPONSE_LOGS_PAYMENTS_USERS_BY_OFFICE_ID,
  data
})
export const requestAccountsLogsPaymentsUsersByEmail = (data) => ({
  type: REQUEST_ACCOUNTS_LOGS_PAYMENTS_USERS_BY_EMAIL,
  data
})
export const responseAccountsLogsPaymentsUsersByEmail = (data) => ({
  type: RESPONSE_ACCOUNTS_LOGS_PAYMENTS_USERS_BY_EMAIL,
  data
})
export const requestDescriptionsLogsPaymentsUsersByAccount = (data) => ({
  type: REQUEST_DESCRIPTIONS_LOGS_PAYMENTS_USERS_BY_ACCOUNT,
  data
})
export const responseDescriptionsLogsPaymentsUsersByAccount = (data) => ({
  type: RESPONSE_DESCRIPTIONS_LOGS_PAYMENTS_USERS_BY_ACCOUNT,
  data
})
export const requestEditUsersByEmail = (data) => ({
  type: REQUEST_EDIT_USERS_BY_EMAIL,
  data
})
export const responseEditUsersByEmail = (data) => ({
  type: RESPONSE_EDIT_USERS_BY_EMAIL,
  data
})
export const requestPaymentsByOfficeId = (data) => ({
  type: REQUEST_PAYMENTS_BY_OFFICE_ID,
  data
})
export const responsePaymentsByOfficeId = (data) => ({
  type: RESPONSE_PAYMENTS_BY_OFFICE_ID,
  data
})
export const responseOfficeConfig = (data) => ({
  type: RESPONSE_OFFICE_CONFIG,
  data
})
export const responseEditOfficeConfigs = (data) => ({
  type: RESPONSE_EDIT_OFFICE_CONFIG,
  data
})

//

export const REQUEST_VALIDATE_ACCOUNT_NUMBER = "REQUEST_VALIDATE_ACCOUNT_NUMBER"
export const RESPONSE_VALIDATE_ACCOUNT_NUMBER = "RESPONSE_VALIDATE_ACCOUNT_NUMBER"

export const requestValidateAccountNumber = (accountNumber) => ({
  type: REQUEST_VALIDATE_ACCOUNT_NUMBER,
  accountNumber
})

export const responseValidateAccountNumber = (data) => ({
  type: RESPONSE_VALIDATE_ACCOUNT_NUMBER,
  data
})

//

export const DELETE_WATER_ACCOUNT = "DELETE_WATER_ACCOUNT"

export const deleteWaterAccount = () => ({
  type: DELETE_WATER_ACCOUNT
})

//

export const REQUEST_DO_PAYMENT = "REQUEST_DO_PAYMENT"
export const RESPONSE_DO_PAYMENT = "RESPONSE_DO_PAYMENT"
export const EDIT_AMOUNT = "EDIT_AMOUNT"

export const requestDoPayment = (data) => ({
  type: REQUEST_DO_PAYMENT,
  data
})

export const responseDoPayment = (data) => ({
  type: RESPONSE_DO_PAYMENT,
  data
})

export const editAmount = (data) => ({
  type: EDIT_AMOUNT,
  data
})

// Users
export const REQUEST_CREATE_ACCOUNT = "REQUEST_CREATE_ACCOUNT"
export const RESPONSE_CREATE_ACCOUNT = "RESPONSE_CREATE_ACCOUNT"
export const REQUEST_LOGIN = "REQUEST_LOGIN"
export const RESPONSE_LOGIN = "RESPONSE_LOGIN"
export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD"
export const RESPONSE_CHANGE_PASSWORD = "RESPONSE_CHANGE_PASSWORD"
export const REQUEST_USER_PROFILE = "REQUEST_USER_PROFILE"
export const RESPONSE_USER_PROFILE = "RESPONSE_USER_PROFILE"
export const REQUEST_EDIT_USER_PROFILE = "REQUEST_EDIT_USER_PROFILE"
export const RESPONSE_EDIT_USER_PROFILE = "RESPONSE_EDIT_USER_PROFILE"
export const REQUEST_DELETE_USER_ACCOUNT = "REQUEST_DELETE_USER_ACCOUNT"
export const RESPONSE_DELETE_USER_ACCOUNT = "RESPONSE_DELETE_USER_ACCOUNT"
export const REQUEST_RECOVERY_PASSWORD = "REQUEST_RECOVERY_PASSWORD"
export const RESPONSE_RECOVERY_PASSWORD = "RESPONSE_RECOVERY_PASSWORD"
export const REQUEST_NEW_PASSWORD = "REQUEST_NEW_PASSWORD"
export const RESPONSE_NEW_PASSWORD = "RESPONSE_NEW_PASSWORD"
export const REQUEST_REGISTER_VALID_TOKEN = "REQUEST_REGISTER_VALID_TOKEN"
export const RESPONSE_REGISTER_VALID_TOKEN = "RESPONSE_REGISTER_VALID_TOKEN"
export const REQUEST_RECOVERY_VALID_TOKEN = "REQUEST_RECOVERY_VALID_TOKEN"
export const RESPONSE_RECOVERY_VALID_TOKEN = "RESPONSE_RECOVERY_VALID_TOKEN"

export const requestRegisterValidToken = (token) => ({
  type: REQUEST_REGISTER_VALID_TOKEN,
  token
})

export const responseRegisterValidToken = (data) => ({
  type: RESPONSE_REGISTER_VALID_TOKEN,
  data
})

export const requestRecoveryValidToken = (token) => ({
  type: REQUEST_RECOVERY_VALID_TOKEN,
  token
})

export const responseRecoveryValidToken = (data) => ({
  type: RESPONSE_RECOVERY_VALID_TOKEN,
  data
})

export const requestCreateAccount = (data) => ({
  type: REQUEST_CREATE_ACCOUNT,
  data
})

export const responseCreateAccount = (data) => ({
  type: RESPONSE_CREATE_ACCOUNT,
  data
})

export const requestLogin = (data) => ({
  type: REQUEST_LOGIN,
  data
})

export const responseLogin = (data) => ({
  type: RESPONSE_LOGIN,
  data
})

export const requestRecoveryPassword = (email) => ({
  type: REQUEST_RECOVERY_PASSWORD,
  email
})

export const responseRecoveryPassword = (data) => ({
  type: RESPONSE_RECOVERY_PASSWORD,
  data
})

export const requestNewPassword = (data) => ({
  type: REQUEST_NEW_PASSWORD,
  data
})

export const responseNewPassword = (data) => ({
  type: RESPONSE_NEW_PASSWORD,
  data
})

export const requestChangePassword = (data) => ({
  type: REQUEST_CHANGE_PASSWORD,
  data
})

export const responseChangePassword = (data) => ({
  type: RESPONSE_CHANGE_PASSWORD,
  data
})

export const requestUserProfile = (userId) => ({
  type: REQUEST_USER_PROFILE,
  userId
})

export const responseUserProfile = (data) => ({
  type: RESPONSE_USER_PROFILE,
  data
})

export const requestEditUserProfile = (data) => ({
  type: REQUEST_EDIT_USER_PROFILE,
  data
})

export const responseEditUserProfile = (data) => ({
  type: RESPONSE_EDIT_USER_PROFILE,
  data
})

export const requestDeleteUserAccount = (data) => ({
  type: REQUEST_DELETE_USER_ACCOUNT,
  data
})

export const responseDeleteUserAccount = (data) => ({
  type: RESPONSE_DELETE_USER_ACCOUNT,
  data
})

// Accounts

export const REQUEST_ACCOUNTS_BY_USER = "REQUEST_ACCOUNTS_BY_USER"
export const RESPONSE_ACCOUNTS_BY_USER = "RESPONSE_ACCOUNTS_BY_USER"

export const requestAccountsByUser = (userId) => ({
  type: REQUEST_ACCOUNTS_BY_USER,
  userId
})

export const responseAccountsByUser = (data) => ({
  type: RESPONSE_ACCOUNTS_BY_USER,
  data
})

// Bancomer
export const REQUEST_ENCRYPT_STRING_BANCOMER = "REQUEST_ENCRYPT_STRING_BANCOMER"
export const RESPONSE_ENCRYPT_STRING_BANCOMER = "RESPONSE_ENCRYPT_STRING_BANCOMER"

export const requestEncryptStringBancomer = (data) => ({
  type: REQUEST_ENCRYPT_STRING_BANCOMER,
  data
})

export const responseEncryptStringBancomer = (data) => ({
  type: RESPONSE_ENCRYPT_STRING_BANCOMER,
  data
})

// Cards

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD"
export const REQUEST_ADD_CARD = "REQUEST_ADD_CARD"
export const RESPONSE_ADD_CARD = "RESPONSE_ADD_CARD"
export const REQUEST_CARDS_BY_USER = "REQUEST_CARDS_BY_USER"
export const RESPONSE_CARDS_BY_USER = "RESPONSE_CARDS_BY_USER"
export const REQUEST_EDIT_CARD = "REQUEST_EDIT_CARD"
export const RESPONSE_EDIT_CARD = "RESPONSE_EDIT_CARD"
export const REQUEST_DELETE_CARD = "REQUEST_DELETE_CARD"
export const RESPONSE_DELETE_CARD = "RESPONSE_DELETE_CARD"

export const addPaymentMethod = (data) => ({
  type: ADD_PAYMENT_METHOD,
  data
})

export const requestAddCard = (data) => ({
  type: REQUEST_ADD_CARD,
  data
})

export const responseAddCard = (data) => ({
  type: RESPONSE_ADD_CARD,
  data
})

export const requestEditCard = (data) => ({
  type: REQUEST_EDIT_CARD,
  data
})

export const responseEditCard = (data) => ({
  type: RESPONSE_EDIT_CARD,
  data
})

export const requestDeleteCard = (data) => ({
  type: REQUEST_DELETE_CARD,
  data
})

export const responseDeleteCard = (data) => ({
  type: RESPONSE_DELETE_CARD,
  data
})

export const requestCardsByUser = (userId) => ({
  type: REQUEST_CARDS_BY_USER,
  userId
})

export const responseCardsByUser = (data) => ({
  type: RESPONSE_CARDS_BY_USER,
  data
})

//

export const ADD_ACCOUNTS_FOR_PAY = "ADD_ACCOUNTS_FOR_PAY"

export const addAccountsForPay = (data) => ({
  type: ADD_ACCOUNTS_FOR_PAY,
  data
})

//

export const SEND_EMAIL = "SEND_EMAIL"
export const RESPONSE_SEND_EMAIL = "RESPONSE_SEND_EMAIL"

export const sendEmail = (data) => ({
  type: SEND_EMAIL,
  data
})

export const responseSendEmail = (data) => ({
  type: RESPONSE_SEND_EMAIL,
  data
})

// Accounts

export const REQUEST_ADD_ACCOUNT = "REQUEST_ADD_ACCOUNT"
export const RESPONSE_ADD_ACCOUNT = "RESPONSE_ADD_ACCOUNT"
export const REQUEST_EDIT_ACCOUNT = "REQUEST_EDIT_ACCOUNT"
export const RESPONSE_EDIT_ACCOUNT = "RESPONSE_EDIT_ACCOUNT"
export const REQUEST_DELETE_ACCOUNT = "REQUEST_DELETE_ACCOUNT"
export const RESPONSE_DELETE_ACCOUNT = "RESPONSE_DELETE_ACCOUNT"

export const requestAddAccount = (data) => ({
  type: REQUEST_ADD_ACCOUNT,
  data
})

export const responseAddAccount = (data) => ({
  type: RESPONSE_ADD_ACCOUNT,
  data
})

export const requestEditAccount = (data) => ({
  type: REQUEST_EDIT_ACCOUNT,
  data
})

export const responseEditAccount = (data) => ({
  type: RESPONSE_EDIT_ACCOUNT,
  data
})

export const requestDeleteAccount = (data) => ({
  type: REQUEST_DELETE_ACCOUNT,
  data
})

export const responseDeleteAccount = (data) => ({
  type: RESPONSE_DELETE_ACCOUNT,
  data
})

//

export const CHECK_ACCOUNT = "CHECK_ACCOUNT"
export const CHECK_ALL_ACCOUNTS = "CHECK_ALL_ACCOUNTS"

export const checkAccount = (idx) => ({
  type: CHECK_ACCOUNT,
  idx
})

export const checkAllAccounts = (status) => ({
  type: CHECK_ALL_ACCOUNTS,
  status
})

// Get payments by account

export const REQUEST_GET_PAYMENTS_BY_ACCOUNT = "REQUEST_GET_PAYMENTS_BY_ACCOUNT"
export const RESPONSE_GET_PAYMENTS_BY_ACCOUNT = "RESPONSE_GET_PAYMENTS_BY_ACCOUNT"
export const REQUEST_GET_FISCAL_DATA = "REQUEST_GET_FISCAL_DATA"
export const RESPONSE_GET_FISCAL_DATA = "RESPONSE_GET_FISCAL_DATA"
export const REQUEST_GENERATE_CFDI = "REQUEST_GENERATE_CFDI"
export const RESPONSE_GENERATE_CFDI = "RESPONSE_GENERATE_CFDI"

export const requestGetPaymentsByAccount = (account) => ({
  type: REQUEST_GET_PAYMENTS_BY_ACCOUNT,
  account
})

export const responseGetPaymentsByAccount = (data) => ({
  type: RESPONSE_GET_PAYMENTS_BY_ACCOUNT,
  data
})

export const requestGetFiscalData = (account) => ({
  type: REQUEST_GET_FISCAL_DATA,
  account
})

export const responseGetFiscalData = (data) => ({
  type: RESPONSE_GET_FISCAL_DATA,
  data
})

export const requestGenerateCFDI = (data) => ({
  type: REQUEST_GENERATE_CFDI,
  data
})

export const responseGenerateCFDI = (data) => ({
  type: RESPONSE_GENERATE_CFDI,
  data
})

// Get receipts by account

export const REQUEST_GET_RECEIPTS_BY_ACCOUNT = "REQUEST_GET_RECEIPTS_BY_ACCOUNT"
export const RESPONSE_GET_RECEIPTS_BY_ACCOUNT = "RESPONSE_GET_RECEIPTS_BY_ACCOUNT"

export const requestGetReceiptsByAccount = (account) => ({
  type: REQUEST_GET_RECEIPTS_BY_ACCOUNT,
  account
})

export const responseGetReceiptsByAccount = (data) => ({
  type: RESPONSE_GET_RECEIPTS_BY_ACCOUNT,
  data
})


// Scheduled payments

export const REQUEST_SCHEDULED_PAYMENTS = "REQUEST_SCHEDULED_PAYMENTS"
export const RESPONSE_SCHEDULED_PAYMENTS = "RESPONSE_SCHEDULED_PAYMENTS"
export const REQUEST_EDIT_SCHEDULED_PAYMENTS = "REQUEST_EDIT_SCHEDULED_PAYMENTS"
export const RESPONSE_EDIT_SCHEDULED_PAYMENTS = "RESPONSE_EDIT_SCHEDULED_PAYMENTS"
export const REQUEST_DELETE_SCHEDULED_PAYMENTS = "REQUEST_DELETE_SCHEDULED_PAYMENTS"
export const RESPONSE_DELETE_SCHEDULED_PAYMENTS = "RESPONSE_DELETE_SCHEDULED_PAYMENTS"

export const requestScheduledPayments = (userId) => ({
  type: REQUEST_SCHEDULED_PAYMENTS,
  userId
})

export const responseScheduledPayments = (data) => ({
  type: RESPONSE_SCHEDULED_PAYMENTS,
  data
})

export const requestEditScheduledPayments = (data) => ({
  type: REQUEST_EDIT_SCHEDULED_PAYMENTS,
  data
})

export const responseEditScheduledPayments = (data) => ({
  type: RESPONSE_EDIT_SCHEDULED_PAYMENTS,
  data
})

export const requestDeleteScheduledPayments = (data) => ({
  type: REQUEST_DELETE_SCHEDULED_PAYMENTS,
  data
})

export const responseDeleteScheduledPayments = (data) => ({
  type: RESPONSE_DELETE_SCHEDULED_PAYMENTS,
  data
})

//

export const REQUEST_OPEN_AUTOMATIC_PAYMENT = "REQUEST_OPEN_AUTOMATIC_PAYMENT"

export const requestOpenAutomaticPayment = (data) => ({
  type: REQUEST_OPEN_AUTOMATIC_PAYMENT,
  data
})



//ORDENES DE PAGO

export const REQUEST_ORDENES_PAGO = "REQUEST_ORDENES_PAGO"
export const RESPONSE_ORDENES_PAGO = "RESPONSE_ORDENES_PAGO"

export const requestOrdenesPago = (data) => ({
  type: REQUEST_ORDENES_PAGO,
  data
})

export const responseOrdenesPago = (data) => ({
  type: RESPONSE_ORDENES_PAGO,
  data
})

//CONSULTA COMPLETA USUARIO
export const REQUEST_CONSULTA_COMPLETA = "REQUEST_CONSULTA_COMPLETA"
export const RESPONSE_CONSULTA_COMPLETA = "RESPONSE_CONSULTA_COMPLETA"

export const requestConsultaCompleta = (data) => ({
  type: REQUEST_CONSULTA_COMPLETA,
  data
})

export const responseConsultaCompleta = (data) => ({
  type: RESPONSE_CONSULTA_COMPLETA,
  data
})


//CONSULTA MENSAJES
export const REQUEST_SEND_MESSAJE_TO_CONFIRM = "REQUEST_SEND_MESSAJE_TO_CONFIRM"
export const RESPONSE_SEND_MESSAJE_TO_CONFIRM = "RESPONSE_SEND_MESSAJE_TO_CONFIRM"

export const requestSendMensajeToConfirm = (data) => ({
  type: REQUEST_SEND_MESSAJE_TO_CONFIRM,
  data
})

export const responseSendMensajeToConfirm = (data) => ({
  type: RESPONSE_SEND_MESSAJE_TO_CONFIRM,
  data
})

export const REQUEST_CONFIRM_CODE = "REQUEST_CONFIRM_CODE"
export const RESPONSE_CONFIRM_CODE = "RESPONSE_CONFIRM_CODE"

export const requestConfirmCode = (data) => ({
  type: REQUEST_CONFIRM_CODE,
  data
})

export const responseConfirmCode = (data) => ({
  type: RESPONSE_CONFIRM_CODE,
  data
})


export const REQUEST_REPORTE = "REQUEST_REPORTE"
export const RESPONSE_REPORTE = "RESPONSE_REPORTE"

export const requestReporte = (data) => ({
  type: REQUEST_REPORTE,
  data
})

export const responseReporte = (data) => ({
  type: RESPONSE_REPORTE,
  data
})


//contribuyentes 
export const REQUEST_GET_CONTRIBUYENTES_POR_CUENTA = "REQUEST_GET_CONTRIBUYENTES_POR_CUENTA"
export const RESPONSE_GET_CONTRIBUYENTES_POR_CUENTA = "RESPONSE_GET_CONTRIBUYENTES_POR_CUENTA"

export const requestContribuyentesPorCuenta = (data) => ({
  type: REQUEST_GET_CONTRIBUYENTES_POR_CUENTA,
  data
})

export const responseContribuyentesPorCuenta = (data) => ({
  type: RESPONSE_GET_CONTRIBUYENTES_POR_CUENTA,
  data
})
//timbrar
export const REQUEST_TIMBRAR = "REQUEST_TIMBRAR"
export const RESPONSE_TIMBRAR = "RESPONSE_TIMBRAR"

export const requestTimbrar = (data) => ({
  type: REQUEST_TIMBRAR,
  data
})

export const responseTimbrar = (data) => ({
  type: RESPONSE_TIMBRAR,
  data
})

export const REQUEST_PDF_FE_CFDI = "REQUEST_PDF_FE_CFDI"
export const RESPONSE_PDF_FE_CFDI = "RESPONSE_PDF_FE_CFDI"

export const requestPdfFeCfdi = (data) => ({
  type: REQUEST_PDF_FE_CFDI,
  data
})

export const responsePdfFeCfdi = (data) => ({
  type: RESPONSE_PDF_FE_CFDI,
  data
})

export const REQUEST_XML_FE_CFDI = "REQUEST_XML_FE_CFDI"
export const RESPONSE_XML_FE_CFDI = "RESPONSE_XML_FE_CFDI"

export const requestXmlFeCfdi = (data) => ({
  type: REQUEST_XML_FE_CFDI,
  data
})

export const responseXmlFeCfdi = (data) => ({
  type: RESPONSE_XML_FE_CFDI,
  data
})


export const REQUEST_ENVIAR_CORREO_CFDI = "REQUEST_ENVIAR_CORREO_CFDI"
export const RESPONSE_ENVIAR_CORREO_CFDI = "RESPONSE_ENVIAR_CORREO_CFDI"

export const requestEnviarCorreoCfdi = (data) => ({
  type: REQUEST_ENVIAR_CORREO_CFDI,
  data
})

export const responseEnviarCorreoCfdi = (data) => ({
  type: RESPONSE_ENVIAR_CORREO_CFDI,
  data
})



export const REQUEST_SAVE_INTENTO_PAGO = "REQUEST_SAVE_INTENTO_PAGO"
export const RESPONSE_SAVE_INTENTO_PAGO = "RESPONSE_SAVE_INTENTO_PAGO"

export const requestIntentoPago = (data) => ({
  type: REQUEST_SAVE_INTENTO_PAGO,
  data
})

export const responseIntentoPago = (data) => ({
  type: RESPONSE_SAVE_INTENTO_PAGO,
  data
})



export const REQUEST_REPORTE_COMPROBANTE_PAGO = "REQUEST_REPORTE_COMPROBANTE_PAGO"
export const RESPONSE_REPORTE_COMPROBANTE_PAGO = "RESPONSE_REPORTE_COMPROBANTE_PAGO"

export const requestComprobantePago = (data) => ({
  type: REQUEST_REPORTE_COMPROBANTE_PAGO,
  data
})

export const responseComprobantePago = (data) => ({
  type: RESPONSE_REPORTE_COMPROBANTE_PAGO,
  data
})


export const REQUEST_REPORTE_ESTADO_CUENTA = "REQUEST_REPORTE_ESTADO_CUENTA"
export const RESPONSE_REPORTE_ESTADO_CUENTA = "RESPONSE_REPORTE_ESTADO_CUENTA"

export const requestEstadoCuenta = (data) => ({
  type: REQUEST_REPORTE_ESTADO_CUENTA,
  data
})

export const responseEstadoCuenta = (data) => ({
  type: RESPONSE_REPORTE_ESTADO_CUENTA,
  data
})


export const REQUEST_GIROS = "REQUEST_GIROS"
export const RESPONSE_GIROS = "RESPONSE_GIROS"

export const requestGiros = (data) => ({
  type: REQUEST_GIROS,
  data
})

export const responseGiros = (data) => ({
  type: RESPONSE_GIROS,
  data
})

export const REQUEST_GUARDAR_IMAGEN_USUARIO = "REQUEST_GUARDAR_IMAGEN_USUARIO"
export const RESPONSE_GUARDAR_IMAGEN_USUARIO = "RESPONSE_GUARDAR_IMAGEN_USUARIO"

export const requestGuardarImagenUsuario = (data) => ({
  type: REQUEST_GUARDAR_IMAGEN_USUARIO,
  data
})

export const responseGuardarImagenUsuario = (data) => ({
  type: RESPONSE_GUARDAR_IMAGEN_USUARIO,
  data
})

export const REQUEST_GUARDAR_IMAGEN_DIRECCION = "REQUEST_GUARDAR_IMAGEN_DIRECCION"
export const RESPONSE_GUARDAR_IMAGEN_DIRECCION = "RESPONSE_GUARDAR_IMAGEN_DIRECCION"

export const requestGuardarImagenDireccion = (data) => ({
  type: REQUEST_GUARDAR_IMAGEN_DIRECCION,
  data
})

export const responseGuardarImagenDireccion = (data) => ({
  type: RESPONSE_GUARDAR_IMAGEN_DIRECCION,
  data
})

export const REQUEST_ACTUALIZAR_IMAGEN_USUARIO = "REQUEST_ACTUALIZAR_IMAGEN_USUARIO"
export const RESPONSE_ACTUALIZAR_IMAGEN_USUARIO = "RESPONSE_ACTUALIZAR_IMAGEN_USUARIO"

export const requestActualizarImagenUsuario = (data) => ({
  type: REQUEST_ACTUALIZAR_IMAGEN_USUARIO,
  data
})

export const responseActualizarImagenUsuario = (data) => ({
  type: RESPONSE_ACTUALIZAR_IMAGEN_USUARIO,
  data
})


export const REQUEST_IMAGENES_USUARIO = "REQUEST_IMAGENES_USUARIO"
export const RESPONSE_IMAGENES_USUARIO = "RESPONSE_IMAGENES_USUARIO"

export const requestImagenesUsuario = (data) => ({
  type: REQUEST_IMAGENES_USUARIO,
  data
})

export const responseImagenesUsuario = (data) => ({
  type: RESPONSE_IMAGENES_USUARIO,
  data
})

export const REQUEST_GUARDAR_PERSONA = "REQUEST_GUARDAR_PERSONA"
export const RESPONSE_GUARDAR_PERSONA = "RESPONSE_GUARDAR_PERSONA"

export const requestGuardarPersona = (data) => ({
  type: REQUEST_GUARDAR_PERSONA,
  data
})

export const responseGuardarPersona = (data) => ({
  type: RESPONSE_GUARDAR_PERSONA,
  data
})

export const REQUEST_GUARDAR_PERSONA_DIRECCION = "REQUEST_GUARDAR_PERSONA_DIRECCION"
export const RESPONSE_GUARDAR_PERSONA_DIRECCION = "RESPONSE_GUARDAR_PERSONA_DIRECCION"

export const requestGuardarPersonaDireccion = (data) => ({
  type: REQUEST_GUARDAR_PERSONA_DIRECCION,
  data
})

export const responseGuardarPersonaDireccion = (data) => ({
  type: RESPONSE_GUARDAR_PERSONA_DIRECCION,
  data
})


export const REQUEST_USUARIO_DIRECCION = "REQUEST_USUARIO_DIRECCION"
export const RESPONSE_USUARIO_DIRECCION = "RESPONSE_USUARIO_DIRECCION"

export const requestUsuarioDireccion = (data) => ({
  type: REQUEST_USUARIO_DIRECCION,
  data
})

export const responseUsuarioDireccion = (data) => ({
  type: RESPONSE_USUARIO_DIRECCION,
  data
})

export const REQUEST_TIPO_IMAGENES_PERSONA = "REQUEST_TIPO_IMAGENES_PERSONA"
export const RESPONSE_TIPO_IMAGENES_PERSONA = "RESPONSE_TIPO_IMAGENES_PERSONA"

export const requestTipoImagenesPersonas = (data) => ({
  type: REQUEST_TIPO_IMAGENES_PERSONA,
  data
})

export const responseTipoImagenesPersonas= (data) => ({
  type: RESPONSE_TIPO_IMAGENES_PERSONA,
  data
})

export const REQUEST_ACTIVAR_INACTIVAR_PERSONA = "REQUEST_ACTIVAR_INACTIVAR_PERSONA"
export const RESPONSE_ACTIVAR_INACTIVAR_PERSONA = "RESPONSE_ACTIVAR_INACTIVAR_PERSONA"

export const requestActivarInactivarPersona = (data) => ({
  type: REQUEST_ACTIVAR_INACTIVAR_PERSONA,
  data
})

export const responseActivarInactivarPersona = (data) => ({
  type: RESPONSE_ACTIVAR_INACTIVAR_PERSONA,
  data
})

export const REQUEST_OBTENER_TIPO_TRAMITE = "REQUEST_OBTENER_TIPO_TRAMITE"
export const RESPONSE_OBTENER_TIPO_TRAMITE = "RESPONSE_OBTENER_TIPO_TRAMITE"

export const requestObtenerTipoTramite = (data) => ({
  type: REQUEST_OBTENER_TIPO_TRAMITE,
  data
})

export const responseObtenerTipoTramite = (data) => ({
  type: RESPONSE_OBTENER_TIPO_TRAMITE,
  data
})

export const REQUEST_GUARDAR_TRAMITE = "REQUEST_GUARDAR_TRAMITE"
export const RESPONSE_GUARDAR_TRAMITE = "RESPONSE_GUARDAR_TRAMITE"

export const requestGuardarTramite = (data) => ({
  type: REQUEST_GUARDAR_TRAMITE,
  data
})

export const responseGuardarTramite = (data) => ({
  type: RESPONSE_GUARDAR_TRAMITE,
  data
})

export const REQUEST_OBTENER_TRAMITES = "REQUEST_OBTENER_TRAMITES"
export const RESPONSE_OBTENER_TRAMITES = "RESPONSE_OBTENER_TRAMITES"

export const requestObtenerTramites = (data) => ({
  type: REQUEST_OBTENER_TRAMITES,
  data
})

export const responseObtenerTramites = (data) => ({
  type: RESPONSE_OBTENER_TRAMITES,
  data
})


export const REQUEST_OBTENER_TIPO_TRAMITES = "REQUEST_OBTENER_TIPO_TRAMITES"
export const RESPONSE_OBTENER_TIPO_TRAMITES = "RESPONSE_OBTENER_TIPO_TRAMITES"

export const requestObtenerTipoTramites = (data) => ({
  type: REQUEST_OBTENER_TIPO_TRAMITES,
  data
})

export const responseObtenerTipoTramites = (data) => ({
  type: RESPONSE_OBTENER_TIPO_TRAMITES,
  data
})


export const REQUEST_LOG_PAYMENTS = "REQUEST_LOG_PAYMENTS"
export const RESPONSE_LOG_PAYMENTS = "RESPONSE_LOG_PAYMENTS"

export const requestLogPayments = (data) => ({
  type: REQUEST_LOG_PAYMENTS,
  data
})

export const responseLogPayments = (data) => ({
  type: RESPONSE_LOG_PAYMENTS,
  data
})