import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

const styles = () => ({
  headerTable: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    fontWeight: 700
  },
  headerAmount: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    fontWeight: 700,
    float: 'right',
    width: 100
  },
  smallCell: {
    margin: 0,
    padding: 0
  },
  emojiAlias: {
    marginRight: 8,
    width: 18
  },
  hidden: {
    display: 'none'
  },
  money: {
    textAlign: 'right',
    margin: 0,
    padding: 0
  }
})

class ReceiptSuiteListTable extends Component {
  render() {
    const { classes } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>Tipo de tramite</Typography></TableCell>
            <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>Nombre persona</Typography></TableCell>
            <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>Cuenta</Typography></TableCell>
            <Hidden smDown>
              <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>Estatus</Typography></TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>Comentario</Typography></TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>Fecha</Typography></TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
      <TableBody>
      {
        this.props.tramites.map((tramite, idx) => {
          return (
            <TableRow key={idx}>
              <TableCell><Typography variant="body2">{tramite.tipoTramite}</Typography></TableCell>
              <TableCell><Typography variant="body2">{tramite.nombreCompleto}</Typography></TableCell>
              <TableCell><Typography variant="body2">{tramite.cuenta}</Typography></TableCell>
              <Hidden smDown>
                <TableCell><Typography variant="body2">{tramite.estatus}</Typography></TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell><Typography variant="body2">{tramite.comentarios}</Typography></TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell><Typography variant="body2">{tramite.fecha}</Typography></TableCell>
              </Hidden>
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, null)
)(ReceiptSuiteListTable)
