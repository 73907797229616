import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

// Material UI
import { withTheme } from '@material-ui/core/styles'

// Components

// Utils
import { Typography } from '@material-ui/core'
import Utils from '../resources/Utils'
import UsersTable from '../components/UsersTable'
class UsersList extends Component {
  render() {
    return (
      <div>
        <Typography><h2>{Utils.getMessages().usersMessages.title}</h2></Typography>
        <UsersTable/>
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps)
)(UsersList)
