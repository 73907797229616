import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

import Switch from '@material-ui/core/Switch';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

import CustomCombo from './CustomCombo'

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';

import AttachFileIcon from '@material-ui/icons/AttachFile';

// Actions
import {  Card, CardActionArea, CardActions, CardContent, CardMedia, FormControlLabel, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@material-ui/core'
import { blue, red } from '@material-ui/core/colors';
import ViewImageModal from './ViewImageModal';
import DeleteIcon from '@material-ui/icons/Delete';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import MySnackbarContentWrapper from './CustomSnackBar';

function NumberOnlyNumber(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        decimalScale={0}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  }

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        decimalScale={2}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        fixedDecimalScale={true}
        decimalSeparator={"."}
        thousandSeparator={","}
        isNumericString
        prefix="$"
      />
    );
  }

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: blue['500'],
		color: 'rgba(255, 255, 255, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);

const styles = theme => ({
	bold: {
		fontWeight:"bold"
	},
	fecha:{
        width:"100%",
    },
	textInputNumberCustom:{
        width: "100%",
		zIndex:0
    },
	itemGrid:{
		paddingRight:theme.spacing(1), paddingBottom:theme.spacing(1)
	},
	media:{
		/*width: "100%",
  		height: "auto",*/
		height: 140,
	},
	rootCard:{
		maxWidth: 245,
	},
	actionCards:{
		float:"right"
	},
	title: {
		fontWeight: 600,
		fontSize: 16
	},
})

class GeneradorForms extends Component {
	constructor(props) {
		super(props)
		this.state = {
			viewImageModalState: {
				open: false,
				titulo:"",
				descripcion:"",
				src:"",
				type: "",
			},
			generadorFormsState: this.props.generadorFormsState,
			openSnack: false,
			variant: 'warning',
			errorMessage: '',
		}

		this.handleOnChangeText = this.handleOnChangeText.bind(this)
		this.handleSwitchChange =this.handleSwitchChange.bind(this)
		this.handleSelectedChange = this.handleSelectedChange.bind(this)
		this.handleDateChange = this.handleDateChange.bind(this)
		this.handleFileChange = this.handleFileChange.bind(this)
		this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this)
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
	
	}

	componentDidUpdate(prevProps){
		const response = this.props.response || 'none'
		if(prevProps.init[response] !== this.props.init[response]){ 
			this.setState({
				modalConfirmacionState:{
					loading: false
				}
			})
		}
	}

	handleOnChangeText(e, origin){
		let generadorFormsState = this.state.generadorFormsState
		let value = e.target.value ? e.target.value : ''
		if(origin.upper){
			value = value.toUpperCase()
		}
		generadorFormsState.form[e.target.name] = value	
		this.setState({generadorFormsState: generadorFormsState})
	}

	handleSelectedChange = (data, name) => {
		let generadorFormsState = this.state.generadorFormsState
		generadorFormsState.form[name] = data	
		this.setState({generadorFormsState: generadorFormsState})
    }

	handleSwitchChange = (e) => {
		let generadorFormsState = this.state.generadorFormsState
		generadorFormsState.form[e.target.name] = e.target.checked	
		this.setState({generadorFormsState: generadorFormsState})
    }

	handleDateChange = (date, name) => {
		let generadorFormsState = this.state.generadorFormsState
		generadorFormsState.form[name] = date	
		this.setState({generadorFormsState: generadorFormsState})
		/*if(this.props.updateAttr){
			this.props.updateAttr(name, date);
		}*/
		this.forceUpdate();
    }
	
	handleOpenSnackbar({message, variant}) {
		this.setState({openSnack: true, variant: variant || 'warning', errorMessage: message })
	}

	handleCloseSnackbar() {
		this.setState({openSnack: false})
	}

	handleFileChange = async (e, name, id) => {

		const bytesToSize = (bytes) => {
			var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			if (bytes == 0) return '0 Byte';
			var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
		}

		const toBase64 = file => new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

		let generadorFormsState = this.state.generadorFormsState
		
		if(e.target.files){
			const file = e.target.files[0]
			if(file){

				if(file.type.includes('pdf') || file.type.includes('image')){
					const MB = Math.round(file.size / Math.pow(1024, 2), 2)
					if(MB <= 2){
						const val = await toBase64(file)
		
						generadorFormsState.form[name] =  {
							nameFile: file.name,
							size: file.size,
							dataBase64: val,
							type: file.type,
							file: file,
						}
						this.setState({generadorFormsState: generadorFormsState})
					} else {
						this.handleOpenSnackbar({message: 'El archivo debe ser menor o igual a 2MB. Archivo de ' + bytesToSize(file.size), variant:'info'})
					}
				} else {
					this.handleOpenSnackbar({message: 'Tipo de archivo no soportado', variant:'info'})
				}			
			}
		} else {
			let elemento = document.getElementById(id);
			elemento.src = null
			elemento.value = null
			generadorFormsState.form[name] = null
			this.setState({generadorFormsState: generadorFormsState})
		}
		
		
    }

	handleOpenModalViewImage = (data, label) => {
		if(data){
			let { viewImageModalState } = this.state
			viewImageModalState.open = true
			viewImageModalState.titulo = label
			viewImageModalState.descripcion = data.nameFile
			viewImageModalState.src = data.dataBase64
			viewImageModalState.type = data.type
			this.setState({ viewImageModalState: viewImageModalState}) 
		}	
	}


	render() {
		const { classes, titulo } = this.props
		const { generadorFormsState } = this.state
		const { form } = generadorFormsState
		let forms = []
		if(Array.isArray(this.props.forms)){
			forms = this.props.forms
		}

		return (
		<form noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
			<Typography variant="h6">
				<strong>{titulo}</strong>
			</Typography>
			<Grid container>						
			{
				forms.map((v,i) => {
					if(v.actions.create){
						switch (v.type || "text") {
							case "read":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
									<div style={{width:"100%"}}>
										<Typography variant="body1">{this.props.labels[v.name]}</Typography>
										<Typography variant="h6" className={classes.title}>{form[v.name] || ''} </Typography>				
									</div>
								</Grid>)
							case "text":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
									<div style={{width:"100%"}}>
									{
										v.tooltip ? 
											<HtmlTooltip
												title={
												<React.Fragment>
													<Typography color="inherit">{v.tooltip.titulo}</Typography>
													{v.tooltip.descripcion}
												</React.Fragment>
												}
											> 
												<TextField
													id={v.name}
													style={{width:"100%", zIndex:"0"}}
													required={v.required}
													label={this.props.labels[v.name]}
													value={form[v.name] || ''}
													name={v.name}
													onChange={ (e) => { this.handleOnChangeText(e, v) } }
													className={classes.textField}
													variant="outlined"
													size="small"
												/>
											</HtmlTooltip>
											: 
											<TextField
												id={v.name}
												style={{width:"100%", zIndex:"0"}}
												required={v.required}
												label={this.props.labels[v.name]}
												value={form[v.name] || ''}
												name={v.name}
												onChange={ (e) => { this.handleOnChangeText(e, v) } }
												className={classes.textField}
												variant="outlined"
												size="small"
											/>
									}					
									</div>
								</Grid>)
							case "email":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
									<div style={{width:"100%"}}>	
										<TextField
											id={v.name}
											style={{width:"100%", zIndex:"0"}}
											required={v.required}
											label={this.props.labels[v.name]}
											value={form[v.name] || ''}
											name={v.name}
											onChange={ (e) => { this.handleOnChangeText(e, v) } }
											className={classes.textField}
											variant="outlined"
											size="small"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<AlternateEmailIcon />
													</InputAdornment>
												),
											}}
										/>												
									</div>
								</Grid>)
							case "number":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
										<div style={{width:"100%"}}>
										{
										v.tooltip ? 
											<HtmlTooltip
												title={
												<React.Fragment>
													<Typography color="inherit">{v.tooltip.titulo}</Typography>
													{v.tooltip.descripcion}
												</React.Fragment>
												}
											> 
											<TextField
												id={v.name}
												variant="outlined"
												size={"small"}
												required={v.required}
												label={this.props.labels[v.name]}
												className={classes.textInputNumberCustom}
												name={v.name}
												value={form[v.name] || ''}
												
												onChange={ (e) => { this.handleOnChangeText(e, v) } } 
												InputProps={{
													inputComponent: NumberOnlyNumber,
												}}
											/>
											</HtmlTooltip>
											: 
											<TextField
												id={v.name}
												variant="outlined"
												size={"small"}
												required={v.required}
												label={this.props.labels[v.name]}
												className={classes.textInputNumberCustom}
												name={v.name}
												value={form[v.name] || ''}
												
												onChange={ (e) => { this.handleOnChangeText(e, v) } } 
												InputProps={{
													inputComponent: NumberOnlyNumber,
												}}
											/>
									}	

									</div>
									
								</Grid>)
							case "multi":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
									<div style={{width:"100%"}}>
										<TextField
											id={v.name}
											style={{width:"100%", zIndex:"0", fontSize:10}}
											label={this.props.labels[v.name]}
											required={v.required}
											multiline
											rows={4}
											value={form[v.name] || ''}
											name={v.name}
											onChange={this.handleOnChangeText}
											variant="outlined"
											size="small"
										/>
									</div>
								</Grid>)
							case "select":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
									<div style={{width:"100%"}}>
										<CustomCombo	
											id={v.name}					
											name={v.name}
											isMulti={ false }
											isClearable={v.isClearable || false}
											value={ form[ 'selected' + v.name] }
											options={ this.props.multiselectData[v.name] || [] }
											placeholder={'Seleccione ' + this.props.labels[v.name]}
											onChange={(event) => { this.handleSelectedChange(event, 'selected' + v.name) }}
										></CustomCombo>
									</div>
									
								</Grid>)

							case "switch":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
									<FormControlLabel
									 	style={{width:"100%"}}
										control={
										<Switch
											id={v.name}	
											checked={ typeof form[v.name] === "boolean" ? form[v.name] : true }
											onChange={this.handleSwitchChange}
											name={v.name}
											color="primary"
										/>
										}
										label={this.props.labels[v.name]}
									/>									
								</Grid>)

							case "date":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
									<MuiPickersUtilsProvider utils={DateFnsUtils} >
									

										<KeyboardDatePicker
											id={v.name}
											style={{margin:0, width:"100%"}}
											size="small"
											disableToolbar={true}
											variant="inline"
											inputVariant="outlined"
											animateYearScrolling={true}
											autoOk={true}
											disableFuture={false}
											format="dd/MM/yyyy"
											margin="normal"
											label={this.props.labels[v.name]}
											key={v.name}
											value={ form[ 'selected' + v.name] }
											onChange={(date) => { this.handleDateChange(date, 'selected' +v.name) }}
											KeyboardButtonProps={{
												'aria-label': 'change date',
												// disabled: true, style: { display: 'none' }
											}}
										/>

									</MuiPickersUtilsProvider>
								</Grid>)
							case "fileupload":
								return (<Grid 
									className={classes.itemGrid}
									key={i} item xs={v.grid.xs || 12} sm={v.grid.sm || 12} md={v.grid.md || 12} lg={v.grid.lg || 12} xl={v.grid.xl || 12}>
									
									<Grid container>
										<Grid item xs={12}>										

										<Card className={classes.rootCard}>
											<CardActionArea
												onClick={() => { this.handleOpenModalViewImage(form[v.name], this.props.labels[v.name]) } }
											>
												{
													form[v.name] ?
													form[v.name].type.includes("image") ?
														<CardMedia
															className={classes.media}
															image={ form[v.name] ? form[v.name].dataBase64 : './noimg.png'}
															src={ form[v.name] ? form[v.name].dataBase64 : './noimg.png'}
															title={( form[v.name] ? form[v.name].nameFile : '[Nombre del archivo]')}
														/>

													: form[v.name].type.includes("pdf") ? 
														<iframe  style={{border:"unset"}} src= { form[v.name].dataBase64 } height="100%" width="100%"></iframe>
													: null : <CardMedia
													className={classes.media}
													image={ form[v.name] ? form[v.name].dataBase64 : './noimg.png'}
													src={ form[v.name] ? form[v.name].dataBase64 : './noimg.png'}
													title={( form[v.name] ? form[v.name].nameFile : '[Nombre del archivo]')}
												/>
												}
												
												<CardContent>
													<Typography gutterBottom variant="body1" component="h5">
														{this.props.labels[v.name]}
													</Typography>
												</CardContent>
											</CardActionArea>
											{
												!v.readyOnly ? 
													<CardActions className={classes.actionCards}>

													{
														form[v.name] ? 
															<IconButton
															variant="contained"
															component="label"
															color="primary"
															onClick={(e) => { this.handleFileChange(e, v.name,  i + '_' + v.name) }}
															>	
																<DeleteIcon style={{color:red[500]}}></DeleteIcon>
														</IconButton>
														:null
													}

													<IconButton
														variant="contained"
														component="label"
														color="primary"
														id={v.name}
														>
															<input
																id={ i + '_' + v.name}
																type="file"
																hidden
																onChange={(e) => { this.handleFileChange(e, v.name,  i + '_' + v.name) }}
															/>
															<AttachFileIcon></AttachFileIcon>
													</IconButton>
													</CardActions>
												: null
											}
											
											</Card>

											
										</Grid>
									</Grid>
						
								</Grid>)
							default:
								return null
						}
					} else {
						return null
					}							
				})
			}		
			</Grid>

			<Snackbar
				anchorOrigin={{vertical: 'top', horizontal: 'center'}}
				open={this.state.openSnack}
				autoHideDuration={5000}
				onClose={this.handleCloseSnackbar}
			>
				<MySnackbarContentWrapper
					onClose={this.handleCloseSnackbar}
					variant={this.state.variant}
					message={
						<span>{this.state.errorMessage}</span>
					}
				/>
			</Snackbar>

			<ViewImageModal
				open={this.state.viewImageModalState.open} 
				titulo={this.state.viewImageModalState.titulo}
				descripcion={this.state.viewImageModalState.descripcion}
				src={this.state.viewImageModalState.src}
				type={this.state.viewImageModalState.type}
				handleClose={ () => { 
					let { viewImageModalState } = this.state
					viewImageModalState.open = false
					this.setState({ viewImageModalState: viewImageModalState}) 
				} }
			></ViewImageModal>

		</form>
		)
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = () => {
	return {

	}
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(GeneradorForms)
