import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// Material UI
import Grid from '@material-ui/core/Grid'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { ChromePicker } from 'react-color'
// Resources
import { Button, IconButton, TextField, Typography } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import { withRouter } from 'react-router-dom'
import { checkAccount, requestEditOfficeConfigs } from '../actions/actions'
import Utils from '../resources/Utils'

const styles = theme => ({

  textField: {
    width: '100%',
    margin: '5px',
    textAlignLast: 'end'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  },
 buttons:{
  height: '20px',
  width: '100px'
 }
  

})


class ConfigsTable extends Component {

  constructor(props) {
    super(props)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.state = {
      snackbar:false,
      activeAppBarColor: false,
      activePrimaryColor: false,
      activeSecondaryColor: false,
      activeTextDescriptionColor:false,
      activeTextTitleColor:false,
      appBarColor: this.props.init.config.data.appBarColor,
      primaryColor: this.props.init.config.data.PrimaryColor,
      secondaryColor: this.props.init.config.data.SecondaryColor,
      textDescriptionColor:this.props.init.config.data.textDescriptionColor,
      textTitleColor:this.props.init.config.data.textTitleColor
    }
  }

  
  UNSAFE_componentWillReceiveProps(nextProps){
    if (nextProps.init.config.devMessage==="Successful") {
      this.useTimer()
    }
  }
  useTimer()  {
    this.setState({ snackbar: true })
    const intervalId = setInterval(() => {
      this.setState({snackbar:false})
    }, 5000);
    return () => clearInterval(intervalId); // Clear interval on component teardown
  } 
  
  handleConfirm() {
    if (this.props.init.config.data['automaticPayment'] === "false"||!this.props.init.config.data['automaticPayment']) {
      this.props.init.config.data['automaticPayment'] = false
    } else {
      this.props.init.config.data['automaticPayment'] = true
    }

    this.props.reqEditOfficeConfigs(this.props.init.config.data);
  }
  handleSubmit(event) {
    event.preventDefault()  
  }
  //click
  handleClick = () => {
    this.setState({ activeAppBarColor: !this.activeAppBarColor})
  };
  handleClick32 = () => {
    this.setState({ activePrimaryColor: !this.activePrimaryColor})
  };
  handleClick33 = () => {
    this.setState({ activeSecondaryColor: !this.activeSecondaryColor})
  };
  handleClick38 = () => {
    this.setState({ activeTextDescriptionColor: !this.activeTextDescriptionColor})
  };
  handleClick39 = () => {
    this.setState({ activeTextTitleColor: !this.activeTextDescriptionColor})
  };
  //close
  handleClose = () => {
    this.setState({ activeAppBarColor: false })
  };
  handleClose32 = () => {
    this.setState({ activePrimaryColor: false })
  };
  handleClose33 = () => {
    this.setState({ activeSecondaryColor: false })
  };
  handleClose38 = () => {
    this.setState({ activeTextDescriptionColor: false })
  };
  handleClose39 = () => {
    this.setState({ activeTextTitleColor: false })
  };
  //change
  handleChange = (e) => {
    this.setState({ appBarColor: e.hex })
  };
  handleChange32 = (e) => {
    this.setState({ primaryColor: e.hex })
  };
  handleChange33 = (e) => {
    this.setState({ secondaryColor: e.hex })
  };
  handleChange38 = (e) => {
    this.setState({ textDescriptionColor: e.hex })
  };
  handleChange39 = (e) => {
    this.setState({ textTitleColor: e.hex })
  };

  handleCloseSnackbar = () => {
    this.setState({ snackbar: false })
  }
  render() {

    const { classes } = this.props
    const configs = this.props.init.config.data;
    const campos = Object.keys(Utils.getMessages().configs);
    const camposNumber = [7, 11, 14, 17, 19, 21, 22, 25, 26, 27, 28, 29, 30, 31, 35, 36, 37, 42];
    const decimals = [25, 26, 27, 29, 30, 35, 36, 42]
    const colorFields = [0, 32, 33, 38, 39]//,32,33,38,39
    return (
      <div>
      {(this.state.snackbar)?<div>
      <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}}  open={this.state.snackbar}  message={<span>{Utils.getMessages().configsmessages.updateSuccess}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.handleCloseSnackbar}
        >
          <CloseIcon />
        </IconButton>
      ]}
      ></Snackbar>
     
      </div>:''} 
        <Typography><h2>{Utils.getMessages().configsmessages.titleFormConfigs}</h2></Typography> 
          <form noValidate autoComplete="off" onSubmit={e => this.handleSubmit(e)}>
          <div style={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" style={{ width: '30%', height: window.innerHeight * 0.05 } } onClick={this.handleConfirm} >{Utils.getMessages().configsmessages.buttomUpdate}</Button>
          </div>
          <div style={{ height: window.innerHeight - 300, overflow: "auto" }}>
            {
              campos.map((campo, idx) => {
                return (
                  <div key={campo} style={{ display: 'flex', width: '100%' }} aria-orientation='vertical'>

                    <Grid container item xs={12} spacing={0}>
                      {
                        (camposNumber.includes(idx)) ?
                          (decimals.includes(idx)) ?
                            <TextField fullWidth type="number" InputLabelProps={{ shrink: true, }} defi id="standard-basic" label={campo} defaultValue={configs[campo]} onChange={e => configs[campo] = parseFloat(e.target.value)} />

                            :
                            <TextField fullWidth type="number" InputLabelProps={{ shrink: true, }} defi id="standard-basic" label={campo} defaultValue={configs[campo]} onChange={e => configs[campo] = parseInt(e.target.value)} />
                          :
                          (colorFields.includes(idx)) ?
                            (idx === 0) ?
                              <div>
                                <Typography>{campo}</Typography>
                                <button className={classes.buttons} key={campo} onClick={this.handleClick} style={{ backgroundColor: configs[campo]}}></button>
                                {(this.state.activeAppBarColor) ? <div>
                                  <div className={classes.cover} onClick={ this.handleClose} />
                                  <ChromePicker key={campo} className={campo} color={configs[campo]} onChange={e => (configs[campo] = e.hex, this.handleChange(e))} />
                                </div> : null}
                              </div>
                              : (idx === 32) ? <div>
                                <Typography>{campo}</Typography>
                                <button className={classes.buttons} key={campo} onClick={this.handleClick32} style={{ backgroundColor: configs[campo]}}></button>
                                {(this.state.activePrimaryColor) ? <div>
                                  <div className={classes.cover} onClick={this.handleClose32} />
                                  <ChromePicker key={campo} className={campo} color={configs[campo]} onChange={e => (configs[campo] = e.hex, this.handleChange32(e))} />
                                </div> : null}
                              </div> : (idx === 33) ? <div>
                                <Typography>{campo}</Typography>
                                <button className={classes.buttons} key={campo} onClick={this.handleClick33} style={{ backgroundColor: configs[campo]}}></button>
                                {(this.state.activeSecondaryColor) ? <div>
                                  <div className={classes.cover} onClick={this.handleClose33} />
                                  <ChromePicker key={campo} className={campo} color={configs[campo]} onChange={e => (configs[campo] = e.hex, this.handleChange33(e))} />
                                </div> : null}
                              </div> : (idx === 38) ? <div>
                                <Typography>{campo}</Typography>
                                <button className={classes.buttons} key={campo} onClick={this.handleClick38} style={{ backgroundColor: configs[campo]}}></button>
                                {(this.state.activeTextDescriptionColor) ? <div>
                                  <div className={classes.cover} onClick={this.handleClose38} />
                                  <ChromePicker key={campo} className={campo} color={configs[campo]} onChange={e => (configs[campo] = e.hex, this.handleChange38(e))} />
                                </div> : null}
                              </div> : (idx === 39) ? <div>
                                <Typography>{campo}</Typography>
                                <button className={classes.buttons} key={campo} onClick={this.handleClick39} style={{ backgroundColor: configs[campo]}}></button>
                                {(this.state.activeTextTitleColor) ? <div>
                                  <div className={classes.cover} onClick={this.handleClose39} />
                                  <ChromePicker key={campo} className={campo} color={configs[campo]} onChange={e => (configs[campo] = e.hex, this.handleChange39(e))} />
                                </div> : null}
                              </div> : null
                            :
                            <TextField fullWidth id="standard-basic" label={campo} defaultValue={configs[campo]} onChange={e => configs[campo] = e.target.value} />
                      }

                    </Grid>


                  </div>

                )
              })
            }
          </div>
        </form>
        </div>
 
      

    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    checkAccount: (idx) => {
      dispatch(checkAccount(idx))
    },
    reqEditOfficeConfigs: (data) => {
      dispatch(requestEditOfficeConfigs(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ConfigsTable)
