import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Utils
import Utils from '../resources/Utils'
import { requestAddAccount, requestEditAccount } from '../actions/actions'

// Images
import Emoji1 from '../resources/images/1.png'
import Emoji2 from '../resources/images/2.png'
import Emoji3 from '../resources/images/3.png'
import Emoji4 from '../resources/images/4.png'
import Emoji5 from '../resources/images/5.png'
import Emoji6 from '../resources/images/6.png'
import Emoji7 from '../resources/images/7.png'
import Emoji8 from '../resources/images/8.png'
import Emoji9 from '../resources/images/9.png'
import Emoji10 from '../resources/images/10.png'
import Emoji11 from '../resources/images/11.png'
import Emoji12 from '../resources/images/12.png'

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      background: 'white',
      width: '90%',
      height: '100%',
      paddingTop: '20%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  modalTitle: {
    fontWeight: 600
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  },
  emojiContainer: {
    marginTop: 16,
    marginBottom: 16
  },
  emoji: {
    padding: 8,
    width: 38,
    opacity: 0.3,
    cursor: 'pointer'
  }
})

class AccountModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountNumber: '',
      alias: '',
      textAccountDisabled: false,
      openSnack: false,
      errorMessage: '',
      emojisAlias: [
        {
          icon: Emoji1,
          checked: true
        },
        {
          icon: Emoji2,
          checked: false
        },
        {
          icon: Emoji3,
          checked: false
        },
        {
          icon: Emoji4,
          checked: false
        },
        {
          icon: Emoji5,
          checked: false
        },
        {
          icon: Emoji6,
          checked: false
        },
        {
          icon: Emoji7,
          checked: false
        },
        {
          icon: Emoji8,
          checked: false
        },
        {
          icon: Emoji9,
          checked: false
        },
        {
          icon: Emoji10,
          checked: false
        },
        {
          icon: Emoji11,
          checked: false
        },
        {
          icon: Emoji12,
          checked: false
        }
      ]
    }

    this.handleChangeAccountNumber = this.handleChangeAccountNumber.bind(this)
    this.handleChangeAlias = this.handleChangeAlias.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.getEmojiAlias = this.getEmojiAlias.bind(this)
    this.clearData = this.clearData.bind(this)
    this.handleRender = this.handleRender.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (Utils.isUserLoggedIn()) {
      if (prevProps.init.user.accounts !== this.props.init.user.accounts) {
        this.handleClose()
      }
      else if (prevProps.init.errorAddAccount !== this.props.init.errorAddAccount) {
        let errorMessage = Utils.getMessages().General.error
        if (this.props.init.errorAddAccount.status === 'WARNING') {
          errorMessage = Utils.getMessages().AddWaterAccount.notFound
        }
        else {
          if (!Utils.isEmpty(this.props.init.errorAddAccount.devMessage)) {
            errorMessage = Utils.getMessages().AddWaterAccount.validation
          }
        }
        this.setState({
          openSnack: true,
          errorMessage: errorMessage
        })
      }
    }
  }
  handleChangeAccountNumber(event) {
    if (event.target.value.length > 32 || !Utils.isNumeric(event.target.value))
      return
    this.setState({accountNumber: event.target.value})
  }

  handleChangeAlias(event) {
    if (event.target.value.length > 32)
      return
    this.setState({alias: event.target.value})
  }

  handleClick(event, idx) {
    let emojis = this.state.emojisAlias
    emojis.forEach(function(emoji) {
      emoji.checked = false
    })
    emojis[idx].checked = true
    this.setState({
      emojisAlias: emojis
    })
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleConfirm()
    }
  }

  handleConfirm() {
    if (this.state.accountNumber.trim() !== "") {
      const userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER)).data.id
      if (this.props.editMode) {
        let data = {
          userId: userId,
          accountNumber: this.state.accountNumber,
          alias: this.state.alias,
          emojiAlias: this.getEmojiAlias()
        }
        this.props.requestEditAccount(data)
      }
      else {
        let data = {
          officeId: this.props.init.officeId,
          userId: userId,
          accountNumber: this.state.accountNumber,
          alias: this.state.alias,
          emojiAlias: this.getEmojiAlias()
        }
        this.props.requestAddAccount(data)
      }
    }
    else {
      this.setState({
        openSnack: true,
        errorMessage: Utils.getMessages().AddWaterAccount.required
      })
    }
  }

  handleCloseSnackbar() {
    this.setState({
      openSnack: false,
      errorMessage: ''
    })
  }

  handleClose() {
    this.clearData()
    this.props.handleClose()
  }

  getEmojiAlias() {
    let emojiSelected = 0
    let emojis = this.state.emojisAlias
    emojis.forEach(function(emoji, idx) {
      if (emoji.checked) {
        emojiSelected = idx
        return
      }
    })
    return emojiSelected.toString()
  }

  clearData() {
    let emojis = this.state.emojisAlias
    emojis.forEach(function(emoji) {
      emoji.checked = false
    })
    emojis[0].checked = true
    this.setState({
      accountNumber: '',
      alias: '',
      textAccountDisabled: false,
      openSnack: false,
      errorMessage: '',
      emojisAlias: emojis
    })
  }

  handleRender() {
    if (this.props.editMode) {
      let emojis = this.state.emojisAlias
      emojis.forEach(function(emoji) {
        emoji.checked = false
      })

      let idx = Number(this.props.account.emojiAlias)
      emojis[idx].checked = true

      this.setState({
        accountNumber: this.props.account.account,
        textAccountDisabled: true,
        alias: this.props.account.alias,
        emojiAlias: emojis
      })
    }
    else {
      this.setState({
        textAccountDisabled: false
      })
    }
  }

  render() {

    const { classes } = this.props

    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
        onRendered={this.handleRender}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
            {
              (this.props.editMode) ?
              Utils.getMessages().AddWaterAccount.titleEdit
              :
              Utils.getMessages().AddWaterAccount.title
            }
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
            {Utils.getMessages().AddWaterAccount.description}
          </Typography>
          <form className={classes.initForm} noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().AddWaterAccount.placeholderAccount}
              type="text"
              value={this.state.accountNumber}
              onChange={this.handleChangeAccountNumber}
              disabled={this.state.textAccountDisabled}
              autoFocus
            />
            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().AddWaterAccount.placeholderAlias}
              type="text"
              value={this.state.alias}
              onChange={this.handleChangeAlias}
              autoFocus={this.props.editMode}
            />
            <Grid className={classes.emojiContainer} container>
            {
              this.state.emojisAlias.map((emoji, idx) => {
                return (
                  <Grid item lg={2} key={idx}>
                  {
                    (emoji.checked) ?

                      <img className={classes.emoji} style={{opacity: 1}} onClick={(event) => this.handleClick(event, idx)} alt="" src={emoji.icon}></img>

                    :

                      <img className={classes.emoji} onClick={(event) => this.handleClick(event, idx)} alt="" src={emoji.icon}></img>
                  }
                  </Grid>
                )
              })
            }
            </Grid>
            <div className={classes.actions}>
              <Button
                  onClick={this.handleClose}
                >
                {Utils.getMessages().AddWaterAccount.cancelButton}
              </Button>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={this.handleConfirm}
              >
                {Utils.getMessages().AddWaterAccount.confirmButton}
              </Button>
            </div>
          </form>
          <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={this.state.openSnack}
            onClose={this.handleCloseSnackbar}
            message={
              <span>{this.state.errorMessage}</span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestAddAccount: (data) => {
      dispatch(requestAddAccount(data))
    },
    requestEditAccount: (data) => {
      dispatch(requestEditAccount(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AccountModal)
