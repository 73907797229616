import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// Components
import PaymentSuiteListTable from '../components/PaymentSuiteListTable'
import Title from '../components/Title'
import Empty from '../components/Empty'
import CFDIModal from '../components/CFDIModal'

// Utils
import Utils from '../resources/Utils'
import emptyImg from '../resources/images/empty.svg'
import { requestAccountsByUser, requestComprobantePago, requestConsultaCompleta, requestContribuyentesPorCuenta, requestPdfFeCfdi, requestXmlFeCfdi } from '../actions/actions'
import MySnackbarContentWrapper from '../components/CustomSnackBar'
import SendMailModal from '../components/SendMailModal'
import CircleLoading from '../components/CircleLoading'

const styles = theme => ({
  paymentTable: {
    marginTop: 16
  },
  select: {
    width: 300
  }
})

class PaymentSuite extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: 'EMPTY',
      openModal: false,
      openSnack: false,
      accountSelected: "",
      showPayments: false,
      payments: [],
      contribuyentes: [],
      payment: {},
      openModalSendMail: false,
      loading: false
    }

    // Modal handlers
    this.changeAccountNumber = this.changeAccountNumber.bind(this)
    this.cfdi = this.cfdi.bind(this)
    this.handlePaymentModal = this.handlePaymentModal.bind(this)
    this.handleConfirmModal = this.handleConfirmModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)

    // Others
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)

    this.printAsPdf = this.printAsPdf.bind(this)
    this.printAsXML = this.printAsXML.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      let user = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
      user = user.data
      this.setState({ loading: true})
      this.props.requestAccountsByUser(user.id)
    }
  }

  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  abrirDatosEnPestaniaNueva(data){
    var html = '<html>' +
    '<style>html,body {padding:0;margin:0;} iframe {width:100%;height:100%;border:0}</style>' +
    '<body>' +                                
    '<iframe src="data:application/pdf;base64, ' + encodeURI(data) + '"></iframe>' +
    '</body></html>';

    var a = window.open("about:blank", "CFDI_PDF_" + data)
    a.document.write(html)
    a.document.close()
  }

  componentDidUpdate(prevProps) {

    if (prevProps.init.xmlFeCfdi !== this.props.init.xmlFeCfdi) {
      const { data, status } = this.props.init.xmlFeCfdi
      if(status === Utils.constants.status.ok){
        if(data.isValid){
          const dataParsed = JSON.parse(data.data)

          this.download("cfdi_" + this.state.accountSelected + "_" + this.state.payment.comprobanteID + ".xml", dataParsed)

        } else {
          this.setState({
            errorMessage: data.message,
            openSnack: true,
            variant: 'warning'
          })

        }

      } 
    }

  
    
    if (prevProps.init.pdfFeCfdi !== this.props.init.pdfFeCfdi) {
      const { data, status } = this.props.init.pdfFeCfdi

      if(status === Utils.constants.status.ok){
        if(data.isValid){
          const dataParsed = JSON.parse(data.data)
          this.abrirDatosEnPestaniaNueva(dataParsed)
        } else {
          this.setState({
            errorMessage: data.message,
            openSnack: true,
            variant: 'warning'
          })

        }

      } 
    }

    if (prevProps.init.enviarCorreoCfdi !== this.props.init.enviarCorreoCfdi) {
      const { data, status } = this.props.init.enviarCorreoCfdi
      if(status === Utils.constants.status.ok){
        this.setState({
          errorMessage: 'El correo se ha enviado exitosamente',
          openSnack: true,
          variant: 'success'
        })
      } 
    }

    if (prevProps.init.timbrar !== this.props.init.timbrar) {
      const { data, status } = this.props.init.timbrar
      if(status === Utils.constants.status.ok){
        if(data.isValid){
          const dataParsed = JSON.parse(data.data)
          this.setState({
            errorMessage: 'El pago se ha timbrado exitosamente, se ha enviado un correo al registrado del contribuyente',
            openSnack: true,
            variant: 'success'
          })
          if(dataParsed.PDF){
            this.abrirDatosEnPestaniaNueva(dataParsed.PDF)
          } else {
            this.setState({
              errorMessage: 'No se ha podido generar el pdf',
              openSnack: true,
              variant: 'warning'
            })
          }
          
          this.props.requestConsultaCompleta({ "account": this.state.accountSelected})
        } else {
          this.setState({
            errorMessage: 'No se pudo realizar el timbre',
            openSnack: true,
            variant: 'warning'
          })

        }

      } 
    }


    if (prevProps.init.user.accounts !== this.props.init.user.accounts) {

      if(this.props.init.user.accounts){
        if (!Utils.isEmpty(this.props.init.user.accounts.data)) {
          if (this.props.init.user.accounts.data.length > 0) {
            let account = this.props.match.params.account
            if(!account){
              account = this.props.init.user.accounts.data[0].account
            }
            
            this.setState({
              accountSelected: account
            })
            this.props.requestConsultaCompleta({ "account": account})
            this.props.requestContribuyentesPorCuenta({ "account": account})
          } else {
              this.setState({
                  status: 'EMPTY',
                  showPayments: true,
                  payments: [],
                  loading: false
              })
          }
        }
      }

    } else
    if (prevProps.init.consultaCompleta !== this.props.init.consultaCompleta) {
        const { data, status } = this.props.init.consultaCompleta
        if(status === Utils.constants.status.ok){
          if(Array.isArray(data.pagos)){
            const pagos = data.pagos
            if (pagos.length > 0) {
                this.setState({
                    status: 'LOAD',
                    showPayments: true,
                    payments: pagos,
                    loading: false
                })
            }
            else {
                this.setState({
                    status: 'EMPTY',
                    showPayments: true,
                    payments: [],
                    loading: false
                })
            }    
          } else {
            this.setState({
                status: 'EMPTY',
                showPayments: true,
                payments: [],
                loading: false
            })
          }
        }  
    }

    if (prevProps.init.contribuyentesPorCuenta !== this.props.init.contribuyentesPorCuenta) {
      const { data, status } = this.props.init.contribuyentesPorCuenta
      if(status === Utils.constants.status.ok){
        if(Array.isArray(data)){          
            this.setState({
              contribuyentes: data
            })
          }
        }
      }

  }

  comprobantePago(payment) {
    this.setState({
      payment: payment
    })

    window.open(Utils.constants.HOST_EMAIL_API + '/reporteCuenta/'+this.state.accountSelected+'/comrprobantePago/'+ payment.comprobantePagoID);
    //this.props.requestComprobantePago({payment: payment, cuenta: this.state.accountSelected})
  }

  cfdi(payment) {
    this.setState({
      openModal: true,
      payment: payment
    })
  }

  printAsPdf(payment){
    this.setState({
      payment: payment
    })
    this.props.requestPdfFeCfdi({ comprobanteID: payment.comprobanteID })
  }

  printAsXML(payment){
    this.setState({
      payment: payment
    })
    this.props.requestXmlFeCfdi({ comprobanteID: payment.comprobanteID  })
  }

  sendMail(payment){
    this.setState({
      openModalSendMail: true,
      payment: payment
    })
  }

  changeAccountNumber(event) {
    this.setState({
      payments: [],
      status: 'LOADING',
      accountSelected: event.target.value
    })
    this.props.requestConsultaCompleta({ "account": event.target.value})
    this.props.requestContribuyentesPorCuenta({ "account": event.target.value})
  }

  // Modal handlers

  handlePaymentModal() {
    this.setState({openModal: true})
  }

  handleConfirmModal() {
    this.setState({openModal: false})
    this.props.history.push("/pago")
  }

  handleCloseModal() {
    this.setState({openModal: false, openModalSendMail: false})
  }

  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  renderWithPayments(classes) {

    let { init } = this.props
    let user = { accounts: { data: [] } };
    const { contribuyentes } = this.state
    if(init.user){
        if(init.user.accounts){
          user = init.user ?  init.user : { accounts: { data: [] } }
        }
    }

    const { accountSelected, payment } = this.state

    return (
      <div>
        <Title 
          title={Utils.getMessages().Payments.title}
          description={Utils.getMessages().Payments.description}
        />
        <br />
        <Select
          value={accountSelected}
          onChange={(event) => {this.changeAccountNumber(event)}}
          className={classes.select}
        >
          {
            user.accounts.data.map((account, idx) => {
              return (<MenuItem key={idx} value={account.account}><strong>{account.account}</strong> - ({account.name})</MenuItem>)
            })
          }
        </Select>

        {
          (this.state.status === 'LOADING') ? 
            <div>
              <Empty
                title={Utils.getMessages().Payments.loadingTitle}
                description={Utils.getMessages().Payments.loadingDescription}
              />
            </div>
          :
            (this.state.status === 'EMPTY') ?
              <div>
                <Empty
                  title={Utils.getMessages().Payments.titleEmpty}
                  description={Utils.getMessages().Payments.descriptionEmpty}
                />
              </div>
            :
            <div>
              <PaymentSuiteListTable 
                className={classes.paymentTable}
                payments={this.state.payments}
                cfdi={(payment) => { this.cfdi(payment) }}
                printAsPdf  ={(payment) => { this.printAsPdf(payment) }}
                printAsXML ={(payment) => { this.printAsXML(payment) }}
                sendMail={(payment) => { this.sendMail(payment) }}
                contribuyentes= {contribuyentes}
                comprobantePago={(payment) => { this.comprobantePago(payment) }}
              />

              <CFDIModal
                open={this.state.openModal}
                handleClose={() => { this.handleCloseModal() }}
                contribuyentes={contribuyentes}
                payment={payment}
                contrato={ user.accounts.data.find(x => x.account === accountSelected) }
              />

              <SendMailModal
                open={this.state.openModalSendMail}
                handleClose={() => { this.handleCloseModal() }}
                payment={payment}
              />

            </div>
        }

          <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={this.state.openSnack}
            autoHideDuration={5000}
            onClose={this.handleCloseSnackbar}
          >
            <MySnackbarContentWrapper
             onClose={this.handleCloseSnackbar}
              variant={this.state.variant}
              message={
                <span>{this.state.errorMessage}</span>
              }
            />
          </Snackbar>
      </div>
    )
  }

  render() {
    
    const { classes } = this.props
    const { loading, showPayments} = this.state

    if(!loading){
      if (showPayments) {
        return this.renderWithPayments(classes)
      }
      else {
        return (
          <div>
            <Empty
              title={Utils.getMessages().Payments.titleEmpty}
              description={Utils.getMessages().Payments.descriptionEmpty}
              emptyImg={emptyImg}
            />
          </div>
        )
      }
    }else {
      return <CircleLoading mensajeCargando={Utils.getMessages().Payments.loading} />
    }


  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestConsultaCompleta: (data) => {
      dispatch(requestConsultaCompleta(data))
    },
    requestAccountsByUser: (userId) => {
        dispatch(requestAccountsByUser(userId))
    },
    requestContribuyentesPorCuenta: (data) => {
      dispatch(requestContribuyentesPorCuenta(data))
    },
    requestPdfFeCfdi: (data) => {
      dispatch(requestPdfFeCfdi(data))
    },
    requestXmlFeCfdi: (data) => {
      dispatch(requestXmlFeCfdi(data))
    },
    requestComprobantePago: (data) => {
      dispatch(requestComprobantePago(data))
    },
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentSuite)
