import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import CardValidator from 'card-validator'
import Utils from '../resources/Utils'

// Actions
import { addPaymentMethod, requestDoPayment } from '../actions/actions'

const styles = theme => ({
  checkboxContainer: {
    width: '60%',
    margin: '0 auto',
    textAlign: 'left',
    marginTop: 24,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  smallGrid: {
    padding: 0,
    margin: 0,
    width: 1
  },
  checkbox: {
    padding: 0,
    margin: 0,
    float: 'right',
    marginRight: 8
  },
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      height: '90%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  creditCardTextField: {
    width: '100%',
    marginTop: 12
  },
  smallTextField: {
    width: '30%',
    paddingRight: '3%',
    marginTop: 12
  },
  LastSmallTextField: {
    width: '33%',
    marginTop: 12
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  actions: {
    textAlign: 'center',
    marginTop: 16
  },
  primaryButton: {
    fontWeight: 600,
    fontSize: 14,
    width: '100%'
  },
  fee: {
    marginTop: 16,
    textAlign: 'center'
  }
})

class PaymentModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      number: '',
      numberMask: '',
      month: '',
      year: '',
      ccv: '',
      titular: '',
      email: '',
      openSnack: false,
      errorMessage: '',
      payButtonDisabled: true,
      onPayment: false,
      checkboxTerms: false
    }

    this.isDataFormValid = this.isDataFormValid.bind(this)
    this.handleClickCheckbox = this.handleClickCheckbox.bind(this)
    this.handleChangeNumber = this.handleChangeNumber.bind(this)
    this.handleChangeMonth = this.handleChangeMonth.bind(this)
    this.handleChangeYear = this.handleChangeYear.bind(this)
    this.handleChangeCCV = this.handleChangeCCV.bind(this)
    this.handleChangeTitular = this.handleChangeTitular.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.doPayment = this.doPayment.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.clearData = this.clearData.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentWillMount() {
    let email = ''
    try {
      let params = this.props.location.search.substr(1, this.props.location.search.length).split('&')
      if (params[3].split('=')[0].toUpperCase() === 'EMAIL') {
        email = params[3].split('=')[1]
      }
    }
    catch(err) {}
    this.setState({email: email})
  }

  isDataFormValid() {
    let number = String(this.state.number).trim()
    let type = ''
    let month = String(this.state.month).trim()
    let year = String(this.state.year).trim()
    let ccv = String(this.state.ccv).trim()
    let email = String(this.state.email).trim()
    let titular = String(this.state.titular).trim()

    if (number === "" || month === "" || year === "" || ccv === "") {
      return {error: true, code: 1, message: Utils.messages.PaymentForm.errorCardRequired}
    }

    // Validar tarjeta
    let validation = CardValidator.number(number).isValid
    validation = (!validation) ? false : CardValidator.expirationMonth(month).isValid
    validation = (!validation) ? false : CardValidator.expirationYear(year).isValid
    validation = (!validation) ? false : CardValidator.cvv(ccv).isValid

    if (!validation) {
      return {error: true, code: 2, message: Utils.messages.PaymentForm.errorCardValidation}
    }

    validation = CardValidator.creditCardType(number)[0].type || ''

    if (validation === '') {
      return {error: true, code: 3, message: Utils.messages.PaymentForm.errorTypeCardValidation}
    }
    else {
      type = validation
    }

    if (titular === "") {
      return {error: true, code: 8, message: Utils.messages.PaymentForm.errorTitularRequired}
    }

    let save = false
    if (Utils.validateEmail(email)) {
      save = true
    }

    return {error: false, code: 0, message: "",
      data: {
        save: save,
        type: type,
        number: number,
        month: month,
        year: year,
        ccv: ccv,
        titular: titular,
        email: email,
        alias: ''
      }
    }
  }

  handleChangeNumber(event) {
    let lastDigit = event.target.value.substr(event.target.value.length - 1, event.target.value.length)
    if (!Utils.isNumeric(lastDigit)) {
      return
    }

    let backspace = false
    if (event.target.value.length < this.state.numberMask.length) {
      backspace = true
    }
    
    // Máximo 16 dígitos (VISA y MASTERCARD)
    if (this.state.number.length >= 16 && !backspace) {
      return
    }

    let numberUpdated = ''
    if (backspace) {
      numberUpdated = this.state.number.substr(0, this.state.number.length - 1)
    }
    else {
      numberUpdated = this.state.number
      numberUpdated += lastDigit
    }

    this.setState({number: numberUpdated}, () => {
      let number = this.state.number
      let mask = ''

      if (number.length > 4) {
        let type = ''
        if (CardValidator.creditCardType(number).length > 0) {
          type = CardValidator.creditCardType(number)[0].type
        }
        let LAST_DIGIT = 4
        if (type === 'american-express') {
          LAST_DIGIT = 5
          if (number.length > 15) {
            number = this.state.number.substr(0, this.state.number.length - 1)
            this.setState({number: number})
          }
        }
        let lastDigits = number.substr(number.length - LAST_DIGIT, number.length)
        for (let i = 0; i < number.length - LAST_DIGIT; i ++) {
          if (type === 'american-express') {
            if (i === 4)
              mask += " ●"
            else if (i === 9)
              mask += "● "
            else
              mask += "●"
          }
          else {
            if (i === 4 || i === 8)
              mask += " ●"
            else if (i === 11)
              mask += "● "
            else
              mask += "●"
          }
        }
        mask += lastDigits
      }
      else {
        mask = number
      }

      let response = this.isDataFormValid()
      this.setState({
        payButtonDisabled: response.error,
        number: number,
        numberMask: mask
      })
    })
  }

  checkDisabled() {
    let response = this.isDataFormValid()
    let disabled = true
    if (!response.error && this.state.checkboxTerms) {
      disabled = false
    }
    return disabled
  }

  handleChangeMonth(event) {
    if (event.target.value.length > 2 || !Utils.isNumeric(event.target.value))
      return
    this.setState({month: event.target.value, payButtonDisabled: this.checkDisabled()})
  }

  handleChangeYear(event) {
    if (event.target.value.length > 2 || !Utils.isNumeric(event.target.value))
      return
    this.setState({year: event.target.value, payButtonDisabled: this.checkDisabled()})
  }

  handleChangeCCV(event) {
    if (event.target.value.length > 4 || !Utils.isNumeric(event.target.value))
      return
    this.setState({ccv: event.target.value, payButtonDisabled: this.checkDisabled()})
  }

  handleChangeTitular(event) {
    if (event.target.value.length > 64)
      return
    let upperCase = event.target.value.toUpperCase()
    this.setState({titular: upperCase, payButtonDisabled: this.checkDisabled()})
  }

  handleChangeEmail(event) {
    if (event.target.value.length > 64)
      return
    this.setState({email: event.target.value})
  }

  handleConfirm() {
    let response = this.isDataFormValid()
    if (response.error) {
      this.setState({
        openSnack: true,
        errorMessage: response.message
      })
    }
    else {
      this.props.addPaymentMethod(response.data)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.payment !== this.props.init.payment) {
      if (this.props.init.payment.status !== 'OK') {
        this.setState({
          onPayment: false
        })
      }
    }

    if (prevProps.init.paymentData !== this.props.init.paymentData) {
      this.doPayment(this.props.init.paymentData)
    }
  }

  doPayment(data) {
    if (this.state.onPayment)
      return

    let account = this.props.account
    if (!this.state.checkboxTerms) {
      this.setState({
        openSnack: true,
        errorMessage: Utils.messages.ConfirmPayment.termsValidation
      })
      return
    }

    if (account.newAmount <= 0) {
      this.setState({
        openSnack: true,
        errorMessage: Utils.messages.ConfirmPayment.amountValidation
      })
      return
    }

    if (account.newAmount > this.props.init.config.data.maxAmountPartialPayment) {
      this.setState({
        openSnack: true,
        errorMessage: Utils.messages.ConfirmPayment.maxAmount
      })
      return
    }

    this.setState({
      onPayment: true,
      openSnack: true,
      errorMessage: Utils.messages.ConfirmPayment.onPayment
    })

    // paymentTypes
    // 0 = ABONO
    // 1 = SALDO A FAVOR
    // 2 = PAGO COMPLETO CON SALDO A FAVOR
    // 3 = PAGO COMPLETO

    // Abono
    if (Number(account.newAmount) < Number(account.amount)) {
      account.paymentType = 0
    }
    else if (Number(account.newAmount) > Number(account.amount)) {
      // Saldo a favor
      if (Number(account.amount) <= 0) {
        account.paymentType = 1
      }
      // Pago completo y saldo a favor
      else {
        account.paymentType = 2
      }
    }
    // Pago completo
    else {
      account.paymentType = 3
    }

    let origin = 'WEB'
    let sender = ''
    let recipient = ''

    try {
      let params = this.props.location.search.substr(1, this.props.location.search.length).split('&')
      origin = params[0].split('=')[1]
      sender = params[1].split('=')[1]
      recipient = params[2].split('=')[1]
    }
    catch (err) {
      origin = 'WEB'
    }

    const paymentData = {
      officeId: this.props.init.officeId,
      userId: null,
      save: data.save,
      type: data.type,
      number: data.number,
      month: data.month,
      year: data.year,
      ccv: data.ccv,
      titular: data.titular || '',
      email: data.email,
      alias: '',
      reference: account.account,
      paymentType: account.paymentType,
      originalAmount: Number(account.amount),
      amount: Number(account.newAmount),
      origin: origin.toUpperCase(),
      senderId: sender,
      recipientId: recipient
    }
    this.props.requestDoPayment(paymentData)
  }

  handleClickCheckbox() {
    if (this.state.onPayment)
      return
    this.setState({
      checkboxTerms: !this.state.checkboxTerms
    }, () => {
      let disabled = this.checkDisabled()
      this.setState({
        payButtonDisabled: disabled
      })
    })
  }

  handleCloseSnackbar() {
    this.setState({errorMessage: '', openSnack: false})
  }

  handleClose() {
    this.clearData()
    this.props.handleClose()
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleConfirm()
    }
  }

  clearData() {
    this.setState({
      number: '',
      numberMask: '',
      month: '',
      year: '',
      ccv: '',
      titular: '',
      email: '',
      openSnack: false,
      errorMessage: '',
      payButtonDisabled: true,
      onPayment: false,
      checkboxTerms: false
    })
  }

  getUserFee() {
    return ((this.props.init.config.data.percentageFee * this.props.init.config.data.userFee) / 100)
  }

  getFee() {
    let total = Number(this.props.account.amount)
    let userFee = this.getUserFee()
    let totalWithFee = ((total * userFee) / 100)
    return totalWithFee
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Typography variant="subtitle1" style={{fontWeight: 600}}>
          Información de pago.
        </Typography>
        {
          (!this.state.onPayment) ?
            <form noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
              <TextField
                className={classes.creditCardTextField}
                label="Número de tarjeta (15/16 dígitos) *"
                type="text"
                value={this.state.numberMask}
                onChange={this.handleChangeNumber}
              />
              <TextField
                className={classes.smallTextField}
                label="Mes *"
                type="text"
                value={this.state.month}
                onChange={this.handleChangeMonth}
              />
              <TextField
                className={classes.smallTextField}
                label="Año *"
                type="text"
                value={this.state.year}
                onChange={this.handleChangeYear}
              />
              <TextField
                className={classes.LastSmallTextField}
                label="CCV *"
                type="text"
                value={this.state.ccv}
                onChange={this.handleChangeCCV}
              />
              <TextField
                className={classes.largeTextField}
                label="Titular *"
                type="text"
                value={this.state.titular}
                onChange={this.handleChangeTitular}
              />
              <TextField
                className={classes.largeTextField}
                label="Correo electrónico"
                type="email"
                value={this.state.email}
                onChange={this.handleChangeEmail}
              />
              <Grid container className={classes.checkboxContainer}>
                <Grid item lg={1} md={1} sm={1} xs={1} className={classes.smallGrid}>
                  <Checkbox
                    className={classes.checkbox}
                    color="primary"
                    checked={this.state.checkboxTerms}
                    onClick={ (event) => { this.handleClickCheckbox() }}
                  />
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11} className={classes.smallGrid}>
                  <Typography variant="body1" className={classes.checkFromText} onClick={ () => { this.handleClickCheckbox() }}>Aceptar términos y condiciones del proveedor de pagos <a rel="noopener noreferrer" target="_blank" href={Utils.constants.paymentsProviderURL}>{Utils.constants.paymentsProvider}</a></Typography>
                </Grid>
              </Grid>
              <div className={classes.actions}>
                <Button
                  className={classes.primaryButton}
                  variant="contained"
                  color="primary"
                  onClick={this.handleConfirm}
                  disabled={this.state.payButtonDisabled}
                >
                  PAGAR
                </Button>
                {
                  (this.props.init.config.data.userFee > 0) ?
                  <Grid item lg={12} md={12} sm={12} xs={12} className={classes.fee}>
                    <Typography variant="body1" style={{fontSize: 14}}>Costo de la transacción: <strong>$ { Utils.numberWithCommas(Number(this.getFee()).toFixed(2)) }</strong></Typography>
                  </Grid>
                  :
                  ''
                }
              </div>
            </form>
          :
            <div>
              <Typography variant="body1">{Utils.messages.ConfirmPayment.onPayment}</Typography>
            </div>
        }
        
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.errorMessage}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestDoPayment: (data) => {
      dispatch(requestDoPayment(data))
    },
    addPaymentMethod: (data) => {
      dispatch(addPaymentMethod(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentModal)
