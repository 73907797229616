import React, { Component } from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import Utils from '../resources/Utils'
import { connect } from 'react-redux'

import { requestEncryptStringBancomer, requestIntentoPago, requestOrdenesPago, responseEncryptStringBancomer } from '../actions/actions';
import { Hidden } from '@material-ui/core'


function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  modalContainer: {
    overflow: 'scroll'
  },
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      background: 'white',
      width: '90%',
      height: '100%',
      paddingTop: '20%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  modalTitle: {
    fontWeight: 600
  },
  creditCardTextField: {
    width: '100%',
    marginTop: 12
  },
  smallTextField: {
    width: '30%',
    paddingRight: '3%',
    marginTop: 12
  },
  LastSmallTextField: {
    width: '33%',
    marginTop: 12
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  containerSwitch: {
    margin: 0,
    padding: 0,
    marginTop: 32
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  }
})

class ResumeToPayment extends Component {
  constructor(props) {
    super(props)

    const date = new Date();

    var day = date.toISOString().slice(8,10)
    var month = date.toISOString().slice(5,7)
    var year =  date.toISOString().slice(0,4)
    var fechaString = year + month + day
    const mp_order = fechaString;

    this.state = {
      send: false,
      numeroCuenta: "",
      nombreUsuario: "",
      direccion: "",
      saldo: "",
      mp_reference: "",
      formReady: false,
      mp_account: Utils.constants.mp_account,
      mp_order: mp_order,
      mp_customername: "",
      mp_product: 1, 
      mp_node: 0,
      mp_concept: 1,
      mp_amount: 0,
      mp_currency: 1, 
      mp_urlsuccess: Utils.constants.HOST_API + "/payments/BBVA/",
      mp_urlfailure: Utils.constants.HOST_API + '/payments/BBVAFailure/',
      mp_signature: "",
      clariti_urlsuccess: Utils.constants.HOST_WEB_API + Utils.constants.paths.bbvaPaymentSuccess,
      clariti_urlfailure: Utils.constants.HOST_WEB_API + Utils.constants.paths.bbvaPaymentFailure,
      clariti_office_id: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      mp_ordenPago:""
    }

    this.handleClose = this.handleClose.bind(this)
    this.handleRender = this.handleRender.bind(this)
    this.handleChangeAmount = this.handleChangeAmount.bind(this)
    this.updateSignature = this.updateSignature.bind(this)
    this.handleSaveOrdenPago = this.handleSaveOrdenPago.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.encryptedStringBancomer !== this.props.init.encryptedStringBancomer) {
      var resp = this.props.init.encryptedStringBancomer;
      if(resp.status === "OK"){
          const mp_signatureHash = resp.data;
          this.setState({
            mp_signature: mp_signatureHash,
            formReady: true
          })

          if(this.state.send){
            var input = document.getElementById("txtmp_signature")
            input.value = mp_signatureHash

            var form = document.getElementById("form")
            form.submit()
          }
      } else {
        this.setState({
          formReady: false
        })
      }
    } 

    if (prevProps.init.intentoPago !== this.props.init.intentoPago) {
      var { status, data } = this.props.init.intentoPago;
      if(status === "OK"){
        if(Utils.isUserLoggedIn()){
          var total = this.props.init.accountsForPay.length
          const { data } = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
    
          if(total > 1){
            
            this.props.requestOrdenesPago({
              clariti_office_id: "1",
              userId: data.id,
              monto: this.state.saldo,
              ordenPagosDetalles: this.props.init.accountsForPay.map( (v) => { return  { cuenta: v.account, monto:  parseFloat(v.toPay) } } )
            })
    
          } else {
    
            var form = document.getElementById("form")
            form.submit()
    
          }
    
        } else {
    
          var form = document.getElementById("form")
          form.submit()
    
        }
      }
    } 


    if (prevProps.init.ordenPago !== this.props.init.ordenPago) {
      var { status, data } = this.props.init.ordenPago;
      if(status === "OK"){
          this.setState({
            mp_reference: data,
            send: true
          })
          this.updateSignature(this.state.mp_amount, data)
      } else {
        this.setState({
          formReady: false
        })
      }
    } 

  }

  handleClose() {
    this.props.handleClose()
  } 
  updateSignature(pAmount, pReference){

    this.setState({
      formReady: false
    })
    
    const mp_order = this.state.mp_order
    const mp_amount = Number(pAmount).toFixed(2)
    const mp_reference = pReference

    const mp_signature = mp_order.toString() + mp_reference.toString() + mp_amount.toString()
    this.props.requestEncryptStringBancomer({ "cadena": mp_signature })

  }

  handleRender() {
    
    var total = this.props.init.accountsForPay.length
    var totalAmountToPay = 0
    var customerName = ""
    var directions = ""
    var acounts = ""
    var mp_ordenPago = ""
    if(total > 1) {
      this.props.init.accountsForPay.forEach(function(value, idx) {
        totalAmountToPay += parseFloat(value.toPay)
      })

      acounts = ""
      if(Utils.isUserLoggedIn){
        const { data } = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
        customerName = data.email
        directions += "MULTIACCOUNTS"
      }
      mp_ordenPago = "MULTIACCOUNTS"
    } else {
     this.props.init.accountsForPay.forEach(function(value, idx) {
        totalAmountToPay += parseFloat(value.toPay)
        customerName += value.name + ", "
        directions += value.address  + ", "
        acounts += value.account  + ", "
      })
      customerName = customerName.slice(0, -1).slice(0, -1)
      directions = directions.slice(0, -1).slice(0, -1)
      acounts = acounts.slice(0, -1).slice(0, -1)
    }
    this.setState({
      numeroCuenta: acounts,
      nombreUsuario: customerName,
      direccion: directions,
      saldo: totalAmountToPay,
      mp_reference: acounts,
      mp_customername: customerName,
      mp_amount: Number(totalAmountToPay).toFixed(2),
      mp_ordenPago: mp_ordenPago
    })
    this.updateSignature(totalAmountToPay, acounts)
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleChangeAmount(event) {
    if (Number(event.target.value).toFixed(2).toString().length >= 13 || !Utils.isDouble(event.target.value))
      return

    if(event.target.value.toString().length >= 13)
      return
    
    this.setState({mp_amount: event.target.value })
  }

  handleSaveOrdenPago(event) {
  
    if(Utils.isUserLoggedIn()){
      var total = this.props.init.accountsForPay.length
      const { data } = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))

      if(total > 1){
        
        this.props.requestIntentoPago({
          clariti_office_id: "1",
          userId: data.id,
          monto: this.state.saldo,
          ordenPagosDetalles: this.props.init.accountsForPay.map( (v) => { return  { cuenta: v.account, monto:  parseFloat(v.toPay) } } ),
          mp_order: this.state.mp_order,
        })

      } else {

        this.props.requestIntentoPago({
          clariti_office_id: "1",
          userId: data.id,
          mp_reference: this.state.mp_reference,      
          mp_order: this.state.mp_order,
          mp_amount: this.state.mp_amount,
        })

      }

    } else {

      this.props.requestIntentoPago({
          clariti_office_id: "1",
          userId: null,
          mp_reference: this.state.mp_reference,
          mp_order: this.state.mp_order,
          mp_amount: this.state.mp_amount,
      })

    }

  }

  render() {
    const { classes, init } = this.props
    const {accountsForPay } = init
    const { 
      mp_account,
      mp_order,
      mp_reference,
      mp_customername,
      mp_product,
      mp_node,
      mp_concept,
      mp_amount,
      mp_currency,
      mp_urlsuccess,
      mp_urlfailure,
      clariti_urlsuccess,
      clariti_urlfailure,
      clariti_office_id,
      mp_signature,
      mp_ordenPago
    } = this.state;

    var hidden = accountsForPay ? (accountsForPay.length !== 1) : false

    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
        className={classes.modalContainer}
        onRendered={this.handleRender}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
            { Utils.getMessages().BBVABancomerModalConfirmation.title }
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
            {Utils.getMessages().BBVABancomerModalConfirmation.description}
          </Typography>

          <div>

            { !hidden ?

            <TextField
                className={classes.largeTextField}
                label={Utils.getMessages().BBVABancomerModalConfirmation.textAccountNumber}
                type="text"
                disabled={true}
                value={this.state.numeroCuenta}
              /> : null
            }
              <TextField
                className={classes.largeTextField}
                label={Utils.getMessages().BBVABancomerModalConfirmation.textName}
                type="text"
                disabled={true}
                value={this.state.nombreUsuario}
              />


            { !hidden ?

              <TextField
                className={classes.largeTextField}
                label={Utils.getMessages().BBVABancomerModalConfirmation.textAdress}
                type="text"
                value={this.state.direccion}
                disabled={true}
              /> : null 
            }

              <TextField
                className={classes.largeTextField}
                label={Utils.getMessages().BBVABancomerModalConfirmation.textAmount}
                type = "text"
                value = { this.state.mp_amount }
                onChange = {this.handleChangeAmount}
                onBlurCapture = {this.handleOnBlurCapture}
                disabled={true}
              />

          </div>

          <form action={Utils.constants.paymentsProviderBancomer} method="POST" id="form">
            <input type="text" name="mp_account" value={mp_account} hidden onChange={this.onChange}  />
            <input type="text" name="mp_order" value={mp_order} hidden onChange={this.onChange} />
            <input type="text" name="mp_reference" value={mp_reference} hidden onChange={this.onChange} />
            <input type="text" name="mp_customername" value={mp_customername} hidden onChange={this.onChange} />
            <input type="text" name="mp_product" value={mp_product} hidden onChange={this.onChange} />
            <input type="text" name="mp_node" value={mp_node} hidden onChange={this.onChange} />
            <input type="text" name="mp_concept" value={mp_concept} hidden onChange={this.onChange} />         
            <input type="text" name="mp_currency" value={mp_currency} hidden onChange={this.onChange} />           
            <input type="text" name="mp_urlsuccess" value={mp_urlsuccess} hidden onChange={this.onChange} />
            <input type="text" name="mp_urlfailure" value={mp_urlfailure} hidden onChange={this.onChange} />
            <input type="text" name="clariti_urlsuccess" value={clariti_urlsuccess} hidden onChange={this.onChange} />
            <input type="text" name="clariti_urlfailure" value={clariti_urlfailure} hidden onChange={this.onChange} />
            <input type="text" name="clariti_office_id" value={clariti_office_id} hidden onChange={this.onChange} />
            <input type="text" name="mp_ordenPago" value={mp_ordenPago} hidden onChange={this.onChange} />

            <input type="text" name="mp_signature" value={mp_signature} hidden onChange={this.onChange} id="txtmp_signature" />
            <input type="text" name="mp_amount" value={Number(this.state.mp_amount).toFixed(2)} hidden onChange={this.onChange} />
            

            <div className={classes.actions}>

              <Button          
                  onClick={this.handleClose}
                >
                {Utils.getMessages().PaymentForm.cancelButton}
              </Button>

              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                type="button"
                disabled={!this.state.formReady}
                onClick={ this.handleSaveOrdenPago }
              >
                {Utils.getMessages().PaymentForm.confirmButton}
              </Button>


            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })


const mapDispatchToProps = dispatch => {
  return { 
    requestEncryptStringBancomer: (data) => {
      dispatch(requestEncryptStringBancomer(data))
    },
    responseEncryptStringBancomer: (data) => {
      dispatch(responseEncryptStringBancomer(data))
    },
    requestOrdenesPago: (data) => {
      dispatch(requestOrdenesPago(data))
    },
    requestIntentoPago: (data) => {
      dispatch(requestIntentoPago(data))
    },
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ResumeToPayment)
