import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// Material UI
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles, withTheme } from '@material-ui/core/styles'

// Resources
import { Button,Box, IconButton,Snackbar,TableContainer, TablePagination, TextField } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { requestUsersByOfficeId,requestEditUsersByEmail } from '../actions/actions'
import Utils from '../resources/Utils'
import CircleLoading from './CircleLoading'

const styles = theme => ({
  headerTable: {
    fontWeight: 700,
    width: '100%',
    Height: '100px'
  },

  smallCell: {
    margin: 0,
    padding: 0
  },
  tableHeader: {
    position: 'sticky',
    top: '0',
  },
  paginationEnd: {
    position: 'sticky',
    bottom: '0',
    with: '100%',

  },
  activeScroll: {
    overflow: 'auto'
  },
  buttonStyle:{
    minWidth:'100px',
    width: '100%',
    height: window.innerHeight * 0.04,
    position:'static'
  }

})
let data = {
  pageNumber: 1,
  pageSize: 5,
  searchByReference: 'default',
}
class UsersTable extends PureComponent {

  constructor(props) {
    super(props)
    this.props.requestUsersByOfficeId(data)
    this.state = {
      notIsData: false,
      isloading: false,
      page: 0,
      rowsPerPage: !Utils.isEmpty(this.props.init.users) && this.props.init.users.status !== 'WARNING' ? this.props.init.users.data.pageSize : 5,
      totalPages: !Utils.isEmpty(this.props.init.users) && this.props.init.users.status !== 'WARNING' ? this.props.init.users.data.totalPage : 0,
      data: !Utils.isEmpty(this.props.init.users) && this.props.init.users.status !== 'WARNING' ? this.props.init.users.data.data : [],
      backgroundColor: this.props.init.config.data.appBarColor,
      height: window.innerHeight - 250,
      snackbar:false
    }

  }
  handleChangePage = (event, newPage) => {
    this.setState({ isloading: true })
    data.pageNumber = newPage + 1
    data.pageSize = this.state.rowsPerPage
    this.props.requestUsersByOfficeId(data)
    this.setState({
      page: newPage,
      totalPages: this.state.totalPages,
      data: this.state.data
    });
  };
  useTimer()  {
    this.setState({ snackbar: true })
    const intervalId = setTimeout(() => {
      this.setState({snackbar:false})
    }, 5000);
    return () => clearInterval(intervalId); // Clear interval on component teardown
  } 
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if(this.props.init.errorEditStatusUser !== prevProps.init.errorEditStatusUser){
      if(!Utils.isEmpty(this.props.init.errorEditStatusUser)){
        if(this.props.init.errorEditStatusUser.status==="ERROR"){
          this.useTimer()
          this.props.init.errorEditStatusUser=''
        }
      }
    }
    if (this.props.init.users !== prevProps.init.users) {
      if (this.props.init.users.status === 'WARNING') {
        this.setState({ notIsData: true, page: 0 })
      } else {
        const { page, rowsPerPage } = this.state;
        const totalPages = this.props.init.users.data.totalPage;
        const data = this.props.init.users.data.data;
        var numPage = page;
        if ((rowsPerPage * page) > totalPages) {
          numPage = Math.round(totalPages / rowsPerPage)
        }
        this.setState({
          notIsData: false,
          isloading: false,
          page: numPage,
          rowsPerPage: rowsPerPage,
          totalPages: totalPages,
          data: data
        })


      }

    }

  }
  handleChangeRowsPerPage = (event) => {
    this.setState({ isloading: true })
    if ((parseInt(event.target.value, 10) * this.state.page) >= this.state.totalPages) {
      for (let i = this.state.page; i >= 0; i--) {
        if ((parseInt(event.target.value, 10) * i) < this.state.totalPages) {
          data.pageNumber = i + 1
          i = 0
        }

      }
    }
    data.pageSize = parseInt(event.target.value, 10)
    this.props.requestUsersByOfficeId(data)
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: this.state.page > 1 ? data.pageNumber - 1 : this.state.page,
      totalPages: this.state.totalPages,
      data: this.state.data    
    });

  };
  handleChangeSearchUser = (event) => {
    if (!(Utils.isEmpty(event.target.value) || event.target.value === '' || event.target.value === '0')) {
      this.setState({ isloading: true })
      data.searchByReference = event.target.value
      this.props.requestUsersByOfficeId(data)
      this.setState({
        isloading: false,
        page: 0,
        rowsPerPage: !Utils.isEmpty(this.props.init.users) && this.props.init.users.status !== 'WARNING' ? this.props.init.users.data.pageSize : 5,
        totalPages: !Utils.isEmpty(this.props.init.users) && this.props.init.users.status !== 'WARNING' ? this.props.init.users.data.totalPage : 0,
        data: !Utils.isEmpty(this.props.init.users) && this.props.init.users.status !== 'WARNING' ? this.props.init.users.data.data : []
      });
    } else {
      data.searchByReference = 'default'
      this.props.requestUsersByOfficeId(data)
    }
  }
  handleUpdateStatusUser=(email,status)=>{
    let data={
      email:email,
      status:status
    }
    this.setState({
      snackbar:false
    })
    this.props.requestEditUsersByEmail(data)
  }
  handleCloseSnackbar=()=>{
    this.setState({
      snackbar:false
    })
  }
  renderTableData = () => {
    const { data, page, rowsPerPage} = this.state;
    const { classes } = this.props
    const slicedData = data.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);
    return (
      <TableBody>
        {(!Utils.isEmpty(slicedData)) ?
          slicedData.map((user, idx) => {
            return (
              <TableRow key={idx} >
                <TableCell><Typography variant="body2" className={classes.smallCell}>{user.email}</Typography></TableCell>
                <TableCell>{(user.status==1)?<Typography variant="body2" color="secondary" className={classes.smallCell}>{Utils.getMessages().usersMessages.active}</Typography>:<Typography variant="body2" color='primary' className={classes.smallCell}>{Utils.getMessages().usersMessages.notActive}</Typography>}</TableCell>
                <TableCell><Typography variant="body2" className={classes.smallCell}>{user.registerDate}</Typography></TableCell>
                <TableCell><Typography variant="body2" className={classes.smallCell}>
                  {(user.status==1)?
                  <Button className={classes.buttonStyle} variant="contained" color="primary" onClick={(e)=>this.handleUpdateStatusUser(user.email,2)} >{Utils.getMessages().usersMessages.buttonBaja}</Button>
                  :
                  <Button className={classes.buttonStyle} variant="contained" color="secondary" onClick={(e)=>this.handleUpdateStatusUser(user.email,1)} >{Utils.getMessages().usersMessages.buttonAlta}</Button>
                  }      
                </Typography></TableCell>
                </TableRow>
            )
          }) : ''

        }
      </TableBody>
    );
  }
  render() {
    const { classes } = this.props
    const { page, rowsPerPage, totalPages, backgroundColor, height, isloading, notIsData,snackbar } = this.state;
    return (
      <div>
        {(snackbar)?<div>
      <Snackbar  anchorOrigin={{vertical:'top',horizontal:'center'}}  open={snackbar}  message={<span>{Utils.getMessages().usersMessages.errorUpdate}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.handleCloseSnackbar}
        >
          <CloseIcon />
        </IconButton>
      ]}
      ></Snackbar>
     
      </div>:''} 
        <TextField fullWidth type="email" InputLabelProps={{ shrink: true, }} style={{minWidth:'140px', width: '30%' }} defi id="standard-basic" label={Utils.getMessages().usersHeaders.email} onChange={this.handleChangeSearchUser} />
        <br/><br/>
        {
          (isloading) ? <CircleLoading mensajeCargando="Cargando datos" />
            : (notIsData) ?
              <Typography variant='body1' className={classes.smallCell} style={{ textAlign: 'center' }}>{Utils.getMessages().paymentsMessages.notData}</Typography>
              :
              <TableContainer>
                <div className={classes.activeScroll} style={{ 'height': height < window.innerHeight - 300 ? height : (window.innerHeight - 300) }}>
                  <Table>
                    <TableHead className={classes.tableHeader} style={{ background: backgroundColor }}>
                      <TableRow>
                        <TableCell><Typography variant="body1" className={classes.headerTable}>{Utils.getMessages().usersHeaders.email}</Typography></TableCell>
                        <TableCell><Typography variant="body1" className={classes.headerTable}>{Utils.getMessages().usersHeaders.status}</Typography></TableCell>
                        <TableCell><Typography variant="body1" className={classes.headerTable}>{Utils.getMessages().usersHeaders.regsiterDate}</Typography></TableCell>
                        <TableCell><Typography variant="body1" className={classes.headerTable}></Typography></TableCell>
                      </TableRow>
                    </TableHead>

                    {
                      this.renderTableData()
                    }

                  </Table>
                  {(!Utils.isEmpty(this.props.init.users)) ?
                    <TablePagination
                      className={classes.paginationEnd}
                      style={{ 'background': backgroundColor}}
                      labelRowsPerPage={<Typography variant="body1">{Utils.getMessages().pagination.rowsPerPages}</Typography>}
                      labelDisplayedRows={({ from, to, count }) => <Typography variant="body1">{from} - {to} {Utils.getMessages().pagination.of} {count} {Utils.getMessages().pagination.rows}</Typography>}
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      component="div"
                      rowsPerPage={rowsPerPage}
                      backIconButtonText={Utils.getMessages().pagination.previousPage}
                      nextIconButtonText={Utils.getMessages().pagination.nextPage}
                      count={totalPages}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    /> : ''
                  }
                </div>
              </TableContainer>}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestUsersByOfficeId: (data) => {
      dispatch(requestUsersByOfficeId(data))
    },
    requestEditUsersByEmail: (data) => {
      dispatch(requestEditUsersByEmail(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(UsersTable)
