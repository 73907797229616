import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import { TextField, Button, Typography } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Utils
import Utils from '../resources/Utils'
import { requestLogin } from '../actions/actions'

const styles = theme => ({
  loginForm: {
    margin: '0 auto',
    backgroundColor: theme.palette.background.secondary,
    padding: "16px 32px"
  },
  textField: {
    width: '100%',
    marginTop: 8
  },
  loginButton: {
    width: '100%',
    marginTop: 16,
    fontWeight: 600,
    fontSize: 14
  },
  containerFooter: {
    marginTop: 32,
    textAlign: 'center'
  }
})

class LoginForm extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      snackbar: {
        open: false,
        message: ''
      },
      email: '',
      password: ''
    }

    this.handleClose = this.handleClose.bind(this)
    this.login = this.login.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleClose() {
    this.setState({
      snackbar: {
        open: false,
        message: this.state.snackbar.message
      }
    })
  }

  login() {
    let MIN_PASSWORD = 0
    if (this.state.email.trim() === "" || this.state.password.trim() === "") {
      this.setState({
        snackbar: {
          open: true,
          message: Utils.getMessages().LoginForm.loginFormRequired
        }
      })
    }
    else {
      let email = this.state.email
      let password = this.state.password
      if (!Utils.validateEmail(email)) {
        this.setState({
          snackbar: {
            open: true,
            message: Utils.getMessages().LoginForm.invalidEmail
          }
        })
      }
      else if (password.trim().length < MIN_PASSWORD) {
        this.setState({
          snackbar: {
            open: true,
            message: Utils.getMessages().LoginForm.invalidPassword
          }
        })
      }
      else {
        this.props.requestLogin({
          email: email,
          password: password,
          employee:false
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.user !== this.props.init.user) {
      if (this.props.init.user.status === "OK") {
        this.props.history.push('/cuentas')
      }
      else if (this.props.init.user.status === "WARNING") {
        this.setState({
          snackbar: {
            open: true,
            message: Utils.getMessages().LoginForm.loginFormValidation
          }
        })
      }
      else {
        if (!Utils.isEmpty(this.props.init.user)) {
          this.setState({
            snackbar: {
              open: true,
              message: Utils.getMessages().General.error
            }
          })
        }
      }
    }
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value })
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value })
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.login()
    }
  }
  
  render() {
    const { snackbar: { open, message } } = this.state
    const { classes } = this.props

    return (
      <form className={classes.loginForm} noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
        <TextField
          label={Utils.getMessages().LoginForm.placeholderEmail}
          type="email"
          value={this.state.email}
          onChange={this.handleChangeEmail}
          className={classes.textField}
          autoFocus
        />
        <TextField
          label={Utils.getMessages().LoginForm.placeholderPassword}
          type="password"
          value={this.state.password}
          onChange={this.handleChangePassword}
          className={classes.textField}
        />
        {
          (this.props.init.config.data) ?
            <Button
              variant="contained"
              color="primary"
              className={classes.loginButton}
              onClick={() => { this.login() }}
            >
              {Utils.getMessages().LoginForm.loginButton}
            </Button>
            :
            ''
        }
        <div className={classes.containerFooter} >
          <Link to={{pathname: Utils.constants.paths.recovery}}>
            <Typography color="primary" variant="body2">{Utils.getMessages().LoginForm.recoveryButton}</Typography>
          </Link>
          {
            (!Utils.isEmpty(this.props.init.config.data)) ?
              (this.props.init.config.data.fastFlow === 1) ?
                <div>
                  <br />
                  <Typography variant="body1">{Utils.getMessages().LoginForm.loginMessage}</Typography>
                  <Link to={{pathname: Utils.constants.paths.home}}>
                    <Typography color="primary" variant="body2">{Utils.getMessages().LoginForm.payButton}</Typography>
                  </Link>
                </div>
              :
              ''
            :
            ''
          }
          <Typography variant="body1">-</Typography>
          <Link to={{pathname: Utils.constants.paths.register}}>
            <Typography color="primary" variant="body2">{Utils.getMessages().LoginForm.registerButton}</Typography>
          </Link>
        </div>
        
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={open}
          onClose={this.handleClose}
          message={
            <span>
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

      </form>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestLogin: (data) => {
      dispatch(requestLogin(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(LoginForm)
