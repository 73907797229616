import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

// Components
import Title from '../components/Title'
import LoginForm from '../components/LoginForm'

// Utils
import Utils from '../resources/Utils'
import { deleteWaterAccount } from '../actions/actions'

const styles = theme => ({
  login: {
    width: '40%',
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
      width: '100%'
    }
  }
})

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openSnack: false,
      messageSnack: ''
    }

    this.handleCloseSnack = this.handleCloseSnack.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      this.props.history.push("/cuentas")
    }
    else {
      this.props.deleteWaterAccount()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init !== this.props.init) {
      if (this.props.init.newPassword) {
        if (this.props.init.newPasswordType === 'register') {
          this.setState({
            openSnack: true,
            messageSnack: Utils.getMessages().CreateAccount.okNewPassword
          })
        }
        else {
          this.setState({
            openSnack: true,
            messageSnack: Utils.getMessages().RecoveryPassword.okNewPassword
          })  
        }
      }
    }
  }

  handleCloseSnack() {
    this.setState({
      openSnack: false,
      messageSnack: ''
    })
  }

  render() {
    
    const { classes } = this.props

    return (
      <div className={classes.login}>
        <Title
          title={Utils.getMessages().LoginForm.title}
          description={Utils.getMessages().LoginForm.description}
        />
        <br />
        <LoginForm/>
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    deleteWaterAccount: () => {
      dispatch(deleteWaterAccount())
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Login)
