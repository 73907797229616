import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid'

// Components
import Title from '../components/Title'
import PayForm from '../components/PayForm'
import Spinner from '../components/Spinner'

// Utils
import Utils from '../resources/Utils'
import { requestValidateAccountNumber, addAccountsForPay } from '../actions/actions'

const styles = theme => ({
  accountTable: {
    marginTop: 16
  },
  checkMarkSuccess: {
    textAlign: 'center'
  }
})

class Pay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openSnack: false,
      messageSnack: '',
      showAccounts: false,
      editAmount: false,
      onEdit: false,
      amount: 0,
      newAmount: 0,
      showCheckMark: false,
    }

    // Others
    this.handleChangeBalanceAmount = this.handleChangeBalanceAmount.bind(this)
    this.confirmNewAmount = this.confirmNewAmount.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.getAccount = this.getAccount.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillMount() {
    this.props.requestValidateAccountNumber(this.props.match.params.accountNumber)
  }

  componentDidUpdate(prevProps) {
    // Mostrar cuentas (usuario no autenticado)
    if (prevProps.init.account !== this.props.init.account) {
      if (Utils.isEmpty(this.props.init.account.data)) {
        this.props.history.push(Utils.constants.paths.home)
      }
      else {
        let openSnack = false
        let errorMessage = Utils.messages.General.error

        if (this.getAccount().amount <= 0) {
          openSnack = true
          errorMessage = Utils.messages.General.notDebit
        }

        let editAmount = false
        if (this.getAccount().amount <= 0) {
          if (this.acceptPositiveBalance())
            editAmount = true
        }
        else {
          if (this.acceptPartialPayment() || this.acceptPositiveBalance()) {
            editAmount = true
          }
        }

        this.props.addAccountsForPay([this.props.init.account.data])

        this.setState({
          showAccounts: true,
          editAmount: editAmount,
          amount: this.getAccount().amount.toFixed(2),
          newAmount: this.getAccount().amount.toFixed(2),
          openSnack: openSnack,
          messageSnack: errorMessage
        })
      }
    }

    let self = this
    if (prevProps.init.payment !== this.props.init.payment) {
      if (this.props.init.payment.status === 'OK') {
        this.setState({showCheckMark: true})
        setTimeout(function() {
          self.props.history.push("/pago")
        }, 3000)
      }
      else {
        let message = Utils.messages.ConfirmPayment.errorPayment
        if (!Utils.isEmpty(this.props.init.payment.devMessage)) {
          message = this.props.init.payment.devMessage
        }
        this.setState({
          openSnack: true,
          messageSnack: message
        })
      }
    }
  }

  // Others
  handleChangeBalanceAmount(event) {
    if (event.target.value.length > 64 || !Utils.isDouble(event.target.value))
      return
    this.setState({
      newAmount: event.target.value
    })
  }

  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  acceptPositiveBalance() {
    return this.props.init.config.data.positiveBalance
  }

  acceptPartialPayment() {
    return this.props.init.config.data.partialPayment
  }

  getAccount() {
    let account = Object.assign({}, this.props.init.account.data, {newAmount: this.state.newAmount})
    return account
  }

  showPayForm() {
    if (this.state.newAmount <= 0 || this.state.onEdit) {
      return false
    }
    return true
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.confirmNewAmount()
    }
  }

  confirmNewAmount() {
    let isHigher = false

    let newAmount = Number(this.state.newAmount)
    let amount = Number(this.state.amount)
    
    if (newAmount > amount) {
      isHigher = true
    }

    let error = false
    let message = ''
    if (!this.acceptPositiveBalance() && isHigher) {
      error = true
      message = Utils.messages.ConfirmPayment.notPositiveBalance
      newAmount = amount
    }
    
    if (!this.acceptPartialPayment() && !isHigher) {
      error = true
      message = Utils.messages.ConfirmPayment.notPartialPayment
      newAmount = amount 
    }

    if (this.acceptPositiveBalance()) {
      let maxAmount = Number(this.props.init.config.data.maxAmountPartialPayment)
      if (newAmount > maxAmount) {
        error = true
        message = 'El monto máximo permitido a pagar es de $' + Utils.numberWithCommas(maxAmount.toFixed(2)) + ' MXN'
        newAmount = amount
      }
    }

    if (this.acceptPartialPayment()) {
      let minAmount = this.props.init.config.data.minAmmountPartialPayment
      let minAmountPerAccount = (this.state.amount * (this.props.init.config.data.percentageAmountPerAccount / 100))
      let min = minAmountPerAccount
      if (minAmountPerAccount < minAmount) {
        min = minAmount
      }
      if (newAmount < min) {
        error = true
        message = 'El monto mínimo permitido a pagar es de $' + Utils.numberWithCommas(min.toFixed(2)) + ' MXN'
        newAmount = amount
      }
    }

    this.setState({
      newAmount: newAmount,
      onEdit: false,
      openSnack: error,
      messageSnack: message
    })
  }

  renderWithAccounts(classes) {
    return (
      <div className={classes.accountsContainer}>
        {
          (!this.state.showCheckMark) ?
          <div>
            <Table style={{marginTop: 8}}>
              <TableBody>
                <TableRow>
                  <TableCell style={{paddingLeft: 0, paddingRight: 16}}>
                    {this.getAccount().account}
                    <br />
                    <strong style={{fontSize: 14}}>Número de cuenta</strong>
                  </TableCell>
                  {
                    (this.state.onEdit) ?
                    <TableCell>
                      <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            noValidate autoComplete="off" onKeyPress={this.handleKeyPress}
                            type="text"
                            value={this.state.newAmount}
                            onChange={this.handleChangeBalanceAmount}
                            autoFocus
                          />
                          <br />
                          <strong style={{fontSize: 14}}>Saldo</strong>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <IconButton style={{marginTop: -10}} onClick={() => { this.confirmNewAmount() }}>
                            <CheckIcon />
                          </IconButton>
                          <IconButton style={{marginTop: -10}} onClick={() => { this.setState({newAmount: this.state.amount, onEdit: false}) }}>
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                    :
                    <TableCell>
                      <Grid container>
                        <Grid item>
                          $ {Utils.numberWithCommas(Number(this.getAccount().newAmount).toFixed(2))}
                          <br />
                          <strong style={{fontSize: 14}}>Saldo</strong>
                        </Grid>
                        <Grid item>
                          {
                            (this.state.editAmount) ?
                              <IconButton style={{marginTop: -10}} onClick={() => { this.setState({onEdit: !this.state.onEdit}) }}>
                                <EditIcon />
                              </IconButton>
                            :
                            ''
                          }
                        </Grid>
                      </Grid>
                    </TableCell>
                  }
                </TableRow>
                <TableRow>
                  <TableCell style={{paddingLeft: 0, paddingRight: 16}}>
                    {this.getAccount().name}
                    <br />
                    <strong style={{fontSize: 14}}>Nombre</strong>
                  </TableCell>
                  <TableCell>
                    {this.getAccount().address}
                    <br />
                    <strong style={{fontSize: 14}}>Dirección</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {
              (this.showPayForm()) ?
              <div style={{marginTop: 24}}>
                <PayForm account={this.getAccount()}/>
              </div>
              :
              ''
            }
          </div>
          :
          <div className={classes.checkMarkSuccess}>
            <Spinner />
            <br />
            <br />
            <br />
            <Title
              style={{paddingTop: 24}}
              title="Pago realizado con éxito."
              description="Gracias por tu pago puntual..."
            />
          </div>
      }

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.messageSnack}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    )
  }

  render() {
    const { classes } = this.props
    if (this.state.showAccounts) {
      return this.renderWithAccounts(classes)
    }
    else {
      return ''
    }
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    addAccountsForPay: (data) => {
      dispatch(addAccountsForPay(data))
    },
    requestValidateAccountNumber: (accountNumber) => {
      dispatch(requestValidateAccountNumber(accountNumber))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Pay)
