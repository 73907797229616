import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

// Material UI
import { withTheme, withStyles } from "@material-ui/core/styles";
import { TextField, Button, Typography, FormControl, InputLabel, FilledInput, InputAdornment, Modal } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// Utils
import Utils from "../resources/Utils";
import { requestValidateAccountNumber } from "../actions/actions";
import { Help } from "@material-ui/icons";

const styles = (theme) => ({
  modalContainer: {
    overflow: 'scroll'
  },
  container: {
    position: 'absolute',
    width: theme.spacing(90) ,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      background: 'white',
      width: '90%',
      height: '100%',
      paddingTop: '20%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  img:{
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      //height: '100%',
      width: "100%"
    }
  },
  modalTitle: {
    fontWeight: 600
  },
  initForm: {
    marginLeft: 32,
    backgroundColor: theme.palette.background.secondary,
    padding: "16px 32px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  textField: {
    width: "100%",
  },
  startButton: {
    width: "100%",
    marginTop: 16,
    fontWeight: 600,
    fontSize: 14,
  },
  containerFooter: {
    marginTop: 16,
    textAlign: "center",
  },
});

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

class InitForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountNumber: "",
      snackbar: {
        open: false,
        message: "",
      },
      openHelp: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleGo = this.handleGo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.account !== this.props.init.account) {
      if (this.props.init.account.status === "OK") {
        this.props.history.push(
          "/cuentas/" + this.props.init.account.data.account
        );
      } else if (this.props.init.account.status === "WARNING") {
        this.setState({
          snackbar: {
            open: true,
            message: Utils.getMessages().InitForm.notFoundAccount,
          },
        });
      } else {
        this.setState({
          snackbar: {
            open: true,
            message: Utils.getMessages().General.error,
          },
        });
      }
    }
  }

  handleClose() {
    this.setState({
      snackbar: {
        open: false,
        message: this.state.snackbar.message,
      },
    });
  }

  handleGo() {
    if (this.state.accountNumber.trim() === "") {
      this.setState({
        snackbar: {
          open: true,
          message: Utils.getMessages().InitForm.validationForm,
        },
      });
    }else if (this.state.accountNumber.length!==10) {
      this.setState({
        snackbar: {
          open: true,
          message: Utils.getMessages().InitForm.notCorrectNumber,
        },
      });
    }else {
      console.log(this.props.requestValidateAccountNumber(this.state.accountNumber));
      this.props.requestValidateAccountNumber(this.state.accountNumber);
    }
  }

  handleChange(event) {
    if (!Utils.isNumeric(event.target.value)) return;
    this.setState({ accountNumber: event.target.value });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleGo();
    }
  }

  render() {
    const {
      snackbar: { open, message },
      openHelp
    } = this.state;
    const { classes } = this.props;

    return (
      <form
        className={classes.initForm}
        noValidate
        autoComplete="off"
        onKeyPress={this.handleKeyPress}
      >

        <FormControl style={{width: "100%"}} variant="outlined">
          <InputLabel variant="outlined" htmlFor="filled-account">{Utils.getMessages().InitForm.placeholder}</InputLabel>
          <FilledInput
            id="filled-account"
            type={"text"}
            value={this.state.accountNumber}
            onChange={this.handleChange}
            autoFocus
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle help visibility"
                  color="primary"
                  onClick={()=> {
                    this.setState({
                      openHelp: !openHelp
                    }); 
                  }}
                  edge="end"
                >
                  <Help />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        
        {
          false ?  <TextField
          label={Utils.getMessages().InitForm.placeholder}
          type="text"
          className={classes.textField}
          value={this.state.accountNumber}
          onChange={this.handleChange}
          autoFocus
        />: null
        }
       

        <Button
          variant="contained"
          color="primary"
          className={classes.startButton}
          onClick={this.handleGo}
        >
          {Utils.getMessages().InitForm.startButton}
        </Button>

  
          <div className={classes.containerFooter}>
            <Typography variant="body1">
              {Utils.getMessages().InitForm.loginMessage}
            </Typography>
            <Link to={{ pathname: Utils.constants.paths.login }}>
              <Typography color="primary" variant="body2">
                {Utils.getMessages().InitForm.loginButton}
              </Typography>
            </Link>
            <Typography variant="body1">-</Typography>
            <Link to={{ pathname: Utils.constants.paths.register }}>
              <Typography color="primary" variant="body2">
                {Utils.getMessages().InitForm.registerButton}
              </Typography>
            </Link>
          </div>

  
    <Modal
        open={openHelp}
        onEscapeKeyDown={() => {
          this.setState({
            openHelp: false
          });
        }}
        onBackdropClick={() => {
          this.setState({
            openHelp: false
          });
        }}
        onRendered={()=> {
          
        }}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
            {
              "Encuentra tu código de usuario"
            }
          </Typography>
            <img alt=""  className={classes.img}  src="help.jpg" />
          <div className={classes.actions}>
          <Button
              onClick={()=> { this.setState({openHelp: false}) }}
            >
            {"Cerrar"}
          </Button>
        </div>
        </div>

     
      </Modal>

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={this.handleClose}
          message={<span>{message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </form>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => {
  return {
    requestValidateAccountNumber: (accountNumber) => {
      dispatch(requestValidateAccountNumber(accountNumber));
    },
  };
};

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InitForm);
