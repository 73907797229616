import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

// Components
import Title from '../components/Title'
import EmployeeLoginForm from '../components/EmployeeLoginForm'

// Utils
import Utils from '../resources/Utils'
import { deleteWaterAccount } from '../actions/actions'

const styles = theme => ({
  login: {
    width: '40%',
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
      width: '100%'
    }
  }
})

class EmployeeLogin extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

 
  render() {
    
    const { classes } = this.props

    return (
      <div className={classes.login}>
        <Title
          title={Utils.getMessages().LoginForm.title}
          description={Utils.getMessages().LoginForm.employeeDescription}
        />
        <br />
        <EmployeeLoginForm/>
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })



export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps)
)(EmployeeLogin)
