import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

// Utils
import Utils from '../resources/Utils'

const styles = theme => ({
  ticketList: {
    width: '100%',
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  ticketItem: {
    paddingLeft: 16,
    marginBottom: 16
  },
  divider: {
    marginBottom: 16
  },
  total: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    textAlign: 'center'
  },
  paymentDetail: {
    marginTop: 8
  },
  fee: {
    fontSize: 10
  }
})

class TicketTable extends Component {
  render() {
    const { classes } = this.props
    return (
      <Card>
        <CardContent className={classes.total}>
          <Typography variant="subtitle1"><strong>{Utils.getMessages().Ticket.totalPaid}</strong></Typography>
          <Typography variant="body1">$ {Utils.numberWithCommas(Number(this.props.total).toFixed(2))}</Typography>
          {
            (this.props.fee > 0) ?
            <div className={classes.paymentDetail}>
              <Typography variant="body1" className={classes.fee}>* {Utils.getMessages().Ticket.balancePaid} ${Utils.numberWithCommas(Number(this.props.balance).toFixed(2))}</Typography>
              <Typography variant="body1" className={classes.fee}>* {Utils.getMessages().Ticket.transactionCost} ${Utils.numberWithCommas(Number(this.props.fee).toFixed(2))}</Typography>
            </div>
            :
            ''
          }
        </CardContent>
        <CardContent>
          <ul className={classes.ticketList}>
          {
            this.props.accounts.map((account, idx) => {
              return (
                <li key={idx} className={classes.ticketItem}>
                  <Divider className={classes.divider}/>
                  <Grid container>
                    <Grid item lg={12}>
                      <Typography variant="subtitle1"><strong># {account.account}</strong></Typography>
                      <Typography variant="caption">{account.name}</Typography>
                      <Typography variant="caption">{account.address}</Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            })
          }
          </ul>
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, null)
)(TicketTable)
