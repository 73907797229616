import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle'
import ReceiptIcon from '@material-ui/icons/Receipt'
import FolderIcon from '@material-ui/icons/Folder'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LabelIcon from '@material-ui/icons/Label'
import SettingsIcon from '@material-ui/icons/Settings' 
import CreditCardIcon from '@material-ui/icons/CreditCard'
import DescriptionIcon from '@material-ui/icons/Description';
import History from '@material-ui/icons/History';
import Payment from '@material-ui/icons/Payment';
import People from '@material-ui/icons/People';



// Utils
import Utils from '../resources/Utils'
import { requestOpenAutomaticPayment } from '../actions/actions'

const drawerWidth = 240

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    top: 66,
    [theme.breakpoints.down('sm')]: {
      width: 72,
    },
    [theme.breakpoints.down('xs')]: {
      top: 54,
      width: 56,
    },
  }
})

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: false,
      selected: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
      ]
    }

    this.goTo = this.goTo.bind(this)
    this.logout = this.logout.bind(this)
    this.simulatePayment = this.simulatePayment.bind(this)
  }

  componentWillMount() {
    let selected = []
    this.state.selected.forEach(function(s) {
      selected.push(false)
    })

    if (this.props.location.pathname === Utils.constants.paths.accountSetting) {
      selected[1] = true
      this.setState({
        selected: selected
      })
    }
    else if (this.props.location.pathname === Utils.constants.paths.cardSetting) {
      selected[2] = true
      this.setState({
        selected: selected
      }) 
    }
    else if (this.props.location.pathname === Utils.constants.paths.payments) {
      selected[3] = true
      this.setState({
        selected: selected
      })
    }
    else if (this.props.location.pathname === Utils.constants.paths.receipts) {
      selected[4] = true
      this.setState({
        selected: selected
      })
    }
    else if (this.props.location.pathname === Utils.constants.paths.profile) {
      selected[5] = true
      this.setState({
        selected: selected
      })
    }
    else if (this.props.location.pathname === Utils.constants.paths.tramites) {
      selected[6] = true
      this.setState({
        selected: selected
      })
    }
    else {
      selected[0] = true
      this.setState({
        selected: selected
      })
    }
  }

  goTo(index, path) {
    let selected = []
    this.state.selected.forEach(function(s) {
      selected.push(false)
    })
    selected[index] = true
    this.setState({
      selected: selected
    })
    this.props.history.push(path)
  }

  logout() {
    localStorage.removeItem(Utils.constants.KEY_LS_USER)
    localStorage.removeItem(Utils.constants.KEY_LS_AUTO_PAYMENT)
    this.props.requestOpenAutomaticPayment({ open: false })
    this.props.history.push('/')
  }

  simulatePayment() {
    this.props.history.push('/pago')
  }

  render() {
    const { classes } = this.props

    return (
      <Drawer
        variant="persistent"
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
      {
       (Utils.isUserEmployeeLoggedIn())?
       <div>
         <List>
            <ListItem selected={this.state.selected[0]} button onClick={ () => { this.goTo(0, '/configs') } }>
              <ListItemIcon>
                <SettingsIcon/>
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.configs} />
              </Hidden>
            </ListItem>
            <ListItem selected={this.state.selected[0]} button onClick={ () => { this.goTo(0, '/historialpagos') } }>
              <ListItemIcon>
                <Payment/>
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.paymentHistory} />
              </Hidden>
            </ListItem>
            <ListItem selected={this.state.selected[0]} button onClick={ () => { this.goTo(0, '/historialusuarios') } }>
              <ListItemIcon>
                <People/>
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.usersHistory} />
              </Hidden>
            </ListItem>
            <ListItem selected={this.state.selected[0]} button onClick={ () => {this.goTo(0, '/historiallogpxu') } }>
              <ListItemIcon>
                <History/>
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.listOfLogUserToPayment} />
              </Hidden>
            </ListItem>
          </List>
       </div>
       :null

      }  
      {
        (Utils.isUserLoggedIn()) ?
        <div>
          <List>
            <ListItem selected={this.state.selected[0]} button onClick={ () => { this.goTo(0, '/ingreso') } }>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.onlinePayment} />
              </Hidden>
            </ListItem>
            <ListItem selected={this.state.selected[1]} button onClick={ () => { this.goTo(1, '/ajustes/cuentas') } }>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.accounts} />
              </Hidden>
            </ListItem>
            {
              false ? 
              (
                <ListItem selected={this.state.selected[3]} button onClick={ () => { this.goTo(3, '/pagosSuite') } }>
                  <ListItemIcon>
                    <SwapVerticalCircleIcon />
                  </ListItemIcon>
                  <Hidden smDown>
                    <ListItemText primary={Utils.getMessages().Menu.payments} />
                  </Hidden>
                </ListItem>
              ) : null
            }
            {
              false ?
              <ListItem selected={this.state.selected[4]} button onClick={ () => { this.goTo(4, '/recibosSuite') } }>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.tickets} />
              </Hidden>
            </ListItem>: null
            }
           {
            false ? 
            <ListItem selected={this.state.selected[5]} button onClick={ () => { this.goTo(5, '/perfil') } }>
            <ListItemIcon>
              <AssignmentInd />
            </ListItemIcon>
            <Hidden smDown>
              <ListItemText primary={Utils.getMessages().Menu.profile} />
            </Hidden>
          </ListItem> : null
           }
           
           {
            false ? 
            <ListItem selected={this.state.selected[6]} button onClick={ () => { this.goTo(6, '/tramites') } }>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <Hidden smDown>
              <ListItemText primary={Utils.getMessages().Menu.tramites} />
            </Hidden>
          </ListItem> : null
           }
           
            <ListItem button onClick={this.logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.logout} />
              </Hidden>
            </ListItem>
          </List>
          <Divider />
        </div>

        :

        <div>
          <List hidden={true}>
            <ListItem button onClick={ () => { this.goTo(0, '/ingreso') } }>
              <ListItemIcon>
                <AssignmentInd />
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.login} />
              </Hidden>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={this.logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <Hidden smDown>
                <ListItemText primary={Utils.getMessages().Menu.exit} />
              </Hidden>
            </ListItem>
          </List>
          <Divider />
        </div>

      }
      </Drawer>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestOpenAutomaticPayment: (data) => {
      dispatch(requestOpenAutomaticPayment(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Menu)
