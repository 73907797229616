import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

import { deleteWaterAccount } from '../actions/actions'

// Components
import Title from '../components/Title'
import RecoveryPasswordForm from '../components/RecoveryPasswordForm'
import NewPasswordForm from '../components/NewPasswordForm'

import Utils from '../resources/Utils'

const styles = theme => ({
  container: {
    width: '40%',
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
      width: '100%'
    }
  }
})

class RecoveryPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      withToken: false
    }
  }
  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      this.props.history.push("/cuentas")
    }
    else {
      if (this.props.match.path === Utils.constants.paths.recoveryWithToken) {
        this.setState({
          withToken: true
        })
      }
      this.props.deleteWaterAccount()
    }
  }

  render() {
    
    const { classes } = this.props

    return (
      <div className={classes.container}>
        {
          (this.state.withToken) ?
          <div>
            <Title 
              title={Utils.getMessages().RecoveryPassword.titleWithToken}
              description={Utils.getMessages().RecoveryPassword.descriptionWithToken}
            />
            <NewPasswordForm
              flow='recovery'
            />
          </div>
          :
          <div>
            <Title 
              title={Utils.getMessages().RecoveryPassword.title}
              description={Utils.getMessages().RecoveryPassword.description}
            />
            <RecoveryPasswordForm />
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    deleteWaterAccount: () => {
      dispatch(deleteWaterAccount())
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(RecoveryPassword)
