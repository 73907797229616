import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'

// Actions
import { Button, Snackbar } from '@material-ui/core'
import MySnackbarContentWrapper from './CustomSnackBar'
import DatosContratoUsuario from './DatosContratoUsuario'
import Utils from '../resources/Utils'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
	modalContainer: {
		overflow: 'scroll'
	},
	container: {
		position: 'absolute',
		width: theme.spacing(60),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(5),
		[theme.breakpoints.down('xs')]: {
			background: 'white',
			width: '90%',
			height: '100%',
			paddingTop: '20%',
			paddingLeft: '5%',
			paddingRight: '5%'
		},
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(70),
		},
		[theme.breakpoints.up('md')]: {
			width: theme.spacing(80),
		},
		[theme.breakpoints.up('lg')]: {
			width: theme.spacing(120),
		},
		[theme.breakpoints.up('xl')]: {
			width: theme.spacing(140),
		}
	},
	modalTitle: {
		fontWeight: 600
	},
	actions: {
		float: 'right',
		marginTop: 32
	  },
	media:{
		width: "100%",
  		height: "auto",
		marginBottom: 15,
		borderRadius: 9,
	},
	mediaIframe:{
		width: "100%",
  		height: "auto",
		marginBottom: 15,
		borderRadius: 9,
		[theme.breakpoints.down('xs')]: {
			height: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			height: 200,
		},
		[theme.breakpoints.up('md')]: {
			height: 300,
		},
		[theme.breakpoints.up('lg')]: {
			height: 400,
		},
		[theme.breakpoints.up('xl')]: {
			height: 500,
		}
	},
	root: {
		display: 'flex',
		float: 'right',
	},
})

class PersonaModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			
			stepperStateUsuario: { activeStep: 0 },
			stateTipoUsuario:{
				value:1
			},
			openSnack: false,
			variant: 'warning',
			errorMessage: '',
			messageLoading: '',
			loading: false,
		}

		this.handleSetState = this.handleSetState.bind(this)
		this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this)
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
		this.handleClose = this.handleClose.bind(this)
		this.handleRender = this.handleRender.bind(this)
	}

	componentDidUpdate(prevProps){
		if (Utils.isUserLoggedIn()) {
		}
	}

	handleClose() {
		this.props.handleClose()
	}

	handleRender() {
		this.setState({
			stepperStateUsuario: { activeStep: 0 },
			stateTipoUsuario:{
				value: this.props.persona ? this.props.persona.tipoPersonaId : 1
			},
		})
	}

	handleOpenSnackbar({message, variant}) {
		this.setState({openSnack: true, variant: variant || 'warning', errorMessage: message })
		this.props.handleOpenSnackbar({openSnack: true, variant: variant || 'warning', errorMessage: message })
	}

	handleCloseSnackbar() {
		this.setState({openSnack: false})
	}

	handleSetState(){
		this.setState({})
	}

	render() {
		const { classes, titulo, descripcion, persona  } = this.props
		const { 
			stateTipoUsuario,
			stepperStateUsuario,
		} = this.state

		const mapping ={
			evidenciaIdentificacion: null,
			evidenciaSelfie: null,
			evidenciaDomicilio: null,
			acreditacionEmpresa: null,
		}
		if(persona){
			persona.imagenes.forEach(  (v) => {
				let name = ''
				if( v.tipoImagen === 1 ){
					name = 'evidenciaIdentificacion'
				} else if( v.tipoImagen === 2 ){
					name = 'evidenciaSelfie'
				} else if( v.tipoImagen === 4 ){
					name = 'evidenciaDomicilio'
				} else if( v.tipoImagen === 3 ){
					name = 'acreditacionEmpresa'
				} 
				mapping[name] = v
			} )
		}
		

		return (
			<Modal
				open={this.props.open}
				onEscapeKeyDown={this.handleClose}
				onBackdropClick={this.handleClose}
				className={classes.modalContainer}
				onRendered={this.handleRender}
			>
				<div style={getModalStyle()} className={classes.container}>
					<Typography variant="h4" className={classes.modalTitle}>
						{titulo}
					</Typography>
					<Typography variant="body1" style={{marginTop: 8}}>
						{descripcion}
					</Typography>
					
					<DatosContratoUsuario
						stepperState={stepperStateUsuario}
						stateTipoUsuario={{
							value: this.props.persona ? this.props.persona.tipoPersonaId || 1 : 1
						}}
						datosEmpresa={{ form: persona }}
						datosUsuario={{ form: persona }}
						datosFilesUsuario={{ form: mapping }}
						handleSetState= {this.handleSetState}
						handleOpenSnackbar = { this.handleOpenSnackbar}
						handleClose={this.handleClose}
						handleSaved={this.props.handleSaved}
					/>
					<div className={classes.actions}>
						<Button
							onClick={this.handleClose}
							>
							Cerrar
						</Button>
					</div>
					<Snackbar
						anchorOrigin={{vertical: 'top', horizontal: 'center'}}
						open={this.state.openSnack}
						autoHideDuration={5000}

						onClose={this.handleCloseSnackbar}
					>
						<MySnackbarContentWrapper
							onClose={this.handleCloseSnackbar}
							variant={this.state.variant}
							message={
								<span>{this.state.errorMessage}</span>
							}
						/>
					</Snackbar>
				</div>

			</Modal>
		)
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = () => {
	return {

	}
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PersonaModal)
