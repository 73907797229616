import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'

import Utils from '../resources/Utils'

// Actions
import { requestTimbrar } from '../actions/actions'
import { Button } from '@material-ui/core'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
	modalContainer: {
		overflow: 'scroll'
	},
	container: {
		position: 'absolute',
		width: theme.spacing(60),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(5),
		[theme.breakpoints.down('xs')]: {
			background: 'white',
			width: '90%',
			height: '100%',
			paddingTop: '20%',
			paddingLeft: '5%',
			paddingRight: '5%'
		}
	},
	modalTitle: {
		fontWeight: 600
	},
	media:{
		width: "100%",
  		height: "auto",
		marginBottom: 15,
		borderRadius: 9,
	},
	mediaIframe:{
		width: "100%",
  		height: "auto",
		marginBottom: 15,
		borderRadius: 9,
		[theme.breakpoints.down('xs')]: {
			height: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			height: 200,
		},
		[theme.breakpoints.up('md')]: {
			height: 300,
		},
		[theme.breakpoints.up('lg')]: {
			height: 400,
		},
		[theme.breakpoints.up('xl')]: {
			height: 500,
		}
	},
	root: {
		display: 'flex',
		float: 'right',
	},
})

class ViewImageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleRender = this.handleRender.bind(this)
  }

  handleClose() {
    this.props.handleClose()
  }

  handleRender() {
  }

  render() {
    const { classes, src,titulo,descripcion, type  } = this.props
    return (
		<Modal
			open={this.props.open}
			onEscapeKeyDown={this.handleClose}
			onBackdropClick={this.handleClose}
			className={classes.modalContainer}
			onRendered={this.handleRender}
		>
			<div style={getModalStyle()} className={classes.container}>
				<Typography variant="h4" className={classes.modalTitle}>
					{titulo}
				</Typography>
				<Typography variant="body1" style={{marginTop: 8}}>
					{descripcion}
				</Typography>
				<div>
					
					
					{
						type.includes("image") ?
							<img
								className={classes.media}
								src={src}
							/>
						: type.includes("pdf") ? 
							<iframe  style={{border:"unset"}} src= { src } className={classes.mediaIframe}></iframe>
						: null 

					}

				</div>
			
				<div className={classes.root}>
					<Button
						onClick={this.handleClose}
						>
						Cerrar
					</Button>
				
				</div>
			</div>
		</Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestTimbrar: (data) => {
      dispatch(requestTimbrar(data))
    },
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ViewImageModal)
