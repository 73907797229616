import axios from 'axios'
import messagesEN from './en.json'
import messagesES from './es.json'

const constants = {
	/*HOST_API: "https://pagoenlinea.clariti.mx:8080",
	HOST_EMAIL_API: "https://pagoenlinea.clariti.mx:3000",*/
	
	/*
	HOST_API: "https://pagoenlinea.oomsapaslc.gob.mx:8080",
	HOST_EMAIL_API: "https://pagoenlinea.oomsapaslc.gob.mx:3000",
	HOST_WEB_API: "https://pagoenlinea.oomsapaslc.gob.mx",
	HOST_REPORTES: "https://reportes.ooms.mx:3000",
	mp_account: 10561,//PRODUCTIVO
	paymentsProviderBancomer: "https://www.adquiramexico.com.mx/clb/endpoint/oomsapasLosCabos",
	*/

  
  //PRUEBAS CHRYSTIAN
	/*HOST_API: "http://localhost:1337",
	HOST_API_WAR_TEMP: "http://localhost:8080",
	HOST_EMAIL_API: "http://localhost:7777",
  //HOST_WEB_API: "http://localhost:3000",
	HOST_WEB_API: "http://pagoenlinea.jumapam.gob.mx",
	HOST_REPORTES: "https://reportes.ooms.mx:3000",
	mp_account: 10370,//PRUEBAS
	paymentsProviderBancomer: "https://prepro.adquiracloud.mx/clb/endpoint/jumapam",
  */
 
  // PRUEBAS MAZATLAN
  /*
    HOST_API: "https://pagoenlinea.jumapam.gob.mx:8080",
    HOST_EMAIL_API: "https://pagoenlinea.jumapam.gob.mx:3000",
    HOST_WEB_API: "https://pagoenlinea.jumapam.gob.mx",
    HOST_REPORTES: "https://reportes.ooms.mx:3000",
    mp_account: 12978,//PRUEBAS
    paymentsProviderBancomer: "https://prepro.adquiracloud.mx/clb/endpoint/jumapam",
   */
    //HOST_API: "http://localhost:8080",
  // PROD MAZATLAN
    HOST_API: "https://pagoenlinea.jumapam.gob.mx:8080",
    HOST_EMAIL_API: "https://pagoenlinea.jumapam.gob.mx:3000",
    HOST_WEB_API: "https://pagoenlinea.jumapam.gob.mx",
    HOST_REPORTES: "https://reportes.ooms.mx:3000",
    mp_account: 12560,
    paymentsProviderBancomer: "https://www.adquiramexico.com.mx/clb/endpoint/jumapam",
    
  //HOST_API: "http://api.clariti.tech:8080",
	//HOST_EMAIL_API: "https://api.clariti.tech:3000",
	version: '1.4.20241023',
	MIN_PASSWORD: 8,
	paymentsProviderURL: 'https://www.banwire.com/home/banwire-terminos-y-condiciones.html',
	paymentsProvider: 'BanWire S.A. de C.V.',
	paths: {
		home: '/',
    loginEmpleado: '/empleados/login',
    allPayments:'/historialpagos/',
    allUsers:'/historialusuarios/',
    logspaymentsbyuser:'/historiallogpxu',
    configs:'/configs',
		register: '/registro',
		registerWithToken: '/registro/:token',
		login: '/ingreso',
		recovery: '/recuperar',
		recoveryWithToken: '/recuperar/:token',
		pay: '/pagar/:accountNumber',
		accounts: '/cuentas',
		accountById: '/cuentas/:accountNumber',
		cards: '/tarjetas',
		confirm: '/confirmacion',
		confirmPayment: '/cuentas/:accountNumber/confirmacion',
		ticket: '/pago',
		accountSetting: '/ajustes/cuentas',
		cardSetting: '/ajustes/tarjetas',
		payments: '/pagos',
		paymentsSuiteWithoutAcoount: '/pagosSuite',
		paymentsSuite: '/pagosSuite/:account',
		receipts: '/recibos',
		receiptsSuiteWithoutAcoount: '/recibosSuite',
		receiptsSuite: '/recibosSuite/:account',
		profile: '/perfil',
    tramites: '/tramites',
		bbvaPaymentFailure: '/bbvaPaymentFailure/:accountNumber',
		bbvaPaymentSuccess: '/bbvaPaymentSuccess/:accountNumber',
		datosCompletosUsuario: '/datosCompletosUsuario',
    logsPayments: '/admin/payments/:tokenU?/:tokenP?/:tokenCode?'
	},
	KEY_LS_USER: 'user',
	KEY_LS_OFFICE: 'officeId',
	KEY_LS_ENTITY_TYPE: 'entityTypeId',
	KEY_LS_AUTO_PAYMENT: 'autoPayment',
	status: {
		ok: 'OK',
		warning: 'WARKING',
		error: 'ERROR'
	}
}

const isNumeric = (val) => {
    var numeric = true
    var chars = "0123456789+"
    var len = val.length
    var char = ""
    for (let i=0; i<len; i++) { char = val.charAt(i); if (chars.indexOf(char)===-1) { numeric = false; } }
    return numeric
}

const isDouble = (val) => {
    var numeric = true
    var chars = "0123456789.+"
    var len = val.length
    var char = ""
    for (let i=0; i<len; i++) { char = val.charAt(i); if (chars.indexOf(char)===-1) { numeric = false; } }
    return numeric
}

const isEmpty = (obj) => {
  try {
    return (Object.keys(obj).length === 0 && obj.constructor === Object)
  } catch (e) {
    return true
  }
}

const secondToDate = (x) => {
  try {
    let date = new Date(x)
    return formatDate(date.toISOString())
  }
  catch(e) {
    return x
  }
}

const formatDate = (x) => {
  try {
    let date = x.split('T')
    date = date[0] + " - " + date[1].split("Z")[0]
    return date
  }
  catch(e) {
    return x
  }
}

const numberWithCommas = (x) => {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase())
}

const isUserLoggedIn = () => {
  let user = JSON.parse(localStorage.getItem(constants.KEY_LS_USER))
  if (isEmpty(user)) {
    return false
  }
  else {
    if (isEmpty(user.data)) {
      return false
    }
  }
  if (!isEmpty(user)) {
    if(user.data.employee==1){
      return false
    }
  }
  
  return true
}
const isUserEmployeeLoggedIn = () => {
  let user = JSON.parse(localStorage.getItem(constants.KEY_LS_USER))
  if (isEmpty(user) ) {
    return false
  }
  else {
    if (isEmpty(user.data)) {
      return false
    }
  }
  if (!isEmpty(user)) {
    if(user.data.employee!==1){
      return false
    }
  }
  return true
}

const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

const getMetadata = async () => {
  let user = null

  if (isUserLoggedIn()) {
    user = JSON.parse(localStorage.getItem(constants.KEY_LS_USER)).data
  }

  const metadata = {
    ip: '',
    user: user,
    appCodeName: window.navigator.appCodeName,
    appName: window.navigator.appName,
    appVersion: window.navigator.appVersion,
    cookieEnabled: window.navigator.cookieEnabled,
    deviceMemory: window.navigator.deviceMemory,
    hardwareConcurrency: window.navigator.hardwareConcurrency,
    language: window.navigator.language,
    platform: window.navigator.platform,
    product: window.navigator.product,
    productSub: window.navigator.productSub,
    userAgent: window.navigator.userAgent,
    vendor: window.navigator.vendor,
    vendorSub: window.navigator.vendorSub,
    screen: {
      height: window.screen.height,
      width: window.screen.width,
      availHeight: window.screen.availHeight,
      availWidth: window.screen.availWidth,
      availTop: window.screen.availTop,
      availLeft: window.screen.availLeft,
      pixelDepth: window.screen.pixelDepth
    }
  }

  try {
    const response = await axios('https://api.ipify.org?format=json')
    const data = response.data
    metadata.ip = data.ip
    return metadata
  }
  catch (err) {
    return metadata
  }
}

const checkLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage
  const lang = userLang.split('-')[0]
  return lang
}

const getMessages = () => {
  if (checkLanguage().toLowerCase() !== 'es') {
    return messagesEN
  }
  else {
    return messagesES
  }
}

const distinct = (value, index, self) =>  {
  if(value){
    return self.indexOf(value) === index
  } else {
    return self.indexOf(value) === index
  }
    
};

const arrayBufferToBase64 = (buffer) => {
  let TYPED_ARRAY = new Uint8Array(buffer);
  const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
      return data + String.fromCharCode(byte);
  }, '');
  return btoa(STRING_CHAR);
}

const scrollTop = () => {
  window.scrollTo(0, 0)
}

const sexos = [ { id: 1, descripcion: "Sin definir" }, { id: 2, descripcion: "Femenino" }, { id: 3, descripcion: "Masculino" }, ]
const tiposContratos = [ { id: 1, descripcion: "DOMESTICO" }, { id: 2, descripcion: "COMERCIAL" }, { id: 3, descripcion: "INSDUSTRIAL" }, ]

export default ({constants: constants, sexos, tiposContratos, create_UUID,arrayBufferToBase64, messages: messagesES, distinct,isNumeric, isDouble, isEmpty, formatDate, numberWithCommas, validateEmail, isUserLoggedIn,isUserEmployeeLoggedIn, getMetadata, checkLanguage, getMessages, scrollTop, secondToDate})
