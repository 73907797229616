import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme } from '@material-ui/core/styles'

class Spinner extends Component {
  render() {
    return (
      <svg 
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle className="checkmark_circle" cx="26" cy="26" r="25" fill="none"/>
        <path className="checkmark_check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps, null)
)(Spinner)