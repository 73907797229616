import React, { Component } from 'react'
import compose from 'recompose/compose'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

// Resources
import Utils from '../resources/Utils'
import Amex from '../resources/images/amex.png'
import MasterCard from '../resources/images/mastercard.png'
import Visa from '../resources/images/visa.png'

const styles = theme => ({
  headerTable: {
    fontWeight: 700
  },
  fitCell: {
    margin: 0,
    padding: 0
  },
  cardType: {
    width: 64
  },
  hidden: {
    display: 'none'
  }
})

class CardTable extends Component {
  constructor(props) {
    super(props)
    this.getAmount = this.getAmount.bind(this)
    this.getCardTypeImg = this.getCardTypeImg.bind(this)
    this.getCardNumber = this.getCardNumber.bind(this)
  }

  getCardTypeImg(type) {
    if (type === 'mastercard') {
      return MasterCard
    }
    else if (type === 'visa') {
      return Visa
    }
    else if (type === 'american-express') {
      return Amex
    }
    else {
      return ''
    }
  }

  getCardNumber(type, number) {
    if (type === 'american-express') {
      return "●●●● ●●●●● " + number
    }
    else {
      return "●●●● ●●●● ●●●● " + number
    }
  }

  getAmount(idx) {
    let value = this.props.accounts[idx].balanceAt
    return Utils.numberWithCommas(Number(value).toFixed(2))
  }

  render() {
    const { classes } = this.props
    return (
      <Table 
        className={this.props.className}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className={classes.fitCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Cards.cardNumber}</Typography></TableCell>
            <Hidden smDown>
              <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Cards.expiration}</Typography></TableCell>
            </Hidden>
            <Hidden xsDown>
              <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Cards.titular}</Typography></TableCell>
            </Hidden>
            <TableCell className={classes.fitCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Cards.alias}</Typography></TableCell>
            <TableCell className={classes.fitCell}></TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
      {
        this.props.cards.map((card, idx) => {
          return (
            <TableRow key={idx}>
              <TableCell className={classes.fitCell}><img className={classes.cardType} alt="" src={this.getCardTypeImg(card.type)}/></TableCell>
              <TableCell className={classes.fitCell}>
                <Typography variant="body2">{this.getCardNumber(card.type, card.number)}</Typography>
              </TableCell>
              <Hidden smDown>
                <TableCell><Typography variant="body2">{card.month}/{card.year}</Typography></TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell><Typography variant="body2">{card.titular}</Typography></TableCell>
              </Hidden>
              <TableCell className={classes.fitCell}><Typography variant="body2">{card.alias}</Typography></TableCell>
              {
                (Utils.isUserLoggedIn()) ?
                  (!Utils.isEmpty(this.props.checkboxChecked)) ?
                  <TableCell className={classes.fitCell}>
                    <Checkbox
                      color="primary"
                      checked={this.props.checkboxChecked[idx]}
                      onClick={ (event) => { this.props.onClick('checkbox-'+idx, event) }}
                    />
                  </TableCell>
                  :
                  <TableCell>
                    <Grid container>
                      <Grid item lg={4}>
                        <IconButton onClick={() => { this.props.onEdit(idx) }}>
                          <EditIcon />
                        </IconButton>
                      </Grid>
                      <Grid item lg={4}>
                        <IconButton onClick={() => { this.props.onDelete(idx) }}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                :
                <TableCell className={classes.hidden}></TableCell>
              }
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>
    )
  }
}

export default compose(
  withTheme,
  withStyles(styles)
)(CardTable)
