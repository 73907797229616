import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import Utils from '../resources/Utils'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 80,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      background: 'white',
      width: '90%',
      height: '100%',
      paddingTop: '20%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  paper: {
    marginTop: 8,
    marginBottom: 16,
    padding: '8px 16px',
    overflow: 'auto'
  },
  emojiAlias: {
    width: 14,
    marginRight: 4
  },
  modalTitle: {
    fontWeight: 600
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  },
  title: {
    marginTop: 4
  },
  headerTable: {
    fontWeight: 600
  },
  smallCell: {
    margin: 0,
    padding: 0,
  },
  money: {
    margin: 0,
    padding: 0,
    textAlign: 'right'
  },
  smallText: {
    fontSize: 12
  }
})

class ShowReceiptSuite extends Component {
  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.handleRender = this.handleRender.bind(this)
    this.getIsCanceled = this.getIsCanceled.bind(this)
  }

  handleRender() {

  }

  getIsCanceled(cancel) {
    if (cancel)
      return Utils.getMessages().ShowReceipt.YES
    return Utils.getMessages().ShowReceipt.NO
  }

  handleClose() {
    this.props.handleClose()
  }

  render() {
    const { classes } = this.props
    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
        onRendered={this.handleRender}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
              {Utils.getMessages().ShowReceipt.amount} :
              {this.props.receipt.montoTotalPagar}
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
            <strong>{Utils.getMessages().ShowReceipt.subtitleGeneral}</strong>
          </Typography>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowReceipt.id}</strong></Typography>
                <Typography variant="body1" className={classes.title}>{this.props.receipt.reciboID}</Typography>          
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowReceipt.consumption}</strong></Typography>
                <Typography variant="body1" className={classes.title}>{this.props.receipt.consumo}</Typography>      
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowReceipt.measurementUnit}</strong></Typography>
                <Typography variant="body1" className={classes.title}>{this.props.receipt.formaCobroDescripcion}</Typography>   
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowReceipt.periodsOwed}</strong></Typography>
                <Typography variant="body1" className={classes.title}>{this.props.receipt.mesesAdeudo}</Typography>    
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowReceipt.isCanceled}</strong></Typography>
                <Typography variant="body1" className={classes.title}>{this.getIsCanceled(this.props.receipt.estaCancelado)}</Typography>    
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowReceipt.registerAt}</strong></Typography>
                <Typography variant="body1" className={classes.title}>{this.props.receipt.periodoConsumo}</Typography>    
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>  
                <Typography variant="body1"><strong>{Utils.getMessages().ShowReceipt.expirationAt}</strong></Typography>
                <Typography variant="body1" className={classes.title}>{Utils.formatDate(this.props.receipt.fechaVencimientoFormato)}</Typography>
              </Grid>
            </Grid>
          </Paper>
          <Typography variant="body1" style={{marginTop: 8}}>
            <strong>{Utils.getMessages().ShowReceipt.subtitleDetail}</strong>
          </Typography>
          <Paper className={classes.paper} style={{overflow: 'auto', height: '10px !important;', maxHeight: "150px" }}>
            <Grid container style={{overflow: 'auto', height: '100px !important;'}}>
              <Table style={{overflow: 'auto', height: '10px !important;'}}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().ShowReceipt.description}</Typography></TableCell>
                    <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().ShowReceipt.total}</Typography></TableCell>
                  </TableRow>
                </TableHead>
              <TableBody style={{overflow: 'auto', height: '10px !important;'}}>
              {
                this.props.receipt.detalleConceptos.map((detail, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.smallCell}>
                        <Typography variant="body2" className={classes.smallText}>{detail.DescripcionConcepto}</Typography>
                      </TableCell>
                      <TableCell className={classes.money}>
                        <Typography variant="body2" className={classes.smallText}> {detail.Importe}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
              </TableBody>
            </Table>
            </Grid>
          </Paper>
          <div className={classes.actions}>
            <Button
                onClick={this.handleClose}
              >
              {Utils.getMessages().ShowReceipt.closeButton}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, null)
)(ShowReceiptSuite)
