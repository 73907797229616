import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Utils from '../resources/Utils'

// Actions
import { requestChangePassword } from '../actions/actions'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      background: 'white',
      width: '90%',
      height: '100%',
      paddingTop: '20%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  modalTitle: {
    fontWeight: 600
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  }
})

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      openSnack: false,
      message: ''
    }

    this.handleChangeCurrentPassword = this.handleChangeCurrentPassword.bind(this)
    this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this)
    this.handleChangeConfirmNewPassword = this.handleChangeConfirmNewPassword.bind(this)

    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearData = this.clearData.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.changePassword !== this.props.init.changePassword) {
      if (this.props.init.changePassword.status === 'OK') {
        this.handleClose()
      }
      else if (this.props.init.changePassword.status === 'WARNING') {
        this.setState({
          openSnack: true,
          message: Utils.getMessages().Profile.notFoundPassword
        })
      }
      else {
        this.setState({
          openSnack: true,
          message: Utils.getMessages().General.error
        })
      }
    }
  }

  handleChangeCurrentPassword(event) {
    if (event.target.value.length > 64)
      return
    this.setState({currentPassword: event.target.value})
  }

  handleChangeNewPassword(event) {
    if (event.target.value.length > 64)
      return
    this.setState({newPassword: event.target.value})
  }

  handleChangeConfirmNewPassword(event) {
    if (event.target.value.length > 64)
      return
    this.setState({confirmNewPassword: event.target.value})
  }

  handleConfirm() {
    if (this.state.newPassword.trim().length >= Utils.constants.MIN_PASSWORD) {
      if (this.state.newPassword.trim() === this.state.confirmNewPassword.trim()) {
        let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
        userId = userId.data.id
        let data = {
          userId: userId,
          currentPassword: this.state.currentPassword,
          newPassword: this.state.newPassword
        }
        this.props.requestChangePassword(data)
      }
      else {
        this.setState({
          openSnack: true,
          message: Utils.getMessages().Profile.errorConfirmPassword
        })
      }
    }
    else {
      this.setState({
        openSnack: true,
        message: Utils.getMessages().Profile.notSecurePassword
      })
    }
  }

  handleCloseSnackbar() {
    this.setState({
      openSnack: false,
      message: ''
    })
  }

  handleClose() {
    this.clearData()
    this.props.handleClose()
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleConfirm()
    }
  }

  clearData() {
    this.setState({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      openSnack: false,
      message: ''
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
            {Utils.getMessages().ChangePasswordModal.title}
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
            {Utils.getMessages().ChangePasswordModal.description}
          </Typography>
          <form noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().ChangePasswordModal.placeholderCurrentPassword}
              type="password"
              value={this.state.currentPassword}
              onChange={this.handleChangeCurrentPassword}
              autoFocus
            />
            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().ChangePasswordModal.placeholderNewPassword}
              type="password"
              value={this.state.newPassword}
              onChange={this.handleChangeNewPassword}
            />
            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().ChangePasswordModal.placeholderConfirmPassword}
              type="password"
              value={this.state.confirmNewPassword}
              onChange={this.handleChangeConfirmNewPassword}
            />
            <div className={classes.actions}>
              <Button
                  onClick={this.handleClose}
                >
                {Utils.getMessages().ChangePasswordModal.cancelButton}
              </Button>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={this.handleConfirm}
              >
                {Utils.getMessages().ChangePasswordModal.confirmButton}
              </Button>
            </div>
          </form>
          <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={this.state.openSnack}
            onClose={this.handleCloseSnackbar}
            message={
              <span>{this.state.message}</span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestChangePassword: (data) => {
      dispatch(requestChangePassword(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ChangePasswordModal)
