import React, { Component } from 'react'

import Title from '../components/Title'

// Utils
import Utils from '../resources/Utils'

class NotFound extends Component {

  componentDidMount() {
    Utils.scrollTop()
  }
  
  render() {
    return (
      <div>
        <Title 
          title={Utils.getMessages().General.notFound}
        />
      </div>
    )
  }
}

export default (NotFound)
