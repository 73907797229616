import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Utils from '../resources/Utils'

const styles = theme => ({
  headerTable: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    fontWeight: 700
  },
  headerAmount: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    fontWeight: 700,
    float: 'right',
    width: 100
  },
  smallCell: {
    margin: 0,
    padding: 0
  },
  emojiAlias: {
    marginRight: 8,
    width: 18
  },
  hidden: {
    display: 'none'
  },
  money: {
    textAlign: 'right',
    margin: 0,
    padding: 0
  }
})

class ReceiptListTable extends Component {
  render() {
    const { classes } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Receipts.id}</Typography></TableCell>
            <Hidden smDown>
              <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Receipts.consumption}</Typography></TableCell>
              <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Receipts.date}</Typography></TableCell>
            </Hidden>
            <TableCell className={classes.money}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Receipts.amount}</Typography></TableCell>
            <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Receipts.download}</Typography></TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
      {
        this.props.receipts.map((receipt, idx) => {
          return (
            <TableRow key={idx}>
              <TableCell className={classes.smallCell}>
                <Typography variant="body2">{receipt.id}</Typography>
              </TableCell>
              <Hidden smDown>
                <TableCell className={classes.smallCell}>
                  <Typography variant="body2">{receipt.consumption.toFixed(2)}</Typography>
                </TableCell>
                <TableCell><Typography variant="body2">{Utils.formatDate(receipt.expirationAt.$date)}</Typography></TableCell>
              </Hidden>
              <TableCell className={classes.money}><Typography variant="body2">$ {Utils.numberWithCommas(receipt.amount)}</Typography></TableCell>
              <TableCell>
                  <IconButton color="primary" onClick={() => { this.props.show(receipt) }}><ReceiptIcon /></IconButton>
                  <IconButton color="primary" onClick={() => { this.props.download(receipt) }}><SaveAltIcon /></IconButton>
               </TableCell>
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, null)
)(ReceiptListTable)
