import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'

import Utils from '../resources/Utils'

// Actions
import { requestEditScheduledPayments } from '../actions/actions'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5
  },
  modalTitle: {
    fontWeight: 600
  },
  checkboxContainer: {
    margin: 0,
    padding: 0,
    marginTop: 16
  },
  link: {
    color: theme.palette.primary.main
  },
  largeTextField: {
    width: '100%',
    marginTop: 16
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  }
})

class ScheduledPaymentsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openSnack: false,
      checked: false,
      amount: '',
      errorMessage: ''
    }

    this.handleLink = this.handleLink.bind(this)
    this.handleChangeAmount = this.handleChangeAmount.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleRender = this.handleRender.bind(this)
    this.clearData = this.clearData.bind(this)
  }

  handleChangeAmount(event) {
    if (event.target.value.length > 64 || !Utils.isDouble(event.target.value))
      return
    this.setState({amount: event.target.value})
  }

  handleConfirm() {
    let amount = Number(this.state.amount)
    if (amount > 0) {
      if (this.props.editMode) {
        // Editar
        let data = {
          id: this.props.scheduledPayment.id,
          amount: amount
        }
        this.props.requestEditScheduledPayments(data)
      }
      else {
        // Agregar
        if (this.state.checked) {
          this.clearData()
          this.props.handleConfirm()
        }
        else {
          this.setState({
            openSnack: true,
            errorMessage: Utils.messages.ScheduledPayments.required
          })
        }
      }
    }
    else {
      this.setState({
        openSnack: true,
        errorMessage: Utils.messages.ScheduledPayments.validation
      })
    }
  }

  handleClick() {
    this.setState({
      checked: !this.state.checked
    })
  }

  handleCloseSnackbar() {
    this.setState({errorMessage: '', openSnack: false})
  }

  handleClose() {
    this.clearData()
    this.props.handleClose()
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleConfirm()
    }
  }

  handleLink(event, value) {
    event.preventDefault()
    window.open(value)
  }

  clearData() {
    this.setState({
      openSnack: false,
      checked: false,
      amount: '',
      errorMessage: ''
    })
  }

  handleRender() {
    if (this.props.editMode) {
      this.setState({
        amount: this.props.scheduledPayment.amountLimit
      })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
        onRendered={this.handleRender}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
          {
            (this.props.editMode) ?

            'Editar pago automático.'

            :

            'Pago automático.'
          }
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
          {
            (this.props.editMode) ?

            'Ingresa el monto límite para procesar tus pagos futuros.'

            :

            <span>
              Se realizarán pagos automáticos con la tarjeta terminación <strong>{this.props.numberCard}</strong> al número de cuenta <strong>#{this.props.accountNumber}</strong>. Si deseas continuar, ingresa el monto máximo que autorizas a la plataforma para procesar pagos futuros (mes con mes).
            </span>

          }
          </Typography>
          <form noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
            <TextField
              className={classes.largeTextField}
              label="Monto límite *"
              type="text"
              value={this.state.amount}
              onChange={this.handleChangeAmount}
              autoFocus
            />
            {
              (!this.props.editMode) ?
                <Grid container className={classes.checkboxContainer}>
                  <Grid item lg={2}>
                    <Checkbox
                      color="primary"
                      checked={this.state.checked}
                      onClick={this.handleClick}
                    />
                  </Grid>
                  <Grid item lg={10}>
                    <Typography variant="body2">Acepto los términos y condiciones así como la política de privacidad.</Typography>
                    <ul>
                      <li>
                        <a href="/" rel="noopener noreferrer" target="_blank" className={classes.link} onClick={(event) => {this.handleLink(event, this.props.init.config.data.urlTerms)}}>Términos y condiciones</a>
                      </li>
                      <li>
                        <a href="/" rel="noopener noreferrer" target="_blank" className={classes.link} onClick={(event) => {this.handleLink(event, this.props.init.config.data.urlPrivacy)}}>Política de privacidad</a>
                      </li>
                      <li>
                        <a href="/" rel="noopener noreferrer" target="_blank" className={classes.link} onClick={(event) => {this.handleLink(event, Utils.constants.paths.terms)}}>Términos y condiciones Clariti ®</a>
                      </li>
                      <li>
                        <a href="/" rel="noopener noreferrer" target="_blank" className={classes.link} onClick={(event) => {this.handleLink(event, Utils.constants.paths.privacy)}}>Política de privacidad Clariti ®</a>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              :

              ''
            }
            <div className={classes.actions}>
              <Button
                  onClick={this.handleClose}
                >
                CANCELAR
              </Button>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={this.handleConfirm}
              >
                CONFIRMAR
              </Button>
            </div>
          </form>
          <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={this.state.openSnack}
            onClose={this.handleCloseSnackbar}
            message={
              <span>{this.state.errorMessage}</span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestEditScheduledPayments: (data) => {
      dispatch(requestEditScheduledPayments(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ScheduledPaymentsModal)
