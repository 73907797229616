import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Components
import CardTable from '../components/CardTable'
import Title from '../components/Title'
import Stepper from '../components/Stepper'
import PaymentModal from '../components/PaymentModalBanWire'
import DeleteDialog from '../components/DeleteDialog'
import Empty from '../components/Empty'

// Utils
import Utils from '../resources/Utils'
import cardsEmptyImg from '../resources/images/cards-empty.svg'
import { requestCardsByUser } from '../actions/actions'

const styles = theme => ({
  cardTable: {
    marginTop: 16
  },
  stepper: {
    marginTop: 32,
    float: 'right'
  }
})

class CardSetting extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editCard: false,
      openDeleteDialog: false,
      openCardModal: false,
      openSnack: false,
      message: '',
      showCards: false,
      card: {}
    }

    //
    this.handleEditCard = this.handleEditCard.bind(this)
    this.handleDeleteCard = this.handleDeleteCard.bind(this)

    // Dialog handlers
    this.handleCancelDeleteCard = this.handleCancelDeleteCard.bind(this)
    this.handleConfirmDeleteCard = this.handleConfirmDeleteCard.bind(this)

    // Modal handlers
    this.handleConfirmCardModal = this.handleConfirmCardModal.bind(this)
    this.handleCloseCardModal = this.handleCloseCardModal.bind(this)

    // Navigation handlers
    this.handleNewCard = this.handleNewCard.bind(this)

    // Others
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.getCards = this.getCards.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {      
      let user = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
      user = user.data
      this.props.requestCardsByUser(user.id)
    }
    else {
      this.props.history.push('/')
    }
  }

  componentDidUpdate(prevProps) {
    if (Utils.isUserLoggedIn()) {

      // Mostrar cuentas
      if (prevProps.init.user !== this.props.init.user) {
        if (this.props.init.user.cards.status === 'OK') {
          let showCards = false
          if (this.props.init.user.cards.data.length > 0) {
            showCards = true
          }
          this.setState({showCards: showCards})
        }
      }

      // Tarjeta agregada
      if (prevProps.init.addCard !== this.props.init.addCard) {
        let showCards = false
        let openSnack = false
        let message = Utils.getMessages().General.error
        if (this.props.init.addCard.status === 'OK') {
          openSnack = true
          if (this.props.init.addCard.data.new) {
            message = Utils.getMessages().Cards.addOk
          }
          else {
            message = Utils.getMessages().Cards.existOk
          }
          if (this.props.init.user.cards.data.length > 0) {
            showCards = true
          }
        }
        else {
          if (!Utils.isEmpty(this.props.init.addCard.devMessage)) {
            message = this.props.init.addCard.devMessage
          }
        }
        this.setState({
          showCards: showCards,
          openSnack: openSnack,
          message: message
        })
      }

      // Tarjeta editada
      if (prevProps.init.editCard !== this.props.init.editCard) {
        let openSnack = false
        let message = Utils.getMessages().General.error
        if (this.props.init.editCard.status === 'OK') {
          openSnack = true
          message = Utils.getMessages().Cards.editOk
        }
        this.setState({
          openSnack: openSnack,
          message: message
        })
      }

      // Tarjeta eliminada
      if (prevProps.init.deleteCard !== this.props.init.deleteCard) {
        let showCards = true
        let openSnack = false
        let message = Utils.getMessages().General.error
        if (this.props.init.deleteCard.status === 'OK') {
          openSnack = true
          message = Utils.getMessages().Cards.deleteOk
          if (this.props.init.user.cards.data.length === 0) {
            showCards = false
          }
        }
        this.setState({
          showCards: showCards,
          openSnack: openSnack,
          message: message
        })
      }

    }
    else {
      this.props.history.push('/')
    }
  }

  //

  handleEditCard(idx) {
    this.setState({
      editCard: true,
      openCardModal: true,
      card: this.props.init.user.cards.data[idx]
    })
  }

  handleDeleteCard(idx) {
    this.setState({
      openDeleteDialog: true,
      card: this.props.init.user.cards.data[idx]
    })
  }

  // Dialog handlers

  handleCancelDeleteCard() {
    this.setState({
      openDeleteDialog: false
    })
  }

  handleConfirmDeleteCard() {
    this.setState({
      openDeleteDialog: false
    })
  }

  // Modal handlers

  handleConfirmCardModal() {
    this.setState({openCardModal: false})
  }

  handleCloseCardModal() {
    this.setState({openCardModal: false})
  }

  handleNewCard() {
    this.setState({
      openCardModal: true,
      editCard: false
    })
  }

  // Others

  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  getCards() {
    return this.props.init.user.cards.data
  }

  renderWithAccounts(classes) {
    return (
      <div>
        <Title 
          title={Utils.getMessages().Cards.title}
          description={Utils.getMessages().Cards.description}
        />
        <CardTable 
          className={classes.cardTable}
          onEdit={this.handleEditCard}
          onDelete={this.handleDeleteCard}
          cards={this.getCards()}
        />
        <Stepper
          className={classes.stepper}
          next={{
            name: Utils.getMessages().Cards.addCardButton
          }}
          onClickNext={this.handleNewCard}
        />

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.message}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <PaymentModal
          open={this.state.openCardModal}
          editMode={this.state.editCard}
          card={this.state.card}
          handleConfirm={this.handleConfirmCardModal}
          handleClose={this.handleCloseCardModal}
        />

        <DeleteDialog
          open={this.state.openDeleteDialog}
          title={Utils.getMessages().Cards.deleteCardTitle}
          description={
            <span>{Utils.getMessages().Cards.deleteCardDescription} <strong>{this.state.card.number}</strong></span>
          }
          dataType="card"
          data={this.state.card}
          onCancel={this.handleCancelDeleteCard}
          onConfirm={this.handleConfirmDeleteCard}
        />

      </div>
    )
  }

  render() {
    
    const { classes } = this.props

    if (this.state.showCards) {
      return this.renderWithAccounts(classes)
    }
    else {
      return (
        <div>
          <Empty
            title={Utils.getMessages().Cards.titleEmpty}
            description={Utils.getMessages().Cards.descriptionEmpty}
            emptyImg={cardsEmptyImg}
            buttonTitle={Utils.getMessages().Cards.addCardButton}
            callToAction={() => { this.handleNewCard() }}
          />
          <PaymentModal
            open={this.state.openCardModal}
            handleConfirm={this.handleConfirmCardModal}
            handleClose={this.handleCloseCardModal}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestCardsByUser: (userId) => {
      dispatch(requestCardsByUser(userId))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CardSetting)
