import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

// Material UI
import { withTheme, withStyles } from "@material-ui/core/styles";

// Components
import Title from "../components/Title";

// Utils
import Utils from "../resources/Utils";
import { requestLogPayments } from "../actions/actions";
import {
  Button,
  Hidden,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import GeneradorForms from "../components/GeneradorForms";
import { Close } from "@material-ui/icons";

const styles = (theme) => ({
  login: {
    width: "40%",
    textAlign: "center",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
      width: "100%",
    },
  },
});

class LogsPayments extends Component {
  constructor(props) {
    super(props);

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.state = {
      loading: false,
      openSnack: false,
      messageSnack: "",
      paymentsLogs: {
        LogsAttemptPaymment: [],
        LogsPaymentEntity: [],
      },
      logTypes: [
        { value: 1, label: "LogsAttemptPaymment", color: "black" },
        { value: 2, label: "LogsPaymentEntity", color: "black" },
      ],
      generadorFormsFiltrosLogState: {
        form: {
          selectedlogTypes: { value: 1, label: "LogsAttemptPaymment", color: "black" },
          selectediniDate: firstDay,
          selectedendDate: lastDay,
          mpOrden: "",
          mpReference: ""
        },
      },
    };

    this.handleCloseSnack = this.handleCloseSnack.bind(this);
  }

  componentDidMount() {
    Utils.scrollTop();
    var tokenU = null,
      tokenP = null,
      tokenCode = null;

    var tokenU = this.props.match.params.tokenU;
    var tokenP = this.props.match.params.tokenP;
    var tokenCode = this.props.match.params.tokenCode;

    if (tokenU && tokenP && tokenCode) {
      // here you must to send to valid in backend
      /** meanwhile continue.. */
      var myDate = new Date(new Date().toLocaleString('en-US', {
        timeZone: 'America/Mazatlan',
        }));
      var strDay = myDate.toLocaleString().split("/")[0].padStart(2,0);
      var strMon = myDate.toLocaleString().split("/")[1].padStart(2,0);
      var strYear = myDate.toLocaleString().split("/")[2].slice(0,4).padStart(4,0);

      if(tokenCode !== strYear.concat(strMon, strDay)){
        this.props.history.push("/");
      }
    } else {
      // if validation failed then return to home
      this.props.history.push("/");
    }
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.logsPayments !== this.props.init.logsPayments) {
      if (this.props.init.logsPayments.status) {
        this.setState({
          paymentsLogs: this.props.init.logsPayments.result,
          loading: false
        });
      } else {
        this.setState({
          openSnack: true,
          loading: false,
          messageSnack: Utils.getMessages().LogPayments.notfound,
        });
      }
    }
  }
  
  search(){

    const { generadorFormsFiltrosLogState } = this.state;

    var tokenU = this.props.match.params.tokenU;
    var tokenP = this.props.match.params.tokenP;
    var tokenCode = this.props.match.params.tokenCode;

    if(generadorFormsFiltrosLogState.form.selectedlogTypes == null){
      this.setState({
        openSnack: true,
        messageSnack: "Selecciona un tipo de log"
      });
      return;
    }

    let objRequest = { 
      x: tokenP,
      y: tokenU,
      k: tokenCode,
      y: tokenP,
      k: tokenU,
      ll: tokenCode,
      ñ: tokenP,
      ñp: tokenU,
      loi: tokenCode,
      metadata: "{}",
    };
    let filters = {

    };
    
    const timestamp = { converted_date : {}};
    let addtimestamp = false;
    if(generadorFormsFiltrosLogState.form.selectediniDate){
      timestamp.converted_date["$gte"] = generadorFormsFiltrosLogState.form.selectediniDate.getTime();
      addtimestamp = true;
    }

    if(generadorFormsFiltrosLogState.form.selectedendDate){
      timestamp.converted_date["$lte"] = generadorFormsFiltrosLogState.form.selectedendDate.getTime();
      addtimestamp = true;
    }
    if(addtimestamp){
      filters = { ...filters, ...timestamp}
    }
  
    const filterMpOrden = { };

    if(generadorFormsFiltrosLogState.form.mpOrden){
      filterMpOrden["request.mp_order"] =  { $regex: '.*' + generadorFormsFiltrosLogState.form.mpOrden + '.*' }
      filters = { ...filters, ...filterMpOrden}
    }

    const filterMpReference = { };
    if(generadorFormsFiltrosLogState.form.mpReference){
      filterMpReference["request.mp_reference"] =  { $regex: '.*' + generadorFormsFiltrosLogState.form.mpReference + '.*' }
      filters = { ...filters, ...filterMpReference}
    }
   
    const filter_mp_amount = {"convertedmp_amount": {}};
    let addMpAmount = false;
    if(generadorFormsFiltrosLogState.form.minAmount){
      filter_mp_amount["convertedmp_amount"]["$gte"] = parseFloat(generadorFormsFiltrosLogState.form.minAmount);
      addMpAmount = true;
    }

    if(generadorFormsFiltrosLogState.form.maxAmount){
      filter_mp_amount["convertedmp_amount"]["$lte"] = parseFloat(generadorFormsFiltrosLogState.form.maxAmount);
      addMpAmount = true;
    }
    if(addMpAmount){
      filters = { ...filters, ...filter_mp_amount}
    }
    
    this.setState({
      loading: true
    });

    objRequest = { ...objRequest, filter: filters}
    this.props.requestLogPayments(objRequest);
  }

  handleCloseSnack() {
    this.setState({
      openSnack: false,
      messageSnack: "",
    });
  }

  getLabelLogsFilter() {
    return {
      id: "Identificador",
      logTypes: "Logs Types",
      iniDate: "Fecha inicial",
      endDate: "Fecha final",
      mpReference: "mp_reference",
      mpOrden: "mp_order",
      minAmount: "Monto Ini",
      maxAmount: "Monto Max",
    };
  }

  getOriginParamsFilterLogs() {
    return [
      {
        type: "text",
        actions: {
          create:true,
          read: false,
        },
        name: "mpReference",
        tooltip:{
          titulo: "Corresponde a la cuenta del usuario (Se buscará coincidencias)",
          description: ""
        },
        grid: {
          xs: 12,
          sm: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
      },
      {
        type: "text",
        actions: {
          create:true,
          read: false,
        },
        name: "mpOrden",
        tooltip:{
          titulo: "Es la fecha en la que se envio la solicitud de BBVA",
          description: ""
        },
        grid: {
          xs: 12,
          sm: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
      },
      {
        type: "date",
        actions: {
          create: true,
          read: true,
        },
        name: "iniDate",
        required: true,
        grid: {
          xs: 12,
          sm: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
      },
      {
        type: "date",
        actions: {
          create: true,
          read: true,
        },
        name: "endDate",
        required: true,
        grid: {
          xs: 12,
          sm: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
      },
      {
        type: "number",
        actions: {
          create:true,
          read: false,
        },
        name: "minAmount",
        grid: {
          xs: 12,
          sm: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
      },
      {
        type: "number",
        actions: {
          create:true,
          read: false,
        },
        name: "maxAmount",
        grid: {
          xs: 12,
          sm: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
      },
      {
        type: "select",
        actions: {
          create: true,
          read: true,
        },
        name: "logTypes",
        required: true,
        filter: true,
        isClearable: false,
        grid: {
          xs: 12,
          sm: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
      },
    ];
  }

  render() {
    const { classes } = this.props;
    const {
      paymentsLogs,
      logTypes,
      generadorFormsFiltrosLogState,
      loading,
      messageSnack,
      openSnack
    } = this.state;

    let tempheaders = [];
    if (
      !Array.isArray(paymentsLogs.LogsAttemptPaymment) ||
      !Array.isArray(paymentsLogs.LogsPaymentEntity)
    ) {
      return <h1>Err.</h1>;
    }
    if (
      paymentsLogs[generadorFormsFiltrosLogState.form.selectedlogTypes.label].length > 0
    ) {
      if (
        paymentsLogs[generadorFormsFiltrosLogState.form.selectedlogTypes.label][0]
          .request != null
      ) {
        tempheaders = Object.keys(
          paymentsLogs[generadorFormsFiltrosLogState.form.selectedlogTypes.label][0]
            .request
        );
        const notallow = [
          "a", "officeId", "clariti_office_id", "userId", //
          "mp_node", "mp_concept", "mp_currency", "mp_paymentMethod", "mp_paymentMethodCode", "mp_paymentMethodcomplete", "mp_responsemsg", "mp_responsecomplete", "mp_response", "mp_responsemsgcomplete", "mp_authorization", "mp_pan", "mp_authorizationcomplete", "mp_pancomplete", "mp_date", "mp_signature", "mp_promo_msi", "mp_bankcode", "mp_saleid", "mp_sale_historyid", "mp_trx_historyid", "mp_trx_historyidComplete"
        ];
        for (let index = 0; index < notallow.length; index++) {
          const element = notallow[index];
          const rmi = tempheaders.findIndex((x) => x == element);
          if(rmi > 0){
            tempheaders.splice(rmi, 1);
          }
     
        }
      }
    }

    return (
      <div className={classes.fluidContainer}>
        <Title
          title={Utils.getMessages().LogPayments.title}
          description={Utils.getMessages().LogPayments.description}
        />

        <GeneradorForms
          key={"formsGeneratorsParymentsLogs"}
          generadorFormsState={generadorFormsFiltrosLogState}
          forms={this.getOriginParamsFilterLogs()}
          labels={this.getLabelLogsFilter()}
          multiselectData={
            /* aqui va el data de los multi select */
            {
              logTypes: logTypes,
            }
          }
          /*updateAttr={(attr, data) => {
            console.log(attr, data);
            this.setState({ generadorFormsFiltrosLogState:  generadorFormsFiltrosLogState });
          }}*/
        />
          <Button
              variant="contained"
              color="primary"
              className={classes.loginButton}
              disabled={loading}
              onClick={() => { this.search() }}
            >
              {loading ? "..." : Utils.getMessages().CreateAccount.confirmButton}
            </Button>
        <hr></hr>
        <div style={{maxWidth:"100%",overflowX:"scroll"}}>
        <Table size="small" padding="checkbox">
          <TableHead>
            <TableRow>
              {tempheaders.map((param, idx) => {
                return (
                  <TableCell className={classes.headerCell}>
                    <Typography
                      variant="subtitle2"
                      className={classes.headerText}
                    >
                      {param}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {
            paymentsLogs[
              generadorFormsFiltrosLogState.form.selectedlogTypes.label
            ].length == 0 
            ?
            loading ? <h1 style={{textAlign:"center"}}>Buscando..</h1> : <h1 style={{textAlign:"center"}}>...</h1>
            : 
            paymentsLogs[
              generadorFormsFiltrosLogState.form.selectedlogTypes.label
            ].map((item, idx) => {
              return (
                <TableRow
                  key={"TableRow" + idx}
                  className={classes.fitTableRow}
                >
                  {tempheaders.map((column, idx) => {
                    return (
                      <TableCell
                        key={"TableCell" + idx}
                        className={classes.contentCell}
                      >
                        <Typography
                          variant="body1"
                          className={classes.contentText}
                        >
                          {item.request[column]}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={openSnack}
          onClose={this.handleCloseSnack}
          message={
            <span>
              {messageSnack}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnack}
            >
              <Close />
            </IconButton>
          ]}
        />
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => {
  return {
    requestLogPayments: (data) => {
      dispatch(requestLogPayments(data));
    },
  };
};

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LogsPayments);
