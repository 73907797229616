import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'

import TitlePerfil from '../components/TitlePerfil'
import Title from '../components/Title'
import ScheduledPaymentsTable from '../components/ScheduledPaymentsTable'
import UserProfileModal from '../components/UserProfileModal'
import ScheduledPaymentsModal from '../components/ScheduledPaymentsModal'
import ChangePasswordModal from '../components/ChangePasswordModal'
import DeleteUserAccountModal from '../components/DeleteUserAccountModal'
import DeleteDialog from '../components/DeleteDialog'

// Utils
import Utils from '../resources/Utils'
import { resetAppState, requestUserProfile, requestScheduledPayments, requestUsuarioDireccion, requestImagenesUsuario, requestGiros, requestGuardarTramite } from '../actions/actions'
import { getScheduledPaymentsAPI, getOfficeConfigAPI } from '../sagas/api'
import ConfirmPhoneModal from '../components/ConfirmPhoneModal'
import GeneradorForms from '../components/GeneradorForms'
import { Divider } from '@material-ui/core'
import CustomPersonasList from '../components/CustomPersonasList'

const styles = theme => ({
	cardTable: {
		marginTop: 16
	},
	paper: {
		marginTop: 22,
		marginBottom: 48,
		padding: '16px 32px'
	},
	paperWithButton: {
		marginTop: 22,
		marginBottom: 48,
		padding: '32px 16px'
	},
	title: {
		fontWeight: 600,
		fontSize: 16
	},
	passwordButton: {
		marginLeft: 16,
		fontWeight: 600,
		fontSize: 14
	},
	deleteButton: {
		marginLeft: 16,
		fontWeight: 600,
		fontSize: 14,
		backgroundColor: '#FF6363',
		color: 'white'
	},
	stepper: {
		marginTop: 32,
		float: 'right'
	}
})

class Profile extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showInfo: false,
			loadingImages: false,
			autoPayments: {
				open: false,
				data: []
			},
			openUserProfileModal: false,
			openDeleteDialog: false,
			openSnack: false,
			message: '',
			user: {
			},
			scheduledPayment: {},
			openScheduledPaymentsModal: false,
			openChangePasswordModal: false,
			openDeleteUserAccountModal: false,
			openModalConfirmPhoneModal: false,
			dataPhoneConfirm: {
				name:'',
				phone:'',
				selectedContryCode:'52',
				confirm: false,
			},
			porcentajePerfil: 0,

			loadingDataUser: false,
			isCompleted: true,
			dataUsuarioDomicilio: {},
			usuarioData: {form: {}},
			domicilioData: {form: {}},
			usuarioFilesData: { form: {}},
			validated: false,
			giros:[]
		}

		this.handleCancelDeleteScheduledPayment = this.handleCancelDeleteScheduledPayment.bind(this)
		this.handleConfirmDeleteScheduledPayment = this.handleConfirmDeleteScheduledPayment.bind(this)
		this.handleEditScheduledPayment = this.handleEditScheduledPayment.bind(this)
		this.handleDeleteScheduledPayment = this.handleDeleteScheduledPayment.bind(this)
		this.handleEditUserProfile = this.handleEditUserProfile.bind(this)
		this.handleCloseUserProfileModal = this.handleCloseUserProfileModal.bind(this)
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
		this.handleCloseScheduledPaymentsModal = this.handleCloseScheduledPaymentsModal.bind(this)
		this.handleCloseChangePasswordModal = this.handleCloseChangePasswordModal.bind(this)
		this.handleCloseDeleteUserAccountModal = this.handleCloseDeleteUserAccountModal.bind(this)
		this.handleCompletarPerfil = this.handleCompletarPerfil.bind(this)
		this.handleSaved = this.handleSaved.bind(this)
	}

	async componentDidUpdate(prevProps) {
		if (Utils.isUserLoggedIn()) {
			if (prevProps.init.user !== this.props.init.user) {
				if (!Utils.isEmpty(this.props.init.user.profile)) {
					if (this.props.init.user.profile.status === 'OK') {

						let dataPhoneConfirm = this.state.dataPhoneConfirm
						dataPhoneConfirm.name = this.props.init.user.profile.data.name
						dataPhoneConfirm.phone = this.props.init.user.profile.data.phone
						dataPhoneConfirm.selectedContryCode = this.props.init.user.profile.data.countryCode
						
						this.setState({
							showInfo: true,
							user: this.props.init.user.profile.data
						})
						this.props.requestScheduledPayments(this.props.init.user.profile.data.id)
					}
				}
			}

			if (prevProps.init.automaticPayment != this.props.init.automaticPayment) {
				this.setState({
					automaticPayment: this.props.init.automaticPayment.open
				})
			}

			if (prevProps.init.editUserProfile !== this.props.init.editUserProfile) {
				if (!Utils.isEmpty(this.props.init.editUserProfile)) {
					if (this.props.init.editUserProfile.status === 'OK') {
						this.setState({
						openSnack: true,
						message: Utils.getMessages().Profile.editOk
						})
					}
					else {
						this.setState({
						openSnack: true,
						message: Utils.getMessages().General.error
						})
					}
				} 
			}
		}

		if (prevProps.init.giros !== this.props.init.giros) {
			const { data, status } = this.props.init.giros
			if(status === Utils.constants.status.ok){
				const { Data, IsValid } = data             
				if(IsValid){
					if(Array.isArray(Data)){
						const 	{usuarioData} = this.state

						const giro = Data.find( x => x.giroID ===  usuarioData.form.giroId )
						if(giro){
							usuarioData.form.giro = giro.descripcionGiro
						}	
						
						this.setState({
							giros: Data,
							usuarioData: usuarioData,
						})  
					} 
				}		
			}  
		}

		// Pago automático editado
		if (prevProps.init.editScheduledPayment !== this.props.init.editScheduledPayment) {
			let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
			userId = userId.data.id

			let openSnack = false
			let message = Utils.getMessages().General.error
			if (this.props.init.editScheduledPayment.status === 'OK') {
				openSnack = true
				message = Utils.getMessages().ScheduledPayments.editOk
				this.handleCloseScheduledPaymentsModal()
			}

			let autoPayments = await getScheduledPaymentsAPI(userId)

			if (autoPayments.status === "WARNING")
				autoPayments.data = []

			this.setState({
				autoPayments: {
				open: true,
				data: autoPayments.data
				},
				openSnack: openSnack,
				message: message
			})
		}

		// Pago automático eliminado
		if (prevProps.init.deleteScheduledPayment !== this.props.init.deleteScheduledPayment) {
		let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
		userId = userId.data.id

		let showScheduledPayments = true
		let openSnack = false
		let message = Utils.getMessages().General.error
		if (this.props.init.deleteScheduledPayment.status === 'OK') {
			openSnack = true
			message = Utils.getMessages().ScheduledPayments.deleteOk
		}

		let autoPayments = await getScheduledPaymentsAPI(userId)

		console.log('auto payments')
		console.log(autoPayments)

		if (autoPayments.status === "WARNING") {
			autoPayments.data = []
		}

		if (autoPayments.data.length <= 0) {
			showScheduledPayments = false
		}

		this.setState({
			autoPayments: {
			open: showScheduledPayments,
			data: autoPayments.data
			},
			openSnack: openSnack,
			message: message
		})
		}

		// Contraseña atualizada
		if (prevProps.init.changePassword !== this.props.init.changePassword) {
		if (this.props.init.changePassword.status === 'OK') {
			this.setState({
			openSnack: true,
			message: Utils.getMessages().Profile.passwordOk
			})
		}
		}

		// Cuenta de usuario eliminada
		if (prevProps.init.deleteUserAccount !== this.props.init.deleteUserAccount) {
		if (this.props.init.deleteUserAccount.status === 'OK') {
			this.props.resetAppState()
			this.props.history.push(Utils.constants.paths.home)
		}
		}

		if (prevProps.init.confirmCode !== this.props.init.confirmCode) {
			const { status, detail } = this.props.init.confirmCode
			if(status === Utils.constants.status.ok){
				let dataPhoneConfirm = this.state.dataPhoneConfirm
				dataPhoneConfirm.confirm = true
				this.setState({
					dataPhoneConfirm: dataPhoneConfirm,
					openUserProfileModal: true,
					openModalConfirmPhoneModal: false,
				})
			} else {
				this.setState({
				snackbar: {
					open: true,
					message: detail
				}
				} )
			}
		}

		if (prevProps.init.usuarioDireccion !== this.props.init.usuarioDireccion) {
			const { data, isValid, message } = this.props.init.usuarioDireccion
			if(isValid){
				const { monitorRequestUsers, usersOomsDirection, usersOomsUser } = data
				if(monitorRequestUsers && usersOomsDirection && usersOomsUser){
					const 	{usuarioData, domicilioData, giros} = this.state

					const sexo = Utils.sexos.find( x => x.id ===  usersOomsUser.sexoId )
					if(sexo){
						usersOomsUser.sexo = sexo.descripcion
					}
					const tipoContrato = Utils.tiposContratos.find( x => x.id ===  usersOomsUser.tipoContratoId )
					if(sexo){
						usersOomsUser.tipoContrato = tipoContrato.descripcion
					}

					const giro = giros.find( x => x.giroID ===  usersOomsUser.giroId )
					if(giro){
						usersOomsUser.giro = giro.descripcionGiro
					}		

					usuarioData.form = usersOomsUser
					domicilioData.form = usersOomsDirection
					this.setState({ porcentajePerfil: usersOomsDirection.validated ? 100 : 95, loadingDataUser:false, isCompleted: true,
						usuarioData: usuarioData,
						domicilioData: domicilioData,
						validated: usersOomsDirection.validated,
					})
	
				} else {
					this.setState({ porcentajePerfil: 25, loadingDataUser:false, isCompleted: false })
				}
			} else {
				this.setState({
					snackbar: {
						open: true,
						message: "Algo paso al cargar los datos"
					},loadingDataUser: false, 
				} )
			}
		}

		if (prevProps.init.imagenesUsuario !== this.props.init.imagenesUsuario) {
			const { data, isValid, message } = this.props.init.imagenesUsuario
			if(isValid){
				const {  usuarioFilesData } = this.state
				const mapping ={
					evidenciaIdentificacion: null,
					evidenciaSelfie: null,
					evidenciaDomicilio: null
				}
				data.forEach(  (v) => {
					let name = ''
					if( v.tipoImagenUsuarioId === 1 ){
						name = 'evidenciaIdentificacion'
					} else if( v.tipoImagenUsuarioId === 2 ){
						name = 'evidenciaSelfie'
					} else if( v.tipoImagenUsuarioId === 3 ){
						name = 'evidenciaDomicilio'
					}
					mapping[name] = {
						type: v.type,
						dataBase64: "data:"+v.type+";base64," + Utils.arrayBufferToBase64(v.data.data),
						nameFile: v.name,
					} 
	
				} )
				usuarioFilesData.form = mapping
				this.setState({ usuarioFilesData: usuarioFilesData, loadingImages: false })
			} else {
				this.setState({
					snackbar: {
						open: true,
						message: "Algo paso al cargar los datos"
					}
					, loadingImages: false
				} )
			}
		}

	}

	async componentDidMount() {
		Utils.scrollTop()
		if (Utils.isUserLoggedIn()) {
			this.setState({loadingDataUser: true, loadingImages: true})
			let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
			userId = userId.data.id

			let automaticPaymenetOpen = false
			let autoPayments = []

			let office = await getOfficeConfigAPI(localStorage.getItem(Utils.constants.KEY_LS_OFFICE))
			if (office.data.automaticPayment) {
				automaticPaymenetOpen = true
				autoPayments = await getScheduledPaymentsAPI(userId)
				if (autoPayments.status === "WARNING")
				autoPayments.data = [] 
			}

			this.setState({
				autoPayments: {
					open: automaticPaymenetOpen,
					data: autoPayments.data
				},
			})

			this.props.requestGiros()
			//this.props.requestUsuarioDireccion({ userId: userId })
			this.props.requestUserProfile(userId)
			//this.props.requestImagenesUsuario({ userId: userId })
			
		}
	}

	handleEditScheduledPayment(idx) {
		let scheduledPayment = this.props.init.scheduledPayments.data[idx]
		this.setState({openScheduledPaymentsModal: true, scheduledPayment: scheduledPayment})
	}

	handleDeleteScheduledPayment(idx) {
		let scheduledPayment = this.props.init.scheduledPayments.data[idx]
		this.setState({openDeleteDialog: true, scheduledPayment: scheduledPayment})
	}

	handleCloseScheduledPaymentsModal() {
		this.setState({
		openScheduledPaymentsModal: false
		})
	}

	handleCancelDeleteScheduledPayment() {
		this.setState({
		openDeleteDialog: false
		})
	}

	handleConfirmDeleteScheduledPayment() {
		this.setState({
		openDeleteDialog: false
		})
	}

	handleCloseSnackbar() {
		this.setState({openSnack: false})
	}

	handleCloseUserProfileModal() {
		this.setState({openUserProfileModal: false})
	}

	handleEditUserProfile() {
		this.setState({openUserProfileModal: true})
	}

	handleCloseChangePasswordModal() {
		this.setState({openChangePasswordModal: false})
	}

	handleCloseDeleteUserAccountModal() {
		this.setState({openDeleteUserAccountModal: false})
	}

	handleCompletarPerfil(){
		this.props.history.push(Utils.constants.paths.datosCompletosUsuario)
	}

	getLabelUsuario(){
        return {
            id: "Identificador",
            nombre: "Nombre",
			apMaterno: "Apellido paterno",
			apPaterno: "Apellido materno",
			fechaNacimiento: "Fecha de nacimiento",
			RFC: "RFC",
			sexo: "Sexo"
        }
    }

	getOriginParamsUsuario(){
        return [
            {
                type: "read",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "nombre",
                required: true,
                upper: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "apPaterno",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "apMaterno",
                upper: true,
                required: false,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
			,{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "fechaNacimiento",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "RFC",
                required: false,
                upper: true,
				grid:{
					xs:12,
                    sm:12,
					md:9,
					lg:9,
					xl:9
                }
			},{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "sexo",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:3,
					lg:3,
					xl:3
                }
			}
        ]
    }

    getLabelEmpresa(){
        return {
            id: "Identificador",
            razonSocial: "Rarzón Social",
			nombreComercial: "Nombre comercial",
			rfcEmpresa: "RFC",
			correoElecEmpresa: "Correo electrónico",
        }
    }

	getOriginParamsEmpresa(){
        return [
            {
                type: "read",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "razonSocial",
                responsive: "lg",
                required: true,
                upper: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "nombreComercial",
                responsive: "lg",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "rfcEmpresa",
                responsive: "lg",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
			,{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "correoElecEmpresa",
                responsive: "lg",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
        ]
    }

    getLabelContrato(){
        return {
            id: "Identificador",
            tipoContrato: "Tipo contrato",
			giro: "Tipo giro",
        }
    }
    getOriginParamsContrato(){
        return [
            {
                type: "read",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            }, {
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "tipoContrato",
                required: true,
                filter: true,
                grid:{
                    xs:12,
                    sm:12,
                    md:4,
                    lg:3,
                    xl:3
                }
            },
            {
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "giro",
                required: true,
                filter: true,
                grid:{
                    xs:12,
                    sm:12,
                    md:4,
                    lg:3,
                    xl:3
                }
            }
        ]
    }

    getLabelFilesUsuario(){
        return {
            id: "Identificador",
            evidenciaIdentificacion: "Evidencia Identificación (INE)",
            evidenciaSelfie: "Evidencia Selfie",
        }
    }

	getOriginParamsFilesUsuario(){
        return [
            {
                type: "text",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "fileupload",
                actions: { 
                    create: true, read: true
                },
                name: "evidenciaIdentificacion",
				readyOnly: true,
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },
            {
                type: "fileupload",
                actions: { 
                    create: true, read: true
                },
                name: "evidenciaSelfie",
                required: true,
				readyOnly: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },
        ]
    }
	
	getLabelDireccion(){
        return {
            id: "Identificador",
            sucursal: "Sucursal",
			ciudad: "Ciudad",
			colonia: "Colonia",
			cumunidad: "Comunidad",
            calle: "Calle",
			entreCalle1: "Entre calle",
            entreCalle2: "y Calle",
            noExt: "No. Ext",
            noInt: "No. Int",
            cp: "Código postal",
            manzana: "Manzana",
            lote: "Lote",
            nivel: "Nivel",
            fraccion: "Fracción",
            numeroTomas: "Número de tomas",
            numeroCatastral: "Número catastral",
            sector: "Sector",
            cuenta: "Cuenta",
            direccionCompleta: "Dirección completa",
        }
    }

	getOriginParamsDireccion(){
        return [
            {
                type: "read",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "ciudad",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "colonia",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "cumunidad",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "calle",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "entreCalle1",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "entreCalle2",
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "noExt",
                required: true,
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "noInt",
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "cp",
                required: true,
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:4,
					lg:2,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "manzana",
                required: true,
                tooltip:{
                    titulo: "Manzana",
                    description: "Lo puedes obtener de la clave catastral"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "lote",
                required: true,
                tooltip:{
                    titulo: "Lote",
                    descripcion: "Lo puedes obtener de la clave catastral"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "nivel",
                tooltip:{
                    titulo: "Nivel",
                    descripcion: "Lo puedes obtener de la clave catastral"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "fraccion",
                tooltip:{
                    titulo: "Fracción",
                    descripcion: "Lo puedes obtener de la clave catastral"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:2,
					lg:1,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "numeroTomas",
                tooltip:{
                    titulo: "Número de tomas",
                    descripcion: "La cantidad de tomas que quiere contratar"
                },
                filter: true,
                grid:{
					xs:6,
                    sm:6,
					md:4,
					lg:2,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "numeroCatastral",
                required: true,
                tooltip:{
                    titulo: "Número catastral",
                    descripcion: "Lo puedes obtener en el recibo del predial"
                },
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:2,
					xl:1
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "direccionCompleta",
                required: true,
                filter: true,
                grid:{
					xs:12,
                }
            },
        ]
    }

    getLabelFilesDireccion(){
        return {
            id: "Identificador",
            evidenciaDomicilio: "Evidencia domicilio",
        }
    }

	getOriginParamsFilesDireccion(){
        return [
            {
                type: "text",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "fileupload",
                actions: { 
                    create: true, read: true
                },
                name: "evidenciaDomicilio",
				readyOnly: true,
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:12,
					lg:12,
					xl:12
                }
            },
        ]
    }

	handleSaved(datos){
		let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
		userId = userId.data.id
		console.log(datos)
		this.props.requestGuardarTramite({
			userId: userId,
			tipoTramiteId: 1,
			personaId: datos.personaId,
		})
		
	}

	render() {

		const { classes, init } = this.props
		const { porcentajePerfil, loadingDataUser, isCompleted, usuarioData, domicilioData, usuarioFilesData, validated, loadingImages } = this.state

		const { user } = init

		let profile = { data : { name : "" } }
		if(user){
			profile = user.profile
		}
		if(!profile){
			profile = { data : { name : "" } }
		}

		return (
		<div>
			<Title
					variant="subtitle"
					title={Utils.getMessages().Profile.titleProfile}
					description={Utils.getMessages().Profile.descriptionProfile}
				/>
			{
			(this.state.showInfo) ?

			<Paper className={classes.paper}>
				<Grid container>
					<Grid item lg={4}>
						<Typography variant="body1">{Utils.getMessages().Profile.nameLabel}</Typography>
						<Typography variant="h6" className={classes.title}>{profile.data.name || '-'}</Typography>
					</Grid>
					<Grid item lg={4}>
						<Typography variant="body1">{Utils.getMessages().Profile.phoneLabel}</Typography>
						<Typography variant="h6" className={classes.title}>{'( '+ (profile.data.countryCode || '-') + ' ) ' +  (profile.data.phone || '-')}</Typography>
					</Grid>
					<Grid item lg={4}>
						<Typography variant="body1">{Utils.getMessages().Profile.emailLabel}</Typography>
						<Typography variant="h6" className={classes.title}>{profile.data.email}</Typography>
						
					</Grid>
					<Grid item lg={4}>
						<IconButton onClick={this.handleEditUserProfile}>
							<EditIcon />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>

			:

			<Paper className={classes.paper}>
				<Typography variant="body1">{Utils.getMessages().Profile.loading}</Typography>
			</Paper>
			}

			{
				//usuarioData.form.tipoUsuarioId ?
				<Title
					variant="subtitle"
					title="Personas."
					description="Aquí puedes ver las personas viculadas a tu cuenta para realizar tramites a sus nombres."
				/>
				//: null
			}
			{
				<Paper className={classes.paper}>
					<CustomPersonasList
						handleSaved={  this.handleSaved }
					></CustomPersonasList>
				</Paper>
			}

			{
				usuarioData.form.tipoUsuarioId ?
				<Title
					variant="subtitle"
					title="Información de contrato."
					description="Aquí puedes ver la infomración que capturaste al momento de completar tu perfil"
				/>
				: null
			}
			{
				usuarioData.form.tipoUsuarioId ?
				<Paper className={classes.paper}>
					
					{
						!loadingDataUser ? 
						
							parseInt(usuarioData.form.tipoUsuarioId) === 1 ?
								<GeneradorForms
									key={'usuarioDataUsuario'}
									titulo={"Datos de usuario"}
									generadorFormsState={ usuarioData }
									forms={this.getOriginParamsUsuario()}
									labels={this.getLabelUsuario()}
								></GeneradorForms>
							:
								<GeneradorForms
									key={'usuarioDataEmpresa'}
									titulo={"Datos de empresa"}
									generadorFormsState={ usuarioData }
									forms={this.getOriginParamsEmpresa()}
									labels={this.getLabelEmpresa()}
								></GeneradorForms>
						
						: 
						<Typography variant="body1">{Utils.getMessages().Profile.loading}</Typography>
					}
					{
						!loadingDataUser ?
						<div>
							<GeneradorForms
								key={'usuarioDataContrato'}
								generadorFormsState={ usuarioData }
								forms={this.getOriginParamsContrato()}
								labels={this.getLabelContrato()}
							></GeneradorForms>
						</div>		
						: 
						<Typography variant="body1">{Utils.getMessages().Profile.loading}</Typography>
					}

					

					{
						!loadingImages ? 
						<div>
							<Divider></Divider>
							<br></br>
							<GeneradorForms
								key={'usuarioFilesData'}
								titulo={"Evidencia de usuario"}
								generadorFormsState={ usuarioFilesData }
								forms={this.getOriginParamsFilesUsuario()}
								labels={this.getLabelFilesUsuario()}
							></GeneradorForms>
						</div>
						: 
						<Typography variant="body1">{Utils.getMessages().Profile.loading}</Typography>
					}

					{
						!loadingDataUser ? 
						<div>
							<Divider></Divider>
							<br></br>
							<GeneradorForms
								key={'domicilioData'}
								titulo={"Datos de domicilio"}
								generadorFormsState={ domicilioData }
								forms={this.getOriginParamsDireccion()}
								labels={this.getLabelDireccion()}
							></GeneradorForms>
						</div>
						:
						<Typography variant="body1">{Utils.getMessages().Profile.loading}</Typography>
					}

					{
						!loadingImages ? 
						<div>
							<Divider></Divider>
							<br></br>
							<GeneradorForms
								key={'direcionFilesData2'}
								titulo={"Evidencia de domicilio"}
								generadorFormsState={ usuarioFilesData }
								forms={this.getOriginParamsFilesDireccion()}
								labels={this.getLabelFilesDireccion()}
							></GeneradorForms>
						</div>
						: 
						<Typography variant="body1">{Utils.getMessages().Profile.loading}</Typography>
					}
			
				</Paper>
				: null
			}

			{
			(this.state.autoPayments.open && this.state.autoPayments.data && this.state.autoPayments.data.length > 0) ?
			<div>
				<Title
				variant="subtitle"
				title="Pagos automáticos."
				description="Cuentas configuradas para que se paguen de forma automática."
				/>
				{
				(this.state.autoPayments.open) ?
				<div>
					<Paper className={classes.paper}>
					<ScheduledPaymentsTable
						className={classes.scheduledPaymentsTable}
						onEdit={this.handleEditScheduledPayment}
						onDelete={this.handleDeleteScheduledPayment}
						scheduledPayments={this.state.autoPayments.data}
					/>
					</Paper>
				</div>
				:
				<Paper className={classes.paper}>
					<Typography variant="body1">-</Typography>
				</Paper>
				}
			</div>
			:
			''
			}
			<Title
			variant="subtitle"
			title={Utils.getMessages().Profile.titlePassword}
			description={Utils.getMessages().Profile.descriptionPassword}
			/>
			<Paper className={classes.paperWithButton}>
			<Button
				variant="contained"
				size="large"
				className={classes.passwordButton}
				onClick={() => { this.setState({openChangePasswordModal: true}) }}
			>
				{Utils.getMessages().Profile.changePasswordButton}
			</Button>
			</Paper>
			<Title
			variant="subtitle"
			title={Utils.getMessages().Profile.titleDeleteUser}
			description={Utils.getMessages().Profile.descriptionDeleteUser}
			/>
			<Paper className={classes.paperWithButton}>
			<Button
				variant="contained"
				size="large"
				className={classes.deleteButton}
				onClick={() => { this.setState({openDeleteUserAccountModal: true}) }}
			>
				{Utils.getMessages().Profile.deleteUserButton}
			</Button>
			</Paper>

			<Snackbar
			autoHideDuration={5000}
			anchorOrigin={{vertical: 'top', horizontal: 'center'}}
			open={this.state.openSnack}
			onClose={this.handleCloseSnackbar}
			message={
				<span>{this.state.message}</span>
			}
			action={[
				<IconButton
				key="close"
				aria-label="Close"
				color="inherit"
				onClick={this.handleCloseSnackbar}
				>
				<CloseIcon />
				</IconButton>
			]}
			/>

			<UserProfileModal
			open={this.state.openUserProfileModal}
			handleClose={this.handleCloseUserProfileModal}
			handleConfirm={() => { this.setState({ openModalConfirmPhoneModal: true, openUserProfileModal: false }) }}
			user={this.state.user}
			dataPhoneConfirm={this.state.dataPhoneConfirm}
			/>

			<ConfirmPhoneModal
				open={this.state.openModalConfirmPhoneModal}
				handleClose={() => { this.setState({ openModalConfirmPhoneModal: false, openUserProfileModal: true }) }}
				numberPhone={this.state.dataPhoneConfirm.phone}
				countryCode={this.state.dataPhoneConfirm.selectedContryCode}
			> 
			
			</ConfirmPhoneModal>


			<ScheduledPaymentsModal
			open={this.state.openScheduledPaymentsModal}
			editMode={true}
			scheduledPayment={this.state.scheduledPayment}
			handleClose={this.handleCloseScheduledPaymentsModal}
			/>

			<ChangePasswordModal
			open={this.state.openChangePasswordModal}
			recoveryPassword={false}
			handleClose={this.handleCloseChangePasswordModal}
			/>

			<DeleteUserAccountModal
			open={this.state.openDeleteUserAccountModal}
			handleClose={this.handleCloseDeleteUserAccountModal}
			/>

			<DeleteDialog
			open={this.state.openDeleteDialog}
			title="¿Desea eliminar el pago automático?"
			description={
				<span>Se eliminará el pago automático para la cuenta número: <strong>{this.state.scheduledPayment.accountNumber}</strong></span>
			}
			dataType="scheduledPayment"
			data={this.state.scheduledPayment}
			onCancel={this.handleCancelDeleteScheduledPayment}
			onConfirm={this.handleConfirmDeleteScheduledPayment}
			/>

		</div>
		)
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
	return {
		resetAppState: () => {
			dispatch(resetAppState())
		},
		requestUserProfile: (userId) => {
			dispatch(requestUserProfile(userId))
		},
		requestScheduledPayments: (userId) => {
			dispatch(requestScheduledPayments(userId))
		},
		requestUsuarioDireccion: (data) => {
			dispatch(requestUsuarioDireccion(data))
		},
		requestImagenesUsuario: (data) => {
			dispatch(requestImagenesUsuario(data))
		},
		requestGiros: (data) => {
			dispatch(requestGiros(data))
		},
		requestGuardarTramite: (data) => {
			dispatch(requestGuardarTramite(data))
		}
	}
}

export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(Profile)
