import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton';
import ViewImageModal from './ViewImageModal'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, Grid, Snackbar } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import PersonaModal from './PersonaModal'
import { requestActivarInactivarPersona, requestGiros, requestImagenesUsuario, requestTipoImagenesPersonas, requestUsuarioDireccion } from '../actions/actions'
import Utils from '../resources/Utils'
import MySnackbarContentWrapper from './CustomSnackBar'

const styles = theme => ({
  itemGrid:{
		paddingRight:theme.spacing(1), paddingBottom:theme.spacing(1)
	},
	media:{
		/*width: "100%",
  		height: "auto",*/
		height: 140,
	},
	rootCard:{
		maxWidth: 245,
	},
	actionCards:{
		float:"right"
	},
	title: {
		fontWeight: 600,
		fontSize: 16
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	}, 
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
})

class CustomList extends Component {

	constructor(props) {
		super(props)
		this.state = {
			modalPersona:{
				open: false,
			},
			viewImageModalState: {
				open: false,
				titulo:"",
				descripcion:"",
				src:"",
				type: "",
			},
			dataPersonas: [],
			tipoImagenes:[],
			persona:{},
			giros:[],
			loadingRemovingPersona: false,
			openSnack: false,
			variant: 'warning',
			errorMessage: '',
		}

		this.handleLoadDataCustomPersonas = this.handleLoadDataCustomPersonas.bind(this)
		this.handleRemovePersona = this.handleRemovePersona.bind(this)
		this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this)
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
		
	}

	componentDidUpdate(prevProps){

		if (prevProps.init.usuarioDireccion !== this.props.init.usuarioDireccion) {
			const { data, isValid, message } = this.props.init.usuarioDireccion
			if(isValid) {
				this.setState({ dataPersonas: data })
				this.props.requestImagenesUsuario({ personaIds : data.map( (v) => { return v.id } ) })
			} 
		}

		if (prevProps.init.tipoImagenes !== this.props.init.tipoImagenes) {
			const { data, isValid, message } = this.props.init.tipoImagenes
			if(isValid) {
				this.setState({ tipoImagenes: data })
			} 
		}

		if (prevProps.init.giros !== this.props.init.giros) {
			const { data, status } = this.props.init.giros
			if(status === Utils.constants.status.ok){
				const { Data, IsValid } = data             
				if(IsValid){
					if(Array.isArray(Data)){                     
						this.setState({
							giros: Data.map( (v) => { return { label: v.descripcionGiro, value: v.giroID, color: "black"} } )
						})  
					} 
				}		
			}  
		}

		if (prevProps.init.activarInactivarPersona !== this.props.init.activarInactivarPersona) {
			const { data, isValid } = this.props.init.activarInactivarPersona
			if(isValid){
				console.log(data)
				this.setState({loadingRemovingPersona: false})
				let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
				userId = userId.data.id
				this.props.requestUsuarioDireccion({ userId:userId })
			}  
		}

		

		if (prevProps.init.imagenesUsuario !== this.props.init.imagenesUsuario) {
			const { data, isValid, message } = this.props.init.imagenesUsuario
			if(isValid){
				const {  dataPersonas, tipoImagenes } = this.state
				
				dataPersonas.forEach( (y) => {
					const mapping = []
					data.filter( x => x.personaId === y.id).forEach(  (v) => {
						let descripcion = ''
						let dddd= tipoImagenes.find( x => x.id === v.tipoImagenPersonaId)
						if(dddd){
							descripcion = dddd.descripcion
						}
						mapping.push({
							tipoImagen: v.tipoImagenPersonaId,
							tipoImagenDescripcion:descripcion,
							type: v.type,
							dataBase64: "data:"+v.type+";base64," + Utils.arrayBufferToBase64(v.data.data),
							nameFile: v.name,
							id: v.id,
						})
					} )
					y.imagenes = mapping
				} )
				this.setState({ dataPersonas: dataPersonas, loadingImages: false })
			} else {
				this.setState({
					snackbar: {
						open: true,
						message: "Algo paso al cargar los datos"
					}
					, loadingImages: false
				} )
			}
		}

	}

	handleOpenSnackbar({message, variant}) {
		this.setState({openSnack: true, variant: variant || 'warning', errorMessage: message })
	}

	handleCloseSnackbar() {
		this.setState({openSnack: false})
	}

  	handleOpenModalViewImage = (data, label) => {
		if(data && label){
			let { viewImageModalState } = this.state
			viewImageModalState.open = true
			viewImageModalState.titulo = label
			viewImageModalState.descripcion = data.nameFile
			viewImageModalState.src = data.dataBase64
			viewImageModalState.type = data.type
			this.setState({ viewImageModalState: viewImageModalState}) 
		}	
	}

	componentDidMount(){
		if (Utils.isUserLoggedIn()) {
			let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
			userId = userId.data.id
			this.props.requestTipoImagenesPersonas()
			this.props.requestUsuarioDireccion({ userId:userId })
		}
	}

	getLabelUsuario(){
        return {
            id: "Identificador",
            nombre: "Nombre",
			apMaterno: "Apellido paterno",
			apPaterno: "Apellido materno",
			fechaNacimiento: "Fecha de nacimiento",
			RFC: "RFC",
			sexo: "Sexo"
        }
    }

	handleRemovePersona(persona){
		this.setState({loadingRemovingPersona: true})
		this.props.requestActivarInactivarPersona({ personaId: persona.id, estaActivo: 0 })
	}
	
	handleLoadDataCustomPersonas(persona){
		const { modalPersona, giros } = this.state
		modalPersona.open = true
		
		const itemSexo = Utils.sexos.filter(x => x.id === persona.sexoId).map( (v) => { return {label: v.descripcion, value: v.id, color:"black"} })
		if(itemSexo.length > 0){
			persona.selectedsexo = itemSexo[0]
		}
		const itemTipoContrato = Utils.tiposContratos.filter(x => x.id === persona.tipoContratoId).map( (v) => { return {label: v.descripcion, value: v.id, color:"black"} })
		if(itemTipoContrato.length > 0){
			persona.selectedtipoContrato = itemTipoContrato[0]
		}
		
		const itemGiro = giros.filter(x => x.value === persona.giroId)
		if(itemGiro.length > 0){
			persona.selectedtipoGiro = itemGiro[0]
		}

		modalPersona.persona = persona

		this.setState({ modalPersona: modalPersona })
	}

	getOriginParamsUsuario(){
        return [
            {
                type: "read",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "nombre",
                required: true,
                upper: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "apPaterno",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "apMaterno",
                upper: true,
                required: false,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
			,{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "fechaNacimiento",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "RFC",
                required: false,
                upper: true,
				grid:{
					xs:12,
                    sm:12,
					md:9,
					lg:9,
					xl:9
                }
			},{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "sexo",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:3,
					lg:3,
					xl:3
                }
			}
        ]
    }

    getLabelEmpresa(){
        return {
            id: "Identificador",
            razonSocial: "Rarzón Social",
			nombreComercial: "Nombre comercial",
			rfcEmpresa: "RFC",
			correoElecEmpresa: "Correo electrónico",
        }
    }

	getOriginParamsEmpresa(){
        return [
            {
                type: "read",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "razonSocial",
                responsive: "lg",
                required: true,
                upper: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "nombreComercial",
                responsive: "lg",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "rfcEmpresa",
                responsive: "lg",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
			,{
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "correoElecEmpresa",
                responsive: "lg",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
        ]
    }

    getLabelContrato(){
        return {
            id: "Identificador",
            tipoContrato: "Tipo contrato",
			giro: "Tipo giro",
        }
    }
    getOriginParamsContrato(){
        return [
            {
                type: "read",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            }, {
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "tipoContrato",
                required: true,
                filter: true,
                grid:{
                    xs:12,
                    sm:12,
                    md:4,
                    lg:3,
                    xl:3
                }
            },
            {
                type: "read",
                actions: { 
                    create: true, read: true
                },
                name: "giro",
                required: true,
                filter: true,
                grid:{
                    xs:12,
                    sm:12,
                    md:4,
                    lg:3,
                    xl:3
                }
            }
        ]
    }
	
	render() {
		const { classes, loadingRemovingPersona } = this.props
		const { dataPersonas } = this.state

		let personas = dataPersonas.map( (v, i) => {

			let identificacion = {}
			if(v.imagenes){
				identificacion = v.imagenes.find( x => x.tipoImagen === 1)
			}
			if(!identificacion){
				identificacion = {}
			}

			return <Grid 
				className={classes.itemGrid}
				key={i} item xs={12} sm={12} md={4} lg={ 3} xl={2}>     
				<Grid container>
					<Grid item xs={12}>
						<Card className={classes.rootCard}>
							<CardActionArea
								onClick={() => { this.handleOpenModalViewImage(identificacion, identificacion.tipoImagenDescripcion) } }
							>
								<CardMedia
									className={classes.media}
									image={ identificacion ?  identificacion.dataBase64 || '' : './noimg.png'}
									src={ identificacion ?  identificacion.dataBase64 || '' : './noimg.png'}
									title={identificacion ?  identificacion.name || '' : '[NOMBRE DE IMAGEN]'}
								/>

								<CardContent>
									<Typography style={{color: red[500], fontStyle:"italic" }} variant="subtitle2" component="h2">
										{  v.validated === 0 ? 'Persona sin validar' : null}
									</Typography>
									<Typography  gutterBottom variant="body1" component="h2">
										{  v.tipoPersonaId === 1 ? v.nombre + ' ' + v.apPaterno + ' ' + v.apMaterno : v.nombreComercial}
									</Typography>
									<Typography variant="body2" color="textSecondary" component="p">
										{ v.direccionCompleta }
									</Typography>
								</CardContent>
							</CardActionArea>

							<CardActions className={classes.actionCards}>

								{
									v.validated === 1 ?
									<span className={classes.wrapper}>
										<IconButton
											variant="contained"
											component="label"
											color="primary"		
											disabled={loadingRemovingPersona}					
											onClick={ (e) => { this.handleRemovePersona(v)	 } }
											>	
												<DeleteIcon style={{color:red[500]}}></DeleteIcon>
										</IconButton>
										{loadingRemovingPersona && <CircularProgress size={24} className={classes.buttonProgress} />}
									</span>
									: null
								}

								

								{
									v.validated === 0 ?
									<IconButton
										variant="contained"
										component="label"
										color="primary"
										onClick={ (e) => {
											this.handleLoadDataCustomPersonas(v)					
										} }
										>
											<EditIcon></EditIcon>
									</IconButton>
									: null
								}

								
							</CardActions>
							
						</Card>
					</Grid>
				</Grid>
			</Grid>
		} )

		return (
			<div>

				<Grid container>
					<Grid item xs={12}>
						<div style={{float:"right", marginBottom:10}}>
							<Button
								startIcon={<PersonAddIcon></PersonAddIcon>}
								variant="contained"
								color="primary"
								onClick={ () => { 
									let { modalPersona } = this.state
									modalPersona.open = true
									modalPersona.persona = {}
									modalPersona.persona.imagenes = []
									this.setState({ modalPersona: modalPersona}) 
								} }
							>
								Agregar
							</Button>
						</div>
					</Grid>
				</Grid>

				<Grid container>
					{personas}          
				</Grid>

				<PersonaModal
					open={this.state.modalPersona.open}
					handleClose={ () => { 
						let { modalPersona } = this.state
						modalPersona.open = false
						let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
						userId = userId.data.id
						this.props.requestUsuarioDireccion({ userId:userId })
						this.setState({ modalPersona: modalPersona}) 
					} }
					titulo={"Agregar persona"}
					descripcion={"Agregar una persona para poder hacer tramites"}
					persona={this.state.modalPersona.persona}
					handleOpenSnackbar = {this.handleOpenSnackbar}
					handleSaved={this.props.handleSaved}
				>
					
				</PersonaModal>

				<ViewImageModal
					open={this.state.viewImageModalState.open} 
					titulo={this.state.viewImageModalState.titulo}
					descripcion={this.state.viewImageModalState.descripcion}
					src={this.state.viewImageModalState.src}
					type={this.state.viewImageModalState.type}
					handleClose={ () => { 
						let { viewImageModalState } = this.state
						viewImageModalState.open = false
						this.setState({ viewImageModalState: viewImageModalState})
					} }
				></ViewImageModal>

				<Snackbar
					anchorOrigin={{vertical: 'top', horizontal: 'center'}}
					open={this.state.openSnack}
					autoHideDuration={5000}

					onClose={this.handleCloseSnackbar}
				>
					<MySnackbarContentWrapper
						onClose={this.handleCloseSnackbar}
						variant={this.state.variant}
						message={
							<span>{this.state.errorMessage}</span>
						}
					/>
				</Snackbar>
			</div>
		)
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
	return {
		requestGiros: (data) => {
			dispatch(requestGiros(data))
		},
        requestUsuarioDireccion: (data) => {
			dispatch(requestUsuarioDireccion(data))
		},
		requestImagenesUsuario: (data) => {
			dispatch(requestImagenesUsuario(data))
		},
		requestTipoImagenesPersonas: (data) => {
			dispatch(requestTipoImagenesPersonas(data))
		},
		requestActivarInactivarPersona: (data) => {
			dispatch(requestActivarInactivarPersona(data))
		},
	}
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CustomList)


