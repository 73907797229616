import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Components
import ReceiptListTable from '../components/ReceiptListTable'
import Title from '../components/Title'
import Empty from '../components/Empty'
import ShowReceipt from '../components/ShowReceipt'

// Utils
import Utils from '../resources/Utils'
import emptyImg from '../resources/images/empty.svg'
import { requestAccountsByUser, requestGetReceiptsByAccount } from '../actions/actions'

const styles = theme => ({
  receiptTable: {
    marginTop: 16
  },
  select: {
    width: 300
  }
})

class Receipt extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: 'EMPTY',
      openModal: false,
      receipt: {
        id: '',
        consumption: 0.00,
        registerAt: {
          $date: '-'
        },
        expirationAt: {
          $date: '-'
        },
        details: []
      },
      openSnack: false,
      accountSelected: "",
      showReceipts: false,
      receipts: []
    }

    // Modal handlers
    this.changeAccountNumber = this.changeAccountNumber.bind(this)

    // Others
    this.downloadReceipt = this.downloadReceipt.bind(this)
    this.showReceipt = this.showReceipt.bind(this)
    this.handleCloseShowReceipt = this.handleCloseShowReceipt.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      let user = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
      user = user.data
      this.props.requestAccountsByUser(user.id)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.user.accounts !== this.props.init.user.accounts) {
      if (!Utils.isEmpty(this.props.init.user.accounts.data)) {
        if (this.props.init.user.accounts.data.length > 0) {
          let accountNumber = this.props.init.user.accounts.data[0].account
          this.setState({
            accountSelected: accountNumber
          })
          this.props.requestGetReceiptsByAccount(accountNumber)
        }
      }
    }
    else if (prevProps.init.receipts !== this.props.init.receipts) {
      let receipts = this.props.init.receipts.data
      if (!Utils.isEmpty(receipts)) {
        if (receipts.length > 0) {
          this.setState({
            status: 'LOAD',
            showReceipts: true,
            receipts: receipts,
          })
        }
        else {
          this.setState({
            status: 'EMPTY',
            showReceipts: true,
            receipts: [],
          })
        }
      }
    }
  }

  downloadReceipt(receipt) {
    const API_PDF = Utils.constants.HOST_EMAIL_API + "/receipts/" + receipt.id + "/download"
    window.open(API_PDF)
  }

  showReceipt(receipt) {
    this.setState({openModal: true, receipt: receipt})
  }

  handleCloseShowReceipt() {
    this.setState({openModal: false})
  }

  changeAccountNumber(event) {
    this.setState({
      receipts: [],
      status: 'LOADING',
      accountSelected: event.target.value
    })
    this.props.requestGetReceiptsByAccount(event.target.value)
  }
  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  renderWithReceipts(classes) {
    return (
      <div>
        <Title 
          title={Utils.getMessages().Receipts.title}
          description={Utils.getMessages().Receipts.description}
        />
        <br />
        <Select
          value={this.state.accountSelected}
          onChange={(event) => {this.changeAccountNumber(event)}}
          className={classes.select}
        >
          {
            this.props.init.user.accounts.data.map((account, idx) => {
              return (<MenuItem key={idx} value={account.account}><strong>{account.account}</strong> - ({account.name})</MenuItem>)
            })
          }
        </Select>

        {
          (this.state.status === 'LOADING') ? 
            <div>
              <Empty
                title={Utils.getMessages().Receipts.loadingTitle}
                description={Utils.getMessages().Receipts.loadingDescription}
              />
            </div>
          :
            (this.state.status === 'EMPTY') ?
              <div>
                <Empty
                  title={Utils.getMessages().Receipts.titleEmpty}
                  description={Utils.getMessages().Receipts.descriptionEmpty}
                />
              </div>
            :
            <div>
              <ReceiptListTable 
                className={classes.receiptTable}
                receipts={this.state.receipts}
                show={(receipt) => { this.showReceipt(receipt) }}
                download={(receipt) => { this.downloadReceipt(receipt) }}
              />
              <ShowReceipt
                open={this.state.openModal}
                receipt={this.state.receipt}
                handleClose={this.handleCloseShowReceipt}
              />
            </div>
        }

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.message}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    )
  }

  render() {
    
    const { classes } = this.props

    if (this.state.showReceipts) {
      return this.renderWithReceipts(classes)
    }
    else {
      return (
        <div>
          <Empty
            title={Utils.getMessages().Receipts.titleEmpty}
            description={Utils.getMessages().Receipts.descriptionEmpty}
            emptyImg={emptyImg}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestGetReceiptsByAccount: (accountNumber) => {
      dispatch(requestGetReceiptsByAccount(accountNumber))
    },
    requestAccountsByUser: (userId) => {
      dispatch(requestAccountsByUser(userId))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Receipt)
