import React, { Component } from 'react';

// Material UI
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Utils
import Utils from '../resources/Utils'
import claritiLogo from '../resources/images/clariti.png'

import location from '../resources/images/location.svg';
import mail from '../resources/images/mail.svg';
import phone from '../resources/images/phone.svg';
const styles = theme => ({
  footer: {
    paddingTop: 0,
    marginTop: 0,
    paddingBottom: 0,
    textAlign: 'center',
    width: '100%',
    display:'flex',
    backgroundColor: 'rgb(240,240,240)',//theme.palette.background.secondary,
    position: 'relative',
    bottom: 0,
    fontSize: 12,
    color: '#6A7688',
    borderTop: '0.5px solid #E0E0E0',
    zIndex: theme.zIndex.drawer + 1
  },
 
  footerLogo: {
    width: 24,
    margin: 8,
    opacity: 0.5,
    filter: 'grayscale(80%)',
  },
  footerText: {
    fontSize: 12
  },
  footerLink: {
    fontSize: 10
  },
  footerVersion: {
    fontSize: 10,
  },
  divFooterDir: {
    marginTop: 0,
    color: '#6A7688',
    justifyContent: 'center',
    width: '100%',//window.innerWidth,
    backgroundColor: 'rgb(240,240,240)',
    minWidth: 0,
    height:"100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(0),
    } 
  },
  footerIcons: {
    width: 25,
    marginTop: 0,
    opacity: 0.6,
    textAlign: 'left',
    filter: 'grayscale(80%)',
  },
})

class Footer extends Component {
  constructor(props) {
    super(props)
    var date = new Date()
    var year = date.getFullYear()
    this.year = year
  }


  render() {
    const { classes } = this.props
    return (
      <footer className={classes.footer}>
          <Grid container>
       
          <Grid item className={classes.divFooterDir}>
           
           <h3 style={{ color: '#6A7688' }}>CONTACTO</h3>
           <div  style={{display:"inline-grid"}}>
            <div style={{ display: "flex" }}>
              <a href='https://maps.app.goo.gl/vUyZ9Di8eVxXENcC7' target='_blank' rel='noopener noreferrer'><img id="footerIcons" className={classes.footerIcons} src={location} alt='Direccion' /></a>
              <div style={{ marginLeft: "10px", textAlign: 'justify',marginTop: "3px" }}><Typography className={classes.footerText} variant="body1"><a href='https://maps.app.goo.gl/vUyZ9Di8eVxXENcC7' target='_blank' rel='noopener noreferrer' style={{color:"#6A7688"}}>Gabriel Leyva y Emiliano Barragan S/N, Col.Centro C.P 82000, Mazatlán, Sinaloa.</a></Typography></div>
            </div>
            <div style={{ display: "flex" }}>
              <a href='tel:+52 669 915-70-00' target='_blank' rel='noopener noreferrer'><img id="footerIcons" className={classes.footerIcons} src={phone} alt='Telefono' /></a>
              <div style={{ marginLeft: "10px", textAlign: 'justify',marginTop: "3px" }}><Typography className={classes.footerText} variant="body1"><a href='tel:+52 669 915-70-00' style={{color:"#6A7688"}}>669&nbsp;915-70-00</a> &nbsp; &nbsp;Aquatel 073</Typography></div>
            </div>
            <div style={{ display: "flex" }}>
              <a href='mailto:jumapam@jumapam.gob.mx' target='_blank' rel='noopener noreferrer'><img id="footerIcons" className={classes.footerIcons} src={mail} alt='Email' /></a>
              <div style={{ marginLeft: "10px", textAlign: 'justify' ,marginTop: "3px"}}><Typography className={classes.footerText} variant="body1"><a href='mailto:jumapam@jumapam.gob.mx' style={{color:"#6A7688"}}>jumapam@jumapam.gob.mx</a></Typography></div>
            </div>
            </div>
            <p/>
            <a href="http://www.clariti.mx/" rel="noopener noreferrer" target="_blank"><img id="footerLogo" className={classes.footerLogo} alt="" src={claritiLogo}></img></a>
            <Typography className={classes.footerText} variant="body1">
              © {this.year} <a style={{ color: '#6A7688' }} href={this.props.website}><strong>{this.props.officeName}</strong></a>. {Utils.getMessages().Footer.copyright}
            </Typography>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginTop: 4 }}>
              <a className={classes.footerLink} rel="noopener noreferrer" target="_blank" href={this.props.urlTerms} style={{ marginRight: 8, color: '#6A7688' }}>{Utils.getMessages().Footer.terms}</a>
              <a className={classes.footerLink} rel="noopener noreferrer" target="_blank" href={this.props.urlPrivacy} style={{ color: '#6A7688' }}>{Utils.getMessages().Footer.privacy}</a>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginTop: 4 }}>
              <Typography className={classes.footerVersion} variant="body1">
                v{Utils.constants.version}
              </Typography>
            </Grid>


          </Grid>
          </Grid>
      </footer>
    )
  }
}

export default withStyles(styles)(Footer)
