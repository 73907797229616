import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// Components

import WarningIcon from '@material-ui/icons/Warning';

// Utils
import Utils from '../resources/Utils'
import { requestGuardarTramite, requestObtenerTipoTramite, requestUsuarioDireccion } from '../actions/actions'
import DatosContratoDireccion from './DatosContratoDireccion'
import { Button, InputAdornment, Modal, Paper, Typography } from '@material-ui/core'
import MySnackbarContentWrapper from './CustomSnackBar'
import { grey, orange } from '@material-ui/core/colors'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FullScreenModal from './FullScreenModal'

const styles = theme => ({
	receiptTable: {
		marginTop: 16
	},
	select: {
		width: 300,
		marginRight: theme.spacing(1)
	},
	paper:{
		padding:  theme.spacing(3)
	},
	modalContainer: {
		overflow: 'scroll',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	container: {
		backgroundColor: grey["50"],
		padding: theme.spacing(5),
	},
	modalTitle: {
		fontWeight: 600
	},
	actions: {
		float: 'right',
		marginTop: 32
	  },
})

class ModalTramite extends Component {
	constructor(props) {
		super(props)

		this.state = {
			openModal: false,
			loading: false,
			
			selectedtipotramite: 0,
			tiposTramite:[],
			selectedpersona: 0,
			personas: [],

			stepperStateDireccion: { activeStep: 0 },
			datosFilesDireccion:{form:{}},
			datosDireccion:{form:{}},
			datosContrato:{form:{}},
			stateDataMap: {},

			openSnack: false,
			variant: 'warning',
			errorMessage: '',
		}

		// Modal handlers
		this.changeTipoTramite = this.changeTipoTramite.bind(this)
		this.changePersona = this.changePersona.bind(this)

		this.handleSetState = this.handleSetState.bind(this)
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
		this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this)

		this.handleClose = this.handleClose.bind(this)
		this.handleRender = this.handleRender.bind(this)
	}

	componentDidMount() {
		Utils.scrollTop()
		let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
		userId = userId.data.id
		this.props.requestUsuarioDireccion({ userId:userId })
		this.props.requestObtenerTipoTramite()
		this.setState({loading:true})
	}

	componentDidUpdate(prevProps) {
		if(prevProps.init.obtenerTipoTramite !== this.props.init.obtenerTipoTramite){
			const { isValid, data } = this.props.init.obtenerTipoTramite
			if(isValid){
				this.setState({tiposTramite: data, loading:false})
			}else {
				this.setState({ loading: false} )
			}
		}
		if(prevProps.init.usuarioDireccion !== this.props.init.usuarioDireccion){
			const { isValid, data } = this.props.init.usuarioDireccion
			if(isValid){
				this.setState({personas: data, loading:false})
			}else {
				this.setState({ loading: false} )
			}
		}

		if (prevProps.init.guardarPersonaDireccionTramite !== this.props.init.guardarPersonaDireccionTramite) {
			const { isValid, message } = this.props.init.guardarPersonaDireccionTramite	
			if(isValid){
				this.handleOpenSnackbar({message: "Se ha guardado el tramite exitosamente", variant: 'success' })		
			} else {
				this.setState({ loading: false} )
				this.handleOpenSnackbar({message: message})
			}
		}
	}

	handleClose() {
		this.props.handleClose()
	}

	handleRender() {
		this.setState({
			openModal: false,
			loading: false,
			
			selectedtipotramite: 0,
			selectedpersona: 0,

			stepperStateDireccion: { activeStep: 0 },
			datosFilesDireccion:{form:{}},
			datosContrato:{form:{}},
			datosDireccion:{form:{}},
			stateDataMap: {},

			openSnack: false,
			variant: 'warning',
			errorMessage: '',
		})
	}

	handleCloseShowReceipt() {
		this.setState({openModal: false})
	}

	changeTipoTramite(event) {
		this.setState({
			selectedtipotramite: event.target.value
		})
		//this.props.requestConsultaCompleta({"account": event.target.value})
	}

	changePersona(event) {
		this.setState({
			selectedpersona: event.target.value
		})
		//this.props.requestConsultaCompleta({"account": event.target.value})
	}

	handleOpenSnackbar({message, variant}) {
		this.setState({openSnack: true, variant: variant || 'warning', errorMessage: message })
	}

	handleCloseSnackbar() {
		this.setState({openSnack: false})
	}

	handleSetState(){
		this.setState({})
	}

	renderWithReceipts(classes) {

		const { tiposTramite, selectedtipotramite, selectedpersona, personas,datosContrato, tabState, stepperStateDireccion, stateDataMap, datosFilesDireccion, datosDireccion } = this.state
		const { titulo, descripcion } = this.props

		return (
			<FullScreenModal
				open={this.props.open}
				titulo={titulo}
				handleClose={this.handleClose}
				onRendered={this.handleRender}
				buttons={
					<Button
						onClick={this.handleClose}
						>
						Cerrar
					</Button>
				}
				contenido={
			
					<div className={classes.container}>
						<Typography variant="body1" style={{marginTop: 8}}>
							{descripcion}
						</Typography>
						{
							personas.length === 0 ?
							<div>
								<br />
								<Button
									startIcon={<PersonAddIcon></PersonAddIcon>}
									variant="contained"
									color="primary"
									onClick={ () => { 
										this.props.history.push(Utils.constants.paths.profile) 
									} }
								>
									Ir a mi perfil
								</Button>
								
							</div>
							: null
						}
						<br />
						<Select
							value={selectedtipotramite}
							onChange={(event) => {this.changeTipoTramite(event)}}
							className={classes.select}
						>
						<MenuItem value={0}> Seleccione un tipo de tramite </MenuItem>
						{
							tiposTramite.map((tipoTramite, idx) => {
								return (<MenuItem key={idx} value={tipoTramite}>{tipoTramite.description}</MenuItem>)
							})
						}
						</Select>

						{
							selectedtipotramite.usaPersona === 1 ?
							<Select
								value={selectedpersona}
								onChange={(event) => {this.changePersona(event)}}
								className={classes.select}
							>
							<MenuItem value={0}> Seleccione una persona </MenuItem>
							{
								
								personas.map((persona, idx) => {
									return (<MenuItem key={idx} value={persona}>
									<span>
										{ persona.validated === 0 ? <WarningIcon style={{color:orange[500], paddingRight:5} }></WarningIcon> : null }
										{ persona.tipoPersonaId === 1 ?  ( persona.nombre  + ' ' +   persona.apPaterno  + ' ' +   persona.apMaterno )  
										: persona.nombreComercial }
									</span>
									</MenuItem>)
								})
							}
							</Select>
							: null
						}
						{
							( selectedpersona.validated === 0 ? 'Persona sin validar' : '')
						}
						<br />
						{
							selectedtipotramite.usaDireccion === 1 && selectedpersona.validated === 1 ?
								<Paper className={classes.paper}>
									<DatosContratoDireccion
										persona={selectedpersona}
										tabState={tabState}
										stepperState={stepperStateDireccion}
										datosDireccion={datosDireccion}
										datosContrato={datosContrato}
										stateDataMap= {stateDataMap}
										datosFilesDireccion= {datosFilesDireccion}
										handleSetState= {this.handleSetState}
										handleOpenSnackbar = { this.handleOpenSnackbar}
										handleSaved={(data) => {
											this.handleOpenSnackbar({message:"Dirección guardada"})
											let userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
											userId = userId.data.id
											this.props.requestGuardarTramite({
												userId: userId,
												tipoTramiteId: selectedtipotramite.id,
												personaId: selectedpersona.id,
												personaDireccionId: data.idPersonaDireccion,
											})
											this.handleClose()
										}}
									></DatosContratoDireccion>
								</Paper>
								
							: null
						}	
						<Snackbar
							anchorOrigin={{vertical: 'top', horizontal: 'center'}}
							open={this.state.openSnack}
							autoHideDuration={5000}

							onClose={this.handleCloseSnackbar}
						>
							<MySnackbarContentWrapper
								onClose={this.handleCloseSnackbar}
								variant={this.state.variant}
								message={
									<span>{this.state.errorMessage}</span>
								}
							/>
						</Snackbar>
					</div>
			} ></FullScreenModal>
		)
	}

	render() {
		
		const { classes } = this.props
		const { loading} = this.state

		if(!loading){
			
			return this.renderWithReceipts(classes)
			
		} return null
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
	return {
		requestGuardarTramite: (data) => {
			dispatch(requestGuardarTramite(data))
		},
		requestUsuarioDireccion: (data) => {
			dispatch(requestUsuarioDireccion(data))
		},
		requestObtenerTipoTramite: (data) => {
			dispatch(requestObtenerTipoTramite(data))
		},
	}
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ModalTramite)
