import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Components
import AccountTable from '../components/AccountTable'
import Title from '../components/Title'
import Breadcrumb from '../components/Breadcrumb'
import Stepper from '../components/Stepper'
import PaymentModal from '../components/PaymentModalBanWire'
import AccountModal from '../components/AccountModal'
import ResumeToPayment from '../components/PaymentModalInformationBancomer'
import Empty from '../components/Empty'

// Utils
import Utils from '../resources/Utils'
import accountsEmptyImg from '../resources/images/accounts-empty.svg'
import { requestValidateAccountNumber, requestAccountsByUser, addAccountsForPay, checkAccount, checkAllAccounts } from '../actions/actions'
import CircleLoading from '../components/CircleLoading'

const styles = theme => ({
  stepper: {
    marginTop: 32,
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      marginTop: 16,
      textAlign: 'center'
    }
  },
})

class AccountList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openPaymentModal: false,
      openAcountModal: false,
      openSnack: false,
      messageSnack: '',
      showAccounts: false,
      loading: false,
      checkboxAll: false,
      openResumeToPaymentModal: false
    }

    // Modal handlers
    this.handleConfirmPaymentModal = this.handleConfirmPaymentModal.bind(this)
    this.handleConfirmAccountModal = this.handleConfirmAccountModal.bind(this)
    this.handleClosePaymentModal = this.handleClosePaymentModal.bind(this)
    this.handleCloseAccountModal = this.handleCloseAccountModal.bind(this)
    
    this.handleCloseResumenBancomerModal = this.handleCloseResumenBancomerModal.bind(this)

    // Navigation handlers
    this.handleStepperNext = this.handleStepperNext.bind(this)
    this.handleStepperNextPayAll = this.handleStepperNextPayAll.bind(this)
    this.handlePaymentData = this.handlePaymentData.bind(this)
    this.handleNewWaterAccount = this.handleNewWaterAccount.bind(this)
    this.handleExit = this.handleExit.bind(this)

    // Others
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.getBalance = this.getBalance.bind(this)
    this.getAccounts = this.getAccounts.bind(this)

   

  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {      
      if (this.props.match.path === Utils.constants.paths.accountById) {
        this.props.history.push(Utils.constants.paths.accounts)
      }
      else {
        let user = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
        user = user.data
        this.setState({ loading: true })
        this.props.requestAccountsByUser(user.id)
      }
    }
    else {
      this.props.requestValidateAccountNumber(this.props.match.params.accountNumber)
    }
  }

  componentDidUpdate(prevProps) {
    // Mostrar cuentas (usuario no autenticado)
    if (prevProps.init.account !== this.props.init.account) {
      if (Utils.isEmpty(this.props.init.account.data)) {
        this.props.history.push(Utils.constants.paths.home)
      }
      else {
        let openSnack = false
        let errorMessage = Utils.getMessages().General.error

        if (this.getBalance() <= 0) {
          openSnack = true
          errorMessage = Utils.getMessages().General.notDebit
        }

        this.setState({
          showAccounts: true,
          loading: false,
          openSnack: openSnack,
          messageSnack: errorMessage
        })
      }
    }
    
    // Mostrar cuentas (usuario autenticado)
    if (prevProps.init.user !== this.props.init.user) {

      if(this.props.init.user.accounts){
        if (this.props.init.user.accounts.status === 'OK') {
          let showAccounts = false
          let openSnackNotDebit = false
          if (this.props.init.user.accounts.data.length > 0) {
            showAccounts = true
            if (this.getBalance() <= 0) {
              openSnackNotDebit = true
            }
          }
          this.setState({
            loading: false,
            showAccounts: showAccounts,
            openSnack: openSnackNotDebit,
            messageSnack: Utils.getMessages().General.notDebitLoggedIn
          })
        }
      }
     
    }

    // Cuenta agregada
    if (prevProps.init.addAccount !== this.props.init.addAccount) {
      let showAccounts = false
      let openSnack = false
      let message = Utils.getMessages().General.error
      if (this.props.init.addAccount.status === 'OK') {
        openSnack = true
        if (this.props.init.addAccount.data.new) {
          message = Utils.getMessages().Accounts.addOk
        }
        else {
          message = Utils.getMessages().Accounts.existOk
        }
        if (this.props.init.user.accounts.data.length > 0) {
          showAccounts = true
        }
      }
      this.setState({
        showAccounts: showAccounts,
        openSnack: openSnack,
        messageSnack: message
      })
    }

    if(prevProps.init.fromBancomer !== this.props.init.fromBancomer){
      let openSnack = false
      let message = Utils.getMessages().General.error

      if(this.props.init.fromBancomer.data.name === "Error" && this.props.init.fromBancomer.data.message === "Network Error"){
        openSnack = true
        message += " " + this.props.init.fromBancomer.data.message
      }

      this.setState({
        openSnack: openSnack,
        messageSnack: message
      })
     
    }


  }

  // Modal handlers

  handleConfirmPaymentModal() {
    this.setState({openPaymentModal: false})
    if (!Utils.isUserLoggedIn()) {
      this.props.addAccountsForPay([this.props.init.account.data])
      this.props.history.push('/cuentas/' + this.props.match.params.accountNumber + '/confirmacion')
    }
  }

  handleClosePaymentModal() {
    this.setState({openPaymentModal: false})
  }

  handleCloseResumenBancomerModal() {
    this.setState({openResumeToPaymentModal: false})
  }

  handleConfirmAccountModal() {
    this.setState({openAcountModal: false})
  }

  handleCloseAccountModal() {
    this.setState({openAcountModal: false})
  }

  // Navigation handlers

  handleExit() {
    if (Utils.isUserLoggedIn()) {
      localStorage.removeItem(Utils.constants.KEY_LS_USER)
    }
    this.props.history.push(Utils.constants.paths.home)
  }

  handleStepperNext() {
    let entityBankType = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_ENTITY_TYPE));
    if (Utils.isUserLoggedIn()) {
      let self = this
      let selectedAccounts = []
      let totalAmmount = 0
      let amountIdx = 0
      let openSnack = false
      let errorMessage = Utils.getMessages().Accounts.errorSelectAccount
      switch(entityBankType){
        case 1:      
            this.props.init.checkboxes.forEach(function(value, idx) {
              if (value) {
                amountIdx = self.props.init.user.accounts.data[idx].amount
                if (amountIdx >= 0 || self.acceptPositiveBalance()) {
                  if (amountIdx > self.props.init.config.data.maxAmountPartialPayment) {
                    openSnack = true
                    errorMessage = Utils.getMessages().Accounts.maxBalanceMessage1 + Utils.numberWithCommas(Number(self.props.init.config.data.maxAmountPartialPayment).toFixed(2)) + ' ' + Utils.getMessages().Accounts.maxBalanceMessage2
                  }
                  else {
                    totalAmmount += amountIdx
                    selectedAccounts.push(self.props.init.user.accounts.data[idx])
                  }
                }
              }
            })

            if (!openSnack && (totalAmmount > 0 || (this.acceptPositiveBalance() && selectedAccounts.length > 0))) {
              this.props.addAccountsForPay(selectedAccounts)
              this.props.history.push('/tarjetas')
            }
            else {
              if (!openSnack && this.acceptPositiveBalance()) {
                errorMessage = Utils.getMessages().Accounts.errorSelectAccountPositiveBalance
              }
              this.setState({
                openSnack: true,
                messageSnack: errorMessage
              })
            }
        break
        case 2:
        
          this.props.init.checkboxes.forEach(function(value, idx) {
            if (value) {
              amountIdx = self.props.init.user.accounts.data[idx].toPay
              if (amountIdx >= 0 || self.acceptPositiveBalance()) {
                if (amountIdx > self.props.init.config.data.maxAmountPartialPayment) {
                  openSnack = true
                  errorMessage = Utils.getMessages().Accounts.maxBalanceMessage1 + Utils.numberWithCommas(Number(self.props.init.config.data.maxAmountPartialPayment).toFixed(2)) + ' ' + Utils.getMessages().Accounts.maxBalanceMessage2
                }
                else {
                  if(amountIdx >= 0){
                    totalAmmount += amountIdx
                    selectedAccounts.push(self.props.init.user.accounts.data[idx])
                  }
                }
              }
            }
          })
  
          if (!openSnack && (totalAmmount > 0 || (this.acceptPositiveBalance() && selectedAccounts.length > 0))) {
            this.props.addAccountsForPay(selectedAccounts)
            this.setState({openResumeToPaymentModal: true })
          }
          else {
            if (!openSnack && this.acceptPositiveBalance()) {
              errorMessage = Utils.getMessages().Accounts.errorSelectAccountPositiveBalance
            }
            this.setState({
              openSnack: true,
              messageSnack: errorMessage
            })
          } 
        break
        default:
        break
      }
    }
  }
  //Codigo de boton para pagar todo
  handleStepperNextPayAll(){
    this.props.init.user.accounts.data.forEach((account,id) => {
      if(account.toPay > 0){
      if(!this.props.init.checkboxes[id]){
        this.handleCheckboxClick('checkbox-'+id)
      }
      }
    })
    this.handleStepperNext()
  }
  //

  handleNewWaterAccount() {
    this.setState({openAcountModal: true})
  }

  handlePaymentData() {
    let entityBankType = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_ENTITY_TYPE));
    switch(entityBankType){
      case 1:
        this.setState({openPaymentModal: true})
      break
      case 2:
        this.props.addAccountsForPay([this.props.init.account.data])
        this.setState({openResumeToPaymentModal: true})
      break
      default:
        break
    }

  }
  // Others

  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  handleCheckboxClick(name, event) {
    if (name === 'checkbox-all') {
      this.props.checkAllAccounts(event.target.checked)
    }
    else {
      let position = Number(name.split('-')[1])

      // Only one per pay
      //this.props.checkAllAccounts(false)
   
      const amountIdx = this.props.init.user.accounts.data[position].toPay

     
      if(amountIdx >= 0){
        this.props.checkAccount(position)
      }
    
 
    }
  }

  getBalance() {
    try {
      if (Utils.isUserLoggedIn()) {
        let total = 0
        
        this.props.init.user.accounts.data.forEach(function(account) {
          total += account.toPay
        })
  

        if(this.props.init.checkboxes){
          for (let index = 0; index < this.props.init.checkboxes.length; index++) {
            const isCheck = this.props.init.checkboxes[index];
            if(isCheck){ //Reset total if one is checked
              total = 0;
            }
          }

          for (let index = 0; index < this.props.init.checkboxes.length; index++) {
            const isCheck = this.props.init.checkboxes[index];
            const account =  this.props.init.user.accounts.data[index];
            total += isCheck ? account.amount : 0;
          }
        }

        return Number(total)
      }
      else {
        return Number(this.props.init.account.data.amount)
      }
    }
    catch (e) {
      return 0
    }
  }

  acceptPositiveBalance() {
    return this.props.init.config.data.positiveBalance
  }

  getAccounts() {
    if (Utils.isUserLoggedIn()) {
      return this.props.init.user.accounts.data
    }
    else {
      return [this.props.init.account.data]
    }
  }

  renderWithAccounts(classes) {
    return (
      <div>
        <Title 
          title={Utils.getMessages().Accounts.titleOnlinePayment}
          description={Utils.getMessages().Accounts.descriptionOnlinePayment}
        />
        <Breadcrumb
          breadcrumbs={[
              {
                name: Utils.getMessages().Accounts.breadcrumb,
                path: this.props.match.url
              }
            ]}
        />
        <AccountTable
          className={classes.accountTable}
          checkboxCheckedAll={this.props.init.checkall}
          checkboxChecked={this.props.init.checkboxes}
          onClick={this.handleCheckboxClick}
          accounts={this.getAccounts()}
        />
        {
          (Utils.isUserLoggedIn()) ?
            (this.getBalance() <= 0) ?
              (this.acceptPositiveBalance()) ?
                <Stepper
                  className={classes.stepper}
                  back={{
                    name: Utils.getMessages().Accounts.addNewAccountButton
                  }}
                  next={{
                    name: Utils.getMessages().Accounts.prepaidButton
                  }}
                  
                  onClickBack={this.handleNewWaterAccount}
                  onClickNext={this.handleStepperNext}
                />
              :
                <Stepper
                  className={classes.stepper}
                  back={{
                    name: Utils.getMessages().Accounts.exitButton
                  }}
                  next={{
                    name: Utils.getMessages().Accounts.addNewAccountButton
                  }}
                  onClickBack={this.handleExit}
                  onClickNext={this.handleNewWaterAccount}
                />
            :
              <Stepper
                className={classes.stepper}
                back={{
                  name: Utils.getMessages().Accounts.addNewAccountButton
                }}
                next={{
                  name: Utils.getMessages().Accounts.continueButton
                }}
                payAll={{ name: Utils.getMessages().Accounts.payAllButton }}

                onClickBack={this.handleNewWaterAccount}
                onClickNext={this.handleStepperNext}
                onClickNextPayAll={this.handleStepperNextPayAll}
              />
          :
            (this.getBalance() <= 0) ?
              (this.acceptPositiveBalance()) ?
                <Stepper
                  className={classes.stepper}
                  back={{
                    name: Utils.getMessages().Accounts.exitButton
                  }}
                  next={{
                    name: Utils.getMessages().Accounts.prepaidButton
                  }}
                  onClickBack={this.handleExit}
                  onClickNext={this.handlePaymentData}
                />
              :
                <Stepper
                  className={classes.stepper}
                  next={{
                    name: Utils.getMessages().Accounts.tryWithAnotherAccountButton
                  }}
                  onClickNext={this.handleExit}
                />
            :
              <Stepper
                className={classes.stepper}
                back={{
                  name: Utils.getMessages().Accounts.exitButton
                }}
                next={{
                  name: Utils.getMessages().Accounts.addPaymentInformationButton
                }}
                onClickBack={this.handleExit}
                onClickNext={this.handlePaymentData}
              />
        }
      
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.messageSnack}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <PaymentModal
          open={this.state.openPaymentModal}
          handleConfirm={this.handleConfirmPaymentModal}
          handleClose={this.handleClosePaymentModal}
        />

        <ResumeToPayment
          open={this.state.openResumeToPaymentModal}
          accountData={this.getAccounts()}
          handleClose={this.handleCloseResumenBancomerModal}
        />

        <AccountModal
          open={this.state.openAcountModal}
          handleConfirm={this.handleConfirmAccountModal}
          handleClose={this.handleCloseAccountModal}
        />

      </div>
    )
  }

  render() {
    
    const { classes } = this.props
    const { loading, showAccounts } = this.state

    if(!loading){
      if (showAccounts) {
        return this.renderWithAccounts(classes)
      }
      else {
        return (
          <div>
            {
              (Utils.isUserLoggedIn()) ?
              <Empty
                title={Utils.getMessages().Accounts.emptyTitle}
                description={Utils.getMessages().Accounts.emptyDescription}
                emptyImg={accountsEmptyImg}
                buttonTitle={Utils.getMessages().Accounts.addAccountButton}
                callToAction={() => { this.handleNewWaterAccount() }}
              />
              :
              ''
            }
            <AccountModal
              open={this.state.openAcountModal}
              handleConfirm={this.handleConfirmAccountModal}
              handleClose={this.handleCloseAccountModal}
            />
          </div>
        )
      }
    } else {
      return <CircleLoading mensajeCargando={Utils.getMessages().Accounts.loading} />
    }
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestValidateAccountNumber: (accountNumber) => {
      dispatch(requestValidateAccountNumber(accountNumber))
    },
    requestAccountsByUser: (userId) => {
      dispatch(requestAccountsByUser(userId))
    },
    addAccountsForPay: (data) => {
      dispatch(addAccountsForPay(data))
    },
    checkAccount: (idx) => {
      dispatch(checkAccount(idx))
    },
    checkAllAccounts: (status) => {
      dispatch(checkAllAccounts(status))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AccountList)
