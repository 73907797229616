import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import Utils from '../resources/Utils'
import Emoji1 from '../resources/images/1.png'
import Emoji2 from '../resources/images/2.png'
import Emoji3 from '../resources/images/3.png'
import Emoji4 from '../resources/images/4.png'
import Emoji5 from '../resources/images/5.png'
import Emoji6 from '../resources/images/6.png'
import Emoji7 from '../resources/images/7.png'
import Emoji8 from '../resources/images/8.png'
import Emoji9 from '../resources/images/9.png'
import Emoji10 from '../resources/images/10.png'
import Emoji11 from '../resources/images/11.png'
import Emoji12 from '../resources/images/12.png'

// Actions
import { requestEditUserProfile } from '../actions/actions'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      background: 'white',
      width: '90%',
      height: '100%',
      paddingTop: '20%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  paper: {
    marginTop: 8,
    marginBottom: 16,
    padding: '8px 16px'
  },
  emojiAlias: {
    width: 14,
    marginRight: 4
  },
  modalTitle: {
    fontWeight: 600
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  }
})

class ShowInfoModal extends Component {
  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.getEmojiAlias = this.getEmojiAlias.bind(this)
    this.getPensionary = this.getPensionary.bind(this)
  }

  getEmojiAlias(emoji) {
    emoji = Number(emoji)
    if (emoji === 0) {
      return Emoji1
    }
    else if (emoji === 1) {
      return Emoji2
    }
    else if (emoji === 2) {
      return Emoji3
    }
    else if (emoji === 3) {
      return Emoji4
    }
    else if (emoji === 4) {
      return Emoji5
    }
    else if (emoji === 5) {
      return Emoji6
    }
    else if (emoji === 6) {
      return Emoji7
    }
    else if (emoji === 7) {
      return Emoji8
    }
    else if (emoji === 8) {
      return Emoji9
    }
    else if (emoji === 9) {
      return Emoji10
    }
    else if (emoji === 10) {
      return Emoji11
    }
    else {
      return Emoji12
    }
  }

  getPensionary(data) {
    if (Number(data) === 0) {
      return '❌'
    }
    return '✅'
  }

  handleClose() {
    this.props.handleClose()
  }

  render() {
    const { classes } = this.props
    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
            {Utils.getMessages().ShowInfoModal.title}
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
            <strong>{Utils.getMessages().ShowInfoModal.subtitleAccount}</strong>
          </Typography>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1" className={classes.title}>{this.props.account.account}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.account}</strong></Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1" className={classes.title}>{this.props.account.contractId}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.contractId}</strong></Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="body1" className={classes.title}>{this.props.account.contractType}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.contractType}</strong></Typography>
              </Grid>
            </Grid>
          </Paper>
          <Typography variant="body1" style={{marginTop: 8}}>
            <strong>{Utils.getMessages().ShowInfoModal.subtitleGeneral}</strong>
          </Typography>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" className={classes.title}>{this.props.account.name}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.name}</strong></Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4} style={{marginTop: 8}}>
                <Typography variant="body1" className={classes.title}>{this.props.account.address}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.address}</strong></Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4} style={{marginTop: 8}}>
                <Typography variant="body1" className={classes.title}>{this.getPensionary(this.props.account.pensionary)}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.pensionary}</strong></Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4} style={{marginTop: 8}}>
                <Typography variant="body1" className={classes.title}><img className={classes.emojiAlias} alt="" src={this.getEmojiAlias(this.props.account.emojiAlias)} /> {this.props.account.alias}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.alias}</strong></Typography>
              </Grid>
            </Grid>
          </Paper>
          <Typography variant="body1" style={{marginTop: 8}}>
            <strong>{Utils.getMessages().ShowInfoModal.subtitlePayment}</strong>
          </Typography>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" className={classes.title}>{this.props.account.expirationAt}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.expirationAt}</strong></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" className={classes.title}>${Utils.numberWithCommas(Number(this.props.account.amount).toFixed(2))}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.amount}</strong></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6} style={{marginTop: 8}}>
                <Typography variant="body1" className={classes.title}>{this.props.account.lastPaymentDate}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.lastPaymentDate}</strong></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6} style={{marginTop: 8}}>
                <Typography variant="body1" className={classes.title}>${Utils.numberWithCommas(Number(this.props.account.lastPaymentAmount).toFixed(2))}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.lastPaymentAmount}</strong></Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: 8}}>
                <Typography variant="body1" className={classes.title}>${Utils.numberWithCommas(Number(this.props.account.positiveBalance).toFixed(2))}</Typography>
                <Typography variant="body1"><strong>{Utils.getMessages().ShowInfoModal.positiveBalance}</strong></Typography>
              </Grid>
            </Grid>
          </Paper>
          <div className={classes.actions}>
            <Button
                onClick={this.handleClose}
              >
              {Utils.getMessages().ShowInfoModal.closeButton}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestEditUserProfile: (data) => {
      dispatch(requestEditUserProfile(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ShowInfoModal)
