import React, { Component } from 'react'
import compose from 'recompose/compose'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
    circularStyle : {
        height: "30em", 
        width: "30em",
        [theme.breakpoints.down('sm')]: {
          height: "20em", 
          width: "20em",
        },
        [theme.breakpoints.down('xs')]: {
          height: "10em", 
          width: "10em",
        }
    }
})

class CircleLoading extends Component {
  render() {
    const { classes, mensajeCargando } = this.props
    return (
        <div style={{ width:"100%", textAlign:"center" }}>
            <Typography variant="body1">{ mensajeCargando}</Typography>
            <br />   
            <CircularProgress className={classes.circularStyle} /> 
        </div>
    )
  }
}

export default compose(
  withTheme,
  withStyles(styles)
)(CircleLoading)
