import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'

// Utils
import Utils from '../resources/Utils'
import GeneradorForms from './GeneradorForms'

import RadioOptions from './RadioOptions'
import StepperCustom from './StepperCustom'

import { requestActualizarImagenUsuario, requestGuardarImagenUsuario, requestGuardarPersona, requestImagenesUsuario } from '../actions/actions'
import { Typography } from '@material-ui/core'

const styles = theme => ({
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: "OpenSans-Regular"
    },
})

class DatosContratoUsuario extends Component {
	constructor(props) {
		super(props)

		this.state = {
            estadoArchivos: [],
            tabState: this.props.tabState,
            stepperState: this.props.stepperState,
			generadorFormsUsuarioState: this.props.datosUsuario,
            generadorFormsEmpresaState: this.props.datosEmpresa,
            formFilesUsuarioState: this.props.datosFilesUsuario,
            stateTipoUsuario: this.props.stateTipoUsuario,
            giros: [],
            messageLoading: '',
			loading: false,
            personaId: 0,
            datosGuardados: {}
            
		}

        this.handleSetState = this.handleSetState.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.procesoSubirImgs = this.procesoSubirImgs.bind(this)
	}

	getLabelUsuario(){
        return {
            id: "Identificador",
            nombre: "Nombre",
			apPaterno: "Apellido paterno",
			apMaterno: "Apellido materno",
			fechaNacimiento: "Fecha de nacimiento",
			RFC: "RFC",
			sexo: "Sexo"
        }
    }

	getOriginParamsUsuario(){
        return [
            {
                type: "text",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "nombre",
                required: true,
                upper: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "apPaterno",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "apMaterno",
                upper: true,
                required: false,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
			,{
                type: "date",
                actions: { 
                    create: true, read: true
                },
                name: "fechaNacimiento",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "RFC",
                required: false,
                upper: true,
				grid:{
					xs:12,
                    sm:12,
					md:9,
					lg:9,
					xl:9
                }
			},{
                type: "select",
                actions: { 
                    create: true, read: true
                },
                name: "sexo",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:3,
					lg:3,
					xl:3
                }
			}
        ]
    }

    getLabelEmpresa(){
        return {
            id: "Identificador",
            razonSocial: "Rarzón Social",
			nombreComercial: "Nombre comercial",
			rfcEmpresa: "RFC",
			correoElecEmpresa: "Correo electrónico",
        }
    }

	getOriginParamsEmpresa(){
        return [
            {
                type: "text",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "razonSocial",
                responsive: "lg",
                required: true,
                upper: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "nombreComercial",
                responsive: "lg",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			},{
                type: "text",
                actions: { 
                    create: true, read: true
                },
                name: "rfcEmpresa",
                responsive: "lg",
                upper: true,
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
			,{
                type: "email",
                actions: { 
                    create: true, read: true
                },
                name: "correoElecEmpresa",
                responsive: "lg",
                required: true,
				grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
			}
        ]
    }

    getLabelFilesUsuario(){
        return {
            id: "Identificador",
            evidenciaIdentificacion: "Evidencia Identificación (INE)",
            evidenciaSelfie: "Evidencia Selfie",
        }
    }

	getOriginParamsFilesUsuario(){
        return [
            {
                type: "text",
                actions: { 
                    create: false, read: false
                },
                name: "id",
                grid:{
                    md:12
                }
            },{
                type: "fileupload",
                actions: { 
                    create: true, read: true
                },
                name: "evidenciaIdentificacion",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },
            {
                type: "fileupload",
                actions: { 
                    create: true, read: true
                },
                name: "evidenciaSelfie",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },
            /*{
                type: "fileupload",
                actions: { 
                    create: true, read: true
                },
                name: "acreditacionEmpresa",
                required: true,
                filter: true,
                grid:{
					xs:12,
                    sm:12,
					md:4,
					lg:3,
					xl:3
                }
            },*/
        ]
    }

    componentDidMount(){
        Utils.scrollTop()
        if (Utils.isUserLoggedIn()) {
           
		}
    }

	componentDidUpdate(prevProps) {
		if (Utils.isUserLoggedIn()) {
            if(prevProps.init.actualizarImagenUsuario !== this.props.init.actualizarImagenUsuario){
                const { isValid, message, data } = this.props.init.actualizarImagenUsuario
                if(isValid){
                    this.procesoSubirImgs(data)	
                }else {
					this.setState({ loading: false} )
					this.props.handleOpenSnackbar({message: message})
				}
            }

            if (prevProps.init.guardarImagenUsuario !== this.props.init.guardarImagenUsuario) {
                const { isValid, message, data } = this.props.init.guardarImagenUsuario
				if(isValid){
					this.procesoSubirImgs(data)	
				} else {
					this.setState({ loading: false} )
					this.props.handleOpenSnackbar({message: message})
				}
			}

			if (prevProps.init.guardarPersona !== this.props.init.guardarPersona) {
				const { isValid, message, data } = this.props.init.guardarPersona	
				if(isValid){
                    this.guardarImagenes(data)
					this.setState({ loading: true} )
					
				} else {
					this.setState({ loading: false} )
					this.props.handleOpenSnackbar({message: message})
				}
			}

			if (prevProps.init.usuarioDireccion !== this.props.init.usuarioDireccion) {
				const { data, isValid, message } = this.props.init.usuarioDireccion
				if(isValid){
					const { monitorRequestUsers, usersOomsDirection, usersOomsUser } = data
					if(monitorRequestUsers && usersOomsDirection && usersOomsUser){
						if(!monitorRequestUsers.validated){
							this.props.handleClose()
						}
					} 
				} 
			}
		}

        console.log(this.state.generadorFormsUsuarioState)
	}

    procesoSubirImgs(data){
        let { estadoArchivos, personaId } = this.state
        let archivosNoSincronizados = estadoArchivos.filter( x => x.sincronizado === false )
        const indexArchivoSincronizado = estadoArchivos.findIndex( x => x.tipoImagenUsuarioId === parseInt(data.tipoImagenPersonaId))
        const { datosGuardados } = this.state
        if(indexArchivoSincronizado > -1){
            estadoArchivos[indexArchivoSincronizado].sincronizado = true
            this.setState({ estadoArchivos: estadoArchivos })
            archivosNoSincronizados = estadoArchivos.filter( x => x.sincronizado === false )
            if(archivosNoSincronizados.length > 0) {
                this.setState({ loading: true, messageLoading: this.getMessageImagenLoading(parseInt(archivosNoSincronizados[0].tipoImagenUsuarioId)) })
                this.guardarImagen({ 
                    imagen: archivosNoSincronizados[0].file.file, 
                    tipoImagenUsuarioId: archivosNoSincronizados[0].tipoImagenUsuarioId,
                    personaId: personaId,
                    id: archivosNoSincronizados[0].file.id,
                })
            } else {
                this.props.handleOpenSnackbar({message: "Datos e Imagenes guardados correctamente..", variant: 'success' })
                this.props.handleClose()
                this.props.handleSaved(datosGuardados)
            }
        } else {
            this.setState({ loading: false} )
            this.props.handleOpenSnackbar({message: "Datos e Imagenes guardados correctamente..", variant: 'success' })
            this.props.handleClose()
            this.props.handleSaved(datosGuardados)
        }
    }

    validarDato(dato, origin, label){

        const element = document.getElementById(origin.name)
        if(origin.required){
            switch (origin.type) {
                case "text":
                    if(dato){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }
                case "date":
                    if(dato){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }
                case "select":
                    if(dato){
                        if(dato.value){
                            return true
                        } else {
                            this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                            element.focus()
                            return false
                        }
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }
                   
                case "email":
                    if(Utils.validateEmail(dato)){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message:'Correo con formato incorrecto.'})
                        element.focus()
                        return false
                    }

                case "fileupload":
                    if(dato){
                        return true
                    } else {
                        this.props.handleOpenSnackbar({message: label + ' es requerido.'})
                        element.focus()
                        return false
                    }
                    
                default:
                    return false
            }
        } else {
            return true
        }
    }   
    
     getMessageImagenLoading(id){
		switch (id) {
			case 1:
				return "SUBIENDO IDENTIFICACIÓN"		
			case 2:
				return "SUBIENDO SELFIE"			
			case 3:
				return "SUBIENDO ACREDITACION EMPRESA'"
            case 4:
				return "SUBIENDO COMPROBANTE DE DOMICILIO"		
			default:
				break;
		}
	}
	guardarDatos(){

		const { 
			generadorFormsUsuarioState,
			generadorFormsEmpresaState,
            stateTipoUsuario,
            
		} = this.state

		const { value } = stateTipoUsuario						

		let dataUsuario = {}
		const userId = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))

		if(parseInt(value) === 1){//USUARIO
			const { apMaterno, apPaterno, fechaNacimiento, nombre, RFC, selectedsexo, id } = generadorFormsUsuarioState.form

			var fcNacimiento = null
			var dtN = new Date(fechaNacimiento),
				current_date = dtN.getDate(),
				current_month = dtN.getMonth() + 1,
				current_year = dtN.getFullYear()

			fcNacimiento = current_year + '-' +  
			( (current_month < 10) ?  ( '0' + current_month ) : current_month ) + '-' + 
			( (current_date < 10) ?  ( '0' + current_date ) : current_date )
  
			dataUsuario = {
                personaId: id,						
				userId: userId.data.id,
				tipoPersonaId: 1,
				apMaterno: apMaterno,
				apPaterno: apPaterno,
				fechaNacimiento: fcNacimiento,
				nombre: nombre,
				RFC: RFC,
				sexoId: selectedsexo ? selectedsexo.value : '',
                estaActivo: true
			}

		} else if (parseInt(value) ===  2){//EMPRESA
			const { correoElecEmpresa, nombreComercial, razonSocial, rfcEmpresa, id } = generadorFormsEmpresaState.form
			dataUsuario = {		
                personaId: id,							
				userId:  userId.data.id,
				tipoPersonaId: 2,
				correoElecEmpresa: correoElecEmpresa,
				nombreComercial: nombreComercial,
				razonSocial: razonSocial,
				rfcEmpresa: rfcEmpresa,
                estaActivo: true
			}
		}
        this.setState({ datosGuardados: dataUsuario})
		this.props.requestGuardarPersona({
			personas: dataUsuario,
		})
	}

	guardarImagenes(personaId){
		const { 
			formFilesUsuarioState,
            generadorFormsUsuarioState,
            datosGuardados
		} = this.state

        datosGuardados.personaId = personaId
		const { evidenciaIdentificacion, evidenciaSelfie } = formFilesUsuarioState.form
		const files = [ 
            { file: evidenciaIdentificacion, sincronizado: false,  tipoImagenUsuarioId: 1}, 
            { file: evidenciaSelfie, sincronizado: false, tipoImagenUsuarioId: 2 } 
        ]

        if(generadorFormsUsuarioState.form){
            if(Array.isArray(generadorFormsUsuarioState.form.imagenes)){
                for (let index = 0; index < files.length; index++) {
                    const element = files[index];
                    if(!element.file.file){        
                        files[index].sincronizado = true          
                    }
                }
            }
        }
        const filesNoSincronizados =  files.filter( x => x.sincronizado === false)

        if(filesNoSincronizados.length > 0){
            this.setState({
                estadoArchivos: files, 
                loading: true,
                messageLoading: this.getMessageImagenLoading(filesNoSincronizados[0].tipoImagenUsuarioId),
                personaId: personaId,
            })
            this.guardarImagen({ 
                imagen: filesNoSincronizados[0].file.file, 
                tipoImagenUsuarioId: filesNoSincronizados[0].tipoImagenUsuarioId,
                personaId: personaId,
                id: filesNoSincronizados[0].file.id,
            })
           
        }  else {
            this.setState({ loading: false} )
            this.props.handleOpenSnackbar({message: "Datos e Imagenes guardados correctamente..", variant: 'success' })
            this.props.handleClose()
            this.props.handleSaved(datosGuardados)
        }
	}

	guardarImagen({ imagen, tipoImagenUsuarioId, personaId, id }){
		let formData = new FormData();
		
        const { generadorFormsUsuarioState,  } = this.state

        if(generadorFormsUsuarioState.form){
            if(Array.isArray(generadorFormsUsuarioState.form.imagenes)){
                const imgData = generadorFormsUsuarioState.form.imagenes.find(x => x.tipoImagen === tipoImagenUsuarioId)
                if(imgData){
                    id = imgData.id
                }
            }
        }

        if(generadorFormsUsuarioState.form){
            console.log("ID IMAGEN PERSONA",id)
            if(id){
                formData.append('imagenesPersonaId', id)
                formData.append('tipoImagenPersonaId', tipoImagenUsuarioId)
                formData.append('image', imagen)
                this.props.requestActualizarImagenUsuario(formData)
            } else {
                formData.append('image', imagen)
                formData.append('personaId', personaId)
                formData.append('tipoImagenPersonaId', tipoImagenUsuarioId)
                this.props.requestGuardarImagenUsuario(formData)
            }
        }	
	}

    handleNext(data){

        const { generadorFormsUsuarioState, generadorFormsEmpresaState,formFilesUsuarioState, stepperState, stateTipoUsuario } = this.state
        const { activeStep } = stepperState

        let formValid = true
        let origins = []
        switch (activeStep) {
            case 0:
                console.log("Tipo usuario",stateTipoUsuario.value)
                if(parseInt(stateTipoUsuario.value) === 1){
                    origins = this.getOriginParamsUsuario();
                    for (let index = 0; index < origins.length; index++) {
                        const v = origins[index]
                        if(!this.validarDato(generadorFormsUsuarioState.form[ (v.type === "select" ? 'selected': '') + v.name ], v, this.getLabelUsuario()[v.name]  )){
                            formValid = false
                            index += origins.length
                        }   
                    }
                } else {
                    origins = this.getOriginParamsEmpresa();
                    for (let index = 0; index < origins.length; index++) {
                        const v = origins[index]
                        console.log(generadorFormsEmpresaState)
                        if(!this.validarDato(generadorFormsEmpresaState.form[ (v.type === "select" ? 'selected': '') + v.name ], v, this.getLabelEmpresa()[v.name]  )){
                            formValid = false
                            index += origins.length
                        }   
                    }
                }
                
                return formValid
            case 1:

                origins = this.getOriginParamsFilesUsuario();
                for (let index = 0; index < origins.length; index++) {
                    const v = origins[index]
                    if(!this.validarDato(formFilesUsuarioState.form[ (v.type === "select" ? 'selected': '') + v.name ], v, this.getLabelFilesUsuario()[v.name]  )){
                        formValid = false
                        index += origins.length
                    }   
                }

                this.guardarDatos()
                
                return formValid
            default:
                break;
        }

    }



    handleSetState(){
        this.setState({})
        this.props.handleSetState()
    }

	render() {

        const {classes} = this.props
		const { generadorFormsUsuarioState, stateTipoUsuario, formFilesUsuarioState, stepperState, generadorFormsEmpresaState } = this.state



        console.log("PERSONA", generadorFormsUsuarioState)
		return (
			<div>
                <StepperCustom
                    pasos={['Datos de la persona', 'Evidencia de la persona']}
                    contenidoFinal="INFOMRACIÓN DE USUARIO CAPTURADA"
                    handleNext= { this.handleNext }
                    stepperState={ stepperState } 
                    reiniciarText={"Revisar infomración capturada"}
                    finalizarText={"Guardar datos"}
                    getContenido={
                        (step) => {
                            switch (step) {
                                case 0:
                                return <div>

                                    <Typography paragraph={true} variant={"subtitle1"} className={classes.instructions}>
                                        <strong>Instrucciones de llenado:</strong> En esta sección deberás de proporcionar los datos de la persona o empresa que será el titular del contrato del servicio del agua potable, alcantarillado y saneamiento.
                                    </Typography>
                                    <hr />
                                    <RadioOptions
                                        handleSetStateParent={this.handleSetState}
                                        topic={"Seleccione el Tipo de persona"}
                                        stateRadio={stateTipoUsuario}
                                        options={
                                            [
                                                {
                                                    label: "Usuario",
                                                    value: 1
                                                },
                                                {
                                                    label: "Empresa",
                                                    value: 2
                                                }
                                            ]
                                        }
                                    ></RadioOptions>

                                    {
                                        parseInt(stateTipoUsuario.value) === 1 ?
                                            <GeneradorForms
                                                key={'generadorFormsUsuarioState'}
                                                generadorFormsState={generadorFormsUsuarioState}
                                                forms={this.getOriginParamsUsuario()}
                                                labels={this.getLabelUsuario()}
                                                multiselectData={
                                                    /* aqui va el data de los multi select */
                                                    {
                                                        sexo: Utils.sexos.map( (v) => { return {label: v.descripcion, value: v.id, color:"black"} }),
                                                    }
                                                }
                                            ></GeneradorForms>
                                        :
                                        <GeneradorForms
                                            key={'generadorFormsEmpresaState'}
                                            generadorFormsState={generadorFormsEmpresaState}
                                            forms={this.getOriginParamsEmpresa()}
                                            labels={this.getLabelEmpresa()}
                                            multiselectData={
                                                /* aqui va el data de los multi select */
                                                {
                                                    
                                                }
                                            }
                                        ></GeneradorForms>
                                    }
                                </div>
                                case 1:
                                    return <div>
                                        <Typography paragraph={true} variant={"subtitle1"} className={classes.instructions}>
                                            <strong>Instrucciones de llenado:</strong> Deberás se subir archivos (imágenes o PDF) que identifiquen a la persona o empresa, como INE / IFE, escrituras, poder del representante legal.
                                        </Typography>
                                        <hr />
                                        <GeneradorForms
                                            key={'formFilesUsuarioState'}
                                            generadorFormsState={formFilesUsuarioState}
                                            forms={this.getOriginParamsFilesUsuario()}
                                            labels={this.getLabelFilesUsuario()}
                                            multiselectData={
                                                /* aqui va el data de los multi select */
                                                {
                                                    
                                                }
                                            }
                                        >

                                        </GeneradorForms>
                                        </div>
                                default:
                                    return 'Unknown step';
                            }
                        }
                    }
                />
			</div>
		)
	}
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
	return {
        requestGuardarPersona: (data) => {
			dispatch(requestGuardarPersona(data))
		},
		requestImagenesUsuario: (data) => {
			dispatch(requestImagenesUsuario(data))
		},
		requestActualizarImagenUsuario: (data) => {
			dispatch(requestActualizarImagenUsuario(data))
		},
        requestGuardarImagenUsuario: (data) => {
			dispatch(requestGuardarImagenUsuario(data))
		},
	}
}

export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(DatosContratoUsuario)
