import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Utils from '../resources/Utils'

// Actions
import { sendEmail } from '../actions/actions'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  container: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      background: 'white',
      width: '90%',
      height: '100%',
      paddingTop: '20%',
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  modalTitle: {
    fontWeight: 600
  },
  largeTextField: {
    width: '100%',
    marginTop: 12
  },
  actions: {
    float: 'right',
    marginTop: 32
  },
  primaryButton: {
    marginLeft: 16,
    fontWeight: 600,
    fontSize: 14
  }
})

class EmailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      openSnack: false,
      errorMessage: ''
    }

    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleChangeEmail(event) {
    if (event.target.value.length > 64)
      return
    this.setState({email: event.target.value})
  }

  handleConfirm() {
    if (this.state.email.trim() === "") {
      this.setState({
          openSnack: true,
          errorMessage: Utils.getMessages().EmailModal.required
        })
    }
    else {
      if (Utils.validateEmail(this.state.email)) {
        this.props.sendEmail({
          email: this.state.email,
          token: this.props.init.payment.data.token
        })
        this.clearData()
        this.props.handleConfirm()
      }
      else {
        this.setState({
          openSnack: true,
          errorMessage: Utils.getMessages().EmailModal.validation
        })
      }
    }
  }

  handleCloseSnackbar() {
    this.setState({errorMessage: '', openSnack: false})
  }

  handleClose() {
    this.clearData()
    this.props.handleClose()
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleConfirm()
    }
  }

  clearData() {
    this.setState({
      email: '',
      openSnack: false,
      errorMessage: ''
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Modal
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onBackdropClick={this.handleClose}
      >
        <div style={getModalStyle()} className={classes.container}>
          <Typography variant="h4" className={classes.modalTitle}>
            {Utils.getMessages().EmailModal.title}
          </Typography>
          <Typography variant="body1" style={{marginTop: 8}}>
            {Utils.getMessages().EmailModal.description}
          </Typography>
          <form noValidate autoComplete="off" onKeyPress={this.handleKeyPress}>
            <TextField
              className={classes.largeTextField}
              label={Utils.getMessages().EmailModal.placeholder}
              type="email"
              value={this.state.email}
              onChange={this.handleChangeEmail}
              autoFocus
            />
            <div className={classes.actions}>
              <Button
                  onClick={this.handleClose}
                >
                {Utils.getMessages().EmailModal.cancelButton}
              </Button>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={this.handleConfirm}
              >
                {Utils.getMessages().EmailModal.sendButton}
              </Button>
            </div>
          </form>
          <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={this.state.openSnack}
            onClose={this.handleCloseSnackbar}
            message={
              <span>{this.state.errorMessage}</span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    sendEmail: (data) => {
      dispatch(sendEmail(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(EmailModal)
