import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Components
import ReceiptSuiteListTable from '../components/ReceiptSuiteListTable'
import Title from '../components/Title'
import Empty from '../components/Empty'
import ShowReceiptSuite from '../components/ShowReceiptSuite'

// Utils
import Utils from '../resources/Utils'
import emptyImg from '../resources/images/empty.svg'
import { requestAccountsByUser, requestConsultaCompleta, requestReporte } from '../actions/actions'
import CircleLoading from '../components/CircleLoading'

const styles = theme => ({
  receiptTable: {
    marginTop: 16
  },
  select: {
    width: 300
  }
})

class ReceiptSuite extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: 'EMPTY',
      openModal: false,
      loading: false,
      receipt: {
        reciboID: '',
        consumo: 0.00,
        periodoConsumo: "",
        fechaVencimientoFormato: "",
        detalleConceptos: []
      },
      openSnack: false,
      accountSelected: "",
      showReceipts: false,
      receipts: [],
    }

    // Modal handlers
    this.changeAccountNumber = this.changeAccountNumber.bind(this)

    // Others
    this.downloadReceipt = this.downloadReceipt.bind(this)
    this.showReceipt = this.showReceipt.bind(this)
    this.handleCloseShowReceipt = this.handleCloseShowReceipt.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      let user = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
      user = user.data
      this.setState({loading:true})
      this.props.requestAccountsByUser(user.id)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.user.accounts !== this.props.init.user.accounts) {

      if(this.props.init.user.accounts){
        if (!Utils.isEmpty(this.props.init.user.accounts.data)) {
          if (this.props.init.user.accounts.data.length > 0) {
            let account = this.props.match.params.account
            if(!account){
              account = this.props.init.user.accounts.data[0].account
            }
            this.setState({
              accountSelected: account
            })
            this.props.requestConsultaCompleta({ "account": account})
          } else {
            this.setState({
                status: 'EMPTY',
                showReceipts: true,
                receipts: [],
                loading: false
            })
          }
        }
      }

      
    }
    else if (prevProps.init.consultaCompleta !== this.props.init.consultaCompleta) {
      const { data, status } = this.props.init.consultaCompleta
      if(status === Utils.constants.status.ok){
          if(Array.isArray(data.recibos)){
            const recibos = data.recibos
            if (recibos.length > 0) {
                this.setState({
                    status: 'LOAD',
                    showReceipts: true,
                    receipts: recibos,
                    loading: false
                })
            }
            else {
                this.setState({
                    status: 'EMPTY',
                    showReceipts: true,
                    receipts: [],
                    loading: false
                })
            }    
        }else {
          this.setState({
            status: 'EMPTY',
            showReceipts: true,
            receipts: [],
            loading: false
        })
        }
      }
    }
    if(prevProps.init.reporte !== this.props.init.reporte){ 
      const result = this.props.init.reporte
      if(result){
        if(!result.status){
           /* var html = '<html>' +
            '<style>html,body {padding:0;margin:0;} iframe {width:100%;height:100%;border:0}</style>' +
            '<body>' +                                
            '<iframe type="application/pdf" src="' +  result.toDataURI() + '"></iframe>' +
            '</body></html>';
            var a = window.open("about:blank", "Report")
            a.document.write(html)
            a.document.close()*/
            result.download(this.state.receipt.cuenta + '_' + this.state.receipt.periodoConsumo  + ' ' + '.pdf')


            //result.render('reportPlaceholder', request);
        } else{
            this.setState({
                openSnack: true,
                errorMessage: 'No se pudo conectar con el servicio de reportes'
            })
        }
      }
    }
  }

  downloadReceipt(receipt) {
    receipt.UrlImagen = this.props.init.config.data.mainLogo
    const shortId = this.props.init.config.data.idReporteRecibo
    this.setState({ receipt: receipt })
    this.props.requestReporte({ shortid: shortId, data: receipt })
  }

  showReceipt(receipt) {
    this.setState({openModal: true, receipt: receipt})
  }

  handleCloseShowReceipt() {
    this.setState({openModal: false})
  }

  changeAccountNumber(event) {
    this.setState({
      receipts: [],
      status: 'LOADING',
      accountSelected: event.target.value
    })
    this.props.requestConsultaCompleta({"account": event.target.value})
  }
  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  renderWithReceipts(classes) {

    let { init } = this.props
    let user = { accounts: { data: [] } };
    if(init.user){
        if(init.user.accounts){
          user = init.user ?  init.user : { accounts: { data: [] } }
        }
    }

    return (
      <div>
        <Title 
          title={Utils.getMessages().Receipts.title}
          description={Utils.getMessages().Receipts.description}
        />
        <br />
        <Select
          value={this.state.accountSelected}
          onChange={(event) => {this.changeAccountNumber(event)}}
          className={classes.select}
        >
          {
            user.accounts.data.map((account, idx) => {
              return (<MenuItem key={idx} value={account.account}><strong>{account.account}</strong> - ({account.name})</MenuItem>)
            })
          }
        </Select>

        {
          (this.state.status === 'LOADING') ? 
            <div>
              <Empty
                title={Utils.getMessages().Receipts.loadingTitle}
                description={Utils.getMessages().Receipts.loadingDescription}
              />
            </div>
          :
            (this.state.status === 'EMPTY') ?
              <div>
                <Empty
                  title={Utils.getMessages().Receipts.titleEmpty}
                  description={Utils.getMessages().Receipts.descriptionEmpty}
                />
              </div>
            :
            <div>
              <ReceiptSuiteListTable 
                className={classes.receiptTable}
                receipts={this.state.receipts}
                show={(receipt) => { this.showReceipt(receipt) }}
                download={(receipt) => { this.downloadReceipt(receipt) }}
              />
              <ShowReceiptSuite
                open={this.state.openModal}
                receipt={this.state.receipt}
                url={this.state.urlrecibo}
                handleClose={this.handleCloseShowReceipt}
              />
            </div>
        }

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.message}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    )
  }

  render() {
    
    const { classes } = this.props
    const { loading, showReceipts} = this.state

    if(!loading){
      if (showReceipts) {
        return this.renderWithReceipts(classes)
      }
      else {
        return (
          <div>
            <Empty
              title={Utils.getMessages().Receipts.titleEmpty}
              description={Utils.getMessages().Receipts.descriptionEmpty}
              emptyImg={emptyImg}
            />
          </div>
        )
      }
    }else {
      return <CircleLoading mensajeCargando={Utils.getMessages().Receipts.loadingDescription} />
    }
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestConsultaCompleta: (data) => {
      dispatch(requestConsultaCompleta(data))
    },
    requestAccountsByUser: (userId) => {
      dispatch(requestAccountsByUser(userId))
    },
    requestReporte: (data) => {
      dispatch(requestReporte(data))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ReceiptSuite)
