import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Components
import PaymentListTable from '../components/PaymentListTable'
import Title from '../components/Title'
import Empty from '../components/Empty'
import CFDIModal from '../components/CFDIModal'

// Utils
import Utils from '../resources/Utils'
import emptyImg from '../resources/images/empty.svg'
import { requestAccountsByUser, requestGetPaymentsByAccount } from '../actions/actions'

const styles = theme => ({
  paymentTable: {
    marginTop: 16
  },
  select: {
    width: 300
  }
})

class Payment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: 'EMPTY',
      openModal: false,
      openSnack: false,
      accountSelected: "",
      showPayments: false,
      payments: []
    }

    // Modal handlers
    this.changeAccountNumber = this.changeAccountNumber.bind(this)
    this.cfdi = this.cfdi.bind(this)
    this.handlePaymentModal = this.handlePaymentModal.bind(this)
    this.handleConfirmModal = this.handleConfirmModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)

    // Others
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
  }

  componentDidMount() {
    Utils.scrollTop()
  }

  componentWillMount() {
    if (Utils.isUserLoggedIn()) {
      let user = JSON.parse(localStorage.getItem(Utils.constants.KEY_LS_USER))
      user = user.data
      this.props.requestAccountsByUser(user.id)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.user.accounts !== this.props.init.user.accounts) {
      if (!Utils.isEmpty(this.props.init.user.accounts.data)) {
        if (this.props.init.user.accounts.data.length > 0) {
          let accountNumber = this.props.init.user.accounts.data[0].account
          this.setState({
            accountSelected: accountNumber
          })
          this.props.requestGetPaymentsByAccount(accountNumber)
        }
      }
    }
    else if (prevProps.init.payments !== this.props.init.payments) {
      let payments = this.props.init.payments.data
      if (!Utils.isEmpty(payments)) {
        if (payments.length > 0) {
          this.setState({
            status: 'LOAD',
            showPayments: true,
            payments: payments,
          })
        }
        else {
          this.setState({
            status: 'EMPTY',
            showPayments: true,
            payments: [],
          })
        }
      }
    }
  }

  cfdi(payment) {
    this.setState({
      openModal: true
    })
  }

  changeAccountNumber(event) {
    this.setState({
      payments: [],
      status: 'LOADING',
      accountSelected: event.target.value
    })
    this.props.requestGetPaymentsByAccount(event.target.value)
  }

  // Modal handlers

  handlePaymentModal() {
    this.setState({openModal: true})
  }

  handleConfirmModal() {
    this.setState({openModal: false})
    this.props.history.push("/pago")
  }

  handleCloseModal() {
    this.setState({openModal: false})
  }

  handleCloseSnackbar() {
    this.setState({openSnack: false})
  }

  renderWithPayments(classes) {
    return (
      <div>
        <Title 
          title={Utils.getMessages().Payments.title}
          description={Utils.getMessages().Payments.description}
        />
        <br />
        <Select
          value={this.state.accountSelected}
          onChange={(event) => {this.changeAccountNumber(event)}}
          className={classes.select}
        >
          {
            this.props.init.user.accounts.data.map((account, idx) => {
              return (<MenuItem key={idx} value={account.account}><strong>{account.account}</strong> - ({account.name})</MenuItem>)
            })
          }
        </Select>

        {
          (this.state.status === 'LOADING') ? 
            <div>
              <Empty
                title={Utils.getMessages().Payments.loadingTitle}
                description={Utils.getMessages().Payments.loadingDescription}
              />
            </div>
          :
            (this.state.status === 'EMPTY') ?
              <div>
                <Empty
                  title={Utils.getMessages().Payments.titleEmpty}
                  description={Utils.getMessages().Payments.descriptionEmpty}
                />
              </div>
            :
            <div>
              <PaymentListTable 
                className={classes.paymentTable}
                payments={this.state.payments}
                cfdi={(payment) => { this.cfdi(payment) }}
              />
              <CFDIModal
                open={this.state.openModal}
                handleClose={() => { this.handleCloseModal() }}
              />
            </div>
        }

        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={this.state.openSnack}
          onClose={this.handleCloseSnackbar}
          message={
            <span>{this.state.message}</span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    )
  }

  render() {
    
    const { classes } = this.props

    if (this.state.showPayments) {
      return this.renderWithPayments(classes)
    }
    else {
      return (
        <div>
          <Empty
            title={Utils.getMessages().Payments.titleEmpty}
            description={Utils.getMessages().Payments.descriptionEmpty}
            emptyImg={emptyImg}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestGetPaymentsByAccount: (accountNumber) => {
      dispatch(requestGetPaymentsByAccount(accountNumber))
    },
    requestAccountsByUser: (userId) => {
      dispatch(requestAccountsByUser(userId))
    }
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Payment)
