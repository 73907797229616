import Utils from '../resources/Utils'
import axios from 'axios'
import jsreport from 'jsreport-browser-client-dist'

export const createAutoPayment = async (data) => {
	try {
		let optionsPost = {
		headers: {
			officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
		},
		method: 'POST',
		url: Utils.constants.HOST_API + "/auto",
		data: JSON.stringify(data)
		}
		const metadata = await Utils.getMetadata()
		optionsPost.headers.metadata = JSON.stringify(metadata)
		const response = await axios(optionsPost)
		return response.data
	} catch (error) {
		return error
	}
}

export const getOfficeConfigAPI = async (officeId) => {
	try {
		let options = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
			}
		}
		const metadata = await Utils.getMetadata()
		options.headers.metadata = JSON.stringify(metadata)
		const response = await axios(Utils.constants.HOST_API + "/offices/" + officeId + "/configs", options)
    return response.data
	} catch (error) {
		return error
	}
}
export const editOfficeConfigAPI = async (officeConfigsData) => {
  try {
    let optionsPut = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'PUT',
      url: Utils.constants.HOST_API + "/offices/"+officeConfigsData.officeId,
      data: JSON.stringify(officeConfigsData)
    }
    const metadata = await Utils.getMetadata()
    optionsPut.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPut)
    return response.data
  } catch (error) {
    return error
  }
}
export const editStatusUserByEmailAPI = async (data) => {
  try {
    let optionsPut = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'PUT',
      url: Utils.constants.HOST_API + "/offices/editstatususer",
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPut.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPut)
    return response.data
  } catch (error) {
    return error
  }
}
export const getPaymentsByOfficeIdAPI = async (data) => {
	try {
		let options = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE).substring(0,localStorage.getItem(Utils.constants.KEY_LS_OFFICE).length).match(/\d+/)
			},
      method: 'POST',
      url: Utils.constants.HOST_API + "/offices/payments",
      data: JSON.stringify(data)
		}
		const metadata = await Utils.getMetadata()
		options.headers.metadata = JSON.stringify(metadata)
		const response = await axios(options)
    return response.data
	} catch (error) {
		return error
	}
}
export const getUsersByOfficeIdAPI = async (data) => {
	try {
		let options = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE).substring(0,localStorage.getItem(Utils.constants.KEY_LS_OFFICE).length).match(/\d+/)
			},
      method: 'POST',
      url: Utils.constants.HOST_API + "/offices/users",
      data: JSON.stringify(data)
		}
		const metadata = await Utils.getMetadata()
		options.headers.metadata = JSON.stringify(metadata)
		const response = await axios(options)
    return response.data
	} catch (error) {
		return error
	}
}
export const getLogsPaymentsUsersByOfficeIdAPI = async (data) => {
	try {
		let options = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE).substring(0,localStorage.getItem(Utils.constants.KEY_LS_OFFICE).length).match(/\d+/)
			},
      method: 'POST',
      url: Utils.constants.HOST_API + "/offices/logspaymentsusers",
      data: JSON.stringify(data)
		}
		const metadata = await Utils.getMetadata()
		options.headers.metadata = JSON.stringify(metadata)
		const response = await axios(options)
    return response.data
	} catch (error) {
		return error
	}
}
export const getAccountsLogsPaymentsUsersByEmailAPI = async (data) => {
	try {
		let options = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE).substring(0,localStorage.getItem(Utils.constants.KEY_LS_OFFICE).length).match(/\d+/)
			},
      method: 'POST',
      url: Utils.constants.HOST_API + "/offices/logspaymentsusers/getaccounts",
      data: JSON.stringify(data)
		}
		const metadata = await Utils.getMetadata()
		options.headers.metadata = JSON.stringify(metadata)
		const response = await axios(options)
    return response.data
	} catch (error) {
		return error
	}
}
export const getDescriptionsLogsPaymentsUsersByAccountAPI = async (data) => {
	try {
		let options = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE).substring(0,localStorage.getItem(Utils.constants.KEY_LS_OFFICE).length).match(/\d+/)
			},
      method: 'POST',
      url: Utils.constants.HOST_API + "/offices/logspaymentsusers/getdescriptions",
      data: JSON.stringify(data)
		}
		const metadata = await Utils.getMetadata()
		options.headers.metadata = JSON.stringify(metadata)
		const response = await axios(options)
    return response.data
	} catch (error) {
		return error
	}
}
export const getAccountNumberAPI = async (accountNumber) => {
	try {
		let options = {
		headers: {
			officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
		}
		}
		const metadata = await Utils.getMetadata()
		options.headers.metadata = JSON.stringify(metadata)
		const response = await axios(Utils.constants.HOST_API + "/accounts/" + accountNumber, options)
		return response.data
	} catch (error) {
		return error
	}
}

export const registerValidTokenAPI = async (token) => {
  try {
    let options = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      }
    }
    const response = await axios(Utils.constants.HOST_EMAIL_API + "/register/" + token + "/valid", options)
    return response.data
  } catch (error) {
    return error
  }
}

export const recoveryValidTokenAPI = async (token) => {
  try {
    let options = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      }
    }
    const response = await axios(Utils.constants.HOST_EMAIL_API + "/recovery/" + token + "/valid", options)
    return response.data
  } catch (error) {
    return error
  }
}

export const createAccountAPI = async (emailData) => {
  try {
    let optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + "/users/register",
      data: JSON.stringify(emailData)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const loginAPI = async (loginData) => {
  try {
    let optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + "/users/login",
      data: JSON.stringify(loginData)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const recoveryPasswordAPI = async (recoveryData) => {
  try {
    let optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'POST',
      url: Utils.constants.HOST_API + "/users/recovery",
      data: JSON.stringify(recoveryData)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const setNewPasswordAPI = async (passwordData) => {
  try {
    let optionsPut = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'PUT',
      url: Utils.constants.HOST_API + "/users/password",
      data: JSON.stringify(passwordData)
    }
    const metadata = await Utils.getMetadata()
    optionsPut.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPut)
    return response.data
  } catch (error) {
    return error
  }
}

export const changePasswordAPI = async (passwordData) => {
  try {
    
    let optionsPut = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'PUT',
      url: Utils.constants.HOST_API + "/users/" + passwordData.userId + "/password",
      data: JSON.stringify(passwordData)
    }

    const metadata = await Utils.getMetadata()
    optionsPut.headers.metadata = JSON.stringify(metadata)

    const response = await axios(optionsPut)
    return response.data
  } catch (error) {
    return error
  }
}

export const getUserProfileAPI = async (userId) => {
  try {
    let options = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      }
    }
    const metadata = await Utils.getMetadata()
    options.headers.metadata = JSON.stringify(metadata)
    const response = await axios(Utils.constants.HOST_API + "/users/" + userId + "/profile", options)
    return response.data
  } catch (error) {
    return error
  }
}

export const updateUserProfileAPI = async (userData) => {
  try {
    let optionsPut = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'PUT',
      url: Utils.constants.HOST_API + "/users/" + userData.userId,
      data: JSON.stringify(userData)
    }
    const metadata = await Utils.getMetadata()
    optionsPut.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPut)
    return response.data
  } catch (error) {
    return error
  }
}

export const deleteUserAccountAPI = async (userData) => {
  try {
    let optionsDelete = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'DELETE',
      url: Utils.constants.HOST_API + "/users/" + userData.userId,
      data: JSON.stringify(userData)
    }
    const metadata = await Utils.getMetadata()
    optionsDelete.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsDelete)
    return response.data
  } catch (error) {
    return error
  }
}

export const getAccountsByUserAPI = async (userId) => {
  try {
    let options = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      }
    }
    const metadata = await Utils.getMetadata()
    options.headers.metadata = JSON.stringify(metadata)
    const response = await axios(Utils.constants.HOST_API + "/users/" + userId + "/accounts", options)
    return response.data
  } catch (error) {
    return error
  }
}

// Cards

export const getCardsByUserAPI = async (userId) => {
  try {
    let options = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      }
    }
    const metadata = await Utils.getMetadata()
    options.headers.metadata = JSON.stringify(metadata)
    const response = await axios(Utils.constants.HOST_API + "/users/" + userId + "/cards", options)
    return response.data
  } catch (error) {
    return error
  }
}

export const sendEmailAPI = async (emailData) => {
  try {
    emailData.officeId = localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
    let optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
        'Content-Type': 'application/json'
      },
      method: 'POST',
      url: Utils.constants.HOST_EMAIL_API + "/email/payments",
      data: JSON.stringify(emailData)
    }
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const saveAccountAPI = async (accountData) => {
  try {
    let optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'POST',
      url: Utils.constants.HOST_API + "/accounts",
      data: JSON.stringify(accountData)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const updateAccountAPI = async (accountData) => {
  try {
    let optionsPut = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'PUT',
      url: Utils.constants.HOST_API + "/accounts/" + accountData.accountNumber,
      data: JSON.stringify(accountData)
    }
    const metadata = await Utils.getMetadata()
    optionsPut.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPut)
    return response.data
  } catch (error) {
    return error
  }
}

export const deleteAccountAPI = async (accountData) => {
  try {
    let optionsDelete = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'DELETE',
      url: Utils.constants.HOST_API + "/accounts/" + accountData.accountNumber,
      data: JSON.stringify(accountData)
    }
    const metadata = await Utils.getMetadata()
    optionsDelete.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsDelete)
    return response.data
  } catch (error) {
    return error
  }
}

// Cards

export const saveCardAPI = async (cardData) => {
  try {
    let optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'POST',
      url: Utils.constants.HOST_API + "/cards",
      data: JSON.stringify(cardData)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const updateCardAPI = async (cardData) => {
  try {
    let optionsPut = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'PUT',
      url: Utils.constants.HOST_API + "/cards/" + cardData.id,
      data: JSON.stringify(cardData)
    }
    const metadata = await Utils.getMetadata()
    optionsPut.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPut)
    return response.data
  } catch (error) {
    return error
  }
}

export const deleteCardAPI = async (cardData) => {
  try {
    let optionsDelete = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'DELETE',
      url: Utils.constants.HOST_API + "/cards/" + cardData.id,
      data: JSON.stringify(cardData)
    }
    const metadata = await Utils.getMetadata()
    optionsDelete.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsDelete)
    return response.data
  } catch (error) {
    return error
  }
}

// Get payments by account

export const getPaymentsByAccountAPI = async (account) => {
  try {
    let options = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      }
    }
    const metadata = await Utils.getMetadata()
    options.headers.metadata = JSON.stringify(metadata)
    const response = await axios(Utils.constants.HOST_API + "/accounts/" + account + "/payments", options)
    return response.data
  } catch (error) {
    return error
  }
}

// Get receipts by account

export const getReceiptsByAccountAPI = async (account) => {
  try {
    let options = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      }
    }
    const metadata = await Utils.getMetadata()
    options.headers.metadata = JSON.stringify(metadata)
    const response = await axios(Utils.constants.HOST_API + "/accounts/" + account + "/receipts", options)
    return response.data
  } catch (error) {
    return error
  }
}

// Scheduled payments

export const getScheduledPaymentsAPI = async (userId) => {
  try {
    let options = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      }
    }
    const metadata = await Utils.getMetadata()
    options.headers.metadata = JSON.stringify(metadata)
    const response = await axios(Utils.constants.HOST_API + "/users/" + userId + "/auto", options)
    return response.data
  } catch (error) {
    return error
  }
}

export const updateScheduledPaymentsAPI = async (scheduledData) => {
  try {
    let optionsPut = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'PUT',
      url: Utils.constants.HOST_API + "/auto/" + scheduledData.id,
      data: JSON.stringify(scheduledData)
    }
    const metadata = await Utils.getMetadata()
    optionsPut.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPut)
    return response.data
  } catch (error) {
    return error
  }
}

export const deleteScheduledPaymentsAPI = async (scheduledData) => {
  try {
    let optionsDelete = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'DELETE',
      url: Utils.constants.HOST_API + "/auto/" + scheduledData.id,
      data: JSON.stringify(scheduledData)
    }
    const metadata = await Utils.getMetadata()
    optionsDelete.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsDelete)
    return response.data
  } catch (error) {
    return error
  }
}

export const savePaymentAPI = async (paymentData) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
      method: 'POST',
      url: Utils.constants.HOST_API + "/payments",
      data: JSON.stringify(paymentData)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const encryptStringBancomer = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
        'Content-Type': 'application/json'
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/payments/Encrypt',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}


export const saveOrdenPagoAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/payments/saveOrdenPago',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const sendMensajeToConnfirmPhoneAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
        'Content-Type': 'application/json'
      },
      method: 'POST',
      url: Utils.constants.HOST_EMAIL_API + '/sms/enviar',
      data: JSON.stringify(data)
    }
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const confirmCodeAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
        'Content-Type': 'application/json'
      },
      method: 'POST',
      url: Utils.constants.HOST_EMAIL_API + '/sms/validarCodigo',
      data: JSON.stringify(data)
    }
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const guardarPersonaAPIAPI = async (data) => {
	try { 
      let url = ''
      if(data.personas.personaId > 0){
        url = '/persona/actualizarPersona'
      } else {
        url = '/persona/guardarPersona'
      }

			const optionsPost = {
        headers: {
          officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        url: Utils.constants.HOST_EMAIL_API + url,
        data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const guardarActivarInactivarPersonaAPI = async (data) => {
	try { 
			const optionsPost = {
        headers: {
          officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        url: Utils.constants.HOST_EMAIL_API + '/persona/activarInactivarPersona',
        data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const obtenertipoTramiteAPI = async (data) => {
	try { 
			const optionsPost = {
        headers: {
          officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
          'Content-Type': 'application/json'
        },
        method: 'GET',
        url: Utils.constants.HOST_EMAIL_API + '/tramites/obtenertipoTramite',
        data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const guardarTramiteAPI = async (data) => {
	try { 
			const optionsPost = {
        headers: {
          officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        url: Utils.constants.HOST_EMAIL_API + '/tramites/guardarTramite',
        data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const guardarPersonaDireccionTramiteAPI = async (data) => {
	try { 
			const optionsPost = {
        headers: {
          officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        url: Utils.constants.HOST_EMAIL_API + '/persona/guardarPersonaDireccionTramite',
        data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const obtenerTramitesPorSuscriptorAPI = async (data) => {
	try { 
			const optionsPost = {
        headers: {
          officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        url: Utils.constants.HOST_EMAIL_API + '/tramites/ListarTramitesPorSuscriptor',
        data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const actualizarImagenUsuarioAPI = async (data) => {
	try {

			const optionsPost = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
        'Content-Type': 'multipart/form-data'
			},
			method: 'POST',
			url: Utils.constants.HOST_EMAIL_API + '/archivos/actualizarImagenPersona',
      data:  data
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const guardarImagenUsuarioAPI = async (data) => {
	try {
			const optionsPost = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
        'Content-Type': 'multipart/form-data'
			},
			method: 'POST',
			url: Utils.constants.HOST_EMAIL_API + '/archivos/guardarImagenPersona',
			data:  data
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const guardarImagenDireccionAPI = async (data) => {
	try {
			const optionsPost = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
        'Content-Type': 'multipart/form-data'
			},
			method: 'POST',
			url: Utils.constants.HOST_EMAIL_API + '/archivos/guardarImagenDireccion',
			data:  data
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const getImagenesUsuarioAPI = async (data) => {
	try {
			const optionsPost = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
				'Content-Type': 'application/json'
			},
			method: 'POST',
			url: Utils.constants.HOST_EMAIL_API + '/archivos/imagenesPersonas',
			data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const getTipoImagenesAPI = async (data) => {
	try {
			const optionsPost = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
				'Content-Type': 'application/json'
			},
			method: 'GET',
			url: Utils.constants.HOST_EMAIL_API + '/archivos/tipoImagenPersona',
			data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const getUsuarioDireccionAPI = async (data) => {
	try {
			const optionsPost = {
			headers: {
				officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
				'Content-Type': 'application/json'
			},
			method: 'POST',
			url: Utils.constants.HOST_EMAIL_API + '/persona/obtenertPersonasPorSucucriptor',
			data: JSON.stringify(data)
			}
			const response = await axios(optionsPost)
			return response.data
	} catch (error) {
		return error
	}
}

export const getContribuyentePorCuentaAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/users/findContribuyente',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const getCfdiXmlAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/users/obtenerXML',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}


export const getCfdiPdfAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/users/obtenerPDF',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const sendCorreoCFDIAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/users/enviarCorreoCFDI',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const getConsultaCompletaAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/users/findAccount',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const timbrarAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/users/timbrarPagoContribuyente',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const saveIntentoPagoAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
      },
      method: 'POST',
      url: Utils.constants.HOST_API + '/payments/saveIntengoPago',
      data: JSON.stringify(data)
    }
    const metadata = await Utils.getMetadata()
    optionsPost.headers.metadata = JSON.stringify(metadata)
    const response = await axios(optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const getReportComprobantePagoAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
    }
    const response = await axios.get(Utils.constants.HOST_EMAIL_API + '/reporteCuenta/'+data.cuenta+'/comrprobantePago/'+ data.payment.comprobantePagoID,optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const getEstadoCuentaAPI = async (data) => {
  try {
    const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
    }
    const response = await axios.post(Utils.constants.HOST_API + '/users/estadoCuenta',data ,optionsPost)
    return response.data
  } catch (error) {
    return error
  }
}

export const getGirosAPI = async (data) => {
	try {
	  const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE)
      },
	  }
	  const response = await axios.post(Utils.constants.HOST_API + '/users/obtenerGiros', data || {} ,optionsPost)
	  return response.data
	} catch (error) {
	  return error
	}
}

export const getLogsPaymentsAPI = async (data) => {
	try {
	  const optionsPost = {
      headers: {
        officeId: localStorage.getItem(Utils.constants.KEY_LS_OFFICE),
        //'Content-Type': 'application/json'
      },
	  }
	  const response = await axios.post(Utils.constants.HOST_EMAIL_API + '/obtenerLogs', data || {} ,optionsPost)
	  return response.data
	} catch (error) {
	  return error
	}
}

//REPORTES
export const getReportAPI = async (action) => {
  try {
    
    var Authorization = "Basic d3JwUmVwb3J0ZXM6OTBZOUI4eWgk"
    /*const dataAuthorization = await axios.get(Utils.constants.HOST_BACKDOOR_TARIFAS + "/ResultadoSimulacion/ObtenerAutorizacionReportes", {"headers": { "token":'{"UsuarioID":818,"FechaCaducidad":"2050-01-29T00:08:19.2684501-07:00","FechaInicio":"2021-01-28T12:08:19.2684501-07:00","Tipo":1,"SucursalID":1,"NombreCompleto":"1  desarrollo 1","NombrePerfil":"ADMINISTRADOR","sistemaDefaultID":16,"NombreSucursal":"OOMSAPAS LA PAZ","TokenID":"","SucursalActual":"","SistemaActual":"","FuncionOrigen":"","FuncionDestino":"","PerfilID":""}' }})
    if(dataAuthorization.status === 200){
      if(dataAuthorization.data){
        if(dataAuthorization.data.isValid){
          if(dataAuthorization.data.data){
            Authorization = dataAuthorization.data.data
          }
        }
      }
    }*/

    var data = {
        template: { 'shortid': action.shortid },
        options: { preview: true },
        data: JSON.stringify(action.data)
    }

    let options = {
      headers: {
        Authorization: Authorization,
      },
      json: data,
      method: "POST",
    }
    
    var headers= {
        Authorization: Authorization
    }
    
    jsreport.serverUrl = Utils.constants.HOST_REPORTES
    jsreport.headers = headers
    jsreport.options = options

    const result = await jsreport.renderAsync(data)
    
    return result
  
  } catch (error) {
    return error
  }
}