import React from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

import "@reach/combobox/styles.css";
import mapStyles from "./mapStyles";
import CustomCombo from "./CustomCombo";
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import { IconButton } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const libraries = ["places"];

const mapContainerStyle = {
  height: window.innerHeight / 20 + "vh",
  width: "100%",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: false,
  zoomControl: true,
};

const apikey = "AIzaSyC3qxEZWuBnlciUGmu-_CbzlaD2Xx_HKZY"
export default function Map(props) {  

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apikey,
        libraries,
    });

    const [center, setCenter] = React.useState(null);
    const [currentMarkerClicked, setCurrentMarkerClicked] = React.useState(null);

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);
    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(18);
    }, []);

    if (loadError) return "No se pudo cargar el mapa.. intentelo más tarde.";
    if (!isLoaded) return "Cargando datos del mapa espere por favor...";
    
    const getAddressFromCoordinates = (lat, lng) => {
        return new Promise((resolve) => {
            const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + lat + ',' + lng + '&key=' + apikey + '&radius=' + 100 * 4000
            fetch(url)
            .then(res => res.json())
            .then((resJson) => {
                resolve(resJson)
            })
            .catch((e) => {
                console.log('Error in getAddressFromCoordinates', e)
                resolve()
            })
        })
    }
    
    return (

        <div>

            <div className="contenedorBuscador">    
                <Search panTo={panTo} />
            </div> 
            <GoogleMap
                id="map"
                mapContainerStyle={mapContainerStyle}
                zoom={props.zoom}
                center={props.center}
                options={options}        
                onLoad={onMapLoad}
                onClick={ async (e) => {
                    const { lat, lng } = e.latLng
                    
                    let stateDataMap = props.stateDataMap
                    const d = {
                        lat: lat(), 
                        lng: lng(), 
                        size: {
                            x: 24,
                            y: 24
                        }, 
                        icon: '/1.png'
                    }
                    
                    try {
                        const results = await getAddressFromCoordinates(d.lat,d.lng);
                        if(results.status === "OK"){
                            setCurrentMarkerClicked(d)
                            
                            stateDataMap.currentMarkerClicked = d
                            stateDataMap.results = results.results

                            props.handleSetState()
                        } else {
                            console.error(results)
                        }
                       
                    } catch (error) {
                        console.error("😱 Error: ", error);
                    }

                } }
            >
                
                {
                    props.stateDataMap.currentMarkerClicked ?
                    <Marker
                        position={{ lat: props.stateDataMap.currentMarkerClicked.lat, lng: props.stateDataMap.currentMarkerClicked.lng }}
                        icon={{
                            url: props.stateDataMap.currentMarkerClicked.icon,
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(5, 5),
                            scaledSize: new window.google.maps.Size(props.stateDataMap.currentMarkerClicked.size.x, props.stateDataMap.currentMarkerClicked.size.y),
                        }}
                    />
                    : null
                }

                {props.dataMarkers.map((marker, i) => (
                    <Marker
                        key={i}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        icon={{
                            url: marker.icon,
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(5, 5),
                            scaledSize: new window.google.maps.Size(marker.size.x, marker.size.y),
                        }}
                    />
                ))}
            </GoogleMap>

        </div>
    );
}
  
  function Search({ panTo }) {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 43.6532, lng: () => -79.3832 },
            radius: 100,
        },
    })

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  
    const handleInput = (text) => {
        setValue(text);
    }
  
    const handleSelect = async (selected) => {
        
        if(selected){
            setValue(selected.label, false);
            clearSuggestions();
        
            try {
                const results = await getGeocode({ address: selected.label });
                console.log(results);
                const { lat, lng } = await getLatLng(results[0]);
                panTo({ lat, lng });
            } catch (error) {
                console.log("😱 Error: ", error);
            }
        }
       
    }
    return (
        <div className="search">
            <div style={{width:"100%"}}>
            <CustomCombo
                isMulti={ false }
                value={ value }
                options={ 
                    data.map( (v) => { return { value: v.place_id, label: v.description, color:"black", data: v } } )
                } 
                placeholder={"Busca tu ubicación"}
                onChange={handleSelect}
                onInputChange={handleInput}
            />
            </div>
            

            <IconButton
                className="locate"
                title={"Centrar mapa a ubicación actual"}
                style={{backgroundColor:"white", marginLeft:"15px"}}
                onClick={() => {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            panTo({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            });
                        },
                        () => null
                    );

                }}
                >
            <CenterFocusStrongIcon style={{color:blue[500]}}></CenterFocusStrongIcon>
        </IconButton>
        </div>
    );
  }
  