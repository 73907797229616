import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import { 
    Button, 
    Typography, 
    StepLabel, 
    Step, 
    Stepper 
} from '@material-ui/core';
import Utils from '../resources/Utils';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import DoneIcon from '@material-ui/icons/Done';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    actions: {
		textAlign: 'end',
        marginTop:theme.spacing(2),
	},
});

class StepperCustom extends Component {

    constructor(props){
        super(props)
      
        this.state = {
            stepperState: this.props.stepperState,
            skipped: new Set(),
            steps: this.props.pasos || ['Necesitas crear passos']
        }
        
        this.isStepSkipped = this.isStepSkipped.bind(this)
        this.isStepOptional = this.isStepOptional.bind(this)
        this.handleSkip = this.handleSkip.bind(this)
        this.setActiveStep = this.setActiveStep.bind(this)
        this.setSkipped = this.setSkipped.bind(this)
        this.handleBack = this.handleBack.bind(this)
        
    }


    isStepOptional = (step) => {
        return step === 9999;
    }
    
    isStepSkipped = (step) => {
        const { skipped } = this.state

        return skipped.has(step);
    }
    
    setActiveStep = (val) => {
        let { stepperState } = this.state
        stepperState.activeStep = val
        this.setState({ stepperState: stepperState })
    }

    setSkipped = (val) => {
        this.setState({ skipped: val })
    }

    handleNext = () => {
        const { skipped, stepperState, steps } = this.state
        const { activeStep } = stepperState
        if(this.props.handleNext){   
            //Check if is form valid or something is valid
            if(this.props.handleNext({
                isLast: (activeStep + 1 === steps.length)
            })){
               
                let newSkipped = skipped;
                if (this.isStepSkipped(activeStep)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStep);
                }
                
                this.setActiveStep(activeStep + 1);
                this.setSkipped(newSkipped);
            } 
        }
        Utils.scrollTop()
    };
    
    handleBack = () => {
        const { stepperState } = this.state
        const { activeStep } = stepperState
        this.setActiveStep(activeStep - 1);

        Utils.scrollTop()
    };
    
    handleSkip = () => {
        const {  stepperState, skipped } = this.state
        const { activeStep } = stepperState

        if (!this.isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        this.setActiveStep(activeStep + 1);
      
        const newSkipped = new Set(skipped.values())
        newSkipped.add(activeStep)

        this.setSkipped(newSkipped)

        Utils.scrollTop()
    }

    handleReset = () => {
        this.setActiveStep(0);
    }

    render() {

        const { classes } = this.props
        const { stepperState, steps  } = this.state
        const { activeStep } = stepperState

		return (
            <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (this.isStepOptional(index)) {
                            labelProps.optional = <Typography variant="caption">Opcional</Typography>;
                        }
                        if (this.isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                        );
                    })}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                { this.props.contenidoFinal }
                            </Typography>
                            <Button  size="small" onClick={this.handleReset} className={classes.button}>
                                { this.props.reiniciarText || 'Reiniciar' }
                            </Button>
                        </div>
                    ) : (
                        <div>
                            { this.props.getContenido(activeStep) }
                            <div className={classes.actions}>
                                <Button 
                                endIcon={<NavigateBeforeIcon></NavigateBeforeIcon>}
                                size="small" disabled={activeStep === 0} onClick={this.handleBack} className={classes.button}>
                                    Regresar
                                </Button>
                                    {this.isStepOptional(activeStep) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSkip}
                                    className={classes.button}
                                    size="small"
                                >
                                    Saltar
                                </Button>
                                )}
                
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleNext}
                                    className={classes.button}
                                    size="small"
                                    startIcon={ activeStep === steps.length - 1 ? <DoneIcon></DoneIcon> : <NavigateNextIcon></NavigateNextIcon>}
                                    >
                                    {activeStep === steps.length - 1 ?  this.props.finalizarText || 'Finalizar'  : 'Avanzar'}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
          </div>
		)
	}
}



const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = () => {
  return {

  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(StepperCustom)

